import { useI18nMessage } from "@lookiero/i18n-react";
import { FC, ReactNode, useMemo } from "react";
import { useFirstMountState } from "react-use";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../../i18n/SelectionI18n";
import Color from "../../../../../../projection/color/model/Color";
import Tooltip from "../../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import "./product-preview-color.css";

type ColorOptionTooltipProps = {
  readonly psColor: Color;
  readonly clientColor: Color;
  readonly children: ReactNode;
};
const ColorOptionTooltip: FC<ColorOptionTooltipProps> = ({
  psColor,
  clientColor,
  children,
}: ColorOptionTooltipProps) => {
  const psColorName = useI18nMessage({ id: psColor.translationKey });
  const clientColorName = useI18nMessage({ id: clientColor.translationKey });

  const tooltipTitle = useI18nMessage({
    prefix: SELECTION_I18N_PREFIX,
    id: SelectionI18n.COLOR_CHANGED,
    values: {
      psColor: psColor.code ? `${psColorName} - ${psColor.code}` : psColorName,
      clientColor: clientColor.code ? `${clientColorName} - ${clientColor.code}` : clientColorName,
    },
  });

  return <Tooltip title={tooltipTitle}>{children}</Tooltip>;
};

type ProductPreviewColorProps = {
  readonly psColor: Color;
  readonly clientColor: Color;
};
const ProductPreviewColor: FC<ProductPreviewColorProps> = ({ psColor, clientColor }: ProductPreviewColorProps) => {
  const colorStyle = useMemo(() => ({ backgroundColor: `#${psColor.hexadecimal}` }), [psColor.hexadecimal]);

  const isFirstMount = useFirstMountState();
  const content = (
    <>
      <div className="product-preview-color__color" style={colorStyle} />
      <svg className="product-preview-color__icon" preserveAspectRatio="none" viewBox="0 0 100 100">
        <line vectorEffect="non-scaling-stroke" x1="10" x2="90" y1="10" y2="90" />
      </svg>
    </>
  );

  return (
    <div aria-label={psColor.code} className="product-preview-color">
      {isFirstMount ? (
        content
      ) : (
        <ColorOptionTooltip clientColor={clientColor} psColor={psColor}>
          {content}
        </ColorOptionTooltip>
      )}
    </div>
  );
};

export { ProductPreviewColor };
