import { FC } from "react";
import Card from "../../../../../../shared/ui/uiKit/components/layouts/card/Card";
import Checkbox, { CheckboxState } from "../../../../../../shared/ui/uiKit/components/molecules/checkbox/Checkbox";
import Skeleton from "../../../../../../shared/ui/uiKit/components/atoms/Skeleton/Skeleton";
import "./checkout.css";
import AspectRatioContainer from "../../../../../../shared/ui/uiKit/components/layouts/aspectRatioContainer/AspectRatioContainer";
import AspectRatio from "../../../../../../shared/ui/uiKit/_common/AspectRatio";

const CheckoutSkeleton: FC = () => (
  <Card className="checkout checkout--skeleton">
    {/* eslint-disable-next-line react/jsx-no-bind */}
    <Checkbox className="checkout__checkbox" state={CheckboxState.DEFAULT} onChange={() => void 0} />
    <div className="checkout__content">
      <div className="checkout__customer">
        <Skeleton className="customer-image" />
        <section className="checkout__info">
          <div className="checkout__header">
            <div className="checkout__header-row">
              <Skeleton />
            </div>
          </div>
          <div className="checkout__comment-container">
            <Skeleton />
          </div>
        </section>
      </div>
      <div className="checkout__items">
        <AspectRatioContainer aspectRatio={AspectRatio.R_4_5} className="checkout-product-variant">
          <Skeleton />
        </AspectRatioContainer>
        <AspectRatioContainer aspectRatio={AspectRatio.R_4_5} className="checkout-product-variant">
          <Skeleton />
        </AspectRatioContainer>
        <AspectRatioContainer aspectRatio={AspectRatio.R_4_5} className="checkout-product-variant">
          <Skeleton />
        </AspectRatioContainer>
        <AspectRatioContainer aspectRatio={AspectRatio.R_4_5} className="checkout-product-variant">
          <Skeleton />
        </AspectRatioContainer>
        <AspectRatioContainer aspectRatio={AspectRatio.R_4_5} className="checkout-product-variant">
          <Skeleton />
        </AspectRatioContainer>
      </div>
    </div>
  </Card>
);

export { CheckoutSkeleton };
