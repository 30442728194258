import { FC } from "react";
import Skeleton from "../../../../../../shared/ui/uiKit/components/atoms/Skeleton/Skeleton";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import "./note-template.css";

const NoteTemplateSkeleton: FC = () => (
  <article aria-label="note-template-skeleton" className="note-template note-template--skeleton" role="listitem">
    <header aria-label="note-template-header" className="note-template__header">
      <div className="note-template__header-info">
        <Text className="note-template__header-title" variant={TextVariant.BODY_BOLD}>
          <Skeleton />
        </Text>
        <Text className="note-template__header-category">
          <Skeleton />
        </Text>
      </div>
    </header>
    <div aria-label="note-template-content" className="note-template__content" role="main">
      <Text>
        <Skeleton />
      </Text>
    </div>
  </article>
);

export default NoteTemplateSkeleton;
