import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import invariant from "ts-invariant";
import { unmarkProductAsFavourite as unmarkProductAsFavouriteCommand } from "../../../../domain/favourite/command/unmarkProductAsFavourite";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";

interface UnmarkProductAsFavouriteFunction {
  (): Promise<void> | undefined;
}

type UseUnmarkProductAsFavouriteResult = [unmark: UnmarkProductAsFavouriteFunction, status: CommandStatus];

interface UseUnmarkProductAsFavouriteArgs {
  readonly favouriteId?: string;
}

interface UseUnmarkProductAsFavourite {
  (args: UseUnmarkProductAsFavouriteArgs): UseUnmarkProductAsFavouriteResult;
}

const useUnmarkProductAsFavourite: UseUnmarkProductAsFavourite = ({ favouriteId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const unmarkProductAsFavourite = useCallback(async () => {
    try {
      invariant(favouriteId !== undefined, "There is no favourite_id defined to unmark product as favourite");

      await commandBus(unmarkProductAsFavouriteCommand({ aggregateId: favouriteId }));
    } catch (error) {}
  }, [commandBus, favouriteId]);

  return [unmarkProductAsFavourite, status];
};

export { useUnmarkProductAsFavourite };
