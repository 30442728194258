import { CheckoutProjection } from "../../../projection/checkout/checkout";

interface CheckoutProjectionDto extends Omit<CheckoutProjection, "plannedFor"> {
  readonly plannedFor: string;
}

interface ToCheckoutProjectionFunctionArgs {
  readonly checkout: CheckoutProjectionDto;
}

interface ToCheckoutProjectionFunction {
  (args: ToCheckoutProjectionFunctionArgs): CheckoutProjection;
}

const toCheckoutProjection: ToCheckoutProjectionFunction = ({ checkout }) => ({
  ...checkout,
  plannedFor: new Date(checkout.plannedFor),
});

export type { CheckoutProjectionDto };
export { toCheckoutProjection };
