import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { submitStockSurvey } from "../../../../domain/stockSurvey/command/submitStockSurvey";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { v4 as uuid } from "uuid";

interface SubmitStockSurveyFunction {
  (): Promise<void>;
}

type UseSubmitStockSurveyResult = [submit: SubmitStockSurveyFunction, status: CommandStatus];

interface UseSubmitStockSurveyFunctionArgs {
  readonly boxId: string;
}

interface UseSubmitStockSurveyFunction {
  (args: UseSubmitStockSurveyFunctionArgs): UseSubmitStockSurveyResult;
}

const useSubmitStockSurvey: UseSubmitStockSurveyFunction = ({ boxId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const submit: SubmitStockSurveyFunction = useCallback(async () => {
    try {
      await commandBus(
        submitStockSurvey({
          aggregateId: uuid(),
          boxId,
        }),
      );
    } catch (error) {}
  }, [boxId, commandBus]);

  return [submit, status];
};

export { useSubmitStockSurvey };
