import { FC, ReactElement, useRef } from "react";
import { useMeasure } from "react-use";
import { useSpring, animated } from "react-spring";
import "./collapsible.css";

const SPRING_CONFIG = {
  tension: 210,
  friction: 26,
};

interface CollapsibleProps {
  readonly opened: boolean;
  readonly children: ReactElement;
}

const Collapsible: FC<CollapsibleProps> = ({ children, opened }: CollapsibleProps) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const animate = useRef(false);
  const previouslyOpened = useRef(opened);
  if (previouslyOpened.current !== opened) {
    previouslyOpened.current = opened;
    animate.current = true;
  }

  const props = useSpring({
    height: opened ? height : 0,
    immediate: !animate.current,
    config: SPRING_CONFIG,
  });

  return (
    <animated.div className="collapsible" style={props}>
      <div ref={ref}>{children}</div>
    </animated.div>
  );
};

export default Collapsible;
