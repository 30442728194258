import { I18nMessage } from "@lookiero/i18n-react";
import classNames from "classnames";
import { FC } from "react";
import Button from "../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/components/atoms/text/Text";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../i18n/SelectionI18n";
import Alert from "../../molecules/alert/Alert";
import { AlertProjection } from "../../../../../projection/alert/alert";
import "./alerts-popup.css";

type AlertsPopupProps = {
  readonly alerts?: AlertProjection[];
  readonly visible: boolean;
  readonly label?: string;
  readonly onClose?: () => void;
};
const AlertsPopup: FC<AlertsPopupProps> = ({ alerts, visible, label, onClose }: AlertsPopupProps) => {
  return (
    <section
      aria-label={label}
      className={classNames("alerts-popup", { "alerts-popup--visible": visible })}
      role="alert"
    >
      <header className="alerts-popup__header">
        <Text variant={TextVariant.BODY_BOLD}>
          <I18nMessage id={SelectionI18n.ALERTS_TITLE} prefix={SELECTION_I18N_PREFIX} />
        </Text>
        <Button aria-label="close-alerts" icon={IconVariant.CLOSE} onClick={onClose} />
      </header>
      <ul className="alerts-popup__list">
        {alerts?.map((alert) => (
          <Alert key={alert.key} alert={alert} />
        ))}
      </ul>
    </section>
  );
};

export default AlertsPopup;
