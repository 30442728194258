import { useI18nMessage } from "@lookiero/i18n-react";
import { Mark } from "@material-ui/core";
import { useCallback, useMemo } from "react";
import Slider from "../../../../../../../../shared/ui/uiKit/components/organisms/slider/Slider";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import classNames from "classnames";
import { findBound, StateFilteringValue } from "../../../ShortlistFilterState";
import {
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../../../../../projection/shortlistFilter/shortlistFilter";
import "./shortlist-filter-slider.css";

const marksForFilters = (filters: ShortlistFilterProjection[]): Mark[] =>
  filters
    .filter((childFilter) => childFilter.type === ShortlistFilterType.RANGE)
    .map((childFilter) => ({ value: Number(childFilter.metadata?.alias) }));

const filterForValue = (filters: ShortlistFilterProjection[]) => (value: number) =>
  filters
    .filter((childFilter) => childFilter.type === ShortlistFilterType.RANGE)
    .find((childFilter) => childFilter.metadata?.alias === `${value}`) as ShortlistFilterProjection;

const stateForValue =
  (filter: ShortlistFilterProjection) =>
  (state: StateFilteringValue[], [lowerValue, upperValue]: [number, number]): StateFilteringValue[] => {
    const cleanState = state.filter(
      ({ id }) =>
        !filter.children?.find((childFilter) => childFilter.children?.find(({ id: filterId }) => filterId === id)),
    );
    const lowerBoundId = filterForValue(filter.children || [])(lowerValue).children?.find(
      ({ type }) => type === ShortlistFilterType.RANGE_LOWER_BOUND,
    )?.id;
    const upperBoundId = filterForValue(filter.children || [])(upperValue).children?.find(
      ({ type }) => type === ShortlistFilterType.RANGE_UPPER_BOUND,
    )?.id;

    return [
      ...cleanState,
      ...[...(lowerBoundId ? [lowerBoundId] : []), ...(upperBoundId ? [upperBoundId] : [])].map((id) => ({ id })),
    ];
  };

const valueForState =
  (filter: ShortlistFilterProjection) =>
  (state: StateFilteringValue[], marks: Mark[]): [number, number] => {
    let value = [marks[0]?.value || 0, marks[marks.length - 1]?.value || 0];

    if (state.length > 0 && filter.children) {
      const lowerBoundFilter = findBound({
        filters: filter.children,
        state,
        boundType: ShortlistFilterType.RANGE_LOWER_BOUND,
      });
      const upperBoundFilter = findBound({
        filters: filter.children,
        state,
        boundType: ShortlistFilterType.RANGE_UPPER_BOUND,
      });
      const stateValue = [
        ...(lowerBoundFilter ? [lowerBoundFilter] : []),
        ...(upperBoundFilter ? [upperBoundFilter] : []),
      ].map((childFilter) => Number(childFilter?.metadata?.alias || 0));

      if (stateValue.length === 2) {
        value = stateValue;
      }
    }

    return value as [number, number];
  };

const ShortlistFilterSlider: ShortlistFilterItemHOC = ({ variant, useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function ShortlistFilterSliderComponent({ filter }) {
    const { state, onChange } = useShortlistFiltering();
    const stateForValueWithFilters = useMemo(() => stateForValue(filter), [filter]);
    const valueForStateWithFilters = useMemo(() => valueForState(filter), [filter]);
    const handleOnChanged = useCallback(
      (value: [number, number]) => onChange({ state: stateForValueWithFilters(state, value) }),
      [onChange, state, stateForValueWithFilters],
    );
    const marks = useMemo(
      () => marksForFilters(filter.children || ([] as ShortlistFilterProjection[])),
      [filter.children],
    );
    const value = useMemo(() => valueForStateWithFilters(state, marks), [marks, state, valueForStateWithFilters]);

    const filterForValueWithFilters = useMemo(
      () => filterForValue(filter.children || ([] as ShortlistFilterProjection[])),
      [filter.children],
    );
    const translate = useI18nMessage;
    const valueLabelFormat = useCallback(
      (value: number) => {
        const filter = filterForValueWithFilters(value);

        return translate({ id: `${filter.translationKey}` });
      },
      [filterForValueWithFilters, translate],
    );

    return (
      <Slider
        className={classNames("shortlist-filter-slider", `shortlist-filter-slider--${variant}`)}
        marks={marks}
        value={value}
        valueLabelFormat={valueLabelFormat}
        onChange={handleOnChanged}
      />
    );
  };

export { ShortlistFilterSlider };
