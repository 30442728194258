import { FC } from "react";
import { LookAndLike } from "./LookAndLike";
import Modal from "../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import { TrackingPage } from "../../../../../tracking/Tracker";
import "./look-and-like-modal.css";

interface LooAndLikeModalProps {
  readonly boxId: string;
  readonly origin: TrackingPage;
  readonly customerId: string;
  readonly visible: boolean;
  readonly familyIds?: string[];
  readonly onCancel: () => void;
}

const LookAndLikeModal: FC<LooAndLikeModalProps> = ({ customerId, visible, familyIds, origin, boxId, onCancel }) => (
  <Modal className="look-and-like-modal" label="look-and-like-modal" visible={visible} onHide={onCancel}>
    <LookAndLike boxId={boxId} customerId={customerId} familyIds={familyIds} origin={origin} />
  </Modal>
);

export { LookAndLikeModal };
