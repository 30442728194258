import { useCallback, useState, ReactNode, useMemo, useRef, useEffect } from "react";
import { animated, useSpring, useTransition } from "react-spring";
import { UseMeasureRect } from "react-use/lib/useMeasure";
import { Target, useDrag, useScroll } from "@use-gesture/react";
import { isEqual, throttle } from "lodash";
import Measured from "../measured/Measured";
import MeasuredSize from "../measured/MeasuredSize";
import { build as buildLayout, indexForCoordinates } from "./SortableGridLayout";
import Layout from "./Layout";
import SortableGridItem from "./SortableGridItem";
import "./sortable-grid.css";

const SPRING_CONFIG = {
  tension: 210,
  friction: 26,
};

const isEqualData = <T,>(itemsA: T[], itemsB: T[]) =>
  itemsA.length === itemsB.length && itemsA.every((item, index) => isEqual(item, itemsB[index]));

const emptyLayoutForData = <T extends { readonly id: DataId }>(data: T[]) =>
  data.reduce((acc, item) => ({ ...acc, [item.id]: { x: 0, y: 0 } }), {});

const move = <T,>(items: T[], indexA: number, indexB: number): T[] => {
  const clonedItems = items.filter((item, index) => index !== indexA);
  clonedItems.splice(indexB, 0, items[indexA]);

  return clonedItems;
};

type DataId = string;

type DraggedItem = {
  readonly id: DataId;
  readonly active: boolean;
  readonly index: number;
  readonly deltaX: number;
  readonly deltaY: number;
};

type UpdateParameters<T> = {
  readonly id: DataId;
  readonly active: boolean;
  readonly data: T[];
  readonly updatedData: T[];
};

type SortableGridProps<T> = {
  readonly data: T[];
  readonly children: (item: T, index: number) => ReactNode;
  readonly itemsPerRow: (width: number) => number;
  readonly onChanged: (data: T[], id: DataId) => void;
  readonly scrollTarget?: Target;
};
const SortableGrid = <T extends { readonly id: DataId }>({
  data,
  children,
  itemsPerRow,
  onChanged,
  scrollTarget = window,
}: SortableGridProps<T>): JSX.Element => {
  const onChangedRef = useRef(onChanged);
  onChangedRef.current = onChanged;

  const emptyLayoutForCurrentData = useMemo(() => emptyLayoutForData(data), [data]);
  const [layout, setLayout] = useState<Layout<DataId>>(emptyLayoutForCurrentData);
  const [tempLayout, setTempLayout] = useState<Layout<DataId>>(layout);
  const [gridWidth, setGridWidth] = useState(0);
  const [gridHeight, setGridHeight] = useState<number>();
  const [itemSizes, setItemSizes] = useState<Record<DataId, MeasuredSize | undefined>>(
    data.reduce((acc, item) => ({ ...acc, [item.id]: undefined }), {}),
  );
  const itemsPerRowForWidth = itemsPerRow(gridWidth);
  const rowsForDataAndWidth = Math.floor(data.length / itemsPerRowForWidth);
  const itemWidth = gridWidth / itemsPerRowForWidth;
  const itemSizesValues = Object.values(itemSizes);
  const itemHeight =
    itemSizesValues.reduce((acc, itemSize) => acc + (itemSize?.height || 0), 0) / itemSizesValues.length;

  const handleOnGridMeasured = useCallback(({ width }: UseMeasureRect) => setGridWidth(width), []);
  const handleOnItemMeasured = useCallback(
    (id: DataId, { width, height }: MeasuredSize) =>
      setItemSizes((prevSizes) => ({ ...prevSizes, [id]: { width, height } })),
    [],
  );

  useEffect(() => {
    if (!itemWidth) {
      return;
    }

    const { layout: updatedLayout, height } = buildLayout({ data, itemsPerRowForWidth, itemSizes, itemWidth });

    setLayout(updatedLayout);
    setTempLayout(updatedLayout);
    setGridHeight(height);
  }, [data, itemSizes, itemWidth, itemsPerRowForWidth]);

  const layoutRef = useRef<Layout<string>>(layout);
  layoutRef.current = layout;
  const updatedDataRef = useRef<T[]>();
  const [draggedItem, setDraggedItem] = useState<DraggedItem>();
  const [scrollY, setScrollY] = useState<number>();
  const initialScrollYRef = useRef<number>();

  const update = useCallback(
    ({ id, active, data, updatedData }: UpdateParameters<T>) => {
      if (active) {
        if (isEqual(updatedDataRef.current, updatedData)) {
          return;
        }

        updatedDataRef.current = updatedData;

        const { layout: updatedLayout, height } = buildLayout({
          data: updatedData,
          itemsPerRowForWidth,
          itemSizes,
          itemWidth,
        });

        setTempLayout(updatedLayout);
        setGridHeight(height);
      } else {
        if (isEqual(data, updatedData)) {
          return;
        }

        onChangedRef.current(updatedData, id);
      }
    },
    [itemSizes, itemWidth, itemsPerRowForWidth],
  );

  const handleScroll = useMemo(
    () =>
      throttle(({ xy: [, y] }) => {
        setScrollY(y);

        if (!draggedItem) {
          return;
        }

        const { id, active, index, deltaX, deltaY } = draggedItem;
        const scrollDeltaY = (scrollY || 0) - (initialScrollYRef.current || 0);

        if (!layoutRef.current[id]) {
          return;
        }

        const updatedData = move(
          data,
          index,
          indexForCoordinates({
            x: layoutRef.current[id].x + deltaX,
            y: layoutRef.current[id].y + deltaY + scrollDeltaY,
            itemWidth,
            itemHeight,
            itemsPerRowForWidth,
            rowsForDataAndWidth,
          }),
        );

        update({ id, active, data, updatedData });
      }, 16),
    [data, draggedItem, itemHeight, itemWidth, itemsPerRowForWidth, rowsForDataAndWidth, scrollY, update],
  );
  useScroll(handleScroll, { target: scrollTarget });

  const handleDrag = useMemo(
    () =>
      throttle(({ args: [id, index], active, movement: [deltaX, deltaY], tap }) => {
        if (tap) {
          return;
        }

        if (active && !initialScrollYRef.current) {
          initialScrollYRef.current = scrollY;
        }

        const scrollDeltaY = (scrollY || 0) - (initialScrollYRef.current || 0);

        const updatedData = move(
          data,
          index,
          indexForCoordinates({
            x: layoutRef.current[id].x + deltaX,
            y: layoutRef.current[id].y + deltaY + scrollDeltaY,
            itemWidth,
            itemHeight,
            itemsPerRowForWidth,
            rowsForDataAndWidth,
          }),
        );

        if (active) {
          setDraggedItem({ id, index, active, deltaX, deltaY: deltaY });
        } else {
          initialScrollYRef.current = undefined;
          setDraggedItem(undefined);
        }

        update({ id, active, data, updatedData });
      }, 16),
    [scrollY, data, itemWidth, itemHeight, itemsPerRowForWidth, rowsForDataAndWidth, update],
  );
  const bind = useDrag(handleDrag, { delay: true, filterTaps: true, preventDefault: true });

  const initialRenderRef = useRef(true);
  const prevDataRef = useRef(data);
  useEffect(() => {
    if (!initialRenderRef.current) {
      return;
    }

    if (!isEqualData(prevDataRef.current, data) || draggedItem) {
      prevDataRef.current = data;
      initialRenderRef.current = false;
    }
  }, [data, draggedItem]);

  const transitions = useTransition(data, {
    keys: (item) => item.id,
    update: (item) => {
      const isDraggedItem = draggedItem && draggedItem.id === item.id;
      const scrollDeltaY = (scrollY || 0) - (initialScrollYRef.current || 0);

      const x = isDraggedItem ? (layout[item.id]?.x || 0) + draggedItem.deltaX : tempLayout[item.id]?.x || 0;
      const y = isDraggedItem
        ? (layout[item.id]?.y || 0) + draggedItem.deltaY + scrollDeltaY
        : tempLayout[item.id]?.y || 0;
      const scale = isDraggedItem ? 1.05 : 1;
      const zIndex = isDraggedItem ? 10 : 0;

      return {
        transform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
        opacity: 1,
        zIndex,
        immediate: isDraggedItem || initialRenderRef.current,
      };
    },
    leave: (item) => {
      const x = layout[item.id]?.x || 0;
      const y = layout[item.id]?.y || 0;
      const scale = 0.8;

      return {
        transform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
        opacity: 0,
        zIndex: 0,
      };
    },
    config: SPRING_CONFIG,
  });

  const gridStyle = useSpring({
    height: gridHeight,
    immediate: initialRenderRef.current,
    config: SPRING_CONFIG,
  });

  return (
    <animated.div className="sortable-grid" style={gridStyle}>
      <Measured className="sortable-grid__content" onChanged={handleOnGridMeasured}>
        {gridWidth
          ? transitions((props, item, state, index) => (
              <animated.div
                key={item.id}
                className="sortable-grid__item"
                style={{
                  touchAction: "none",
                  width: itemWidth,
                  ...props,
                }}
                {...bind(item.id, index)}
              >
                {/* This @ts-ignore is required due to the ShortableGridItem's memoization */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <SortableGridItem children={children} index={index} item={item} onMeasured={handleOnItemMeasured} />
              </animated.div>
            ))
          : null}
      </Measured>
    </animated.div>
  );
};

export default SortableGrid;
