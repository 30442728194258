import { FC, useEffect } from "react";
import { ProductVariantWithProductInfo as ProductVariantWithProductInfoTemplate } from "./components/productVariantWithProductInfo/ProductVariantWithProductInfo";
import { useViewProductVariantWithProductInfoById } from "../../../projection/productVariantWithProductInfo/react/useViewProductVariantWithProductInfoById";
import { ProductVariantWithProductInfoSkeleton } from "./components/productVariantWithProductInfoSkeleton/ProductVariantWithProductInfoSkeleton";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { TrackingPage } from "../../../tracking/Tracker";
import { useViewPersonalShopper } from "../../../projection/personalShopper/react/useViewPersonalShopper";

interface ProductVariantWithProductInfoProps {
  readonly productVariantId?: string;
  readonly boxId: string;
  readonly customerId: string;
  readonly origin: TrackingPage;
  readonly visible: boolean;
}

const ProductVariantWithProductInfo: FC<ProductVariantWithProductInfoProps> = ({
  productVariantId,
  boxId,
  customerId,
  origin,
  visible,
}) => {
  const [productVariantWithProductInfo] = useViewProductVariantWithProductInfoById({ productVariantId });
  const [personalShopper] = useViewPersonalShopper();
  const trackPageView = useTrackPageView({
    boxId,
    section: TrackingPage.PRODUCT_VARIANT_DETAIL,
    userId: customerId,
    origin,
    psId: personalShopper?.id,
  });

  useEffect(() => {
    if (!productVariantWithProductInfo || !visible) {
      return;
    }

    trackPageView();
  }, [productVariantWithProductInfo, trackPageView, visible]);

  if (!productVariantWithProductInfo) {
    return <ProductVariantWithProductInfoSkeleton />;
  }

  return <ProductVariantWithProductInfoTemplate productVariantWithProductInfo={productVariantWithProductInfo} />;
};

export { ProductVariantWithProductInfo };
