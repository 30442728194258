import { FC, useCallback, useMemo, useRef, useState } from "react";
import ProductPreview from "../../../../../../components/organisms/productPreview/ProductPreview";
import { Routes } from "../../../../../../routing/Routing";
import useBoxSharedContext from "../../../../../../hooks/useBoxSharedContext";
import useProductVariantComparer from "../../../../../../hooks/useProductVariantComparer";
import { asyncActionStateForCommandStatus } from "../../../../../../../../shared/ui/uiKit/_common/AsyncActionState";
import ProductPreviewActionButton from "../../../../../../components/molecules/productPreviewActionButton/ProductPreviewActionButton";
import { IconVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { useMarkProductVariantAsCandidate } from "../../../../../../../domain/selection/react/useMarkProductVariantAsCandidate";
import { useCanMarkOrUnmarkProductVariantAsCandidate } from "../../../../../../../projection/selection/react/useCanMarkOrUnmarkProductVariantAsCandidate";
import DeselectProductVariantConfirmationModal from "../../../../../../components/organisms/deselectProductVariantConfirmationModal/DeselectProductVariantConfirmationModal";
import useProductVariantSelection from "../../../../../../hooks/useProductVariantSelection";
import useFavourites from "../../../../../../hooks/useFavourites";
import { generatePath, useNavigate } from "react-router-dom";
import { useViewBoxPreviewByBoxId } from "../../../../../../../projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { SelectionAlertProjection } from "../../../../../../../../projection/alert/selectionAlert";
import { useListLooksForSelection } from "../../../../../../../projection/looks/react/useListLooksForSelection";
import { TrackingPage } from "../../../../../../../tracking/Tracker";

import { useDeselectProductVariant } from "../../../../../../../domain/selection/react/useDeselectProductVariant";
import { useReplaceProductVariant } from "../../../../../../../domain/selection/react/useReplaceProductVariant";
import { useLogger } from "../../../../../../../logging/useLogger";
import { CommandStatus } from "@lookiero/messaging-react";
import { useUnmarkProductVariantAsCandidate } from "../../../../../../../domain/selection/react/useUnmarkProductVariantAsCandidate";
import Locale from "../../../../../../../../domain/country/model/Locale";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
  isProductVariantIdIncludedInSelection,
} from "../../../../../../../../projection/selection/selection";
import CandidateTag from "../candidateTag/CandidateTag";
import SelectionProductPreviewCandidateActions from "../selectionProductPreviewCandidateActions/SelectionProductPreviewCandidateActions";
import "./selection-product-preview.css";
import SelectionProductPreviewAlerts from "../selectionProductPreviewAlerts/SelectionProductPreviewAlerts";

interface OnDeselectProductVariantFunctionArgs {
  readonly position: number;
  readonly productVariantId: string;
}
interface OnDeselectProductVariantFunction {
  (args: OnDeselectProductVariantFunctionArgs): void;
}

interface OnClickProductFunctionArgs {
  readonly position: number;
  readonly productId: string;
}
interface OnClickProductFunction {
  (args: OnClickProductFunctionArgs): void;
}

interface SelectionProductPreviewProps {
  readonly product: SelectionProductProjection;
  readonly alerts?: SelectionAlertProjection[];
  readonly locale: Locale;
  readonly position?: number;
  readonly onDeselectProductVariant?: OnDeselectProductVariantFunction;
  readonly onClickProduct?: OnClickProductFunction;
}

const SelectionProductPreview: FC<SelectionProductPreviewProps> = ({
  product,
  alerts,
  locale,
  position = 0,
  onDeselectProductVariant,
  onClickProduct,
}) => {
  const { box, selection } = useBoxSharedContext();
  const logger = useLogger();

  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box.id });
  const { add: addProductVariantToComparer } = useProductVariantComparer();

  const [deselectProductVariant, deselectProductVariantStatus] = useDeselectProductVariant({
    selectionId: selection?.selectionId,
    boxNumber: String(box.boxNumber),
    boxPreviewStatus: boxPreview?.status,
    logger,
  });
  const [replaceProductVariant, replaceProductVariantStatus] = useReplaceProductVariant({
    selectionId: selection?.selectionId,
    boxId: box.id,
    boxNumber: String(box.boxNumber),
    boxPreviewStatus: boxPreview?.status,
    logger,
  });
  const [markProductVariantAsCandidate, markProductVariantAsCandidateStatus] = useMarkProductVariantAsCandidate({
    boxNumber: String(box.boxNumber),
    selectionId: selection?.selectionId,
    boxId: box.id,
    logger,
  });
  const [unmarkProductVariantAsCandidate, unmarkProductVariantAsCandidateStatus] = useUnmarkProductVariantAsCandidate({
    boxNumber: String(box.boxNumber),
    selectionId: selection?.selectionId,
    boxId: box.id,
    logger,
  });
  const [canMarkOrUnmarkProductVariantAsCandidate] = useCanMarkOrUnmarkProductVariantAsCandidate({
    boxNumber: String(box.boxNumber),
    boxId: box.id,
  });
  const [looks] = useListLooksForSelection({ selection });

  const {
    isFavourite,
    markProductAsFavouriteState,
    markProductAsFavourite,
    unmarkProductAsFavouriteState,
    unmarkProductAsFavourite,
  } = useFavourites({ product, section: TrackingPage.SELECTION, position });

  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;
  const handleProductPreviewClick = useCallback(() => {
    onClickProduct?.({ position, productId: product.id });

    const productLink = generatePath(Routes.BOX_SELECTION_PRODUCT, {
      locale,
      product: product.id,
      box: String(box.legacyId),
    });

    navigateRef.current(productLink);
  }, [box.legacyId, locale, onClickProduct, position, product.id]);

  const selectionProductVariantId = product.productVariants.find((productVariant: SelectionProductVariantProjection) =>
    isProductVariantIdIncludedInSelection(selection, productVariant.id),
  )?.id;
  const selectionProductVariant = product.productVariants.find(
    (productVariant) => productVariant.id === selectionProductVariantId,
  );
  const [productVariant, setProductVariant] = useProductVariantSelection({
    selectionProductVariant,
    product,
  });

  const replacedFor = useMemo(
    () => (selectionProductVariantId ? boxPreview?.replacedFor[selectionProductVariantId] : undefined),
    [boxPreview?.replacedFor, selectionProductVariantId],
  );

  const handleOnReplaceProductVariant = useCallback(
    async (productVariantToReplace: SelectionProductVariantProjection) => {
      try {
        await replaceProductVariant({
          deselectedProductVariantId: productVariant.id,
          selectedProductVariantId: productVariantToReplace.id,
        });
        setProductVariant(productVariantToReplace);
      } catch {}
    },
    [productVariant, replaceProductVariant, setProductVariant],
  );
  const handleOnAddToComparer = useCallback(
    () => addProductVariantToComparer(product.id, productVariant),
    [addProductVariantToComparer, product.id, productVariant],
  );

  const [deselectConfirmationVisible, setDeselectConfirmationVisible] = useState(false);
  const showDeselectConfirmationModal = useCallback(() => setDeselectConfirmationVisible(true), []);
  const hideDeselectConfirmationModal = useCallback(() => setDeselectConfirmationVisible(false), []);
  const handleDeselectConfirmed = useCallback(() => {
    hideDeselectConfirmationModal();
    deselectProductVariant({ productVariantId: productVariant.id });
    onDeselectProductVariant?.({ position, productVariantId: productVariant.id });
  }, [deselectProductVariant, hideDeselectConfirmationModal, onDeselectProductVariant, position, productVariant]);

  const handleOnDeselectProductVariant = useCallback(() => {
    const isProductVariantIncludedInALook = looks.some((look) =>
      look.some((lookProductVariantId) => lookProductVariantId === productVariant.id),
    );

    if (isProductVariantIncludedInALook) {
      showDeselectConfirmationModal();
    } else {
      deselectProductVariant({ productVariantId: productVariant.id });
      onDeselectProductVariant?.({ position, productVariantId: productVariant.id });
    }
  }, [
    deselectProductVariant,
    looks,
    onDeselectProductVariant,
    position,
    productVariant,
    showDeselectConfirmationModal,
  ]);

  const actions = useMemo(
    () => (
      <>
        <div>
          {isFavourite ? (
            <ProductPreviewActionButton
              icon={IconVariant.HEART_FILLED}
              label="unmark-product-as-favourite"
              state={unmarkProductAsFavouriteState}
              onClick={unmarkProductAsFavourite}
            />
          ) : (
            <ProductPreviewActionButton
              icon={IconVariant.HEART}
              label="mark-product-as-favourite"
              state={markProductAsFavouriteState}
              onClick={markProductAsFavourite}
            />
          )}
          <ProductPreviewActionButton
            icon={IconVariant.COMPARE}
            label="add-to-comparer"
            onClick={handleOnAddToComparer}
          />
        </div>
        <div>
          {canMarkOrUnmarkProductVariantAsCandidate && (
            <SelectionProductPreviewCandidateActions
              markAsCandidateState={asyncActionStateForCommandStatus[markProductVariantAsCandidateStatus]}
              selectedProductVariant={productVariant}
              unmarkAsCandidateState={asyncActionStateForCommandStatus[unmarkProductVariantAsCandidateStatus]}
              onMarkAsCandidate={markProductVariantAsCandidate}
              onUnmarkAsCandidate={unmarkProductVariantAsCandidate}
            />
          )}
          <ProductPreviewActionButton
            icon={IconVariant.MINUS}
            label="deselect-product-variant"
            state={asyncActionStateForCommandStatus[deselectProductVariantStatus]}
            onClick={handleOnDeselectProductVariant}
          />
        </div>
      </>
    ),
    [
      canMarkOrUnmarkProductVariantAsCandidate,
      deselectProductVariantStatus,
      handleOnAddToComparer,
      handleOnDeselectProductVariant,
      isFavourite,
      markProductAsFavourite,
      markProductAsFavouriteState,
      markProductVariantAsCandidate,
      markProductVariantAsCandidateStatus,
      productVariant,
      unmarkProductAsFavourite,
      unmarkProductAsFavouriteState,
      unmarkProductVariantAsCandidate,
      unmarkProductVariantAsCandidateStatus,
    ],
  );

  return useMemo(
    () => (
      <>
        <div
          aria-label="selection-product-preview"
          className="selection-product-preview"
          role="listitem"
          onClick={handleProductPreviewClick}
        >
          <ProductPreview
            actions={actions}
            alerts={<SelectionProductPreviewAlerts alerts={alerts} productVariant={productVariant} />}
            candidate={<CandidateTag selectedProductVariant={productVariant} />}
            disabled={replaceProductVariantStatus === CommandStatus.LOADING}
            product={product}
            productVariant={productVariant}
            replacedFor={replacedFor}
            onProductVariantChanged={handleOnReplaceProductVariant}
          />
        </div>
        <DeselectProductVariantConfirmationModal
          visible={deselectConfirmationVisible}
          onCancel={hideDeselectConfirmationModal}
          onConfirm={handleDeselectConfirmed}
        />
      </>
    ),
    [
      handleProductPreviewClick,
      actions,
      alerts,
      productVariant,
      replaceProductVariantStatus,
      product,
      replacedFor,
      handleOnReplaceProductVariant,
      deselectConfirmationVisible,
      hideDeselectConfirmationModal,
      handleDeselectConfirmed,
    ],
  );
};

export { SelectionProductPreview };
