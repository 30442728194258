import { FC, useCallback, useRef, useState } from "react";
import useBoxSharedContext from "../../../../../../hooks/useBoxSharedContext";
import SortableGrid from "../../../../../../../../shared/ui/uiKit/components/layouts/sortableGrid/SortableGrid";
import { useTrackSelectDeselectProductVariant } from "../../../../../../../tracking/useTrackSelectDeselectProductVariant";
import { useViewPersonalShopper } from "../../../../../../../projection/personalShopper/react/useViewPersonalShopper";
import { useSortSelectionOrder } from "../../../../../../../domain/selectionOrder/react/useSortSelectionOrder";
import { Subsection, TrackingPage } from "../../../../../../../tracking/Tracker";
import { ProductVariantSelectionProvider } from "../../../../../../hooks/useProductVariantSelection";

import { useViewSortedProductsByBoxNumber } from "../../../../../../../projection/selection/react/useViewSortedProductsByBoxNumber";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
} from "../../../../../../../../projection/selection/selection";
import Loader from "../../../../../../../../shared/ui/uiKit/components/atoms/loader/Loader";
import { useLogger } from "../../../../../../../logging/useLogger";
import { AutomaticSelectionStatus, useAutomaticSelection } from "../../../../../../hooks/useAutomaticSelection";
import { ProductDetailModal } from "../../../../../productDetail/components/productDetailModal/ProductDetailModal";
import "./selection-summary-products.css";
import { SelectionSummaryProductPreview } from "../selectionSummaryProductPreview/SelectionSummaryProductPreview";

interface HandleOnDeselectProductVariantFunctionArgs {
  readonly position: number;
  readonly productVariantId: string;
}
interface HandleOnDeselectProductVariantFunction {
  (args: HandleOnDeselectProductVariantFunctionArgs): void;
}

const SelectionSummaryProducts: FC = () => {
  const { box, selection } = useBoxSharedContext();
  const logger = useLogger();

  const { automaticSelectionStatus, selectionAlerts } = useAutomaticSelection();
  const [personalShopper] = useViewPersonalShopper();

  const [sortedProducts] = useViewSortedProductsByBoxNumber({ boxNumber: String(box.boxNumber) });
  const [sortSelectionOrder] = useSortSelectionOrder({
    boxNumber: String(box.boxNumber),
    selectionId: selection?.selectionId,
    logger,
  });

  const itemsPerRow = useCallback(() => 1, []);
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  const [productId, setProductId] = useState<string | undefined>();
  const [productDetailModalVisible, setProductDetailModalVisible] = useState(false);
  const handleOnHideProductDetailModal = useCallback(() => setProductDetailModalVisible(false), []);
  const handleOnClick = useCallback((productId: string) => {
    setProductId(productId);
    setProductDetailModalVisible(true);
  }, []);

  const handleOnOrderChanged = useCallback(
    (selectionProducts: SelectionProductProjection[]) => {
      const sortedProductVariantIds = selectionProducts.map(
        (product) =>
          (
            product.productVariants.find(
              (productVariant) => productVariant.isSelected,
            ) as SelectionProductVariantProjection
          ).id,
      );

      sortSelectionOrder({ productVariantIds: sortedProductVariantIds });
    },
    [sortSelectionOrder],
  );

  const trackSelectDeselectProductVariant = useTrackSelectDeselectProductVariant({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.SELECTION_SUMMARY,
    subsection: Subsection.SELECTION,
    userId: box.customerId,
  });
  const handleOnDeselectProductVariant: HandleOnDeselectProductVariantFunction = useCallback(
    ({ position, productVariantId }) => {
      trackSelectDeselectProductVariant({
        position,
        productVariantId,
        select: false,
      });
    },
    [trackSelectDeselectProductVariant],
  );

  const handleOnSelectProductVariant = useCallback(
    (productVariantId: string) => {
      trackSelectDeselectProductVariant({ productVariantId, select: true });
    },
    [trackSelectDeselectProductVariant],
  );

  const handleOnDeselectProductVariantFromModal = useCallback(
    (productVariantId: string) => {
      trackSelectDeselectProductVariant({ productVariantId, select: false });
    },
    [trackSelectDeselectProductVariant],
  );

  const selectionProductRenderer = useCallback(
    (product: SelectionProductProjection, index: number) => (
      <SelectionSummaryProductPreview
        key={product.id}
        alerts={selectionAlerts}
        boxId={box.id}
        legacyBoxId={String(box.legacyId)}
        product={product}
        onClick={handleOnClick}
        // eslint-disable-next-line react/jsx-no-bind
        onDeselectProductVariant={(productVariantId) =>
          handleOnDeselectProductVariant({ position: index, productVariantId })
        }
      />
    ),
    [box.id, box.legacyId, handleOnClick, handleOnDeselectProductVariant, selectionAlerts],
  );

  return (
    <>
      {automaticSelectionStatus === AutomaticSelectionStatus.STARTED ? (
        <div className="selection-summary-products__automatic-selection-loader">
          <Loader />
        </div>
      ) : (
        <div
          ref={scrollTargetRef}
          aria-label="selection-summary-products"
          className="selection-summary-products"
          role="list"
        >
          {sortedProducts && (
            <SortableGrid
              data={sortedProducts}
              itemsPerRow={itemsPerRow}
              scrollTarget={scrollTargetRef}
              onChanged={handleOnOrderChanged}
            >
              {selectionProductRenderer}
            </SortableGrid>
          )}
        </div>
      )}

      <ProductVariantSelectionProvider>
        <ProductDetailModal
          productId={productId}
          visible={productDetailModalVisible}
          onDeselectProductVariant={handleOnDeselectProductVariantFromModal}
          onHide={handleOnHideProductDetailModal}
          onSelectProductVariant={handleOnSelectProductVariant}
        />
      </ProductVariantSelectionProvider>
    </>
  );
};

export { SelectionSummaryProducts };
