import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { updateAnnotation } from "../../../../domain/annotation/command/updateAnnotation";

interface UpdateAnnotationFunctionArgs {
  readonly positive: string;
  readonly negative: string;
}
interface UpdateAnnotationFunction {
  (args: UpdateAnnotationFunctionArgs): Promise<void>;
}

interface UseUpdateAnnotationArgs {
  readonly id: string | undefined;
  readonly customerId: string;
}

interface UseUpdateAnnotation {
  (args: UseUpdateAnnotationArgs): [update: UpdateAnnotationFunction, status: CommandStatus];
}

const useUpdateAnnotation: UseUpdateAnnotation = ({ id, customerId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const update: UpdateAnnotationFunction = useCallback(
    async ({ positive, negative }) => {
      try {
        await commandBus(updateAnnotation({ aggregateId: id ?? uuid(), customerId, positive, negative }));
      } catch (error) {}
    },
    [commandBus, customerId, id],
  );

  return [update, status];
};

export { useUpdateAnnotation };
