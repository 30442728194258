import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { ShortlistFilters } from "../../../../projection/shortlist/countShortlistProductsByShortlistFilters";
import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { ShortlistProductProjection } from "../../../../projection/shortlist/shortlistProduct";
import { searchShortlistProductsByShortlistFilters } from "../../../../projection/shortlist/searchShortlistProductsByShortlistFilters";
import { isProductMarkedAsFavourite } from "../../../../domain/favourite/model/productMarkedAsFavourite";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isProductUnmarkedAsFavourite } from "../../../../domain/favourite/model/productUnmarkedAsFavourite";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { isPersonalShopperFilteringSet } from "../../../../domain/personalShopperFiltering/model/personalShopperFilteringSet";

interface UseSearchShortlistProductsByShortlistFiltersFunctionArgs {
  readonly psId: string | undefined;
  readonly boxNumber: string;
  readonly search?: string;
  readonly favourites?: boolean;
  readonly positives: ShortlistFilters;
  readonly negatives: ShortlistFilters;
  readonly page: number;
  readonly perPage: number;
}

interface UseSearchShortlistProductsByShortlistFiltersFunction {
  (args: UseSearchShortlistProductsByShortlistFiltersFunctionArgs): UseQueryFunctionResult<
    Record<number, ShortlistProductProjection[]>
  >;
}

const useSearchShortlistProductsByShortlistFilters: UseSearchShortlistProductsByShortlistFiltersFunction = ({
  psId,
  boxNumber,
  search,
  favourites,
  positives,
  negatives,
  page,
  perPage,
}) => {
  const [pagedProducts, setPagedProducts] = useState<Record<number, ShortlistProductProjection[]>>({});
  const positivesRef = useRef(positives);
  const negativesRef = useRef(negatives);
  const favouritesRef = useRef(favourites);
  const searchRef = useRef(search);

  /**
   * Reset pagedProducts when shortlistFilters changed
   */
  useEffect(() => {
    if (
      isEqual(positivesRef.current, positives) &&
      isEqual(negativesRef.current, negatives) &&
      isEqual(favouritesRef.current, favourites) &&
      isEqual(searchRef.current, search)
    ) {
      return;
    }

    positivesRef.current = positives;
    negativesRef.current = negatives;
    favouritesRef.current = favourites;
    searchRef.current = search;

    setPagedProducts({});
  }, [positives, negatives, favourites, search]);

  const invalidationId = [
    "SearchShortlistProductsByShortlistFilters",
    psId,
    boxNumber,
    search,
    favourites,
    positives,
    negatives,
    perPage,
  ];
  const queryId = [...invalidationId, page];

  const [result, status] = useQuery<ShortlistProductProjection[], Error>({
    id: queryId,
    invalidationId,
    invalidationRefetchType: "active",
    contextId: MESSAGING_CONTEXT_ID,
    query: searchShortlistProductsByShortlistFilters({
      criteria: {
        psId: psId as string,
        boxNumber,
        search,
        favourites,
        shortlistFilters: positives,
        negativeFilters: negatives.map(({ id }) => ({ id })),
        page,
        perPage,
      },
    }),
    invalidation: (event: DomainEvent<MessageName>) =>
      isProductMarkedAsFavourite(event) || isProductUnmarkedAsFavourite(event) || isPersonalShopperFilteringSet(event),
    options: {
      staleTime: favourites ? 0 : 5 * 60 * 1000,
      enabled: psId !== undefined,
      refetchOnWindowFocus: false,
    },
  });

  /**
   * Add new page-results to pagedProducts
   */
  useEffect(() => {
    if (!result || isEqual(pagedProducts[page], result)) {
      return;
    }

    setPagedProducts((pagedProducts) => ({ ...pagedProducts, [page]: result }));
  }, [result, page, pagedProducts]);

  return [pagedProducts, status];
};

export { useSearchShortlistProductsByShortlistFilters };
