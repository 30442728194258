import { FC, memo } from "react";
import CheckboxShortlistFilterItem from "../../shortlistFilterItem/components/checkboxShortlistFilterItem/CheckboxShortlistFilterItem";
import ButtonOptionShortlistFilterItem from "../../shortlistFilterItem/components/buttonOptionShortlistFilterItem/ButtonOptionShortlistFilterItem";
import ColorOptionShortlistFilterItem from "../../shortlistFilterItem/components/colorOptionShortlistFilterItem/ColorOptionShortlistFilterItem";
import IconOptionShortlistFilterItem from "../../shortlistFilterItem/components/iconOptionShortlistFilterItem/IconOptionShortlistFilterItem";
import DefaultHostShortlistFilterItem from "../../shortlistFilterItem/components/defaultHostShortlistFilterItem/DefaultHostShortlistFilterItem";
import SearchHostShortlistFilterItem from "../../shortlistFilterItem/components/searchHostShortlistFilterItem/SearchHostShortlistFilterItem";
import NullShortlistFilterItem from "../../shortlistFilterItem/components/nullShortlistFilterItem/NullShortlistFilterItem";
import {
  ShortlistFilterItemProvider,
  ShortlistFilterItems,
} from "../../shortlistFilterItem/hooks/useShortlistFilterItem";
import ShortlistFilters from "../../ShortlistFilters";
import { useShortlistFilteringPositives } from "../../shortlistFilterItem/hooks/useShortlistFilteringPositives";
import {
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../../../../projection/shortlistFilter/shortlistFilter";
import { RangeHostShortlistFilterItem } from "../../shortlistFilterItem/components/rangeHostShortlistFilterItem/RangeHostShortlistFilterItem";
import { LegacyRangeHostShortlistFilterItem } from "../../shortlistFilterItem/components/legacyRangeHostShortlistFilterItem/LegacyRangeHostShortlistFilterItem";
import "./shortlist-filters-positives.css";

const SHORTLIST_FILTER_ITEMS: ShortlistFilterItems = {
  [ShortlistFilterType.HOST_NULL]: NullShortlistFilterItem,
  [ShortlistFilterType.HOST_DEFAULT]: DefaultHostShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.HOST_SEARCH]: SearchHostShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.HOST_RANGE]: LegacyRangeHostShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.HOST_RANGE_2]: RangeHostShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.CHECKBOX]: CheckboxShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.OPTION]: ButtonOptionShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.COLOR]: ColorOptionShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.RANGE]: NullShortlistFilterItem,
  [ShortlistFilterType.RANGE_LOWER_BOUND]: NullShortlistFilterItem,
  [ShortlistFilterType.RANGE_UPPER_BOUND]: NullShortlistFilterItem,
  [ShortlistFilterType.ICON]: IconOptionShortlistFilterItem({
    variant: "positive",
    useShortlistFiltering: useShortlistFilteringPositives,
  }),
  [ShortlistFilterType.NULL]: NullShortlistFilterItem,
};

type ShortlistFiltersPositivesProps = {
  readonly filters: ShortlistFilterProjection[];
};
const ShortlistFiltersPositives: FC<ShortlistFiltersPositivesProps> = ({ filters }) => (
  <section className="shortlist-filters-positives" data-testid="shortlist-filters-positives">
    <ShortlistFilterItemProvider shortlistFilterItems={SHORTLIST_FILTER_ITEMS}>
      <div className="shortlist-filters-positives__content">
        <ShortlistFilters filters={filters} />
      </div>
    </ShortlistFilterItemProvider>
  </section>
);

const MemoizedShortlistFiltersPositives = memo(ShortlistFiltersPositives);

export { MemoizedShortlistFiltersPositives as ShortlistFiltersPositives };
