import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { ProductVariantWithProductInfoProjection } from "../../../../projection/productVariantWithProductInfo/productVariantWithProductInfo";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { viewProductVariantWithProductInfoById } from "../../../../projection/productVariantWithProductInfo/viewProductVariantWithProductInfoById";

interface UseViewProductVariantWithProductInfoByIdFunctionArgs {
  readonly productVariantId?: string;
}

interface UseViewProductVariantWithProductInfoByIdFunction {
  (
    args: UseViewProductVariantWithProductInfoByIdFunctionArgs,
  ): UseQueryFunctionResult<ProductVariantWithProductInfoProjection>;
}

const useViewProductVariantWithProductInfoById: UseViewProductVariantWithProductInfoByIdFunction = ({
  productVariantId,
}) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewProductVariantWithProductInfoById({ productVariantId: productVariantId as string }),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(productVariantId),
    },
  });

export { useViewProductVariantWithProductInfoById };
