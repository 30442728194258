import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { updateUiSetting } from "../../../domain/command/updateUiSetting";

interface UpdateFunctionArgs {
  readonly key: string;
  readonly value: unknown;
}

interface UpdateFunction {
  (args: UpdateFunctionArgs): Promise<void>;
}

type UseUpdateUiSetting = [update: UpdateFunction, status: CommandStatus];

interface UseUpdateUiSettingFunctionArgs {
  readonly contextId: string;
}

interface UseUpdateUiSettingFunction {
  (args: UseUpdateUiSettingFunctionArgs): UseUpdateUiSetting;
}

const useUpdateUiSetting: UseUpdateUiSettingFunction = ({ contextId }) => {
  const [commandBus, status] = useCommand({ contextId });

  const update: UpdateFunction = useCallback(
    async ({ key, value }) => {
      try {
        await commandBus(
          updateUiSetting({
            aggregateId: uuid(),
            key,
            value,
          }),
        );
      } catch (error) {
        console.log(error);
      }
    },
    [commandBus],
  );

  return [update, status];
};

export { useUpdateUiSetting };
