import { useMemo } from "react";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import ShortlistFilterHeader from "../shortlistFilterHeader/ShortlistFilterHeader";
import ShortlistFilterItemRoot from "../shortlistFilterItemRoot/ShortlistFilterItemRoot";
import { LegacyShortlistFilterSlider } from "../legacyShortlistFilterSlider/LegacyShortlistFilterSlider";
import "./legacy-range-host-shortlist-filter-item.css";

const LegacyRangeHostShortlistFilterItem: ShortlistFilterItemHOC = ({ variant, useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function RangeHostShortlistFilterItemComponent({ filter, level }) {
    const ShortlistFilterSliderComponent = useMemo(
      () => LegacyShortlistFilterSlider({ variant, useShortlistFiltering }),
      [],
    );
    const ShortlistFilterItemRootComponent = useMemo(() => ShortlistFilterItemRoot({ useShortlistFiltering }), []);

    return (
      <ShortlistFilterItemRootComponent
        className="legacy-range-host-shortlist-filter-item"
        filter={filter}
        item={<ShortlistFilterHeader filter={filter} level={level} />}
        level={level}
      >
        <div className="legacy-range-host-shortlist-filter-item__slider">
          <ShortlistFilterSliderComponent filter={filter} />
        </div>
      </ShortlistFilterItemRootComponent>
    );
  };

export { LegacyRangeHostShortlistFilterItem };
