import { FC, useCallback, useMemo, useState } from "react";
import { StyleProfileStyles } from "../../../../../../projection/styleProfile/styleProfile";
import { StyleProfileCard } from "../styleProfileCard/StyleProfileCard";
import { StyleProfileCardTitle } from "../styleProfileCard/components/Title";
import { StyleProfileQuestion } from "../styleProfileQuestion/StyleProfileQuestion";
import { TextQuestionItem } from "../styleProfileQuestion/components/textQuestionItem/TextQuestionItem";
import { ImageQuestionItem } from "../styleProfileQuestion/components/imageQuestionItem/ImageQuestionItem";
import { I18nMessage, useI18nMessage } from "@lookiero/i18n-react";
import { CUSTOMER_I18N_PREFIX, CustomerI18n } from "../../../../i18n/CustomerI18n";
import { Answer } from "../answer/Answer";
import Modal from "../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { CustomerInsights } from "../customerInsights/CustomerInsights";
import { CustomerInsightProjection } from "../../../../../../projection/customerInsight/customerInsight";
import Button from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { ProductVariantWithProductInfoModal } from "../../../productVariantWithProductInfo/ProductVariantWithProductInfoModal";
import { TrackingPage } from "../../../../../tracking/Tracker";
import "./styles.css";

type MandatoryStyles = Pick<StyleProfileStyles, "leisure" | "work">;
const MANDATORY: (keyof MandatoryStyles)[] = ["leisure", "work"];

type ExcludeStyles = Pick<StyleProfileStyles, "styles" | "focusLeisure" | "focusWork" | "focusNight">;
const EXCLUDE: (keyof ExcludeStyles)[] = ["styles", "focusLeisure", "focusWork", "focusNight"];

type RestStyles = Omit<StyleProfileStyles, keyof MandatoryStyles | keyof ExcludeStyles>;

interface Location {
  readonly col: number;
  readonly row: number;
}

const MANDATORY_LOCATION: Record<"defined", Location> = {
  defined: {
    col: 1,
    row: 1,
  },
};

interface StylesProps {
  readonly boxId: string;
  readonly customerId: string;
  readonly origin: TrackingPage;
  readonly styles: StyleProfileStyles;
  readonly showStylesDefinedText?: boolean;
  readonly showImages?: boolean;
  readonly previousStyles?: StyleProfileStyles;
  readonly customerInsights: CustomerInsightProjection | undefined;
  readonly showCustomerInsights: boolean;
  readonly onClickLookAndLike: () => void;
  readonly onClickCustomerInsight: (familyIds: string[]) => void;
}

const Styles: FC<StylesProps> = ({
  boxId,
  customerId,
  origin,
  styles,
  previousStyles,
  showStylesDefinedText = false,
  showImages = true,
  customerInsights,
  showCustomerInsights,
  onClickLookAndLike,
  onClickCustomerInsight,
}) => {
  const titleText = useI18nMessage({ id: CustomerI18n.STYLE_TITLE, prefix: CUSTOMER_I18N_PREFIX });
  const showLookAndLikeText = useI18nMessage({
    id: CustomerI18n.SHOW_LOOK_AND_LIKE_BUTTON,
    prefix: CUSTOMER_I18N_PREFIX,
  });

  const restStyles: RestStyles = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(styles).filter(
          ([key]) => !MANDATORY.includes(key as keyof MandatoryStyles) && !EXCLUDE.includes(key as keyof ExcludeStyles),
        ),
      ) as RestStyles,
    [styles],
  );

  const [leisureWorkModalVisible, setLeisureWorkModalVisible] = useState(false);
  const openLeisureWorkModal = useCallback(() => setLeisureWorkModalVisible(true), []);
  const closeLeisureWorkModal = useCallback(() => setLeisureWorkModalVisible(false), []);

  const [stylesModalVisible, setStylesModalVisible] = useState(false);
  const openStylesModal = useCallback(() => setStylesModalVisible(true), []);
  const closeStylesModal = useCallback(() => setStylesModalVisible(false), []);

  const [productVariantId, setProductVariantId] = useState<string | null>(null);
  const handleOnClickProductVariant = useCallback(
    (productVariantId: string) => setProductVariantId(productVariantId),
    [],
  );
  const closeProductVariantWithProductInfoModal = useCallback(() => setProductVariantId(null), []);

  return (
    <>
      <ProductVariantWithProductInfoModal
        boxId={boxId}
        customerId={customerId}
        origin={origin}
        productVariantId={productVariantId || ""}
        visible={Boolean(productVariantId)}
        onCancel={closeProductVariantWithProductInfoModal}
      />

      <StyleProfileCard className="styles" label="styles-card">
        <div className="styles__header">
          <StyleProfileCardTitle>{titleText}</StyleProfileCardTitle>

          {showCustomerInsights && (
            <Button aria-label="look-and-like-button" onClick={onClickLookAndLike}>
              {showLookAndLikeText}
            </Button>
          )}
        </div>

        <div>
          {showCustomerInsights && customerInsights && customerInsights.insights.length > 0 && (
            <>
              <CustomerInsights
                customerInsights={customerInsights.insights}
                onClick={onClickCustomerInsight}
                onClickProductVariant={handleOnClickProductVariant}
              />
              <div className="styles__divider" />
            </>
          )}

          <div className="styles__content">
            <StyleProfileQuestion
              style={{ gridColumn: MANDATORY_LOCATION.defined.col, gridRow: MANDATORY_LOCATION.defined.row }}
              title={CustomerI18n.STYLE_DEFINED}
            >
              {showStylesDefinedText && (
                <>
                  <Answer answer={styles.leisure} previousAnswer={previousStyles?.leisure}>
                    {(leisure) => <TextQuestionItem>{leisure}</TextQuestionItem>}
                  </Answer>
                  <Answer answer={styles.work} previousAnswer={previousStyles?.work}>
                    {(work) => <TextQuestionItem>{work}</TextQuestionItem>}
                  </Answer>
                </>
              )}

              {showImages && (
                <>
                  <Modal
                    className="styles__modal"
                    visible={leisureWorkModalVisible}
                    title={
                      <Text variant={TextVariant.HEADING_3}>
                        <I18nMessage id={CustomerI18n.LEISURE_WORK_MODAL_TITLE} prefix={CUSTOMER_I18N_PREFIX} />
                      </Text>
                    }
                    onHide={closeLeisureWorkModal}
                  >
                    <div className="styles__modal-images">
                      {styles.leisure && (
                        <div className="styles__modal-image">
                          <ImageQuestionItem alt={styles.leisure.translationKey} src={styles.leisure.image} />
                        </div>
                      )}
                      {styles.work && (
                        <div className="styles__modal-image">
                          <ImageQuestionItem alt={styles.work.translationKey} src={styles.work.image} />
                        </div>
                      )}
                    </div>
                  </Modal>
                  <div className="styles__content-multiple-images">
                    <Answer
                      answer={styles.leisure}
                      className="styles__content-image-highlight"
                      previousAnswer={previousStyles?.leisure}
                    >
                      {(leisure) => (
                        <ImageQuestionItem
                          alt={leisure.translationKey}
                          src={leisure.image}
                          onClick={openLeisureWorkModal}
                        />
                      )}
                    </Answer>
                    <Answer
                      answer={styles.work}
                      className="styles__content-image-highlight"
                      previousAnswer={previousStyles?.work}
                    >
                      {(work) => (
                        <ImageQuestionItem alt={work.translationKey} src={work.image} onClick={openLeisureWorkModal} />
                      )}
                    </Answer>
                  </div>
                </>
              )}
            </StyleProfileQuestion>

            {styles.styles && styles.styles.length > 0 && (
              <StyleProfileQuestion title={CustomerI18n.STYLE_ADD}>
                <Answer answer={styles.styles} previousAnswer={previousStyles?.styles}>
                  {(styles) => (
                    <div className="styles__content-list">
                      {styles.map((item, index) => (
                        <TextQuestionItem key={index}>{item}</TextQuestionItem>
                      ))}
                    </div>
                  )}
                </Answer>

                {showImages && (
                  <>
                    <Modal
                      className="styles__modal"
                      visible={stylesModalVisible}
                      title={
                        <Text variant={TextVariant.HEADING_3}>
                          <I18nMessage id={CustomerI18n.STYLE_MODAL_TITLE} prefix={CUSTOMER_I18N_PREFIX} />
                        </Text>
                      }
                      onHide={closeStylesModal}
                    >
                      <div className="styles__modal-images">
                        {styles.styles.map((style, index) => (
                          <div key={index} className="styles__modal-image">
                            <ImageQuestionItem alt={style.translationKey} src={style.image} />
                          </div>
                        ))}
                      </div>
                    </Modal>
                    <div className="styles__content-multiple-images">
                      <Answer
                        answer={styles.styles}
                        className="styles__content-image-highlight"
                        previousAnswer={previousStyles?.styles}
                      >
                        {(styles) =>
                          styles.map((item, index) => (
                            <ImageQuestionItem
                              key={index}
                              alt={item.translationKey}
                              src={item.image}
                              onClick={openStylesModal}
                            />
                          ))
                        }
                      </Answer>
                    </div>
                  </>
                )}
              </StyleProfileQuestion>
            )}

            {(styles.focusLeisure || styles.focusWork || styles.focusNight) && (
              <StyleProfileQuestion title={CustomerI18n.FOCUS}>
                <Answer answer={styles.focusLeisure} previousAnswer={previousStyles?.focusLeisure}>
                  {(focusLeisure) => (
                    <div className="styles__focus">
                      <TextQuestionItem>
                        <I18nMessage id={CustomerI18n.IDEAL_FOCUS_LEISURE} prefix={CUSTOMER_I18N_PREFIX} />
                      </TextQuestionItem>
                      <TextQuestionItem>{focusLeisure}</TextQuestionItem>
                    </div>
                  )}
                </Answer>
                <Answer answer={styles.focusWork} previousAnswer={previousStyles?.focusWork}>
                  {(focusWork) => (
                    <div className="styles__focus">
                      <TextQuestionItem>
                        <I18nMessage id={CustomerI18n.IDEAL_FOCUS_WORK} prefix={CUSTOMER_I18N_PREFIX} />
                      </TextQuestionItem>
                      <TextQuestionItem>{focusWork}</TextQuestionItem>
                    </div>
                  )}
                </Answer>
                <Answer answer={styles.focusNight} previousAnswer={previousStyles?.focusNight}>
                  {(focusNight) => (
                    <div className="styles__focus">
                      <TextQuestionItem>
                        <I18nMessage id={CustomerI18n.IDEAL_FOCUS_EVENING} prefix={CUSTOMER_I18N_PREFIX} />
                      </TextQuestionItem>
                      <TextQuestionItem>{focusNight}</TextQuestionItem>
                    </div>
                  )}
                </Answer>
              </StyleProfileQuestion>
            )}

            {Object.entries(restStyles).map(
              ([key, value]) =>
                value && (
                  <StyleProfileQuestion key={key} title={key}>
                    <Answer answer={value} previousAnswer={previousStyles?.[key as keyof RestStyles]}>
                      {(value) => <TextQuestionItem>{value}</TextQuestionItem>}
                    </Answer>
                  </StyleProfileQuestion>
                ),
            )}
          </div>
        </div>
      </StyleProfileCard>
    </>
  );
};

export { Styles };
