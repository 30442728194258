import { I18nMessage } from "@lookiero/i18n-react";
import { FC, ReactNode, useMemo } from "react";
import Measurement from "../../../atoms/measurement/Measurement";
import Size from "../../../atoms/size/Size";
import { modelIsSize } from "../../../../../../projection/model/model/model.typeguard";
import { Model } from "../../../../../../projection/model/model/Model";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import "./product-model-morphology.css";

type ExcludeModel = Pick<Model, "id" | "fashionModelTestedSize" | "fashionModelTestedSizeFitting">;
const EXCLUDE: (keyof ExcludeModel)[] = ["id", "fashionModelTestedSize", "fashionModelTestedSizeFitting"];

type RestModel = Omit<Model, keyof ExcludeModel>;

interface ProductModelMorphologyProps {
  readonly children?: ReactNode;
  readonly model?: Model;
}

const ProductModelMorphology: FC<ProductModelMorphologyProps> = ({ model = {} as Model, children }) => {
  const restModel: RestModel = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(model).filter(([key]) => !EXCLUDE.includes(key as keyof ExcludeModel)),
      ) as RestModel,
    [model],
  );

  return (
    <section className="product-model-morphology">
      {children && <header className="product-model-morphology__header">{children}</header>}

      <ul className="product-model-morphology__list">
        {Object.entries(restModel).map(
          ([key, value]) =>
            value && (
              <li key={key} className="product-model-morphology__list-item">
                <Text variant={TextVariant.BODY}>
                  <I18nMessage id={value.translationKey} />
                  :&nbsp;
                </Text>
                <Text variant={TextVariant.BODY_BOLD}>
                  {modelIsSize(value) ? <Size size={value} /> : <Measurement measurement={value} />}
                </Text>
              </li>
            ),
        )}
      </ul>
    </section>
  );
};

export default ProductModelMorphology;
