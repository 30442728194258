import { FC, useCallback } from "react";
import Button, { ButtonVariant } from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import Icon, { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import "./shortlist-filter-favourites.css";

type ShortlistFilterFavouritesProps = {
  readonly selected: boolean;
  readonly onClick: (selected: boolean) => void;
};
const ShortlistFilterFavourites: FC<ShortlistFilterFavouritesProps> = ({
  selected,
  onClick,
}: ShortlistFilterFavouritesProps) => {
  const handleOnClick = useCallback(() => onClick(!selected), [onClick, selected]);

  return (
    <Button
      aria-label="shortlist-filter-favourites"
      className="shortlist-filter-favourites-button"
      type="button"
      variant={selected ? ButtonVariant.BASIC : ButtonVariant.PRIMARY}
      onClick={handleOnClick}
    >
      <Icon variant={selected ? IconVariant.HEART_FILLED : IconVariant.HEART} />
    </Button>
  );
};

export default ShortlistFilterFavourites;
