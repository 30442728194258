import { FC, ReactNode, useCallback, useRef, useState } from "react";
import { CustomerCarousel } from "../customerCarousel/CustomerCarousel";
import { useMeasure, useMouseHovered } from "react-use";
import { animated, useSpring } from "react-spring";
import useCaptureAndStopEvent from "../../../../../../../shared/ui/uiKit/hooks/useCaptureAndStopEvent";
import "./customer-zoom-carousel.css";

const config = {
  tension: 15,
  friction: 15,
};

interface CustomerZoomCarouselProps {
  readonly activeIndex: number;
  readonly photos: string[];
  readonly onZoomClose: () => void;
  readonly onChange: (index: number) => void;
}

const CustomerZoomCarousel: FC<CustomerZoomCarouselProps> = ({ activeIndex, photos, onZoomClose, onChange }) => {
  const handleOnZoomClose = useCaptureAndStopEvent(onZoomClose);

  const [hovered, setHovered] = useState(false);
  const handleMouseOut = useCallback(() => setHovered(false), []);
  const handleMouseOver = useCallback(() => setHovered(true), []);

  const zoomCarouselRef = useRef(null);
  const [itemRef, { height }] = useMeasure<HTMLDivElement>();
  const { elY, elH } = useMouseHovered(zoomCarouselRef, { bound: true, whenHovered: true });

  const yDelta = hovered ? (elH / 2 - elY) * (height / (2 * elH)) || 0 : 0;
  const springStyle = useSpring({ transform: `translate3d(0,${yDelta}px,0)`, config });

  const itemWrapper = useCallback(
    (item: ReactNode) => (
      <animated.div
        ref={itemRef}
        aria-label="zoom-carousel-viewport"
        className="customer-zoom-carousel__item-wrapper"
        role="presentation"
        style={springStyle}
      >
        {item}
      </animated.div>
    ),
    [itemRef, springStyle],
  );

  return (
    <section
      ref={zoomCarouselRef}
      aria-label="customer-zoom-carousel"
      className="customer-zoom-carousel"
      onClick={handleOnZoomClose}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <CustomerCarousel
        activeIndex={activeIndex}
        itemWrapper={itemWrapper}
        photos={photos}
        keyboardControlled
        onChange={onChange}
      />
    </section>
  );
};

export { CustomerZoomCarousel };
