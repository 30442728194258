import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { synchronizeCapacityCalendar } from "../../../../domain/capacityCalendar/command/synchronizeCapacityCalendar";
import { Segment } from "../../../../projection/segment/segment";

interface SynchronizeCapacityCalendarFunction {
  (): Promise<void>;
}

type UseSynchronizeCapacityCalendarReturn = [sync: SynchronizeCapacityCalendarFunction, status: CommandStatus];

interface UseSynchronizeCapacityCalendarFunctionArgs {
  readonly segments: Segment[];
}

interface UseSynchronizeCapacityCalendarFunction {
  (args: UseSynchronizeCapacityCalendarFunctionArgs): UseSynchronizeCapacityCalendarReturn;
}

const useSynchronizeCapacityCalendar: UseSynchronizeCapacityCalendarFunction = ({ segments }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const sync: SynchronizeCapacityCalendarFunction = useCallback(
    async () => await commandBus(synchronizeCapacityCalendar({ aggregateId: uuid(), segments })),
    [commandBus, segments],
  );

  return [sync, status];
};

export { useSynchronizeCapacityCalendar };
