import { useI18nMessage } from "@lookiero/i18n-react";
import { FC, useCallback, useMemo } from "react";
import Dropdown from "../../../../../../shared/ui/uiKit/components/organisms/dropdown/Dropdown";
import FormInputOption from "../../../../../../shared/ui/uiKit/_common/FormInputOption";
import RealTimeBoxProductionI18n, {
  REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
} from "../../../../i18n/RealTimeBoxProductionI18n";
import { PersonalShopperTeamProjection } from "../../../../../../projection/personalShopperTeam/personalShopperTeam";

interface PersonalShopperTeamsDropdownProps {
  readonly personalShopperTeams: PersonalShopperTeamProjection[];
  readonly personalShopperTeamSelected?: string;
  readonly onChanged: (personalShopperTeamId: string) => void;
}
const PersonalShopperTeamsDropdown: FC<PersonalShopperTeamsDropdownProps> = ({
  personalShopperTeams,
  personalShopperTeamSelected,
  onChanged,
}) => {
  const personalShopperTeamsDropdownLabel = useI18nMessage({
    id: RealTimeBoxProductionI18n.PERSONAL_SHOPPER_TEAMS_DROPDOWN_LABEL,
    prefix: REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
  });

  const personalShopperTeamsOptions: FormInputOption[] = useMemo(
    () =>
      personalShopperTeams.map((personalShopperTeam) => ({
        label: personalShopperTeam.name,
        value: personalShopperTeam.id,
      })),
    [personalShopperTeams],
  );

  const handleOnPersonalShopperTeamChanged = useCallback(
    (personalShopperTeamId: string | string[] | null | undefined) => onChanged(personalShopperTeamId as string),
    [onChanged],
  );

  return (
    <Dropdown
      isSearchable={false}
      label={personalShopperTeamsDropdownLabel}
      options={personalShopperTeamsOptions}
      value={personalShopperTeamSelected}
      onChange={handleOnPersonalShopperTeamChanged}
    />
  );
};

export { PersonalShopperTeamsDropdown };
