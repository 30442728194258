import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { setPersonalShopperFiltering as setPersonalShopperFilteringCommand } from "../../../../domain/personalShopperFiltering/command/setPersonalShopperFiltering";
import { FilteringValue } from "../../../../domain/personalShopperFiltering/model/personalShopperFiltering";

interface SetPersonalShopperFilteringFunctionArgs {
  readonly personalShopperFilteringId?: string;
  readonly positives: FilteringValue[];
  readonly negatives: FilteringValue[];
}
interface SetPersonalShopperFilteringFunction {
  (args: SetPersonalShopperFilteringFunctionArgs): Promise<void> | undefined;
}

type UseSetPersonalShopperFilteringResult = [set: SetPersonalShopperFilteringFunction, status: CommandStatus];

interface UseSetPersonalShopperFilteringFunctionArgs {
  readonly boxId: string;
  readonly customerId: string;
  readonly psId: string;
}

interface UseSetPersonalShopperFilteringFunction {
  (args: UseSetPersonalShopperFilteringFunctionArgs): UseSetPersonalShopperFilteringResult;
}

const useSetPersonalShopperFiltering: UseSetPersonalShopperFilteringFunction = ({ boxId, customerId, psId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const setPersonalShopperFiltering: SetPersonalShopperFilteringFunction = useCallback(
    async ({ personalShopperFilteringId, positives, negatives }) =>
      await commandBus(
        setPersonalShopperFilteringCommand({
          aggregateId: personalShopperFilteringId || uuid(),
          boxId,
          customerId,
          positives,
          negatives,
          psId,
        }),
      ),
    [boxId, commandBus, customerId, psId],
  );

  return [setPersonalShopperFiltering, status];
};

export { useSetPersonalShopperFiltering };
