import { FC, useCallback, useMemo } from "react";
import classNames from "classnames";
import "./bullets.css";
import useCaptureAndStopEvent from "../../../hooks/useCaptureAndStopEvent";

const MAX = 5;
const DIAMETER_MAX = 8;
const DIAMETER_MIN = 2;

interface BulletProps {
  readonly index: number;
  readonly activeIndex: number;
  readonly maxBullets: number;
  readonly onClick: (index: number) => void;
}

const Bullet: FC<BulletProps> = ({ index, activeIndex, maxBullets, onClick }) => {
  const pivot = Math.floor(maxBullets / 2) + 1;
  const delta = Math.abs(index - activeIndex);
  const diameter = delta > 1 ? DIAMETER_MAX - delta * DIAMETER_MIN : DIAMETER_MAX;

  const active = index === activeIndex;

  const handleClick = useCallback(() => onClick(index), [index, onClick]);
  const handleOnClick = useCaptureAndStopEvent(handleClick);

  return delta >= pivot ? null : (
    <span
      aria-checked={active}
      aria-label="bullet"
      className={classNames("bullets__bullet", active && "bullets__bullet--active")}
      style={{
        width: diameter,
        height: diameter,
        borderRadius: diameter,
      }}
      onClick={handleOnClick}
    />
  );
};

interface BulletsProps {
  readonly activeIndex: number;
  readonly onChange: (index: number) => void;
  readonly count: number;
  readonly maxBullets?: number;
}

const Bullets: FC<BulletsProps> = ({ activeIndex, count, maxBullets = MAX, onChange }) => {
  const bullets = useMemo(() => [...Array(count).keys()], [count]);

  return (
    <div aria-label="bullets" className="bullets">
      {bullets.map((index) => (
        <Bullet key={index} activeIndex={activeIndex} index={index} maxBullets={maxBullets} onClick={onChange} />
      ))}
    </div>
  );
};

export { Bullets };
