import { FC } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Locale, { DEFAULT_LOCALE_FOR_LANGUAGE, isValidLocale } from "../../../domain/country/model/Locale";
import useEnvironment from "../hooks/useEnvironment";
import { Language } from "../../../domain/country/model/language";
import ToLegacy from "../views/toLegacy/ToLegacy";

interface StrictLocaleProps {
  readonly children: JSX.Element;
}

const StrictLocale: FC<StrictLocaleProps> = ({ children }) => {
  const {
    internationalization: { defaultLocale },
  } = useEnvironment();
  const { locale } = useParams<{ locale: Locale | Language }>();
  const { pathname } = useLocation();

  if (!pathname || !locale) {
    return <ToLegacy />;
  }

  if (!isValidLocale(locale as Locale)) {
    const newLocale = DEFAULT_LOCALE_FOR_LANGUAGE[locale as Language] || defaultLocale;

    return <Navigate to={pathname.replace(new RegExp(`/${locale}`), `/${newLocale}`)} replace />;
  }

  return children;
};

export { StrictLocale };
