import { FC, useEffect } from "react";
import useEnvironment from "../../hooks/useEnvironment";
import { useLocation } from "react-router-dom";

const ToLegacy: FC = () => {
  const { pathname } = useLocation();
  const { labsLegacyDomain } = useEnvironment();

  useEffect(() => {
    if (pathname && labsLegacyDomain) {
      window.location.href = `${labsLegacyDomain}${pathname}`;
    }
  }, [labsLegacyDomain, pathname]);

  return null;
};

export default ToLegacy;
