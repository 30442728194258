import { useCallback } from "react";
import invariant from "ts-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { Logger } from "../../../logging/Logger";
import { markProductVariantAsCandidate } from "../../../../domain/selection/command/markProductVariantAsCandidate";

interface MarkProductVariantAsCandidateFunctionArgs {
  readonly productVariantId: string;
}

interface MarkProductVariantAsCandidateFunction {
  (args: MarkProductVariantAsCandidateFunctionArgs): Promise<void>;
}

type UseMarkProductVariantAsCandidateFunctionResult = [
  mark: MarkProductVariantAsCandidateFunction,
  status: CommandStatus,
];

interface UseMarkProductVariantAsCandidateFunctionArgs {
  readonly selectionId: string | undefined;
  readonly boxNumber: string;
  readonly boxId: string;
  readonly logger: Logger;
}

interface UseMarkProductVariantAsCandidateFunction {
  (args: UseMarkProductVariantAsCandidateFunctionArgs): UseMarkProductVariantAsCandidateFunctionResult;
}

const useMarkProductVariantAsCandidate: UseMarkProductVariantAsCandidateFunction = ({
  boxNumber,
  selectionId,
  boxId,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const mark: MarkProductVariantAsCandidateFunction = useCallback(
    async ({ productVariantId }) => {
      invariant(selectionId, "Cannot mark product variant as candidate without a selectionId");

      try {
        await commandBus(
          markProductVariantAsCandidate({
            aggregateId: selectionId,
            boxNumber,
            productVariantId,
            boxId,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [selectionId, commandBus, boxNumber, boxId, logger],
  );

  return [mark, status];
};

export { useMarkProductVariantAsCandidate };
