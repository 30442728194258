import { FC, ReactNode } from "react";
import classNames from "classnames";
import AspectRatio from "../../../_common/AspectRatio";
import "./aspect-ratio-container.css";

type AspectRatioContainerProps = {
  readonly aspectRatio: AspectRatio;
  readonly children: ReactNode;
  readonly className?: string;
  readonly tag?: keyof JSX.IntrinsicElements;
};
const AspectRatioContainer: FC<AspectRatioContainerProps> = ({
  className,
  aspectRatio,
  children,
  tag: Tag = "div",
}: AspectRatioContainerProps) => (
  <Tag className={classNames("aspect-ratio-container", `aspect-ratio-container--${aspectRatio}`, className)}>
    {children}
  </Tag>
);

export default AspectRatioContainer;
