import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import {
  ViewFilteringByCustomerIdResult,
  viewFilteringByCustomerId,
} from "../../../../projection/filtering/viewFilteringByCustomerId";
import { isPersonalShopperFilteringSet } from "../../../../domain/personalShopperFiltering/model/personalShopperFilteringSet";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isPersonalShopperFilteringSet(event);

interface UseViewFilteringByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseViewFilteringByCustomerIdFunction {
  (args: UseViewFilteringByCustomerIdFunctionArgs): UseQueryFunctionResult<ViewFilteringByCustomerIdResult>;
}

const useViewFilteringByCustomerId: UseViewFilteringByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewFilteringByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: { staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useViewFilteringByCustomerId };
