import {
  CheckoutsCountByPersonalShopperIdView,
  CountCheckoutsByPersonalShopperIdResult,
} from "../../../projection/checkout/countCheckoutsByPersonalShopperId";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpCheckoutsCountByPersonalShopperIdView extends CheckoutsCountByPersonalShopperIdView {}

interface HttpCheckoutsCountByPersonalShopperIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutsCountByPersonalShopperIdViewFunction {
  (args: HttpCheckoutsCountByPersonalShopperIdViewFunctionArgs): HttpCheckoutsCountByPersonalShopperIdView;
}

const httpCheckoutsCountByPersonalShopperIdView: HttpCheckoutsCountByPersonalShopperIdViewFunction =
  ({ httpPost }) =>
  async ({ personalShopperId, signal }) =>
    await httpPost<CountCheckoutsByPersonalShopperIdResult>({
      endpoint: "/count-checkouts-by-personal-shopper-id",
      body: { personalShopperId },
      signal,
      result: {
        error: 0,
        success: (response) => response,
      },
    });

export { httpCheckoutsCountByPersonalShopperIdView };
