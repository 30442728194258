import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useRef } from "react";
import { invariant } from "ts-invariant";
import { useViewPersonalShopper } from "../../../../../../projection/personalShopper/react/useViewPersonalShopper";
import { useViewActivityTimeByPsIdAndBoxId } from "../../../../../../projection/activityTime/react/useViewActivityTimeByPsIdAndBoxId";
import { useRegisterActivityTime } from "../../../../../../domain/activityTime/react/useRegisterActivityTime";
import nonClosableBoxStatuses from "../../../../../../../domain/box/model/NonClosableBoxStatuses";
import { useViewBoxByBoxNumber } from "../../../../../../projection/box/react/useViewBoxByBoxNumber";
import { useLogger } from "../../../../../../logging/useLogger";
import { ActivityTimeSection } from "../../../../../../../domain/activityTime/model/activityTime";
import { useRegisterActivityTimePreviewedWatermark } from "../../../../../../domain/activityTime/react/useRegisterActivityTimePreviewedWatermark";
import { useStopwatch } from "react-timer-hook";
import { usePageVisibility } from "./usePageVisibility";
import { useActivityTimeSection } from "./useActivityTimeSection";

interface RegisterFunctionArgs {
  readonly elapsedTime: number;
  readonly section: ActivityTimeSection;
  readonly userIsActive: boolean;
}

interface RegisterFunction {
  (args: RegisterFunctionArgs): Promise<void>;
}

interface ActivityTimeContextApi {
  readonly elapsedTime: number;
  readonly previewedElapsedTime: number | null;
  readonly stop: () => void;
  readonly enabled: boolean;
}

const ActivityTimeContext = createContext<ActivityTimeContextApi>(null as unknown as ActivityTimeContextApi);

interface ActivityTimeProviderProps {
  readonly boxNumber: string;
  readonly children: ReactNode;
}

const ActivityTimeProvider: FC<ActivityTimeProviderProps> = ({ children, boxNumber }) => {
  const logger = useLogger();
  const [box] = useViewBoxByBoxNumber({ boxNumber });
  const [personalShopper] = useViewPersonalShopper();
  const activityTimeEnabled = Boolean(
    box !== undefined && !nonClosableBoxStatuses.includes(box.status) && boxNumber && personalShopper?.id,
  );

  const [registerActivityTime] = useRegisterActivityTime({ boxId: box?.id, psId: personalShopper?.id, logger });
  const [registerActivityTimePreviewedWatermark] = useRegisterActivityTimePreviewedWatermark({
    boxId: box?.id,
    psId: personalShopper?.id,
    logger,
  });
  const [activityTime] = useViewActivityTimeByPsIdAndBoxId({
    psId: personalShopper?.id,
    boxId: box?.id,
  });

  const { reset, pause, totalSeconds } = useStopwatch({ autoStart: false });
  const resetRef = useRef(reset);
  resetRef.current = reset;
  const pauseRef = useRef(pause);
  pauseRef.current = pause;
  const elapsedTimeRef = useRef(0);

  useEffect(() => {
    elapsedTimeRef.current = 0;
    resetRef.current();

    if (!activityTimeEnabled) {
      pauseRef.current();
    }
  }, [activityTime, activityTimeEnabled]);

  const register: RegisterFunction = useCallback(
    async ({ elapsedTime, section, userIsActive }) => {
      if (!activityTimeEnabled) {
        return;
      }

      const end = Date.now();
      const init = end - elapsedTime * 1000;

      if (end - init < 1000) {
        return;
      }

      elapsedTimeRef.current = elapsedTime;
      resetRef.current();
      pauseRef.current();

      await registerActivityTime({
        init,
        end,
        section,
        userIsActive,
      });
    },
    [activityTimeEnabled, registerActivityTime],
  );

  const previewedWatermarkRegistered = useRef(false);
  useEffect(() => {
    if (previewedWatermarkRegistered.current || !activityTimeEnabled) {
      return;
    }

    const previewed = async () => {
      const init = Date.now();

      await registerActivityTimePreviewedWatermark({ watermark: init });
      await register({
        section: ActivityTimeSection.NONE,
        // ensure elapsedTime is >= 1s, otherwise it won't be registered
        elapsedTime: Math.ceil((Date.now() - init) / 1000) || 1,
        userIsActive: true,
      });

      previewedWatermarkRegistered.current = true;
    };

    previewed();
  }, [activityTimeEnabled, register, registerActivityTimePreviewedWatermark]);

  const section = useActivityTimeSection();
  const sectionRef = useRef(section);
  useEffect(() => {
    if (section === sectionRef.current) {
      return;
    }

    if (sectionRef.current !== ActivityTimeSection.NONE) {
      register({ elapsedTime: totalSeconds, section: sectionRef.current, userIsActive: true });
    }

    sectionRef.current = section;
  }, [register, section, totalSeconds]);

  const handleOnPageVisibilityChanged = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        return;
      }

      register({
        elapsedTime: totalSeconds,
        section: sectionRef.current,
        userIsActive: false,
      });
    },
    [register, totalSeconds],
  );
  usePageVisibility({ onChanged: handleOnPageVisibilityChanged });

  const stop = useCallback(
    () => register({ elapsedTime: totalSeconds, section: sectionRef.current, userIsActive: true }),
    [register, totalSeconds],
  );

  const elapsedSeconds = totalSeconds || elapsedTimeRef.current;

  const elapsedTime = (activityTime?.elapsedTime || 0) + elapsedSeconds;
  const previewedElapsedTime =
    activityTime && activityTime?.previewedElapsedTime !== null
      ? activityTime.previewedElapsedTime + elapsedSeconds
      : null;

  const value = {
    elapsedTime,
    previewedElapsedTime,
    stop,
    enabled: activityTimeEnabled,
  };

  return <ActivityTimeContext.Provider value={value}>{children}</ActivityTimeContext.Provider>;
};

const useActivityTime = (): ActivityTimeContextApi => {
  const activityTimeContext = useContext<ActivityTimeContextApi>(ActivityTimeContext);

  invariant(
    activityTimeContext,
    "Your are trying to use the activityTimeContext hook without wrapping your app with the <ActivityTimeProvider>.",
  );

  return activityTimeContext;
};

export type { ActivityTimeContextApi };
export { ActivityTimeProvider, useActivityTime };
