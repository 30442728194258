import {
  ClosedBoxItemFeedbackIcon,
  ClosedBoxItemFeedbackType,
} from "../../../../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";
import Icon, { IconVariant } from "../../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { FeedbackItem } from "../FeedbackItem";
import "./feedback-icon-item.css";

const FEEDBACK_ICON: Record<ClosedBoxItemFeedbackIcon, IconVariant> = {
  "return_question.icon.filled_heart": IconVariant.HEART_FILLED,
  "return_question.icon.crossed_heart": IconVariant.HEART_CROSSED,
  "return_question.icon.quality": IconVariant.STAMP,
  "return_question.icon.price": IconVariant.LABEL,
  "return_question.icon.cut": IconVariant.SCISSORS,
  "return_question.icon.others": IconVariant.ACTIONS,
  "return_question.icon.size_small": IconVariant.SIZING_SMALL,
  "return_question.icon.size_big": IconVariant.SIZING_LARGE,
  "return_question.icon.size_correct": IconVariant.SIZING_CORRECT,
  "return_question.icon.shoes_comfortable": IconVariant.SHOES_COMFORTABLE,
  "return_question.icon.shoes_uncomfortable": IconVariant.SHOES_UNCOMFORTABLE,
  "return_question.icon.comment": IconVariant.COMMENT,
};

const FeedbackIconItem: FeedbackItem<ClosedBoxItemFeedbackType.OPTION> = ({ answer }) => (
  <Icon className="feedback-icon-item" variant={FEEDBACK_ICON[answer.icon] || IconVariant.ALERT_WARNING} />
);

export { FeedbackIconItem };
