import { useCallback } from "react";
import { Section } from "./Tracker";
import { useTracker } from "./useTracker";

interface TrackMarkUnmarkFavouriteFunctionArgs {
  readonly position?: number;
  readonly productId: string;
  readonly selected: boolean;
}

interface TrackMarkUnmarkFavouriteFunction {
  (args: TrackMarkUnmarkFavouriteFunctionArgs): void;
}

interface UseTrackMarkUnmarkFavouriteFunctionArgs {
  readonly boxId: string;
  readonly boxNumber: string;
  readonly psId?: string;
  readonly section: Section;
  readonly userId: string;
}

interface UseTrackMarkUnmarkFavouriteFunction {
  (args: UseTrackMarkUnmarkFavouriteFunctionArgs): TrackMarkUnmarkFavouriteFunction;
}
const useTrackMarkUnmarkFavourite: UseTrackMarkUnmarkFavouriteFunction = ({
  section,
  boxId,
  boxNumber,
  psId,
  userId,
}) => {
  const tracker = useTracker();

  const trackMarkUnmarkFavourite: TrackMarkUnmarkFavouriteFunction = useCallback(
    ({ position, productId, selected }) => {
      if (!(tracker && psId)) {
        return;
      }

      tracker.markUnmarkFavourite({
        section,
        boxId,
        boxNumber,
        position,
        productId,
        psId,
        selected,
        userId,
      });
    },
    [boxId, boxNumber, psId, section, tracker, userId],
  );

  return trackMarkUnmarkFavourite;
};

export { useTrackMarkUnmarkFavourite };
