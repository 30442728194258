import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import ReturnReasonEnum from "../../../../../domain/returnReason/model/ReturnReason";
import Icon, { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Tooltip from "../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../../i18n/HistoryI18n";
import "./return-reason.css";

const iconAndLabelForReturnReason = (returnReason: ReturnReasonEnum): { icon: IconVariant; label: string } => {
  switch (returnReason) {
    case ReturnReasonEnum.PRICE:
      return {
        icon: IconVariant.LABEL,
        label: HistoryI18n.RETURN_REASON_PRICE,
      };
    case ReturnReasonEnum.QUALITY:
      return {
        icon: IconVariant.STAMP,
        label: HistoryI18n.RETURN_REASON_QUALITY,
      };
    case ReturnReasonEnum.STYLE:
      return {
        icon: IconVariant.HEART_CROSSED,
        label: HistoryI18n.RETURN_REASON_STYLE,
      };
    case ReturnReasonEnum.CUT:
      return {
        icon: IconVariant.SCISSORS,
        label: HistoryI18n.RETURN_REASON_CUT,
      };
    case ReturnReasonEnum.SMALL:
      return {
        icon: IconVariant.SIZING_SMALL,
        label: HistoryI18n.RETURN_REASON_SMALL,
      };
    case ReturnReasonEnum.BIG:
      return {
        icon: IconVariant.SIZING_LARGE,
        label: HistoryI18n.RETURN_REASON_BIG,
      };
  }
};

type ReturnReasonProps = {
  readonly returnReason: ReturnReasonEnum;
};
const ReturnReason: FC<ReturnReasonProps> = ({ returnReason }: ReturnReasonProps) => {
  const { icon, label } = iconAndLabelForReturnReason(returnReason);
  const tooltipLabel = useI18nMessage({ id: label, prefix: HISTORY_I18N_PREFIX });

  return (
    <div className="return-reason">
      <Tooltip title={tooltipLabel}>
        <Icon variant={icon} />
      </Tooltip>
    </div>
  );
};

export default ReturnReason;
