import { useMemo, useRef } from "react";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
} from "../../../../projection/selection/selection";
import { QueryStatus } from "@lookiero/messaging-react";
import { useViewSelectionOrderByBoxNumber } from "../../selectionOrder/react/useViewSelectionOrderByBoxNumber";
import { useAutomaticSelection } from "../../../ui/hooks/useAutomaticSelection";

type UseViewSortedProductsByBoxNumberFunctionResult = [SelectionProductProjection[] | undefined, QueryStatus];

interface UseViewSortedProductsByBoxNumberFunctionArgs {
  readonly boxNumber: string;
}

interface UseViewSortedProductsByBoxNumberFunction {
  (args: UseViewSortedProductsByBoxNumberFunctionArgs): UseViewSortedProductsByBoxNumberFunctionResult;
}

const useViewSortedProductsByBoxNumber: UseViewSortedProductsByBoxNumberFunction = ({ boxNumber }) => {
  const { selection, selectionStatus } = useAutomaticSelection();

  const [selectionOrder, selectionOrderStatus] = useViewSelectionOrderByBoxNumber({
    boxNumber,
  });

  const sortedProductsRef = useRef<SelectionProductProjection[]>();

  const sortedProducts = useMemo(() => {
    if (selectionStatus === QueryStatus.LOADING || selectionOrderStatus === QueryStatus.LOADING) {
      return sortedProductsRef.current;
    }

    return selectionOrder && selection?.products
      ? [
          ...selection.products.sort((aProduct, anotherProduct): number => {
            const aSelectedProductVariant = aProduct.productVariants.find(
              (productVariant) => productVariant.isSelected,
            ) as SelectionProductVariantProjection;
            const anotherSelectedProductVariant = anotherProduct.productVariants.find(
              (productVariant) => productVariant.isSelected,
            ) as SelectionProductVariantProjection;

            return (
              selectionOrder.productVariantIds.indexOf(aSelectedProductVariant.id) -
              selectionOrder.productVariantIds.indexOf(anotherSelectedProductVariant.id)
            );
          }),
        ]
      : selection?.products;
  }, [selection?.products, selectionOrder, selectionOrderStatus, selectionStatus]);

  sortedProductsRef.current = sortedProducts;

  return [sortedProducts, selectionStatus];
};

export { useViewSortedProductsByBoxNumber };
