import { createContext, FC, ReactNode, useContext } from "react";
import { invariant } from "ts-invariant";
import RootLoader from "../components/atoms/rootLoader/RootLoader";
import { useViewBoxByBoxNumber } from "../../projection/box/react/useViewBoxByBoxNumber";
import { BoxProjection } from "../../../projection/box/box";
import { SelectionProjection } from "../../../projection/selection/selection";
import { useAutomaticSelection } from "./useAutomaticSelection";

interface BoxSharedContextApi {
  readonly box: BoxProjection;
  readonly selection: SelectionProjection | undefined;
}

const BoxSharedContext = createContext<BoxSharedContextApi>(null as unknown as BoxSharedContextApi);

interface BoxSharedContextProviderProps {
  readonly boxNumber: string;
  readonly children: ReactNode;
}

const BoxSharedContextProvider: FC<BoxSharedContextProviderProps> = ({ boxNumber, children }) => {
  const [box] = useViewBoxByBoxNumber({ boxNumber });
  const { selection } = useAutomaticSelection();

  const value: BoxSharedContextApi = {
    // This explicit casting is safe as we are not providing the value through the context until we get the box
    box: box as BoxProjection,
    selection,
  };

  return box ? <BoxSharedContext.Provider value={value}>{children}</BoxSharedContext.Provider> : <RootLoader />;
};

const useBoxSharedContext = (): BoxSharedContextApi => {
  const boxSharedContext = useContext<BoxSharedContextApi>(BoxSharedContext);

  invariant(
    boxSharedContext,
    "Your are trying to use the useBoxSharedContext hook without wrapping your app with the <BoxSharedContextProvider>.",
  );

  return boxSharedContext;
};

export { BoxSharedContextProvider };

export default useBoxSharedContext;
