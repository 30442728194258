import { I18nMessage } from "@lookiero/i18n-react";
import classNames from "classnames";
import { FC } from "react";
import Icon, { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Text from "../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { SELECTION_I18N_PREFIX } from "../../../i18n/SelectionI18n";
import { AlertProjection } from "../../../../../projection/alert/alert";
import "./alert.css";

type AlertProps = {
  readonly alert: AlertProjection;
};
const Alert: FC<AlertProps> = ({ alert }: AlertProps) => (
  <li key={alert.key} aria-label="alert" className={classNames("alert", { "alert--blocker": alert.isBlocker })}>
    <Icon variant={IconVariant.ALERT_WARNING} />
    <Text>
      <I18nMessage
        id={`alerts.${alert.message.placeholder}`}
        prefix={SELECTION_I18N_PREFIX}
        values={{ ...alert.message.params, strong: (chunks) => <strong>{chunks}</strong> }}
      />
    </Text>
  </li>
);

export default Alert;
