import { useI18nDate } from "@lookiero/i18n-react";
import { FC } from "react";
import Icon, { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Tooltip from "../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import "./bought-on.css";

type BoughtOnProps = {
  readonly date: Date;
};
const BoughtOn: FC<BoughtOnProps> = ({ date }: BoughtOnProps) => {
  const tooltipLabel = useI18nDate({ value: date, dateStyle: "medium" });

  return (
    <div className="bought-on">
      <Tooltip title={tooltipLabel}>
        <Icon variant={IconVariant.CALENDAR} />
      </Tooltip>
    </div>
  );
};

export default BoughtOn;
