import { FC } from "react";
import { useParams } from "react-router-dom";
import { Routes } from "../../routing/Routing";
import Modal from "../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import useBoxSharedContext from "../../hooks/useBoxSharedContext";
import { useViewNoteByBoxId } from "../../../../infrastructure/projection/note/react/useViewNoteByBoxId";
import useEnvironment from "../../hooks/useEnvironment";
import { I18nMessage } from "@lookiero/i18n-react";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../i18n/NoteI18n";
import { generatePath } from "react-router-dom";
import { useNavigateBackWithFallback } from "../../../../shared/ui/uiKit/hooks/useNavigateBackWithFallback";
import "./note-preview.css";

const NotePreview: FC = () => {
  const { labsBackUrl } = useEnvironment();
  const { box } = useBoxSharedContext();
  const [note] = useViewNoteByBoxId({ boxId: box.id });
  const { locale, box: legacyBoxId } = useParams();
  const previousLocation = generatePath(Routes.BOX_SELECTION, {
    locale: locale as string,
    box: legacyBoxId as string,
  });
  const navigateBack = useNavigateBackWithFallback({ fallback: previousLocation });

  const notePreviewSrc = `${labsBackUrl}/view-note-as-pdf-by-box-id/${box.id}`;

  return (
    <Modal
      className="note-preview-modal"
      title={<I18nMessage id={NoteI18n.PREVIEW_TITLE} prefix={NOTE_I18N_PREFIX} />}
      visible
      onHide={navigateBack}
    >
      {note && (
        <iframe
          className="note-preview-modal__iframe"
          data-testid="note-preview"
          src={notePreviewSrc}
          title="note-preview"
        />
      )}
    </Modal>
  );
};

export default NotePreview;
