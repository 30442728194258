import React, { FC } from "react";
import { Outlet, useRoutes } from "react-router-dom";
import { Box } from "../views/box/Box";
import RedirectToNoteTemplates from "../views/notes/components/RedirectToNoteTemplatesRoute";
import developmentOnlyProtected from "./developmentOnlyProtected/developmentOnlyProtected";
import { ShortlistForBox } from "../views/shortlist/components/shortlistForBox/ShortlistForBox";
import { ProductDetail } from "../views/productDetail/ProductDetail";
import { SelectionAndNote } from "../views/selectionAndNote/SelectionAndNote";
import NotePreview from "../views/notePreview/NotePreview";
import Notes from "../views/notes/Notes";
import HistoryNotes from "../views/historyNotes/HistoryNotes";
import NoteTemplates from "../views/noteTemplates/NoteTemplates";
import NoteTemplateDetail from "../views/noteTemplateDetail/NoteTemplateDetail";
import ToLegacy from "../views/toLegacy/ToLegacy";
import UiKit from "../views/uiKit/UiKit";
import RealTimeBoxProduction from "../views/realTimeBoxProduction/RealTimeBoxProduction";
import { Planning } from "../views/planning/Planning";
import { Customer } from "../views/customer/Customer";
import RootLoader from "../components/atoms/rootLoader/RootLoader";
import { I18n } from "@lookiero/i18n-react";
import { Locale } from "@lookiero/i18n";
import { StrictLocale } from "./StrictLocale";
import { LayersProvider } from "../../../shared/ui/uiKit/hooks/useLayers";
import { Capacity } from "../views/capacity/Capacity";
import { EditorSharedContextProvider } from "../hooks/useEditorSharedContext";
import { MESSAGING_CONTEXT_ID } from "../../../bootstrap";
import { Notifications } from "../../../shared/notifications/infrastructure/ui/views/Notifications";
import { Checkouts } from "../views/checkouts/Checkouts";

enum Routes {
  UI_KIT = "/ui-kit",
  HOME = "/:locale",
  REAL_TIME_BOX_PRODUCTION = "/:locale/real-time-box-production",
  PLANNING = "/:locale/planning",
  CAPACITY = "/:locale/capacity",
  // Box id (legacy_id) related routes
  BOX = "/:locale/box/:box",
  BOX_CUSTOMER = "/:locale/box/:box/customer",
  // BOX_CUSTOMER_PINNED = "/:locale/box/:box/customer-pinned",
  BOX_SHORTLIST = "/:locale/box/:box/catalog",
  BOX_SHORTLIST_PRODUCT = "/:locale/box/:box/catalog/:product",
  BOX_PREVIEW = "/:locale/box/:box/preview",
  // BOX_SELECTION_SUMMARY = "/:locale/box/:box/selection-summary",
  BOX_SELECTION = "/:locale/box/:box/selection",
  BOX_SELECTION_PRODUCT = "/:locale/box/:box/selection/:product",
  BOX_SELECTION_PREVIEW_NOTE = "/:locale/box/:box/selection/preview-note",
  BOX_SELECTION_NOTES = "/:locale/box/:box/selection/notes",
  BOX_SELECTION_HISTORY_NOTES = "/:locale/box/:box/selection/notes/history",
  BOX_SELECTION_NOTES_TEMPLATES = "/:locale/box/:box/selection/notes/templates",
  BOX_SELECTION_NOTES_TEMPLATES_NEW = "/:locale/box/:box/selection/notes/templates/new",
  BOX_SELECTION_NOTES_TEMPLATES_DETAIL = "/:locale/box/:box/selection/notes/templates/:template",
  // PersonalShopper related routes
  PERSONAL_SHOPPER_CHECKOUTS = "/:locale/:psId/checkouts",
}

interface RoutingProps {
  readonly I18nRootComponent: I18n;
  readonly locale: Locale;
  readonly onI18nError: (error: Error) => void;
}

const Routing: FC<RoutingProps> = ({ I18nRootComponent, locale, onI18nError }) =>
  useRoutes([
    {
      path: Routes.HOME,
      element: (
        <StrictLocale>
          <I18nRootComponent loader={<RootLoader />} locale={locale as Locale} onError={onI18nError}>
            <LayersProvider>
              <Notifications contextId={MESSAGING_CONTEXT_ID} />
              <Outlet />
            </LayersProvider>
          </I18nRootComponent>
        </StrictLocale>
      ),
      children: [
        {
          path: Routes.BOX,
          element: (
            <Box>
              <EditorSharedContextProvider>
                <Outlet />
              </EditorSharedContextProvider>
            </Box>
          ),
          children: [
            {
              path: Routes.BOX_CUSTOMER,
              element: <Customer />,
            },
            {
              path: Routes.BOX_SHORTLIST,
              element: (
                <ShortlistForBox>
                  <Outlet />
                </ShortlistForBox>
              ),
              children: [
                {
                  path: Routes.BOX_SHORTLIST_PRODUCT,
                  element: <ProductDetail />,
                },
              ],
            },
            {
              path: Routes.BOX_SELECTION,
              element: (
                <SelectionAndNote>
                  <Outlet />
                </SelectionAndNote>
              ),
              children: [
                {
                  path: Routes.BOX_SELECTION_PRODUCT,
                  element: <ProductDetail />,
                },
                {
                  path: Routes.BOX_SELECTION_PREVIEW_NOTE,
                  element: <NotePreview />,
                },
                {
                  path: Routes.BOX_SELECTION_NOTES,
                  element: (
                    <Notes>
                      <Outlet />
                    </Notes>
                  ),
                  children: [
                    {
                      path: Routes.BOX_SELECTION_NOTES_TEMPLATES,
                      element: (
                        <NoteTemplates>
                          <Outlet />
                        </NoteTemplates>
                      ),
                      children: [
                        {
                          path: Routes.BOX_SELECTION_NOTES_TEMPLATES_DETAIL,
                          element: (
                            <EditorSharedContextProvider>
                              <NoteTemplateDetail />
                            </EditorSharedContextProvider>
                          ),
                        },
                        {
                          path: Routes.BOX_SELECTION_NOTES_TEMPLATES_NEW,
                          element: (
                            <EditorSharedContextProvider>
                              <NoteTemplateDetail />
                            </EditorSharedContextProvider>
                          ),
                        },
                      ],
                    },
                    {
                      path: Routes.BOX_SELECTION_HISTORY_NOTES,
                      element: <HistoryNotes />,
                    },
                    {
                      path: "",
                      element: <RedirectToNoteTemplates />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: Routes.REAL_TIME_BOX_PRODUCTION,
          element: <RealTimeBoxProduction />,
        },
        {
          path: Routes.PLANNING,
          element: <Planning />,
        },
        {
          path: Routes.CAPACITY,
          element: <Capacity />,
        },
        {
          path: Routes.PERSONAL_SHOPPER_CHECKOUTS,
          element: <Checkouts />,
        },
      ],
    },
    {
      path: Routes.UI_KIT,
      Component: developmentOnlyProtected(UiKit),
    },
    {
      path: "",
      element: <ToLegacy />,
    },
  ]);

export { Routing, Routes };
