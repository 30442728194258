import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import invariant from "tiny-invariant";
import useQueryParameters from "../../../../../routing/queryParams/useQueryParameters";
import QueryParams from "../../../../../routing/queryParams/config";
import { TrackingPage } from "../../../../../../tracking/Tracker";
import useBoxSharedContext from "../../../../../hooks/useBoxSharedContext";
import { useTrackFilters } from "../../../../../../tracking/useTrackFilters";
import { StateFilteringValue } from "../../ShortlistFilterState";
import { useFirstMountState } from "react-use";
import { useViewPersonalShopper } from "../../../../../../projection/personalShopper/react/useViewPersonalShopper";
import { ShortlistFilterProjection } from "../../../../../../../projection/shortlistFilter/shortlistFilter";

interface OnChangeFunctionArgs {
  readonly state: StateFilteringValue[];
}

interface OnChangeFunction {
  (args: OnChangeFunctionArgs): void;
}

interface ShortlistFilteringPositivesContextShape {
  readonly state: StateFilteringValue[];
  readonly filtering: { readonly id: string }[];
  readonly onChange: OnChangeFunction;
}

const ShortlistFilteringPositivesContext = createContext<ShortlistFilteringPositivesContextShape>(
  null as unknown as ShortlistFilteringPositivesContextShape,
);

interface ShortlistFilteringPositivesProviderProps {
  readonly filters: ShortlistFilterProjection[];
  readonly filtering: { readonly id: string }[];
  readonly children: ReactNode;
}

const ShortlistFilteringPositivesProvider: FC<ShortlistFilteringPositivesProviderProps> = ({
  filters,
  filtering,
  children,
}) => {
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();
  const trackFilters = useTrackFilters({
    section: TrackingPage.CATALOG,
    boxId: box.id,
    psId: personalShopper?.id,
    userId: box.customerId,
  });

  const { params, setParams } = useQueryParameters();

  const filtersParams = params[QueryParams.FILTERS];
  const state = useMemo(() => (filtersParams ? JSON.parse(filtersParams as string) : []), [filtersParams]);

  const isFirstMount = useFirstMountState();
  useEffect(() => {
    if (!isFirstMount || state.length > 0) {
      return;
    }

    setParams(
      {
        [QueryParams.PAGE]: null,
        [QueryParams.FILTERS]: filtering.length > 0 ? JSON.stringify(filtering) : null,
      },
      { replace: true },
    );
  }, [filtering, isFirstMount, setParams, state.length]);

  const onChange = useCallback<OnChangeFunction>(
    ({ state }) => {
      trackFilters({ filters, state });
      setParams({
        [QueryParams.PAGE]: null,
        [QueryParams.FILTERS]: state.length > 0 ? JSON.stringify(state) : null,
      });
    },
    [filters, setParams, trackFilters],
  );

  const value = useMemo(() => ({ state, onChange, filtering }), [state, onChange, filtering]);

  return (
    <ShortlistFilteringPositivesContext.Provider value={value}>{children}</ShortlistFilteringPositivesContext.Provider>
  );
};

interface UseShortlistFilteringPositivesFunction {
  (): ShortlistFilteringPositivesContextShape;
}

const useShortlistFilteringPositives: UseShortlistFilteringPositivesFunction = () => {
  const shortlistFilteringPositives = useContext<ShortlistFilteringPositivesContextShape>(
    ShortlistFilteringPositivesContext,
  );

  invariant(
    shortlistFilteringPositives,
    "Your are trying to use the useShortlistFilteringPositives hook without wrapping your app with the <ShortlistFilteringPositivesContext>.",
  );

  return shortlistFilteringPositives;
};

export { useShortlistFilteringPositives, ShortlistFilteringPositivesProvider };
