import { useCallback } from "react";
import invariant from "ts-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { unmarkProductVariantAsCandidate } from "../../../../domain/selection/command/unmarkProductVariantAsCandidate";
import { Logger } from "../../../logging/Logger";

interface UnmarkProductVariantAsCandidateFunctionArgs {
  readonly productVariantId: string;
}

interface UnmarkProductVariantAsCandidateFunction {
  (args: UnmarkProductVariantAsCandidateFunctionArgs): Promise<void>;
}

type UseUnmarkProductVariantAsCandidateFunctionResult = [
  unmark: UnmarkProductVariantAsCandidateFunction,
  status: CommandStatus,
];

interface UseUnmarkProductVariantAsCandidateFunctionArgs {
  readonly selectionId: string | undefined;
  readonly boxNumber: string;
  readonly boxId: string;
  readonly logger: Logger;
}

interface UseUnmarkProductVariantAsCandidateFunction {
  (args: UseUnmarkProductVariantAsCandidateFunctionArgs): UseUnmarkProductVariantAsCandidateFunctionResult;
}

const useUnmarkProductVariantAsCandidate: UseUnmarkProductVariantAsCandidateFunction = ({
  boxNumber,
  selectionId,
  boxId,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const unmark: UnmarkProductVariantAsCandidateFunction = useCallback(
    async ({ productVariantId }) => {
      invariant(selectionId, "Cannot unmark product variant as candidate without a selectionId");

      try {
        await commandBus(
          unmarkProductVariantAsCandidate({
            aggregateId: selectionId,
            boxNumber,
            productVariantId,
            boxId,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [selectionId, commandBus, boxNumber, boxId, logger],
  );

  return [unmark, status];
};

export { useUnmarkProductVariantAsCandidate };
