import { FC, ReactNode, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useViewFilteringByCustomerId } from "../../../../infrastructure/projection/filtering/react/useViewFilteringByCustomerId";
import QueryParams from "../../routing/queryParams/config";
import useQueryParameters from "../../routing/queryParams/useQueryParameters";
import RootLoader from "../../components/atoms/rootLoader/RootLoader";
import ShortlistFiltersSummary from "../../components/organisms/shortlistFilters/components/shortlistFiltersSummary/ShortlistFiltersSummary";
import { ShortlistFilteringNegativesProvider } from "../../components/organisms/shortlistFilters/shortlistFilterItem/hooks/useShortlistFilteringNegatives";
import { ShortlistFilteringPositivesProvider } from "../../components/organisms/shortlistFilters/shortlistFilterItem/hooks/useShortlistFilteringPositives";
import useBoxSharedContext from "../../hooks/useBoxSharedContext";
import { ProductVariantSelectionProvider } from "../../hooks/useProductVariantSelection";
import ShortlistFilterFavourites from "./components/shortlistFilterFavourites/ShortlistFilterFavourites";
import { ShortlistFilters } from "./components/shortlistFilters/ShortlistFilters";
import { ShortlistFiltersTabs } from "./components/shortlistFiltersTabs/ShortlistFiltersTabs";
import ShortlistProducts from "./components/shortlistProducts/ShortlistProducts";
import { useListShortlistFiltersByBoxId } from "../../../../infrastructure/projection/shortlistFilter/react/useListShortlistFiltersByBoxId";
import { QueryStatus } from "@lookiero/messaging-react";
import "./shortlist.css";

interface ShortlistProps {
  readonly children?: ReactNode;
}

const Shortlist: FC<ShortlistProps> = ({ children }) => {
  const { box: boxNumber } = useParams<{ box: string }>();
  const { params, setParams } = useQueryParameters();
  const { box } = useBoxSharedContext();
  const [shortlistFilters, shortlistFiltersStatus] = useListShortlistFiltersByBoxId({
    boxId: box.id,
  });
  const [filtering] = useViewFilteringByCustomerId({ customerId: box.customerId });

  const handleOnPageChanged = useCallback(
    (page: number): void => setParams({ [QueryParams.PAGE]: page === 1 ? null : String(page) }),
    [setParams],
  );
  const handleOnSearchChanged = useCallback(
    (search: string) => setParams({ [QueryParams.SEARCH]: search === "" ? null : search }),
    [setParams],
  );
  const handleOnFavouritesClicked = useCallback(
    (favourites: boolean) => setParams({ [QueryParams.FAVOURITES]: favourites ? true : null }),
    [setParams],
  );

  const page = parseInt(params[QueryParams.PAGE] as string) || 1;
  const search = params[QueryParams.SEARCH] as string;
  const favourites = Boolean(params[QueryParams.FAVOURITES]) || undefined;

  const positives = useMemo(() => filtering?.positives.map(({ id }) => ({ id })) || [], [filtering?.positives]);
  const negatives = useMemo(() => filtering?.negatives.map(({ id }) => ({ id })) || [], [filtering?.negatives]);

  return (
    <section className="shortlist">
      {shortlistFiltersStatus === QueryStatus.LOADING ? (
        <RootLoader />
      ) : shortlistFilters ? (
        <ShortlistFilteringPositivesProvider filtering={positives} filters={shortlistFilters}>
          <ShortlistFilteringNegativesProvider filtering={negatives}>
            {filtering ? (
              <ShortlistFiltersTabs
                filtering={filtering}
                filters={shortlistFilters}
                search={search}
                onSearchChanged={handleOnSearchChanged}
              />
            ) : (
              <ShortlistFilters filters={shortlistFilters} search={search} onSearchChanged={handleOnSearchChanged} />
            )}

            <ProductVariantSelectionProvider>
              <main className="shortlist__main">
                <header className="shortlist__header">
                  <ShortlistFilterFavourites selected={Boolean(favourites)} onClick={handleOnFavouritesClicked} />
                  <ShortlistFiltersSummary filters={shortlistFilters} />
                </header>
                <ShortlistProducts
                  boxNumber={boxNumber as string}
                  favourites={favourites}
                  page={page}
                  search={search}
                  onPageChanged={handleOnPageChanged}
                />
              </main>

              <section className="shortlist__routes">{children}</section>
            </ProductVariantSelectionProvider>
          </ShortlistFilteringNegativesProvider>
        </ShortlistFilteringPositivesProvider>
      ) : null}
    </section>
  );
};

export default Shortlist;
