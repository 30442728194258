import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { NoteTemplateValidation } from "../../../../projection/noteTemplateValidation/noteTemplateValidation";
import { validateNoteTemplate } from "../../../../projection/noteTemplateValidation/validateNoteTemplate";
import { NoteTemplateProjection } from "../../../../projection/noteTemplate/noteTemplate";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";

interface UseValidateNoteTemplateFunctionArgs {
  readonly noteTemplate: NoteTemplateProjection | null | undefined;
}

interface UseValidateNoteTemplateFunction {
  (args: UseValidateNoteTemplateFunctionArgs): UseQueryFunctionResult<NoteTemplateValidation>;
}

const useValidateNoteTemplate: UseValidateNoteTemplateFunction = ({ noteTemplate }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: validateNoteTemplate({ noteTemplate: noteTemplate as NoteTemplateProjection }),
    options: { enabled: Boolean(noteTemplate), keepPreviousData: true },
  });

export { useValidateNoteTemplate };
