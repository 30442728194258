import { FC, useCallback, useState } from "react";
import useProductVariantComparer from "../../../../hooks/useProductVariantComparer";
import ProductVariantComparerPreviewTemplate from "./components/productVariantComparerPreview/ProductVariantComparerPreview";
import ProductVariantComparerDetailTemplate from "./components/productVariantComparerDetail/ProductVariantComparerDetail";
import { useTrackClick } from "../../../../../tracking/useTrackClick";
import useBoxSharedContext from "../../../../hooks/useBoxSharedContext";
import { Routes } from "../../../../routing/Routing";
import { TrackingClick, TrackingPage } from "../../../../../tracking/Tracker";
import { useMatch } from "react-router-dom";
import { useViewPersonalShopper } from "../../../../../projection/personalShopper/react/useViewPersonalShopper";

const ProductVariantComparer: FC = () => {
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();

  const shortlistMatch = useMatch(`${Routes.BOX_SHORTLIST}/*`);

  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const showDetail = useCallback(() => setIsDetailVisible(true), []);
  const hideDetail = useCallback(() => setIsDetailVisible(false), []);

  const { productVariants, productVariantDetails, remove, clear } = useProductVariantComparer();

  const trackClick = useTrackClick({
    section: shortlistMatch ? TrackingPage.CATALOG : TrackingPage.SELECTION,
    psId: personalShopper?.id,
    boxId: box.id,
    userId: box.customerId,
  });

  const handleOnCompare = useCallback(() => {
    showDetail();

    const productVariantIds = productVariants.map((productVariant) => (productVariant ? productVariant.id : ""));

    trackClick({
      clickId: TrackingClick.COMPARE,
      productVariantIds,
    });
  }, [productVariants, showDetail, trackClick]);

  return (
    <>
      <ProductVariantComparerDetailTemplate
        productVariants={productVariantDetails}
        visible={isDetailVisible}
        onCancel={hideDetail}
      />
      <ProductVariantComparerPreviewTemplate
        productVariants={productVariants}
        onCancel={clear}
        onCompare={handleOnCompare}
        onRemove={remove}
      />
    </>
  );
};

export default ProductVariantComparer;
