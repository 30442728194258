import { FC } from "react";
import Skeleton from "../../../../../../shared/ui/uiKit/components/atoms/Skeleton/Skeleton";
import "./capacity-list-item.css";

const CapacityListItemSkeleton: FC = () => (
  <>
    <td className="capacity-list-item__col">
      <Skeleton />
    </td>
    <td className="capacity-list-item__col">
      <Skeleton />
    </td>
    <td className="capacity-list-item__col">
      <Skeleton />
    </td>
    <td className="capacity-list-item__col">
      <Skeleton />
    </td>
    <td className="capacity-list-item__col">
      <Skeleton />
    </td>
    <td className="capacity-list-item__col">
      <Skeleton />
    </td>
    <td className="capacity-list-item__col">
      <Skeleton />
    </td>
  </>
);

export { CapacityListItemSkeleton };
