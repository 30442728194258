import { FC, useMemo } from "react";
import PriceProjection from "../../../../../projection/price/model/Price";
import Price from "../../atoms/price/Price";
import SelectionHeaderSummaryCounter from "./selectionHeaderSummaryCounter/SelectionHeaderSummaryCounter";
import { BoxPreviewStatus } from "../../../../../domain/boxPreview/model/BoxPreviewStatus";
import { boxPreviewStatusesFromSent } from "../../../../../domain/boxPreview/model/BoxPreviewStatusesFromSent";
import Text from "../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { ProductVariantProjection } from "../../../../../projection/product/productVariant";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
} from "../../../../../projection/selection/selection";
import Tooltip from "../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import { makeStyles } from "@material-ui/core";
import { I18nMessage } from "@lookiero/i18n-react";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../i18n/SelectionI18n";
import "./selection-header-summary.css";

const useStylesBootstrap = makeStyles(() => ({
  tooltip: {
    backgroundColor: "#0c0f1c",
    paddingRight: 16,
    paddingLeft: 16,
    maxWidth: 198,
    borderRadius: 8,
  },
  arrow: {
    color: "#0c0f1c",
  },
}));

interface ProductVariantsPriceFunctionArgs {
  readonly productVariants: ProductVariantProjection[];
}

interface ProductVariantsPriceFunction {
  (args: ProductVariantsPriceFunctionArgs): PriceProjection;
}

const productVariantsPrice: ProductVariantsPriceFunction = ({ productVariants }) =>
  productVariants.reduce(
    (accPrice, productVariant) => ({
      amount: accPrice.amount + productVariant.price.amount,
      currency: accPrice.currency || productVariant.price.currency,
      discountedPrice: accPrice.discountedPrice
        ? {
            amount: accPrice.discountedPrice.amount + (productVariant.price.discountedPrice?.amount || 0),
            // percentage needs to be !== 0 to be properly displayed (if applies)
            percentage: accPrice.discountedPrice.percentage || productVariant.price.discountedPrice?.percentage ? 1 : 0,
          }
        : productVariant.price.discountedPrice,
    }),
    {
      amount: 0,
    } as PriceProjection,
  );

interface SelectionHeaderSummaryProps {
  readonly products?: SelectionProductProjection[];
  readonly boxPreviewStatus?: BoxPreviewStatus;
  readonly showCandidatesPrice?: boolean;
  readonly showAutomaticSelectionTooltip?: boolean;
}

const SelectionHeaderSummary: FC<SelectionHeaderSummaryProps> = ({
  products,
  boxPreviewStatus,
  showCandidatesPrice = false,
  showAutomaticSelectionTooltip = false,
}) => {
  const classes = useStylesBootstrap();

  const isBoxPreviewSent = boxPreviewStatus ? boxPreviewStatusesFromSent.includes(boxPreviewStatus) : false;

  const selectionProductVariants = useMemo(
    () =>
      products
        ? products.reduce((productVariants, product) => {
            const selectedProductVariant: SelectionProductVariantProjection = product.productVariants.find(
              (productVariant) => productVariant.isSelected,
            ) as SelectionProductVariantProjection;

            return [...productVariants, selectedProductVariant];
          }, [] as SelectionProductVariantProjection[])
        : undefined,
    [products],
  );

  const candidateProductVariants = useMemo(
    () => selectionProductVariants?.filter((productVariant) => productVariant.isCandidate),
    [selectionProductVariants],
  );

  const candidatesPrice: PriceProjection | undefined = useMemo(
    () =>
      !isBoxPreviewSent && candidateProductVariants && candidateProductVariants?.length > 0
        ? productVariantsPrice({ productVariants: candidateProductVariants })
        : undefined,
    [candidateProductVariants, isBoxPreviewSent],
  );

  const price: PriceProjection | undefined = useMemo(
    () =>
      selectionProductVariants && selectionProductVariants.length > 0
        ? productVariantsPrice({ productVariants: selectionProductVariants })
        : undefined,
    [selectionProductVariants],
  );

  return (
    <div className="selection-header-summary">
      <Tooltip
        classes={classes}
        open={showAutomaticSelectionTooltip}
        placement="left"
        title={
          <Text tag="p">
            <I18nMessage id={SelectionI18n.AUTOMATIC_SELECTION_FINISHED_TOOLTIP} prefix={SELECTION_I18N_PREFIX} />
          </Text>
        }
        arrow
      >
        <SelectionHeaderSummaryCounter
          candidateProductVariants={candidateProductVariants}
          selectionProductVariants={selectionProductVariants}
        />
      </Tooltip>
      {(candidatesPrice || price) && (
        <div className="selection-header-summary__price" data-testid="selection-header-summary-price">
          {showCandidatesPrice && candidatesPrice && (
            <>
              <Price price={candidatesPrice} />
              <Text className="selection-header-summary__price-separator">/</Text>
            </>
          )}
          {price && <Price className="selection-header-summary__main-price" price={price} />}
        </div>
      )}
    </div>
  );
};

export default SelectionHeaderSummary;
