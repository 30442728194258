import classNames from "classnames";
import { FC, useCallback } from "react";
import Media, { mainImageFromMedia } from "../../../../../../projection/media/model/Media";
import Picture from "../../../../../../shared/ui/uiKit/components/atoms/picture/Picture";
import AspectRatio from "../../../../../../shared/ui/uiKit/_common/AspectRatio";
import cdnImageUrl from "../../../../util/cdnImageUrl";
import { useI18nMessage } from "@lookiero/i18n-react";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../../../i18n/BoxPreviewI18n";
import Color from "../../../../../../projection/color/model/Color";
import { ReplacedFor } from "../../../../../../projection/boxPreview/boxPreview";
import "./box-preview-product-variant.css";

const BOX_PREVIEW_PRODUCT_VARIANT_IMAGE_WIDTH = 80;

interface ProductVariant {
  readonly id: string;
  readonly color: Color;
  readonly media: Media[];
  readonly chosen: boolean | null;
}

interface BoxPreviewProductVariantProps {
  readonly productVariant: ProductVariant;
  readonly couldNotBeenReplaced: boolean;
  readonly replacedFor?: ReplacedFor;
  readonly onClick?: (productVariantId: string) => void;
}

const BoxPreviewProductVariant: FC<BoxPreviewProductVariantProps> = ({
  productVariant,
  couldNotBeenReplaced,
  replacedFor,
  onClick,
}) => {
  const src = cdnImageUrl({
    url: mainImageFromMedia(replacedFor?.media || productVariant.media).url,
    width: BOX_PREVIEW_PRODUCT_VARIANT_IMAGE_WIDTH,
  });

  const outOfStockText = useI18nMessage({ id: BoxPreviewI18n.OUT_OF_STOCK, prefix: BOX_PREVIEW_I18N_PREFIX });

  const handleOnClick = useCallback(() => {
    onClick?.(productVariant.id);
  }, [onClick, productVariant.id]);

  return (
    <article
      aria-label="box-preview-product-variant"
      className={classNames("box-preview-product-variant", {
        "box-preview-product-variant--discarded": !productVariant.chosen,
      })}
      onClick={handleOnClick}
    >
      <Picture aspectRatio={AspectRatio.R_4_5} label={productVariant.id} src={src} />

      {couldNotBeenReplaced && (
        <div aria-label="could-not-been-replaced" className="box-preview-product-variant__could-not-been-replaced">
          <span className="box-preview-product-variant__could-not-been-replaced__text">{outOfStockText}</span>
        </div>
      )}

      {!productVariant.chosen && (
        <svg className="box-preview-product-variant__discarded-stroke" preserveAspectRatio="none" viewBox="0 0 100 100">
          <line vectorEffect="non-scaling-stroke" x1="20" x2="80" y1="20" y2="80" />
        </svg>
      )}
    </article>
  );
};

export { BoxPreviewProductVariant };
