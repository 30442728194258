import { FC, useCallback } from "react";
import Media from "../../../../../../projection/media/model/Media";
import Skeleton from "../../../../../../shared/ui/uiKit/components/atoms/Skeleton/Skeleton";
import ProductVariantDetailGallery from "../../../../components/organisms/productVariantDetailGallery/ProductVariantDetailGallery";
import { ProductDetailDataSkeleton } from "../../../../components/organisms/productDetailData/ProductDetailDataSkeleton";
import "./product-detail.css";

const ProductDetailSkeleton: FC = () => {
  const emptyFn = useCallback(() => void 0, []);

  return (
    <article aria-label="product-detail-skeleton" className="product-detail product-detail--skeleton">
      <ProductVariantDetailGallery
        media={[{ url: "" }, { url: "" }, { url: "" }] as Media[]}
        selectedItemIndex={0}
        onChange={emptyFn}
        onZoomed={emptyFn}
      />
      <section className="product-detail__details">
        <header className="product-detail__header">
          <div className="product-detail__header-main">
            <ProductDetailDataSkeleton />
            <section className="product-detail__size">
              <Skeleton />
            </section>
          </div>

          <div className="product-detail__actions">
            <Skeleton />
            <Skeleton />
          </div>
        </header>
      </section>
    </article>
  );
};

export default ProductDetailSkeleton;
