import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { CheckoutsGetFunction, CheckoutsSaveFunction } from "../../../../domain/checkout/model/checkouts";
import { httpCheckoutReviewed } from "./httpCheckoutReviewed";

interface HttpCheckoutsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpCheckoutsGetFunction extends CheckoutsGetFunction<HttpCheckoutsGetFunctionArgs> {}

const getCheckout: HttpCheckoutsGetFunction = () => async () => {
  throw new Error("Checkout not found");
};

interface HttpCheckoutsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutsSaveFunction extends CheckoutsSaveFunction<HttpCheckoutsSaveFunctionArgs> {}

const saveCheckout: HttpCheckoutsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await httpCheckoutReviewed({ httpPost })(aggregateRoot);
  };

export type { HttpCheckoutsSaveFunction };
export { getCheckout, saveCheckout };
