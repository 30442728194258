import { FC } from "react";
import Skeleton from "../../../../../../shared/ui/uiKit/components/atoms/Skeleton/Skeleton";
import "./planned-box.css";

const PlannedBoxSkeleton: FC = () => (
  <>
    <td className="planned-box__col">
      <Skeleton />
    </td>
    <td className="planned-box__col">
      <Skeleton />
    </td>
    <td className="planned-box__col">
      <Skeleton />
    </td>
    <td className="planned-box__col">
      <Skeleton />
    </td>
    <td className="planned-box__col">
      <Skeleton />
    </td>
    <td className="planned-box__col">
      <Skeleton />
    </td>
    <td className="planned-box__col">
      <Skeleton />
    </td>
  </>
);

export { PlannedBoxSkeleton };
