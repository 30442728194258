import { useI18nDate, useI18nTime, I18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import { BoxPreviewStatus } from "../../../../../../domain/boxPreview/model/BoxPreviewStatus";
import Icon, { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../../../i18n/BoxPreviewI18n";
import "./box-preview-summary.css";

const summaryTextByStatus = (status: BoxPreviewStatus) => {
  switch (status) {
    case BoxPreviewStatus.EXPIRED:
      return BoxPreviewI18n.PREVIEW_EXPIRED;
    case BoxPreviewStatus.SURPRISE:
      return BoxPreviewI18n.PREVIEW_SURPRISE_CHOSEN;
    case BoxPreviewStatus.SUBMITTED:
      return BoxPreviewI18n.SUBMITTED;
    default:
      return BoxPreviewI18n.PREVIEW_IN_PROGRESS;
  }
};

type BoxPreviewSummaryProps = {
  readonly status: BoxPreviewStatus;
  readonly expiration: Date;
};
const BoxPreviewSummary: FC<BoxPreviewSummaryProps> = ({ status, expiration }: BoxPreviewSummaryProps) => {
  const noDate = new Date();
  const expirationDate = useI18nDate({ value: expiration || noDate, dateStyle: "long" });
  const expirationTime = useI18nTime({
    value: expiration || noDate,
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <div className="box-preview-summary">
      <Icon variant={IconVariant.INFO} />
      <Text variant={TextVariant.BODY_SMALL}>
        <I18nMessage
          id={summaryTextByStatus(status)}
          prefix={BOX_PREVIEW_I18N_PREFIX}
          values={{ expirationDate, expirationTime }}
        />
      </Text>
    </div>
  );
};

export default BoxPreviewSummary;
