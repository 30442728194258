import { FC, useMemo } from "react";
import RouteFirewall from "../../firewall/RouteFirewall";
import firewallProtected from "../../routing/firewallProtected/firewallProtected";
import { Routes } from "../../routing/Routing";
import { useRoutes } from "react-router-dom";

type RootFirewallProps = {
  readonly config: Partial<Record<Routes, RouteFirewall>>;
  readonly children: JSX.Element;
};
const RootFirewall: FC<RootFirewallProps> = ({ config, children }: RootFirewallProps) => {
  const Component = useMemo(() => firewallProtected(config, () => <>{children}</>), [children, config]);

  return useRoutes([{ path: "*", Component }]);
};

export default RootFirewall;
