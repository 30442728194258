import { UiSettingByKeyView, UiSettingProjection } from "../../projection/viewUiSettingByKey";
import { Storage } from "../../../../infrastructure/persistence/storage";
import { UiSettingDto } from "../../../../infrastructure/persistence/uiSettingDto";

interface ToUiSettingProjectionFunction {
  (uiSettingDto: UiSettingDto): UiSettingProjection;
}

const toUiSettingProjection: ToUiSettingProjectionFunction = (uiSettingDto) => ({
  id: uiSettingDto.id,
  key: uiSettingDto.key,
  value: uiSettingDto.value,
});

interface StorageUiSettingByKeyView extends UiSettingByKeyView {}

interface StorageUiSettingByKeyViewFunctionArgs {
  readonly storage: Storage<UiSettingDto>;
}

interface StorageUiSettingByKeyViewFunction {
  (args: StorageUiSettingByKeyViewFunctionArgs): StorageUiSettingByKeyView;
}

const storageUiSettingByKeyView: StorageUiSettingByKeyViewFunction =
  ({ storage: { read } }) =>
  async ({ key }) => {
    try {
      const uiSettingDto = await read(key);

      return uiSettingDto ? toUiSettingProjection(uiSettingDto) : null;
    } catch (ignored) {
      throw new Error("Could not fetch the uiSetting");
    }
  };

export { storageUiSettingByKeyView };
