import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { viewSelectionOrderByBoxNumber } from "../../../../projection/selectionOrder/viewSelectionOrderByBoxNumber";
import { SelectionOrderProjection } from "../../../../projection/selectionOrder/selectionOrder";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isSelectionOrderRemoved } from "../../../../domain/selectionOrder/model/selectionOrderRemoved";
import { isSelectionOrderSorted } from "../../../../domain/selectionOrder/model/selectionOrderSorted";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isSelectionOrderRemoved(event) || isSelectionOrderSorted(event);

interface UseViewSelectionOrderByBoxNumberFunctionArgs {
  readonly boxNumber: string;
}

interface UseViewSelectionOrderByBoxNumberFunction {
  (args: UseViewSelectionOrderByBoxNumberFunctionArgs): UseQueryFunctionResult<SelectionOrderProjection>;
}

const useViewSelectionOrderByBoxNumber: UseViewSelectionOrderByBoxNumberFunction = ({ boxNumber }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewSelectionOrderByBoxNumber({ boxNumber }),
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  });

export { useViewSelectionOrderByBoxNumber };
