import { FC } from "react";
import { useIntl } from "react-intl";
import Locale from "../../../../../domain/country/model/Locale";
import { cmsToImperialHeight } from "./units";

interface HeightProps {
  readonly height: number;
}
const Height: FC<HeightProps> = ({ height }) => {
  const { locale } = useIntl();

  const { feet, inches } = cmsToImperialHeight({ cms: height });

  const heightFormatted = locale === Locale.en_GB ? `${feet} ft ${inches} in` : `${height} cm`;

  return <>{heightFormatted}</>;
};

export { Height };
