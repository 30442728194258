import { I18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Icon, { IconVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import ActionConfirmationModal from "../../../../../../../../shared/ui/uiKit/components/organisms/actionConfirmationModal/ActionConfirmationModal";
import Alert from "../../../../../../components/molecules/alert/Alert";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../../../../../i18n/BoxPreviewI18n";
import { SelectionAlertProjection } from "../../../../../../../../projection/alert/selectionAlert";
import "./box-preview-sender-confirmation-modal.css";

type BoxPreviewSenderConfirmationModalProps = {
  readonly visible: boolean;
  readonly previewAlerts?: SelectionAlertProjection[];
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};
const BoxPreviewSenderConfirmationModal: FC<BoxPreviewSenderConfirmationModalProps> = ({
  visible,
  previewAlerts,
  onCancel,
  onConfirm,
}) => {
  const previewNonBlockingAlerts = previewAlerts?.filter((alert) => !alert.isBlocker) || [];

  return (
    <ActionConfirmationModal
      cancelButtonText={<I18nMessage id={BoxPreviewI18n.CANCEL} prefix={BOX_PREVIEW_I18N_PREFIX} />}
      className="box-preview-sender-confirmation-modal"
      label="box-preview-sender-confirmation-modal"
      visible={visible}
      confirmButtonText={
        <I18nMessage
          id={previewAlerts && previewAlerts.length > 0 ? BoxPreviewI18n.SEND_ANYWAY : BoxPreviewI18n.SEND}
          prefix={BOX_PREVIEW_I18N_PREFIX}
        />
      }
      title={
        <header className="box-preview-sender-confirmation-modal__header">
          <Icon variant={IconVariant.ALERT_WARNING} />
          <I18nMessage id={BoxPreviewI18n.SEND_PREVIEW_CONFIRMATION_TITLE} prefix={BOX_PREVIEW_I18N_PREFIX} />
        </header>
      }
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <div className="box-preview-sender__alerts">
        {previewNonBlockingAlerts?.map((alert) => (
          <Alert key={alert.key} alert={alert} />
        ))}
      </div>
      <I18nMessage id={BoxPreviewI18n.SEND_PREVIEW_CONFIRMATION} prefix={BOX_PREVIEW_I18N_PREFIX} />
    </ActionConfirmationModal>
  );
};

export default BoxPreviewSenderConfirmationModal;
