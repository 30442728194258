import { useI18nMessage } from "@lookiero/i18n-react";
import { CommandStatus } from "@lookiero/messaging-react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Text, { TextVariant } from "../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import ActionButton from "../../../../../../../shared/ui/uiKit/components/molecules/actionButton/ActionButton";
import TextArea from "../../../../../../../shared/ui/uiKit/components/molecules/textArea/TextArea";
import { asyncActionStateForCommandStatus } from "../../../../../../../shared/ui/uiKit/_common/AsyncActionState";
import AnnotationsI18n, { ANNOTATIONS_I18N_PREFIX } from "../../../../../i18n/AnnotationsI18n";
import Modal from "../../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import { ButtonVariant } from "../../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import "./annotations-modal.css";

interface OnUpdateFunctionArgs {
  readonly positive: string;
  readonly negative: string;
}
interface OnUpdateFunction {
  (args: OnUpdateFunctionArgs): void;
}

interface AnnotationsModalProps {
  readonly visible: boolean;
  readonly locale: string;
  readonly positive?: string;
  readonly negative?: string;
  readonly updatedOn?: string | null;
  readonly updateStatus: CommandStatus;
  readonly onUpdate: OnUpdateFunction;
  readonly onCancel: () => void;
}

const AnnotationsModal: FC<AnnotationsModalProps> = ({
  visible,
  locale,
  positive = "",
  negative = "",
  updatedOn,
  updateStatus,
  onUpdate,
  onCancel,
}) => {
  const titleText = useI18nMessage({ id: AnnotationsI18n.TITLE, prefix: ANNOTATIONS_I18N_PREFIX });
  const positivesText = useI18nMessage({ id: AnnotationsI18n.POSITIVES, prefix: ANNOTATIONS_I18N_PREFIX });
  const negativesText = useI18nMessage({ id: AnnotationsI18n.NEGATIVES, prefix: ANNOTATIONS_I18N_PREFIX });
  const saveText = useI18nMessage({ id: AnnotationsI18n.SAVE, prefix: ANNOTATIONS_I18N_PREFIX });
  const updatedOnText = useI18nMessage({
    id: AnnotationsI18n.UPDATED_ON,
    prefix: ANNOTATIONS_I18N_PREFIX,
    values: {
      updatedOn: updatedOn ? new Date(updatedOn).toLocaleDateString(locale, { month: "short", day: "numeric" }) : "",
    },
  });

  const [positiveValue, setPositiveValue] = useState(positive);
  const positiveRef = useRef(positive);

  const [negativeValue, setNegativeValue] = useState(negative);
  const negativeRef = useRef(negative);

  const handleOnUpdateClick = useCallback(
    () => onUpdate({ positive: positiveValue, negative: negativeValue }),
    [negativeValue, onUpdate, positiveValue],
  );

  const hasChanged = positive !== positiveValue || negative !== negativeValue;

  useEffect(() => {
    if (positive === positiveRef.current) {
      return;
    }

    setPositiveValue(positive);
    positiveRef.current = positive;
  }, [positive]);

  useEffect(() => {
    if (negative === negativeRef.current) {
      return;
    }

    setNegativeValue(negative);
    negativeRef.current = negative;
  }, [negative]);

  return (
    <Modal
      className="annotations-modal"
      label="annotations-modal"
      title={<Text variant={TextVariant.HEADING_3}>{titleText}</Text>}
      visible={visible}
      onHide={onCancel}
    >
      <main className="annotations-modal__main">
        <div className="annotations-modal__content">
          <div className="annotations-modal__textarea">
            <Text className="annotations-modal__label" variant={TextVariant.BODY_SMALL}>
              {positivesText}
            </Text>
            <TextArea placeholder="" value={positiveValue} onChange={setPositiveValue}></TextArea>
          </div>

          <div className="annotations-modal__textarea">
            <Text className="annotations-modal__label" variant={TextVariant.BODY_SMALL}>
              {negativesText}
            </Text>
            <TextArea placeholder="" value={negativeValue} onChange={setNegativeValue}></TextArea>
          </div>
        </div>

        <div className="annotations-modal__footer">
          <ActionButton
            aria-label="annotations-modal-update-button"
            disabled={!hasChanged}
            state={asyncActionStateForCommandStatus[updateStatus]}
            variant={ButtonVariant.PRIMARY}
            onClick={handleOnUpdateClick}
          >
            {saveText}
          </ActionButton>

          {updatedOn && (
            <Text className="annotations-modal__updated-on" variant={TextVariant.BODY_SMALL}>
              {updatedOnText}
            </Text>
          )}
        </div>
      </main>
    </Modal>
  );
};

export { AnnotationsModal };
