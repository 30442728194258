import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { createToastNotification } from "../../../../domain/notification/command/createToastNotification";
import { NotificationLevel } from "../../../../domain/notification/model/notification";
import { Logger } from "../../../../../../infrastructure/logging/Logger";

interface CreateToastNotificationFunctionArgs {
  readonly aggregateId?: string;
  readonly level: NotificationLevel;
  readonly bodyI18nKey: string;
}

interface CreateToastNotificationFunction {
  (args: CreateToastNotificationFunctionArgs): Promise<void>;
}

interface UseCreateToastNotificationFunctionArgs {
  readonly contextId: string;
  readonly logger: Logger;
}

interface UseCreateToastNotificationFunction {
  (args: UseCreateToastNotificationFunctionArgs): [create: CreateToastNotificationFunction, status: CommandStatus];
}

const useCreateToastNotification: UseCreateToastNotificationFunction = ({ contextId, logger }) => {
  const [commandBus, status] = useCommand({ contextId });

  const create: CreateToastNotificationFunction = useCallback(
    async ({ aggregateId, level, bodyI18nKey }) => {
      try {
        await commandBus(
          createToastNotification({
            aggregateId: aggregateId || uuid(),
            level,
            bodyI18nKey,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [commandBus, logger],
  );

  return [create, status];
};

export { useCreateToastNotification };
