import { FC, ReactNode } from "react";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { NoteTemplateProjection } from "../../../../../../projection/noteTemplate/noteTemplate";
import "./note-template.css";

type NoteTemplateProps = {
  readonly noteTemplate: NoteTemplateProjection;
  readonly actions: ReactNode;
};
const NoteTemplate: FC<NoteTemplateProps> = ({ noteTemplate, actions }) => {
  return (
    <article aria-label="note-template" className="note-template" role="listitem">
      <header aria-label="note-template-header" className="note-template__header">
        <div className="note-template__header-info">
          <Text className="note-template__header-title" variant={TextVariant.BODY_BOLD}>
            {noteTemplate.title}
          </Text>
          <Text className="note-template__header-category">{noteTemplate.category}</Text>
        </div>
        {actions}
      </header>
      <div aria-label="note-template-content" className="note-template__content" role="main">
        <Text>
          <span dangerouslySetInnerHTML={{ __html: noteTemplate.content }}></span>
        </Text>
      </div>
    </article>
  );
};

export default NoteTemplate;
