import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { UiSettingProjection, viewUiSettingByKey } from "../../../projection/viewUiSettingByKey";
import { isUiSettingUpdated } from "../../../domain/model/uiSettingUpdated";

interface UseViewUiSettingByKeyFunctionArgs {
  readonly contextId: string;
  readonly key: string;
}

interface UseViewUiSettingByKeyFunction {
  (args: UseViewUiSettingByKeyFunctionArgs): UseQueryFunctionResult<UiSettingProjection>;
}

const useViewUiSettingByKey: UseViewUiSettingByKeyFunction = ({ contextId, key }) =>
  useQuery({
    query: viewUiSettingByKey({ key }),
    contextId,
    invalidation: isUiSettingUpdated,
    options: { refetchOnMount: "always", staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useViewUiSettingByKey };
