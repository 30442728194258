import { FC, ReactNode, useCallback, useMemo } from "react";
import { Routes } from "../../routing/Routing";
import Modal from "../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import RouterTabs from "../../../../shared/ui/uiKit/components/layouts/routerTabs/RouterTabs";
import { useListNoteTemplateCategoriesByPsId } from "../../../../infrastructure/projection/noteTemplateCategory/react/useListNoteTemplateCategoriesByPsId";
import useBoxSharedContext from "../../hooks/useBoxSharedContext";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../i18n/NoteI18n";
import { useI18nMessage } from "@lookiero/i18n-react";
import NavItem from "../../../../shared/ui/uiKit/components/molecules/navItem/NavItem";
import Dropdown from "../../../../shared/ui/uiKit/components/organisms/dropdown/Dropdown";
import NoteTemplateI18n, { NOTE_TEMPLATE_I18N_PREFIX } from "../../i18n/NoteTemplateI18n";
import NoteTemplateCreate from "./components/NoteTemplateCreate";
import Locale from "../../../../domain/country/model/Locale";
import FormInputOption from "../../../../shared/ui/uiKit/_common/FormInputOption";
import useQueryParameters from "../../routing/queryParams/useQueryParameters";
import QueryParams from "../../routing/queryParams/config";
import { useCountNoteTemplatesByCriteria } from "../../../../infrastructure/projection/noteTemplate/react/useCountNoteTemplatesByCriteria";
import { generatePath, useMatch, useNavigate, useParams } from "react-router-dom";
import { useViewPersonalShopper } from "../../../../infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useListHistoryNotesByCustomerId } from "../../../../infrastructure/projection/historyNote/react/useListHistoryNotesByCustomerId";
import "./notes.css";

const mapCategoriesToFormInputOptions = (listNoteTemplateCategories: string[] | undefined): FormInputOption[] =>
  listNoteTemplateCategories?.map((category: string) => ({ label: category, value: category })) || [];

interface NotesProps {
  readonly children?: ReactNode;
}

const Notes: FC<NotesProps> = ({ children }) => {
  const { box } = useBoxSharedContext();
  const navigate = useNavigate();
  const { locale, box: legacyBoxId } = useParams();
  const routeParams = useMemo(() => ({ locale: locale as string, box: legacyBoxId as string }), [legacyBoxId, locale]);
  const navigateBack = useCallback(
    () => navigate(generatePath(Routes.BOX_SELECTION, routeParams)),
    [navigate, routeParams],
  );

  const [personalShopper] = useViewPersonalShopper();

  const [historyNotes] = useListHistoryNotesByCustomerId({ customerId: box.customerId });

  const [listNoteTemplateCategories] = useListNoteTemplateCategoriesByPsId({
    personalShopperId: personalShopper?.id,
  });
  const categoriesOptions = useMemo(
    () => mapCategoriesToFormInputOptions(listNoteTemplateCategories),
    [listNoteTemplateCategories],
  );

  const { params, setParams } = useQueryParameters();
  const categoriesParams = params[QueryParams.CATEGORIES];
  const categories = useMemo(
    () => (categoriesParams ? JSON.parse(categoriesParams as string) : []),
    [categoriesParams],
  );
  const handleChangeCategory = useCallback(
    (newCategories: string | string[] | null | undefined) =>
      setParams({
        [QueryParams.CATEGORIES]: newCategories && newCategories.length > 0 ? JSON.stringify(newCategories) : null,
      }),
    [setParams],
  );

  const categoriesLabel = useI18nMessage({ id: NoteTemplateI18n.TEMPLATES_FILTER, prefix: NOTE_TEMPLATE_I18N_PREFIX });
  const noFiltersI18nMessage = useI18nMessage({
    id: NoteTemplateI18n.TEMPLATES_NO_FILTER,
    prefix: NOTE_TEMPLATE_I18N_PREFIX,
  });
  const noCategoriesMessage = useCallback(() => noFiltersI18nMessage, [noFiltersI18nMessage]);

  const [countNoteTemplates] = useCountNoteTemplatesByCriteria({
    psId: personalShopper?.id,
    categories,
    itemCountWhileQuerying: undefined,
  });

  const isNotesHistoryRoute = Boolean(useMatch(Routes.BOX_SELECTION_HISTORY_NOTES));
  const noteTemplatesMatch = useMatch(`${Routes.BOX_SELECTION_NOTES_TEMPLATES}/*`);
  const noteTemplatesHomeMatch = useMatch(Routes.BOX_SELECTION_NOTES_TEMPLATES);
  const isNoteTemplatesRoute = Boolean(noteTemplatesMatch);
  const isExactNoteTemplatesRoute = Boolean(noteTemplatesHomeMatch);

  const templatesLabel = useI18nMessage({
    id: NoteTemplateI18n.TEMPLATES_TITLE,
    prefix: NOTE_TEMPLATE_I18N_PREFIX,
  });
  const sentNotesLabel = useI18nMessage({ id: NoteI18n.SENT_NOTES, prefix: NOTE_I18N_PREFIX });
  const links = useMemo(
    () => [
      {
        url: generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES, routeParams),
        label: (
          <NavItem active={isNoteTemplatesRoute}>
            {templatesLabel}
            {countNoteTemplates !== undefined && <span className="notes-modal__tab-counter">{countNoteTemplates}</span>}
          </NavItem>
        ),
      },
      {
        url: generatePath(Routes.BOX_SELECTION_HISTORY_NOTES, routeParams),
        label: (
          <NavItem active={isNotesHistoryRoute}>
            {sentNotesLabel}
            <span className="notes-modal__tab-counter">{(historyNotes && historyNotes.length) || 0}</span>
          </NavItem>
        ),
      },
    ],
    [
      countNoteTemplates,
      historyNotes,
      isNoteTemplatesRoute,
      isNotesHistoryRoute,
      routeParams,
      sentNotesLabel,
      templatesLabel,
    ],
  );

  const actions = useMemo(
    () =>
      isNoteTemplatesRoute ? (
        <div className="notes-template-actions">
          <Dropdown
            label={categoriesLabel}
            noOptionsMessage={noCategoriesMessage}
            options={categoriesOptions}
            value={categories}
            isMulti
            onChange={handleChangeCategory}
          />
          <NoteTemplateCreate
            disabled={!isExactNoteTemplatesRoute}
            legacyBoxId={legacyBoxId as string}
            locale={locale as Locale}
          />
        </div>
      ) : null,
    [
      categories,
      categoriesLabel,
      categoriesOptions,
      handleChangeCategory,
      isExactNoteTemplatesRoute,
      isNoteTemplatesRoute,
      legacyBoxId,
      locale,
      noCategoriesMessage,
    ],
  );

  return (
    <section aria-label="notes" className="notes">
      <Modal className="notes-modal" visible onHide={navigateBack}>
        <RouterTabs actions={actions} links={links}>
          {children}
        </RouterTabs>
      </Modal>
    </section>
  );
};

export default Notes;
