import { FC, ReactNode } from "react";
import classNames from "classnames";
import AsyncActionState from "../../../_common/AsyncActionState";
import Modal from "../../layouts/modal/Modal";
import ActionButton from "../../molecules/actionButton/ActionButton";
import Button, { ButtonVariant } from "../../atoms/button/Button";
import Text from "../../atoms/text/Text";
import "./action-confirmation-modal.css";

interface ActionConfirmationModalProps {
  readonly className?: string;
  readonly label?: string;
  readonly visible: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly confirmButtonText: ReactNode;
  readonly cancelButtonText: ReactNode;
  readonly confirmButtonVariant?: ButtonVariant;
  readonly actionState?: AsyncActionState;
  readonly title?: ReactNode;
  readonly children: ReactNode;
}

const ActionConfirmationModal: FC<ActionConfirmationModalProps> = ({
  className,
  label,
  visible,
  onCancel,
  onConfirm,
  confirmButtonText,
  cancelButtonText,
  confirmButtonVariant = ButtonVariant.PRIMARY,
  actionState,
  title,
  children,
}: ActionConfirmationModalProps) => (
  <Modal
    className={classNames("action-confirmation-modal", className)}
    label={label}
    title={title}
    visible={visible}
    onHide={onCancel}
  >
    <Text className="action-confirmation-modal__content" tag="div">
      {children}
    </Text>
    <div className="action-confirmation-modal__actions">
      <Button aria-label="action-confirmation-modal-cancel" type="button" onClick={onCancel}>
        {cancelButtonText}
      </Button>
      <ActionButton
        aria-label="action-confirmation-modal-confirm"
        state={actionState}
        type="button"
        variant={confirmButtonVariant}
        onClick={onConfirm}
      >
        {confirmButtonText}
      </ActionButton>
    </div>
  </Modal>
);

export default ActionConfirmationModal;
