import { useCallback } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { Logger } from "../../../logging/Logger";
import { registerPreviewedWatermark } from "../../../../domain/activityTime/command/registerPreviewedWatermark";

interface RegisterPreviewedWatermarkFunctionArgs {
  readonly watermark: number;
}

interface RegisterPreviewedWatermarkFunction {
  (args: RegisterPreviewedWatermarkFunctionArgs): Promise<void> | undefined;
}

interface UseRegisterActivityTimePreviewedWatermarkFunctionArgs {
  readonly psId?: string;
  readonly boxId?: string;
  readonly logger: Logger;
}

interface UseRegisterActivityTimePreviewedWatermarkFunction {
  (args: UseRegisterActivityTimePreviewedWatermarkFunctionArgs): [
    registerPreviewedWatermakr: RegisterPreviewedWatermarkFunction,
    status: CommandStatus,
  ];
}

const useRegisterActivityTimePreviewedWatermark: UseRegisterActivityTimePreviewedWatermarkFunction = ({
  boxId,
  psId,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const register: RegisterPreviewedWatermarkFunction = useCallback(
    async ({ watermark }) => {
      invariant(boxId, "boxId must be defined in order to register activityTime");
      invariant(psId, "psId must be defined in order to register activityTime");

      try {
        await commandBus(registerPreviewedWatermark({ boxId, psId, watermark }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [boxId, commandBus, logger, psId],
  );

  return [register, status];
};

export { useRegisterActivityTimePreviewedWatermark };
