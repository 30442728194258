import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ProductDetail as ProductDetailTemplate } from "../productDetail/ProductDetail";
import ProductDetailSkeleton from "../productDetail/ProductDetailSkeleton";
import Modal from "../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import useBoxSharedContext from "../../../../hooks/useBoxSharedContext";
import useProductVariantComparer from "../../../../hooks/useProductVariantComparer";
import useProductVariantSelection from "../../../../hooks/useProductVariantSelection";
import ActionButton from "../../../../../../shared/ui/uiKit/components/molecules/actionButton/ActionButton";
import Button, { ButtonVariant } from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { I18nMessage } from "@lookiero/i18n-react";
import ShortlistI18n, { SHORTLIST_I18N_PREFIX } from "../../../../i18n/ShortlistI18n";
import { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { asyncActionStateForCommandStatus } from "../../../../../../shared/ui/uiKit/_common/AsyncActionState";
import { TrackingPage } from "../../../../../tracking/Tracker";
import { useViewPersonalShopper } from "../../../../../projection/personalShopper/react/useViewPersonalShopper";
import { useMatch } from "react-router-dom";
import { Routes } from "../../../../routing/Routing";
import { useViewBoxPreviewByBoxId } from "../../../../../projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { useListLooksForSelection } from "../../../../../projection/looks/react/useListLooksForSelection";
import { ProductVariantProjection } from "../../../../../../projection/product/productVariant";
import { useViewProductById } from "../../../../../projection/product/react/useViewProductById";
import { isProductVariantIdIncludedInSelection } from "../../../../../../projection/selection/selection";
import { useDeselectProductVariant } from "../../../../../domain/selection/react/useDeselectProductVariant";
import { useReplaceProductVariant } from "../../../../../domain/selection/react/useReplaceProductVariant";
import { useSelectProductVariant } from "../../../../../domain/selection/react/useSelectProductVariant";
import { useLogger } from "../../../../../logging/useLogger";
import { CommandStatus } from "@lookiero/messaging-react";
import { useTrackDetailView } from "../../../../../tracking/useTrackDetailView";
import DeselectProductVariantConfirmationModal from "../../../../components/organisms/deselectProductVariantConfirmationModal/DeselectProductVariantConfirmationModal";
import "./product-detail-modal.css";

interface ProductDetailModalProps {
  readonly visible: boolean;
  readonly productId: string | undefined;
  readonly onHide: () => void;
  readonly onSelectProductVariant: (productVariantId: string) => void;
  readonly onDeselectProductVariant: (productVariantId: string) => void;
}
const ProductDetailModal: FC<ProductDetailModalProps> = ({
  visible,
  productId,
  onHide,
  onSelectProductVariant,
  onDeselectProductVariant,
}) => {
  const { box, selection } = useBoxSharedContext();
  const logger = useLogger();

  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box.id });
  const [product] = useViewProductById({ productId, boxId: box.id });
  const [looks] = useListLooksForSelection({ selection });
  const [personalShopper] = useViewPersonalShopper();

  const isSelectionRoute = Boolean(useMatch(`${Routes.BOX_SELECTION}/*`));

  const [selectProductVariant, selectProductVariantStatus] = useSelectProductVariant({
    boxNumber: String(box.boxNumber),
    boxId: box.id,
    selectionId: selection?.selectionId,
    boxPreviewStatus: boxPreview?.status,
    logger,
  });
  const [deselectProductVariant, deselectProductVariantStatus] = useDeselectProductVariant({
    selectionId: selection?.selectionId,
    boxNumber: String(box.boxNumber),
    boxPreviewStatus: boxPreview?.status,
    logger,
  });
  const [replaceProductVariant, replaceProductVariantStatus] = useReplaceProductVariant({
    selectionId: selection?.selectionId,
    boxId: box.id,
    boxNumber: String(box.boxNumber),
    boxPreviewStatus: boxPreview?.status,
    logger,
  });
  const { add: addProductVariantToComparer } = useProductVariantComparer();

  const selectionProductVariantId = product?.productVariants.find((productVariant: ProductVariantProjection) =>
    isProductVariantIdIncludedInSelection(selection, productVariant.id),
  )?.id;
  const selectionProductVariant = product?.productVariants.find(
    (productVariant) => productVariant.id === selectionProductVariantId,
  );
  const [productVariant, setProductVariant] = useProductVariantSelection({
    selectionProductVariant,
    product,
  });

  const handleOnAddToComparer = useCallback(() => {
    if (!product?.id) {
      return;
    }

    addProductVariantToComparer(product.id, productVariant);

    onHide();
  }, [addProductVariantToComparer, onHide, product?.id, productVariant]);

  const handleOnSelectProductVariant = useCallback(async () => {
    try {
      await selectProductVariant({ productVariantId: productVariant.id });

      onSelectProductVariant(productVariant.id);

      onHide();
    } catch (error) {}
  }, [onHide, onSelectProductVariant, productVariant, selectProductVariant]);

  const handleOnReplaceProductVariant = useCallback(
    async (productVariantToReplace: ProductVariantProjection) => {
      try {
        if (selectionProductVariant) {
          await replaceProductVariant({
            deselectedProductVariantId: productVariant.id,
            selectedProductVariantId: productVariantToReplace.id,
          });
        }

        setProductVariant(productVariantToReplace);
      } catch {}
    },
    [productVariant, replaceProductVariant, selectionProductVariant, setProductVariant],
  );

  const deselectProductVariantAndHide = useCallback(
    async (productVariant: ProductVariantProjection) => {
      try {
        await deselectProductVariant({ productVariantId: productVariant.id });

        onDeselectProductVariant(productVariant.id);

        onHide();
      } catch (error) {}
    },
    [deselectProductVariant, onDeselectProductVariant, onHide],
  );

  const [deselectConfirmationVisible, setDeselectConfirmationVisible] = useState(false);
  const showDeselectConfirmationModal = useCallback(() => setDeselectConfirmationVisible(true), []);
  const hideDeselectConfirmationModal = useCallback(() => setDeselectConfirmationVisible(false), []);
  const handleDeselectConfirmed = useCallback(() => {
    hideDeselectConfirmationModal();
    deselectProductVariantAndHide(productVariant);
  }, [deselectProductVariantAndHide, hideDeselectConfirmationModal, productVariant]);

  const handleOnDeselectProductVariant = useCallback(() => {
    const isProductVariantIncludedInALook = looks.some((look) =>
      look.some((lookProductVariantId) => lookProductVariantId === productVariant.id),
    );

    if (isProductVariantIncludedInALook) {
      showDeselectConfirmationModal();
    } else {
      deselectProductVariantAndHide(productVariant);
    }
  }, [deselectProductVariantAndHide, looks, productVariant, showDeselectConfirmationModal]);

  const trackDetailView = useTrackDetailView({
    section: isSelectionRoute ? TrackingPage.SELECTION : TrackingPage.CATALOG,
    psId: personalShopper?.id,
    userId: box.customerId,
  });

  useEffect(() => {
    if (!productId) {
      return;
    }

    trackDetailView({ productId, boxId: box.id });
  }, [box.id, productId, trackDetailView]);

  const actions = useMemo(
    () => (
      <>
        {selectionProductVariant ? (
          <ActionButton
            aria-label="deselect-product-variant"
            className="product-detail-actions__button"
            state={asyncActionStateForCommandStatus[deselectProductVariantStatus]}
            variant={ButtonVariant.DESTRUCTIVE}
            onClick={handleOnDeselectProductVariant}
          >
            <I18nMessage id={ShortlistI18n.REMOVE_FROM_SELECTION} prefix={SHORTLIST_I18N_PREFIX} />
          </ActionButton>
        ) : (
          <ActionButton
            aria-label="select-product-variant"
            className="product-detail-actions__button"
            state={asyncActionStateForCommandStatus[selectProductVariantStatus]}
            variant={ButtonVariant.PRIMARY}
            onClick={handleOnSelectProductVariant}
          >
            <I18nMessage id={ShortlistI18n.ADD_TO_SELECTION} prefix={SHORTLIST_I18N_PREFIX} />
          </ActionButton>
        )}
        <Button
          aria-label="add-to-comparer"
          className="product-detail-actions__button"
          icon={IconVariant.COMPARE}
          onClick={handleOnAddToComparer}
        >
          <I18nMessage id={ShortlistI18n.ADD_TO_COMPARER} prefix={SHORTLIST_I18N_PREFIX} />
        </Button>
      </>
    ),
    [
      deselectProductVariantStatus,
      handleOnAddToComparer,
      handleOnDeselectProductVariant,
      handleOnSelectProductVariant,
      selectProductVariantStatus,
      selectionProductVariant,
    ],
  );

  return (
    <>
      <Modal className="product-detail-modal" visible={visible} onHide={onHide}>
        {product ? (
          <ProductDetailTemplate
            actions={actions}
            disabled={replaceProductVariantStatus === CommandStatus.LOADING}
            product={product}
            productVariant={productVariant}
            onProductVariantChanged={handleOnReplaceProductVariant}
          />
        ) : (
          <ProductDetailSkeleton />
        )}
      </Modal>

      <DeselectProductVariantConfirmationModal
        visible={deselectConfirmationVisible}
        onCancel={hideDeselectConfirmationModal}
        onConfirm={handleDeselectConfirmed}
      />
    </>
  );
};

export { ProductDetailModal };
