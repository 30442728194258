import { FC, useCallback, useEffect, useState } from "react";
import Modal from "../../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import Text, { TextVariant } from "../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { CustomerCarousel } from "../customerCarousel/CustomerCarousel";
import { I18nMessage } from "@lookiero/i18n-react";
import { CUSTOMER_I18N_PREFIX, CustomerI18n } from "../../../../../i18n/CustomerI18n";
import { CustomerZoomCarousel } from "../customerZoomCarousel/CustomerZoomCarousel";
import { IconVariant } from "../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Button from "../../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import "./photos-modal.css";

interface PhotosModalProps {
  readonly photos: string[];
  readonly visible: boolean;
  readonly onCancel: () => void;
}
const PhotosModal: FC<PhotosModalProps> = ({ photos, visible, onCancel }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => setActiveIndex(0), [visible]);

  const [zoomed, setZoomed] = useState(false);
  const handleOnOpenZoom = useCallback(() => setZoomed(true), []);
  const handleOnCloseZoom = useCallback(() => setZoomed(false), []);

  return (
    <>
      <Modal
        className="photos-modal"
        label="photos-modal"
        visible={visible}
        title={
          <Text variant={TextVariant.HEADING_3}>
            <I18nMessage id={CustomerI18n.PHOTOS_MODAL_TITLE} prefix={CUSTOMER_I18N_PREFIX} />
          </Text>
        }
        onHide={onCancel}
      >
        <CustomerCarousel
          activeIndex={activeIndex}
          keyboardControlled={!zoomed}
          photos={photos}
          onChange={setActiveIndex}
          onClick={handleOnOpenZoom}
        />
        <Button className="photos-modal__carousel-zoom" icon={IconVariant.SEARCH} onClick={handleOnOpenZoom} />
      </Modal>

      <Modal label="photos-zoom-modal" visible={zoomed} onHide={handleOnCloseZoom}>
        <CustomerZoomCarousel
          activeIndex={activeIndex}
          photos={photos}
          onChange={setActiveIndex}
          onZoomClose={handleOnCloseZoom}
        />
      </Modal>
    </>
  );
};

export { PhotosModal };
