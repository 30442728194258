import { FC, ReactNode, useCallback, useRef, useState } from "react";
import { useMeasure, useMouseHovered } from "react-use";
import { animated, useSpring } from "react-spring";
import useCaptureAndStopEvent from "../../../../../shared/ui/uiKit/hooks/useCaptureAndStopEvent";
import AspectRatio from "../../../../../shared/ui/uiKit/_common/AspectRatio";
import ProductVariantGallery from "../productVariantGallery/ProductVariantGallery";
import { isVideoSrc } from "../../../util/srcType";
import cdnImageUrl from "../../../util/cdnImageUrl";
import Media from "../../../../../projection/media/model/Media";
import "./product-variant-zoom-gallery.css";

const PRODUCT_VARIANT_ZOOM_GALLERY_DETAIL_WIDTH = 1440;

const config = {
  tension: 15,
  friction: 15,
};

interface ProductVariantZoomGalleryProps {
  readonly media: Media[];
  readonly selectedItemIndex: number;
  readonly onChange: (itemIndex: number) => void;
  readonly onZoomClose: () => void;
}

const ProductVariantZoomGallery: FC<ProductVariantZoomGalleryProps> = ({
  media,
  selectedItemIndex,
  onChange,
  onZoomClose,
}) => {
  /**
   * This explicit dpi (1) is required in order to not re-scaling the image above its intrinsic size.
   */
  const galleryMedia = media.map((media) =>
    isVideoSrc(media.url)
      ? media.url
      : cdnImageUrl({ url: media.url, width: PRODUCT_VARIANT_ZOOM_GALLERY_DETAIL_WIDTH, dpi: 1 }),
  );

  const handleOnZoomClose = useCaptureAndStopEvent(onZoomClose);

  const [hovered, setHovered] = useState(false);
  const handleMouseOut = useCallback(() => setHovered(false), []);
  const handleMouseOver = useCallback(() => setHovered(true), []);

  const zoomGalleryRef = useRef(null);
  const [itemRef, { height }] = useMeasure<HTMLDivElement>();
  const { elY, elH } = useMouseHovered(zoomGalleryRef, { bound: true, whenHovered: true });

  const yDelta = hovered ? (elH / 2 - elY) * (height / (2 * elH)) || 0 : 0;
  const springStyle = useSpring({ transform: `translate3d(0,${yDelta}px,0)`, config });

  const itemWrapper = useCallback(
    (item: ReactNode) => (
      <animated.div
        ref={itemRef}
        aria-label="zoom-gallery-viewport"
        className="product-variant-zoom-gallery__item-wrapper"
        role="presentation"
        style={springStyle}
      >
        {item}
      </animated.div>
    ),
    [itemRef, springStyle],
  );

  return (
    <section
      ref={zoomGalleryRef}
      aria-label="product-variant-zoom-gallery"
      className="product-variant-zoom-gallery"
      onClick={handleOnZoomClose}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <ProductVariantGallery
        aspectRatio={AspectRatio.R_4_5}
        className="product-variant-zoom-gallery__variant-gallery"
        index={selectedItemIndex}
        itemLabel="zoom"
        itemWrapper={itemWrapper}
        media={galleryMedia}
        isActive
        keyboardControlled
        onChange={onChange}
      />
    </section>
  );
};

export default ProductVariantZoomGallery;
