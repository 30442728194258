import { FC } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import { ReactComponent as EmptyCheckoutsImage } from "./empty-checkouts.svg";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { CHECKOUTS_I18N_PREFIX, CheckoutsI18n } from "../../../../i18n/CheckoutsI18n";
import "./empty-checkouts.css";

const EmptyCheckouts: FC = () => (
  <section className="empty-checkouts">
    <EmptyCheckoutsImage className="empty-checkouts__image" />
    <Text className="empty-checkouts__title" variant={TextVariant.HEADING_1}>
      <I18nMessage id={CheckoutsI18n.EMPTY_TITLE} prefix={CHECKOUTS_I18N_PREFIX} />
    </Text>
    <Text variant={TextVariant.BODY}>
      <I18nMessage id={CheckoutsI18n.EMPTY_DESCRIPTION} prefix={CHECKOUTS_I18N_PREFIX} />
    </Text>
  </section>
);

export { EmptyCheckouts };
