import { FC } from "react";
import { useIntl } from "react-intl";
import Locale from "../../../../../domain/country/model/Locale";
import Size, { sizeFormat } from "../../../../../projection/size/model/Size";
import ShortlistI18n from "../../../i18n/ShortlistI18n";
import ProductNotification from "../productNotification/ProductNotification";

type ProductModelNotificationProps = {
  readonly testedSize: Size;
};
const ProductModelNotification: FC<ProductModelNotificationProps> = ({ testedSize }: ProductModelNotificationProps) => {
  const { locale } = useIntl();

  const size = sizeFormat({ locale: locale as Locale, size: testedSize });

  return <ProductNotification i18nMessageId={ShortlistI18n.MODEL_TESTED_SIZE} i18nValues={{ size }} />;
};

export default ProductModelNotification;
