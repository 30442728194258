import { FC } from "react";
import { FeedbackItems, FeedbackItemProvider } from "../../hooks/useFeedbackItem";
import { FeedbackTextareaItem } from "../feedbacks/components/feedbackTextareaItem/FeedbackTextareaItem";
import { FeedbackTextItem } from "../feedbacks/components/feedbackTextItem/FeedbackTextItem";
import { HostDefaultFeedbackItem } from "../feedbacks/components/hostDefaultFeedbackItem/HostDefaultFeedbackItem";
import { Feedbacks } from "../feedbacks/Feedbacks";
import {
  ClosedBoxItemFeedbackProjection,
  ClosedBoxItemFeedbackType,
} from "../../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";

const feedbackItems: FeedbackItems = {
  [ClosedBoxItemFeedbackType.HOST_DEFAULT]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.HOST_TEXTAREA]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.HOST_SELECT]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.HOST_STACK]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.TEXTAREA]: FeedbackTextareaItem,
  [ClosedBoxItemFeedbackType.OPTION]: FeedbackTextItem,
};

interface ClosedBoxItemFeedbackTooltipProps {
  readonly feedbacks: ClosedBoxItemFeedbackProjection[];
}

const ClosedBoxItemFeedbackTooltip: FC<ClosedBoxItemFeedbackTooltipProps> = ({ feedbacks }) => (
  <FeedbackItemProvider feedbackItems={feedbackItems}>
    <Feedbacks feedbacks={feedbacks} />
  </FeedbackItemProvider>
);

export { ClosedBoxItemFeedbackTooltip };
