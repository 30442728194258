import { FC, useCallback, useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import { CheckoutProjection } from "../../../../../../projection/checkout/checkout";
import Card from "../../../../../../shared/ui/uiKit/components/layouts/card/Card";
import {
  ClosedBoxItem,
  ClosedBoxItemOnViewDetailFunction,
} from "../../../../components/organisms/closedBoxItem/ClosedBoxItem";
import { ClosedBoxItemFeedback } from "../../../../components/organisms/closedBoxItem/components/closedBoxItemFeedback/ClosedBoxItemFeedback";
import { ClosedBoxItemSize } from "../../../../components/molecules/closedBoxItemSize/ClosedBoxItemSize";
import { ClosedBoxItemFeedbackProjection } from "../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";
import { CheckoutProductVariantProjection } from "../../../../../../projection/checkout/checkoutProductVariant";
import Checkbox, { CheckboxState } from "../../../../../../shared/ui/uiKit/components/molecules/checkbox/Checkbox";
import { CustomerImage } from "../../../../components/molecules/customerImage/CustomerImage";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { ClosedBoxPreviewButton } from "../../../../components/molecules/closedBoxPreviewButton/ClosedBoxPreviewButton";
import { I18nDate, I18nMessage, useI18nMessage } from "@lookiero/i18n-react";
import Tooltip from "../../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import { CUSTOMER_I18N_PREFIX, CustomerI18n } from "../../../../i18n/CustomerI18n";
import { useReviewCheckout } from "../../../../../domain/checkout/react/useReviewCheckout";
import { CHECKOUTS_I18N_PREFIX, CheckoutsI18n } from "../../../../i18n/CheckoutsI18n";
import "./checkout.css";

interface CheckoutProductVariantProps {
  readonly productVariant: CheckoutProductVariantProjection;
  readonly onViewDetail: ClosedBoxItemOnViewDetailFunction;
}

const CheckoutProductVariant: FC<CheckoutProductVariantProps> = ({ productVariant, onViewDetail }) => {
  const { stripFeedbacks, bottomFeedbacks } = useMemo(
    () =>
      productVariant.feedback.reduce(
        (acc, returnQuestion) => ({
          stripFeedbacks: [
            ...acc.stripFeedbacks,
            ...(returnQuestion.question.section === "strip" ? [returnQuestion] : []),
          ],
          bottomFeedbacks: [
            ...acc.bottomFeedbacks,
            ...(returnQuestion.question.section === "bottom" ? [returnQuestion] : []),
          ],
        }),
        {
          stripFeedbacks: [] as ClosedBoxItemFeedbackProjection[],
          bottomFeedbacks: [] as ClosedBoxItemFeedbackProjection[],
        },
      ),
    [productVariant],
  );

  return (
    <ClosedBoxItem
      className="checkout-product-variant"
      label="checkout-product-variant"
      productVariant={productVariant}
      size={<ClosedBoxItemSize size={productVariant.size} sizeChange={productVariant.sizeChange} />}
      stripFeedback={<ClosedBoxItemFeedback feedbacks={stripFeedbacks} />}
      bottomFeedback={
        <ClosedBoxItemFeedback className="checkout-product-variant__bottom-feedback" feedbacks={bottomFeedbacks} />
      }
      onViewDetail={onViewDetail}
    />
  );
};

interface OnNavigateToBoxFunctionArgs {
  readonly boxNumber: string;
}

interface OnNavigateToBoxFunction {
  (args: OnNavigateToBoxFunctionArgs): void;
}

interface OnViewBoxPreviewFunctionArgs {
  readonly boxId: string;
  readonly customerId: string;
}

interface OnViewBoxPreviewFunction {
  (args: OnViewBoxPreviewFunctionArgs): void;
}

interface OnViewProductVariantDetailFunctionArgs {
  readonly boxId: string;
  readonly customerId: string;
  readonly productId: string;
  readonly productVariantId: string;
}

interface OnViewProductVariantDetailFunction {
  (args: OnViewProductVariantDetailFunctionArgs): void;
}

interface CheckoutProps {
  readonly personalShopperId: string;
  readonly checkout: CheckoutProjection;
  readonly onViewProductVariantDetail: OnViewProductVariantDetailFunction;
  readonly onViewBoxPreview: OnViewBoxPreviewFunction;
  readonly onNavigateToBox: OnNavigateToBoxFunction;
}

const Checkout: FC<CheckoutProps> = ({
  personalShopperId,
  checkout,
  onViewProductVariantDetail,
  onViewBoxPreview,
  onNavigateToBox,
}) => {
  const [reviewed, setReviewed] = useState(false);
  const [reviewCheckout] = useReviewCheckout({ personalShopperId });

  const handleOnCheckboxChanged = useCallback(
    async (checked: boolean) => {
      if (checked) {
        setReviewed(true);
        try {
          setTimeout(async () => {
            await reviewCheckout({ checkoutId: checkout.id });
          }, 1000);
        } catch (e) {
          setReviewed(false);
        }
      }
    },
    [checkout.id, reviewCheckout],
  );

  const customerFirstOrderLabelId = checkout.customer.firstOrder
    ? CheckoutsI18n.CUSTOMER_FIRST_ORDER
    : CheckoutsI18n.CUSTOMER_NON_FIRST_ORDER;
  const orderCommentLabel = useI18nMessage({ id: CustomerI18n.COMMENT_GENERAL_PREVIOUS, prefix: CUSTOMER_I18N_PREFIX });
  const checkoutCommentLabel = useI18nMessage({
    id: CustomerI18n.COMMENT_CHECKOUT,
    prefix: CUSTOMER_I18N_PREFIX,
  });
  const customerServiceCommentLabel = useI18nMessage({
    id: CustomerI18n.COMMENT_CUSTOMER_SERVICE,
    prefix: CUSTOMER_I18N_PREFIX,
  });

  const handleOnViewProductVariantDetail: ClosedBoxItemOnViewDetailFunction = useCallback(
    ({ productId, productVariantId }) =>
      onViewProductVariantDetail({
        productId,
        productVariantId,
        boxId: checkout.boxId,
        customerId: checkout.customer.id,
      }),
    [checkout.boxId, checkout.customer.id, onViewProductVariantDetail],
  );

  const handleOnViewBoxPreview = useCallback(() => {
    onViewBoxPreview({ boxId: checkout.boxId, customerId: checkout.customer.id });
  }, [checkout.boxId, checkout.customer.id, onViewBoxPreview]);

  const handleOnCustomerImageClick = useCallback(() => {
    onNavigateToBox({ boxNumber: checkout.boxNumber });
  }, [checkout.boxNumber, onNavigateToBox]);

  const style = useSpring({
    delay: 200,
    to: reviewed
      ? { transform: "translate3d(110%, 0px, 0px)", maxHeight: "0px" }
      : { transform: "translate3d(0%, 0px, 0px)", maxHeight: "1000px" },
  });

  return (
    <animated.article aria-label="checkout" role="listitem" style={style}>
      <Card className="checkout">
        <Checkbox
          className="checkout__checkbox"
          state={reviewed ? CheckboxState.CHECKED : CheckboxState.DEFAULT}
          onChange={handleOnCheckboxChanged}
        />
        <div className="checkout__content">
          <div className="checkout__customer">
            <div
              aria-label="checkout-customer-image"
              className="checkout__customer-image"
              onClick={handleOnCustomerImageClick}
            >
              <CustomerImage id={checkout.customer.photos[0]} />
            </div>
            <section className="checkout__info">
              <div className="checkout__header">
                <div className="checkout__header-row">
                  <Text variant={TextVariant.HEADING_3}>{checkout.customer.name}</Text>
                  <Text className="checkout__separator">|</Text>
                  <Text className="checkout__subscription" variant={TextVariant.HEADING_3}>
                    <I18nMessage id={customerFirstOrderLabelId} prefix={CHECKOUTS_I18N_PREFIX} />
                  </Text>
                </div>
                <div className="checkout__header-row">
                  <Text className="checkout__box-data" variant={TextVariant.BODY_SMALL}>
                    <I18nMessage id={CheckoutsI18n.BOX_TITLE} prefix={CHECKOUTS_I18N_PREFIX} /> {checkout.boxNumber}
                    <span> · </span>
                    <I18nDate dateStyle="medium" value={checkout.plannedFor} />
                  </Text>
                </div>
              </div>

              <div className="checkout__comment-container">
                {checkout.comment && (
                  <Tooltip title={checkout.comment}>
                    <Text className="checkout__comment-text" variant={TextVariant.BODY_SMALL}>
                      <span className="checkout__comment-title">{orderCommentLabel}: </span> {checkout.comment}
                    </Text>
                  </Tooltip>
                )}

                {checkout.checkoutComment && (
                  <Tooltip title={checkout.checkoutComment}>
                    <Text className="checkout__comment-text" variant={TextVariant.BODY_SMALL}>
                      <span className="checkout__comment-title">{checkoutCommentLabel}: </span>{" "}
                      {checkout.checkoutComment}
                    </Text>
                  </Tooltip>
                )}

                {checkout.customerServiceComment && (
                  <Tooltip title={checkout.customerServiceComment}>
                    <Text className="checkout__comment-text" variant={TextVariant.BODY_SMALL}>
                      <span className="checkout__comment-title">{customerServiceCommentLabel}: </span>
                      {checkout.customerServiceComment}
                    </Text>
                  </Tooltip>
                )}
              </div>
              <div className="checkout__row">
                {checkout.preview && (
                  <ClosedBoxPreviewButton status={checkout.preview.status} onClick={handleOnViewBoxPreview} />
                )}
              </div>
            </section>
          </div>
          <div className="checkout__items">
            {checkout.items.map((item) => (
              <CheckoutProductVariant
                key={item.productVariantId}
                productVariant={item}
                onViewDetail={handleOnViewProductVariantDetail}
              />
            ))}
          </div>
        </div>
      </Card>
    </animated.article>
  );
};

export { Checkout };
export type { OnViewBoxPreviewFunction, OnNavigateToBoxFunction, OnViewProductVariantDetailFunction };
