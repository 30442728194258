import { FC } from "react";
import ShortlistFilter from "./components/shortlistFilter/ShortlistFilter";
import { ShortlistFilterProjection } from "../../../../../projection/shortlistFilter/shortlistFilter";

interface ShortlistFiltersProps {
  readonly filters: ShortlistFilterProjection[];
}

const ShortlistFilters: FC<ShortlistFiltersProps> = ({ filters }: ShortlistFiltersProps) => (
  <>
    {filters.map((filter: ShortlistFilterProjection) => (
      <ShortlistFilter key={filter.id} filter={filter} />
    ))}
  </>
);

export default ShortlistFilters;
