import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { Section } from "./Tracker";

interface TrackActivatePredefinedFilterFunctionArgs {
  readonly enabled: boolean;
}
interface TrackActivatePredefinedFilterFunction {
  (args: TrackActivatePredefinedFilterFunctionArgs): void;
}

interface UseTrackActivatePredefinedFilterFunctionArgs {
  readonly section: Section;
  readonly boxId?: string;
  readonly psId?: string;
  readonly userId?: string;
}
interface UseTrackActivatePredefinedFilterFunction {
  (args: UseTrackActivatePredefinedFilterFunctionArgs): TrackActivatePredefinedFilterFunction;
}

const useTrackActivatePredefinedFilter: UseTrackActivatePredefinedFilterFunction = ({
  section,
  psId,
  userId,
  boxId,
}) => {
  const tracker = useTracker();

  const trackActivatePredefinedFilter: TrackActivatePredefinedFilterFunction = useCallback(
    ({ enabled }) => {
      if (!(tracker && section && psId && boxId && userId)) {
        return;
      }

      tracker.activatePredefinedFilter({
        boxId,
        psId,
        section,
        userId,
        enabled,
      });
    },
    [boxId, psId, section, tracker, userId],
  );

  return trackActivatePredefinedFilter;
};

export { useTrackActivatePredefinedFilter };
