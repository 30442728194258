import { uniqBy } from "lodash";
import {
  ShortlistFilterAction,
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../../projection/shortlistFilter/shortlistFilter";

interface StateFilteringValue {
  readonly id: string;
  readonly value?: string;
}

interface LeafFiltersFunctionArgs {
  readonly filters: ShortlistFilterProjection[];
  readonly result?: ShortlistFilterProjection[];
}

interface LeafFiltersFunction {
  (args: LeafFiltersFunctionArgs): ShortlistFilterProjection[];
}

const leafFilters: LeafFiltersFunction = ({ filters, result = [] }) => {
  for (let i = 0, length = filters.length; i < length; i++) {
    if (!filters[i].children || filters[i].children?.length === 0) {
      result.push(filters[i]);
    } else {
      result = leafFilters({ filters: filters[i].children || [], result });
    }
  }

  return result;
};

interface ActionFilteredLeafFiltersFunctionArgs extends LeafFiltersFunctionArgs {
  readonly action: ShortlistFilterAction;
  readonly includeChildren?: boolean;
}

interface ActionFilteredLeafFiltersFunction {
  (args: ActionFilteredLeafFiltersFunctionArgs): ShortlistFilterProjection[];
}

const actionFilteredLeafFilters: ActionFilteredLeafFiltersFunction = ({
  action,
  filters,
  result = [],
  includeChildren = false,
}) => {
  for (let i = 0, length = filters.length; i < length; i++) {
    if (!filters[i].children || filters[i].children?.length === 0) {
      if (filters[i].metadata?.action === action) {
        result.push(filters[i]);
      }
    } else {
      const actionFilteredFilters = filters[i].children?.filter((filter) =>
        includeChildren
          ? filter.metadata?.action === null || filter.metadata?.action === action
          : filter.metadata?.action === action,
      );

      result = actionFilteredLeafFilters({ action, filters: actionFilteredFilters || [], result, includeChildren });
    }
  }

  return result;
};

interface IsAnyLeafActiveFunctionArgs {
  readonly action?: ShortlistFilterAction;
  readonly filter: ShortlistFilterProjection;
  readonly state: StateFilteringValue[];
}

interface IsAnyLeafActiveFunction {
  (args: IsAnyLeafActiveFunctionArgs): boolean;
}

const isAnyLeafActive: IsAnyLeafActiveFunction = ({ action, filter, state }) => {
  let filters = leafFilters({ filters: [filter] });

  if (action) {
    filters = filters.filter(
      (leafFilter) => leafFilter.metadata?.action === action || leafFilter.metadata?.action === null,
    );
  }

  return [filter, ...filters].some(({ id: leafId }) => state.find(({ id: stateId }) => leafId === stateId));
};

interface IsAnyLeafFilteringActiveFunctionArgs {
  readonly filter: ShortlistFilterProjection;
  readonly state: StateFilteringValue[];
  readonly filtering?: { readonly id: string }[];
}

interface IsAnyLeafFilteringActiveFunction {
  (args: IsAnyLeafFilteringActiveFunctionArgs): boolean;
}

const isAnyLeafFilteringActive: IsAnyLeafFilteringActiveFunction = ({ filter, state, filtering }) => {
  const filterLeafFilters = leafFilters({ filters: [filter] });

  return [filter, ...filterLeafFilters]
    .filter(({ id: leafId }) => state.some(({ id: stateId }) => leafId === stateId))
    .every(({ id: leafId }) => filtering?.some(({ id: stateId }) => leafId === stateId));
};

interface AreAllLeavesActiveFunctionArgs {
  readonly action?: ShortlistFilterAction;
  readonly filter: ShortlistFilterProjection;
  readonly state: StateFilteringValue[];
}

interface AreAllLeavesActiveFunction {
  (args: AreAllLeavesActiveFunctionArgs): boolean;
}

const areAllLeavesActive: AreAllLeavesActiveFunction = ({ action, filter, state }): boolean => {
  const filterLeafFilters = action
    ? actionFilteredLeafFilters({ action, filters: [filter] })
    : leafFilters({ filters: [filter] });

  return filterLeafFilters.every(({ id: leafId }) => state.find(({ id: stateId }) => leafId === stateId));
};

interface ActivateFunctionArgs {
  readonly action?: ShortlistFilterAction;
  readonly filter: ShortlistFilterProjection;
  readonly state: StateFilteringValue[];
}

interface ActivateFunction {
  (args: ActivateFunctionArgs): StateFilteringValue[];
}

const activate: ActivateFunction = ({ action, filter, state }) => {
  const filterLeafFilters = filter.children
    ? (action ? actionFilteredLeafFilters({ action, filters: [filter] }) : leafFilters({ filters: [filter] })).map(
        ({ id }) => ({ id }),
      )
    : [{ id: filter.id }];

  return [...state, ...filterLeafFilters];
};

interface DeactivateFunctionArgs {
  readonly action?: ShortlistFilterAction;
  readonly filter: ShortlistFilterProjection;
  readonly state: StateFilteringValue[];
}

interface DeactivateFunction {
  (args: DeactivateFunctionArgs): StateFilteringValue[];
}

const deactivate: DeactivateFunction = ({ action, filter, state }) => {
  const filterLeafFilters = (
    action
      ? actionFilteredLeafFilters({ action, filters: [filter], includeChildren: true })
      : leafFilters({ filters: [filter] })
  ).map(({ id }) => ({ id }));

  return state.filter(
    ({ id: stateId }) => stateId !== filter.id && !filterLeafFilters.find(({ id: leafId }) => stateId === leafId),
  );
};

interface FindParentFilterFunctionArgs {
  readonly filters: ShortlistFilterProjection[];
  readonly id: string;
}

interface FindParentFilterFunction {
  (args: FindParentFilterFunctionArgs): ShortlistFilterProjection | null;
}

const findParentFilter: FindParentFilterFunction = ({ filters, id }) => {
  if (!filters) {
    return null;
  }

  return filters.reduce((acc, filter) => {
    if (acc || !filter.children) {
      return acc;
    }

    if (filter.children?.find(({ id: filterId }) => filterId === id)) {
      return filter;
    }

    return findParentFilter({ filters: filter.children, id }) || null;
  }, null as ShortlistFilterProjection | null);
};

interface CountFiltersFunctionArgs {
  readonly state: StateFilteringValue[];
  readonly filters: ShortlistFilterProjection[];
}

interface CountFiltersFunction {
  (args: CountFiltersFunctionArgs): number;
}

const countFilters: CountFiltersFunction = ({ state, filters }) => {
  const parentFilters: ShortlistFilterProjection[] = state
    .map(({ id: stateId }) => findParentFilter({ filters, id: stateId }))
    .filter((filter) => filter) as ShortlistFilterProjection[];

  const { hostRangeFilters, nonHostRangeFilters } = parentFilters.reduce(
    (acc, filter) => ({
      hostRangeFilters: [
        ...acc.hostRangeFilters,
        ...([ShortlistFilterType.HOST_RANGE, ShortlistFilterType.HOST_RANGE_2].includes(filter.type) ? [filter] : []),
      ],
      nonHostRangeFilters: [
        ...acc.nonHostRangeFilters,
        ...(![ShortlistFilterType.HOST_RANGE, ShortlistFilterType.HOST_RANGE_2].includes(filter.type) ? [filter] : []),
      ],
    }),
    {
      hostRangeFilters: [] as ShortlistFilterProjection[],
      nonHostRangeFilters: [] as ShortlistFilterProjection[],
    },
  );

  return uniqBy(hostRangeFilters, "id").length + nonHostRangeFilters.length;
};

interface FindBoundFunctionArgs {
  readonly state: StateFilteringValue[];
  readonly filters: ShortlistFilterProjection[];
  readonly boundType: ShortlistFilterType.RANGE_LOWER_BOUND | ShortlistFilterType.RANGE_UPPER_BOUND;
}

interface FindBoundFunction {
  (args: FindBoundFunctionArgs): ShortlistFilterProjection | undefined;
}

const findBound: FindBoundFunction = ({ filters, state, boundType }) =>
  filters?.reduce(
    (filter, childFilter) =>
      filter ||
      childFilter.children?.find(
        ({ type, id }) => type === boundType && state.find(({ id: stateId }) => stateId === id),
      ),
    undefined as ShortlistFilterProjection | undefined,
  );

export type { StateFilteringValue };
export {
  activate,
  deactivate,
  leafFilters,
  isAnyLeafActive,
  areAllLeavesActive,
  countFilters,
  isAnyLeafFilteringActive,
  findBound,
};
