import { useI18nMessage } from "@lookiero/i18n-react";
import { FC, useMemo } from "react";
import { AxisOptions, Chart, Series } from "react-charts";
import RealTimeBoxProductionI18n, {
  REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
} from "../../../../i18n/RealTimeBoxProductionI18n";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { PersonalShopperProductionProjection } from "../../../../../../projection/personalShopperProduction/personalShopperProduction";
import "./real-time-box-production-chart.css";

const BAR_MIN_WIDTH = 24;

interface PersonalShopperProductionDatum {
  readonly psNumber: number;
  readonly closedBoxes?: number;
  readonly sentPreviews?: number;
}
interface RealTimeBoxProductionChartProps {
  readonly personalShopperProduction: PersonalShopperProductionProjection[];
}
const RealTimeBoxProductionChart: FC<RealTimeBoxProductionChartProps> = ({ personalShopperProduction }) => {
  const chartTitle = useI18nMessage({
    id: RealTimeBoxProductionI18n.CHART_TITLE,
    prefix: REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
  });
  const closedBoxesLabel = useI18nMessage({
    id: RealTimeBoxProductionI18n.CLOSED_BOXES_CHART_LABEL,
    prefix: REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
  });
  const sentPreviewsLabel = useI18nMessage({
    id: RealTimeBoxProductionI18n.SENT_PREVIEWS_CHART_LABEL,
    prefix: REAL_TIME_BOX_PRODUCTION_I18N_PREFIX,
  });

  const data = useMemo(
    () => [
      {
        label: closedBoxesLabel,
        color: "#feb779",
        data: personalShopperProduction.map(({ psNumber, closedBoxes }) => ({
          psNumber,
          closedBoxes,
        })),
      },
      {
        label: sentPreviewsLabel,
        color: "#9dc7de",
        data: personalShopperProduction.map(({ psNumber, sentPreviews }) => ({
          psNumber,
          sentPreviews,
        })),
      },
    ],
    [closedBoxesLabel, personalShopperProduction, sentPreviewsLabel],
  );

  const primaryAxis = useMemo(
    (): AxisOptions<PersonalShopperProductionDatum> => ({
      getValue: (datum) => datum.psNumber,
      scaleType: "band",
      showGrid: false,
      formatters: {
        scale: (value: unknown) => `PS: ${value as string}`,
      },
      innerBandPadding: 0.4,
      innerSeriesBandPadding: 0.4,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<PersonalShopperProductionDatum>[] => [
      {
        getValue: (datum) => datum.closedBoxes || datum.sentPreviews || 0,
      },
    ],
    [],
  );

  const options = useMemo(
    () => ({
      data,
      primaryAxis,
      secondaryAxes,
      padding: {
        top: 12,
        bottom: 12,
        left: 12,
        right: 12,
      },
      primaryCursor: {
        showLabel: false,
      },
      secondaryCursor: {
        show: false,
      },
      getSeriesStyle: (series: Series<PersonalShopperProductionDatum>) => ({
        stroke: series.originalSeries.color,
        strokeWidth: 2,
        fill: series.originalSeries.color,
      }),
    }),
    [data, primaryAxis, secondaryAxes],
  );

  return (
    <div className="real-time-box-production-chart">
      <Text className="real-time-box-production-chart__title" variant={TextVariant.BODY_BOLD}>
        {chartTitle}
      </Text>

      <div className="real-time-box-production-chart__chart-container">
        <div
          className="real-time-box-production-chart__chart"
          style={{ minWidth: BAR_MIN_WIDTH * personalShopperProduction.length }}
        >
          <Chart options={options} />
        </div>
      </div>

      <div className="real-time-box-production-chart__legend">
        <div className="real-time-box-production-chart__legend-item real-time-box-production-chart__legend-item--closed-boxes">
          <div className="real-time-box-production-chart__legend-item-badge" />
          <Text>{closedBoxesLabel}</Text>
        </div>
        <div className="real-time-box-production-chart__legend-item real-time-box-production-chart__legend-item--sent-previews">
          <div className="real-time-box-production-chart__legend-item-badge" />
          <Text>{sentPreviewsLabel}</Text>
        </div>
      </div>
    </div>
  );
};

export { RealTimeBoxProductionChart };
