import { useCallback } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { registerActivityTime } from "../../../../domain/activityTime/command/registerActivityTime";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { Logger } from "../../../logging/Logger";
import { ActivityTimeSection } from "../../../../domain/activityTime/model/activityTime";

interface RegisterActivityTimeFunctionArgs {
  readonly init: number;
  readonly end: number;
  readonly section: ActivityTimeSection;
  readonly userIsActive: boolean;
}

interface RegisterActivityTimeFunction {
  (args: RegisterActivityTimeFunctionArgs): Promise<void> | undefined;
}

interface UseRegisterActivityTimeArgs {
  readonly psId?: string;
  readonly boxId?: string;
  readonly logger: Logger;
}

interface UseRegisterActivityTimeFunction {
  (args: UseRegisterActivityTimeArgs): [register: RegisterActivityTimeFunction, status: CommandStatus];
}

const useRegisterActivityTime: UseRegisterActivityTimeFunction = ({ boxId, psId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const register: RegisterActivityTimeFunction = useCallback(
    async ({ init, end, section, userIsActive }) => {
      invariant(boxId, "boxId must be defined in order to register activityTime");
      invariant(psId, "psId must be defined in order to register activityTime");

      try {
        await commandBus(registerActivityTime({ boxId, psId, init, end, section, userIsActive }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [boxId, commandBus, logger, psId],
  );

  return [register, status];
};

export { useRegisterActivityTime };
