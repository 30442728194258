import { useMemo } from "react";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import ShortlistFilterHeader from "../shortlistFilterHeader/ShortlistFilterHeader";
import ShortlistFilterItemRoot from "../shortlistFilterItemRoot/ShortlistFilterItemRoot";
import { ShortlistFilterSlider } from "../shortlistFilterSlider/ShortlistFilterSlider";
import "./range-host-shortlist-filter-item.css";

const RangeHostShortlistFilterItem: ShortlistFilterItemHOC = ({ variant, useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function RangeHostShortlistFilterItemComponent({ filter, level }) {
    const ShortlistFilterSliderComponent = useMemo(() => ShortlistFilterSlider({ variant, useShortlistFiltering }), []);
    const ShortlistFilterItemRootComponent = useMemo(() => ShortlistFilterItemRoot({ useShortlistFiltering }), []);

    return (
      <ShortlistFilterItemRootComponent
        className="range-host-shortlist-filter-item"
        filter={filter}
        item={<ShortlistFilterHeader filter={filter} level={level} />}
        level={level}
      >
        <div className="range-host-shortlist-filter-item__slider">
          <ShortlistFilterSliderComponent filter={filter} />
        </div>
      </ShortlistFilterItemRootComponent>
    );
  };

export { RangeHostShortlistFilterItem };
