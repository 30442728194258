import { I18nMessage } from "@lookiero/i18n-react";
import classNames from "classnames";
import { FC, useCallback, useState } from "react";
import { boxPreviewFinalStatuses } from "../../../../../../domain/boxPreview/model/BoxPreviewFinalStatuses";
import Icon, { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import Modal from "../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import { BoxPreviewDetail } from "../boxPreviewDetail/BoxPreviewDetail";
import BoxPreviewI18n, { BOX_PREVIEW_I18N_PREFIX } from "../../../../i18n/BoxPreviewI18n";
import { BoxPreviewProjection } from "../../../../../../projection/boxPreview/boxPreview";
import "./box-preview-detail-collapsible.css";

type BoxPreviewDetailCollapsibleProps = {
  readonly boxPreview: BoxPreviewProjection;
  readonly onClick?: (productVariantId: string) => void;
};

const BoxPreviewDetailCollapsible: FC<BoxPreviewDetailCollapsibleProps> = ({ boxPreview, onClick }) => {
  const [opened, setOpened] = useState(false);
  const toggleOpened = useCallback(() => setOpened((opened) => !opened), []);

  const showAlertIndicator = boxPreviewFinalStatuses.includes(boxPreview.status);

  return (
    <>
      <div
        className={classNames("box-preview-detail-collapsible", {
          "box-preview-detail-collapsible--opened": opened,
        })}
        onClick={toggleOpened}
      >
        <div>
          <Text variant={TextVariant.BODY_BOLD}>
            <I18nMessage id={BoxPreviewI18n.PREVIEW_DETAIL_TITLE} prefix={BOX_PREVIEW_I18N_PREFIX} />
          </Text>
          {showAlertIndicator && <span className="box-preview-detail-collapsible__indicator" />}
        </div>
        <Icon variant={IconVariant.CHEVRON_DOWN} />
      </div>

      <Modal className="box-preview-detail-collapsible__modal" visible={opened} onHide={toggleOpened}>
        <BoxPreviewDetail boxPreview={boxPreview} onClick={onClick} />
      </Modal>
    </>
  );
};

export { BoxPreviewDetailCollapsible };
