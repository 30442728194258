import { I18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import { animated, useTransition } from "react-spring";
import Text, { TextVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../../../../i18n/SelectionI18n";
import { SelectionProductVariantProjection } from "../../../../../../../../projection/selection/selection";
import "./candidate-tag.css";

type CandidateTagProps = {
  readonly selectedProductVariant: SelectionProductVariantProjection;
};
const CandidateTag: FC<CandidateTagProps> = ({ selectedProductVariant }: CandidateTagProps) => {
  const transitions = useTransition(selectedProductVariant.isCandidate, {
    from: { opacity: 0, transform: "scale(1.2)" },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(1.2)" },
    unique: true,
  });

  return (
    <>
      {transitions(
        (props, item) =>
          item && (
            <animated.div className="candidate-tag" style={props}>
              <Text variant={TextVariant.SMALL_CAPS}>
                <I18nMessage id={SelectionI18n.CANDIDATE_LABEL} prefix={SELECTION_I18N_PREFIX} />
              </Text>
            </animated.div>
          ),
      )}
    </>
  );
};

export default CandidateTag;
