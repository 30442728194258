import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { QueryStatus, useQuery } from "@lookiero/messaging-react";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { NoteTemplateProjection } from "../../../../projection/noteTemplate/noteTemplate";
import { NoteTemplateCategory } from "../../../../projection/noteTemplateCategory/noteTemplateCategory";
import { searchNoteTemplatesByCriteria } from "../../../../projection/noteTemplate/searchNoteTemplatesByCriteria";
import { isNoteTemplateWritten } from "../../../../domain/noteTemplate/model/noteTemplateWritten";
import { isNoteTemplateRemoved } from "../../../../domain/noteTemplate/model/noteTemplateRemoved";

interface UseSearchNoteTemplatesByCriteriaFunctionArgs {
  readonly psId: string | undefined;
  readonly categories: NoteTemplateCategory[];
  readonly page: number;
  readonly perPage: number;
}

interface UseSearchNoteTemplatesByCriteriaFunction {
  (args: UseSearchNoteTemplatesByCriteriaFunctionArgs): [Record<number, NoteTemplateProjection[]>, QueryStatus];
}

const useSearchNoteTemplatesByCriteria: UseSearchNoteTemplatesByCriteriaFunction = ({
  psId,
  categories,
  page,
  perPage,
}) => {
  const [pagedNoteTemplates, setPagedNoteTemplates] = useState<Record<number, NoteTemplateProjection[]>>({});
  const pagedNoteTemplatesRef = useRef(pagedNoteTemplates);
  pagedNoteTemplatesRef.current = pagedNoteTemplates;

  const invalidatePagedNoteTemplates = useCallback(() => setPagedNoteTemplates({}), []);

  const queryId = ["SearchNoteTemplatesByCriteria", psId, perPage, categories, page];
  const invalidationId = ["SearchNoteTemplatesByCriteria", psId, perPage, categories];

  const [result, status] = useQuery<NoteTemplateProjection[], Error>({
    contextId: MESSAGING_CONTEXT_ID,
    id: queryId,
    invalidationId,
    query: searchNoteTemplatesByCriteria({
      criteria: {
        psId: psId as string,
        page,
        perPage,
        categories,
      },
    }),
    invalidation: (event: DomainEvent<MessageName>) => {
      const invalidate = isNoteTemplateWritten(event) || isNoteTemplateRemoved(event);

      if (invalidate) {
        invalidatePagedNoteTemplates();
      }

      return invalidate;
    },
    options: { staleTime: 5 * 60 * 1000, enabled: psId !== undefined },
  });

  /**
   * Add new page-results to pagedNoteTemplates
   */
  useEffect(() => {
    if (!result || isEqual(pagedNoteTemplatesRef.current[page], result)) {
      return;
    }
    setPagedNoteTemplates({ ...pagedNoteTemplatesRef.current, [page]: result });
  }, [result, page]);

  return [pagedNoteTemplates, status];
};

export { useSearchNoteTemplatesByCriteria };
