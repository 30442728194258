import { FC } from "react";
import { Potential as CustomerPotential } from "../../../../../../../projection/customer/potential";
import Pill from "../../../../../../../shared/ui/uiKit/components/molecules/pill/Pill";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CUSTOMER_POTENTIAL_I18N_PREFIX } from "../../../../../i18n/CustomerI18n";
import "./potential.css";
import invariant from "ts-invariant";

interface CustomerPotentialType {
  readonly classname: string;
  readonly icon: string;
}

const CUSTOMER_POTENTIAL_MAP: Record<CustomerPotential, CustomerPotentialType> = {
  [CustomerPotential.LOW]: {
    classname: "potential--low",
    icon: "🥉",
  },
  [CustomerPotential.MEDIUM]: {
    classname: "potential--medium",
    icon: "🥈",
  },
  [CustomerPotential.HIGH]: {
    classname: "potential--high",
    icon: "🥇",
  },
  [CustomerPotential.TOP]: {
    classname: "potential--top",
    icon: "💎",
  },
};

interface PotentialProps {
  readonly potential: CustomerPotential;
}

const Potential: FC<PotentialProps> = ({ potential }) => {
  const potentialText = useI18nMessage({ id: potential.toLowerCase(), prefix: CUSTOMER_POTENTIAL_I18N_PREFIX });

  const customerPotential = CUSTOMER_POTENTIAL_MAP[potential];

  invariant(customerPotential, "No customer potential found!");

  return (
    <Pill className={customerPotential.classname}>
      {customerPotential.icon} {potentialText}
    </Pill>
  );
};

export { Potential };
