import { FC, useCallback, useState } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import useBoxSharedContext from "../../../../hooks/useBoxSharedContext";
import { SelectionProducts } from "./components/selectionProducts/SelectionProducts";
import SelectionHeader from "./components/selectionHeader/SelectionHeader";
import Looks from "./components/looks/Looks";
import Button from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../../i18n/SelectionI18n";
import { BoxPreviewSender } from "./components/boxPreviewSender/BoxPreviewSender";
import { useSendBoxPreview } from "../../../../../../infrastructure/domain/boxPreview/react/useSendBoxPreview";
import { BoxPreviewDetailCollapsible } from "../../../boxPreviewDetail/components/boxPreviewDetailCollapsible/BoxPreviewDetailCollapsible";
import { useTrackClick } from "../../../../../tracking/useTrackClick";
import { Subsection, TrackingClick, TrackingPage } from "../../../../../tracking/Tracker";
import { useTrackSelectDeselectProductVariant } from "../../../../../tracking/useTrackSelectDeselectProductVariant";
import { useTrackPreviewSent } from "../../../../../tracking/useTrackPreviewSent";
import { useViewPersonalShopper } from "../../../../../../infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useViewBoxPreviewByBoxId } from "../../../../../../infrastructure/projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { SelectionAlertProjection, isSelectionAlert } from "../../../../../../projection/alert/selectionAlert";
import { useListAlertsByBoxNumber } from "../../../../../../infrastructure/projection/alert/react/useListAlertsByBoxNumber";
import { LookProjection } from "../../../../../../projection/looks/look";
import { LookTemplate } from "../../../../../../projection/looks/lookTemplate";
import { useListLooksForSelection } from "../../../../../../infrastructure/projection/looks/react/useListLooksForSelection";
import { useAutomaticSelection } from "../../../../hooks/useAutomaticSelection";
import { useParams } from "react-router-dom";
import Locale from "../../../../../../domain/country/model/Locale";
import { useLogger } from "../../../../../logging/useLogger";
import { asyncActionStateForCommandStatus } from "../../../../../../shared/ui/uiKit/_common/AsyncActionState";
import { AlertContext } from "../../../../../../projection/alert/alert";
import { SelectionProductVariantProjection } from "../../../../../../projection/selection/selection";
import { ProductVariantWithProductInfoModal } from "../../../productVariantWithProductInfo/ProductVariantWithProductInfoModal";
import "./selection.css";

interface HandleOnSaveFunctionArgs {
  readonly look: LookProjection;
  readonly lookTemplate: LookTemplate;
}
interface HandleOnSaveFunction {
  (args: HandleOnSaveFunctionArgs): void;
}

interface HandleOnClickBoxPreviewSenderFunctionArgs {
  readonly alerts: SelectionAlertProjection[];
}
interface HandleOnClickBoxPreviewSenderFunction {
  (args: HandleOnClickBoxPreviewSenderFunctionArgs): void;
}

interface HandleOnDeselectProductVariantFunctionArgs {
  readonly position: number;
  readonly productVariantId: string;
}
interface HandleOnDeselectProductVariantFunction {
  (args: HandleOnDeselectProductVariantFunctionArgs): void;
}

interface HandleOnClickProductFunctionArgs {
  readonly position: number;
  readonly productId: string;
}
interface HandleOnClickProductFunction {
  (args: HandleOnClickProductFunctionArgs): void;
}

type SelectionProps = {
  readonly isNoteCollapsed: boolean;
  readonly onToggleNote: () => void;
};

const Selection: FC<SelectionProps> = ({ isNoteCollapsed, onToggleNote }) => {
  const logger = useLogger();
  const { locale } = useParams<{ locale: Locale }>();
  const { box, selection } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();
  const [looks] = useListLooksForSelection({ selection });

  const selectionProductVariantIds =
    selection?.products.map(
      (product) =>
        (
          product.productVariants.find(
            (productVariant) => productVariant.isSelected,
          ) as SelectionProductVariantProjection
        ).id,
    ) || [];

  const alerts = useListAlertsByBoxNumber({ boxNumber: String(box.boxNumber) });
  const { selectionAlerts } = useAutomaticSelection();
  const previewAlerts = selectionAlerts?.filter((alert) =>
    isSelectionAlert(alert) ? alert.context.includes(AlertContext.PREVIEW) : false,
  );

  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box?.id });
  const [sendBoxPreview, sendBoxPreviewStatus] = useSendBoxPreview({
    boxId: box.id,
    selectionId: selection?.selectionId,
    selectionProductVariantIds,
    looks,
    logger,
  });
  const trackSendBoxPreview = useTrackPreviewSent({
    boxId: box.id,
    section: TrackingPage.SELECTION,
    userId: box.customerId,
    psId: personalShopper?.id,
  });

  const handleSendBoxPreview = useCallback(async () => {
    await sendBoxPreview();
    trackSendBoxPreview({ boxNumber: String(box.boxNumber) });
  }, [box.boxNumber, sendBoxPreview, trackSendBoxPreview]);

  const trackClick = useTrackClick({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.SELECTION,
    userId: box.customerId,
  });
  const trackSelectDeselectProductVariant = useTrackSelectDeselectProductVariant({
    boxId: box.id,
    psId: personalShopper?.id,
    section: TrackingPage.SELECTION,
    subsection: Subsection.SELECTION,
    userId: box.customerId,
  });

  const [lookCreatorVisible, setLookCreatorVisible] = useState(false);
  const showLookCreator = useCallback(() => setLookCreatorVisible(true), []);
  const hideLookCreator = useCallback(() => setLookCreatorVisible(false), []);

  const handleOnClickShowLookCreator = useCallback(() => {
    showLookCreator();
    trackClick({
      clickId: TrackingClick.ACCESS_CREATE_LOOK,
    });
  }, [showLookCreator, trackClick]);

  const handleOnDeselectProductVariant: HandleOnDeselectProductVariantFunction = useCallback(
    ({ position, productVariantId }) => {
      trackSelectDeselectProductVariant({
        position,
        productVariantId,
        select: false,
      });
    },
    [trackSelectDeselectProductVariant],
  );

  const handleOnClickProduct: HandleOnClickProductFunction = useCallback(
    ({ position, productId }) => {
      trackClick({
        clickId: TrackingClick.ITEM,
        position,
        productId,
      });
    },
    [trackClick],
  );

  const [productVariantId, setProductVariantId] = useState<string>("");
  const [productVariantWithProductInfoModalVisible, setProductVariantWithProductInfoModalVisible] = useState(false);
  const handleOnClickBoxPreviewDetailProductVariant = useCallback((selectedProductVariantId: string) => {
    setProductVariantId(selectedProductVariantId);
    setProductVariantWithProductInfoModalVisible(true);
  }, []);
  const handleOnCancelProductVariantWithProductInfoModal = useCallback(
    () => setProductVariantWithProductInfoModalVisible(false),
    [],
  );

  const handleOnClickBoxPreviewSender: HandleOnClickBoxPreviewSenderFunction = useCallback(
    ({ alerts }) => {
      trackClick({
        clickId: TrackingClick.SEND_PREVIEW,
        alerts,
      });
    },
    [trackClick],
  );

  const handleOnChangedTemplate = useCallback(
    (lookTemplate: LookTemplate) =>
      trackClick({
        clickId: TrackingClick.CHANGE_LOOK_TEMPLATE,
        lookTemplate,
      }),
    [trackClick],
  );

  const handleOnSaveLook: HandleOnSaveFunction = useCallback(
    ({ look, lookTemplate }) => {
      hideLookCreator();
      trackClick({
        clickId: TrackingClick.CREATE_LOOK,
        look,
        lookTemplate,
      });
    },
    [hideLookCreator, trackClick],
  );

  return (
    <section className="selection" data-testid="selection">
      <ProductVariantWithProductInfoModal
        boxId={box.id}
        customerId={box.customerId}
        origin={TrackingPage.SELECTION}
        productVariantId={productVariantId}
        visible={productVariantWithProductInfoModalVisible}
        onCancel={handleOnCancelProductVariantWithProductInfoModal}
      />

      <SelectionHeader
        alerts={alerts}
        boxPreviewStatus={boxPreview?.status}
        isNoteCollapsed={isNoteCollapsed}
        products={selection?.products}
        onToggleNote={onToggleNote}
      >
        {boxPreview ? (
          <BoxPreviewDetailCollapsible boxPreview={boxPreview} onClick={handleOnClickBoxPreviewDetailProductVariant} />
        ) : (
          <BoxPreviewSender
            looks={looks}
            previewAlerts={previewAlerts}
            sendBoxPreviewState={asyncActionStateForCommandStatus[sendBoxPreviewStatus]}
            onClick={handleOnClickBoxPreviewSender}
            onSendBoxPreview={handleSendBoxPreview}
          />
        )}
        {!boxPreview && (
          <Button
            aria-label="selection-header-create-look"
            disabled={!Boolean(selection?.products.length !== 0)}
            icon={IconVariant.LOOK_TEMPLATE}
            onClick={handleOnClickShowLookCreator}
          >
            <I18nMessage id={SelectionI18n.CREATE_LOOK} prefix={SELECTION_I18N_PREFIX} />
          </Button>
        )}
      </SelectionHeader>

      <div className="selection__content">
        <div className="selection__preview-and-products">
          <SelectionProducts
            alerts={selectionAlerts}
            locale={locale as Locale}
            onClickProduct={handleOnClickProduct}
            onDeselectProductVariant={handleOnDeselectProductVariant}
          />
        </div>
        {!boxPreview && (
          <Looks
            creatorVisible={lookCreatorVisible}
            onChangedTemplate={handleOnChangedTemplate}
            onHideCreator={hideLookCreator}
            onSave={handleOnSaveLook}
          />
        )}
      </div>
    </section>
  );
};

export { Selection };
