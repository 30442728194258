/**
 * This type has been extracted from type-fest library
 * https://github.com/sindresorhus/type-fest/blob/main/source/empty-object.d.ts
 */
declare const emptyObjectSymbol: unique symbol;
type EmptyObject = { [emptyObjectSymbol]?: never };

enum ClosedBoxItemFeedbackType {
  HOST_DEFAULT = "HOST_DEFAULT",
  HOST_TEXTAREA = "HOST_TEXTAREA",
  HOST_SELECT = "HOST_SELECT",
  HOST_STACK = "HOST_STACK",
  TEXTAREA = "TEXTAREA",
  OPTION = "OPTION",
}

type ClosedBoxItemFeedbackIconPrefix = "return_question.icon";

type ClosedBoxItemFeedbackIcon =
  | `${ClosedBoxItemFeedbackIconPrefix}.filled_heart`
  | `${ClosedBoxItemFeedbackIconPrefix}.crossed_heart`
  | `${ClosedBoxItemFeedbackIconPrefix}.quality`
  | `${ClosedBoxItemFeedbackIconPrefix}.price`
  | `${ClosedBoxItemFeedbackIconPrefix}.cut`
  | `${ClosedBoxItemFeedbackIconPrefix}.others`
  | `${ClosedBoxItemFeedbackIconPrefix}.size_small`
  | `${ClosedBoxItemFeedbackIconPrefix}.size_big`
  | `${ClosedBoxItemFeedbackIconPrefix}.size_correct`
  | `${ClosedBoxItemFeedbackIconPrefix}.shoes_comfortable`
  | `${ClosedBoxItemFeedbackIconPrefix}.shoes_uncomfortable`
  | `${ClosedBoxItemFeedbackIconPrefix}.comment`;

interface ClosedBoxItemFeedbackComponentOption {
  readonly icon: ClosedBoxItemFeedbackIcon;
  readonly translationKey: string;
  readonly child?: ClosedBoxItemFeedbackAnswer<ClosedBoxItemFeedbackType.OPTION>;
}

interface ClosedBoxItemFeedbackComponentTextarea {
  readonly icon: ClosedBoxItemFeedbackIcon;
  readonly comment: string;
}

type ClosedBoxItemFeedbackComponent = Record<ClosedBoxItemFeedbackType, EmptyObject> & {
  [ClosedBoxItemFeedbackType.OPTION]: ClosedBoxItemFeedbackComponentOption;
  [ClosedBoxItemFeedbackType.TEXTAREA]: ClosedBoxItemFeedbackComponentTextarea;
};

type ClosedBoxItemFeedbackSection = "strip" | "bottom";

interface ClosedBoxItemFeedbackQuestion {
  readonly id: string;
  readonly translationKey: string;
  readonly section: ClosedBoxItemFeedbackSection;
}

type ClosedBoxItemFeedbackAnswer<FT extends ClosedBoxItemFeedbackType = ClosedBoxItemFeedbackType> =
  FT extends infer K extends ClosedBoxItemFeedbackType
    ? { readonly type: K } & ClosedBoxItemFeedbackComponent[K]
    : never;

interface ClosedBoxItemFeedback {
  readonly question: ClosedBoxItemFeedbackQuestion;
  readonly answer: ClosedBoxItemFeedbackAnswer;
}

export { ClosedBoxItemFeedbackType };
export type {
  ClosedBoxItemFeedback as ClosedBoxItemFeedbackProjection,
  ClosedBoxItemFeedbackQuestion as ClosedBoxItemFeedbackQuestionProjection,
  ClosedBoxItemFeedbackAnswer as ClosedBoxItemFeedbackAnswerProjection,
  ClosedBoxItemFeedbackIcon,
};
