import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";

const COUNT_CHECKOUTS_BY_PERSONAL_SHOPPER_ID = "count_checkouts_by_personal_shopper_id";

interface CountCheckoutsByPersonalShopperIdPayload {
  readonly personalShopperId: string;
}

interface CountCheckoutsByPersonalShopperId
  extends Query<typeof COUNT_CHECKOUTS_BY_PERSONAL_SHOPPER_ID>,
    CountCheckoutsByPersonalShopperIdPayload {}

interface CountCheckoutsByPersonalShopperIdFunction {
  (payload: CountCheckoutsByPersonalShopperIdPayload): CountCheckoutsByPersonalShopperId;
}

const countCheckoutsByPersonalShopperId: CountCheckoutsByPersonalShopperIdFunction = (payload) => ({
  ...query({ name: COUNT_CHECKOUTS_BY_PERSONAL_SHOPPER_ID }),
  ...payload,
});

type CountCheckoutsByPersonalShopperIdResult = number;

interface CheckoutsCountByPersonalShopperIdViewArgs extends CancelableQueryViewArgs {
  readonly personalShopperId: string;
}

interface CheckoutsCountByPersonalShopperIdView {
  (args: CheckoutsCountByPersonalShopperIdViewArgs): Promise<CountCheckoutsByPersonalShopperIdResult>;
}

interface CountCheckoutsByPersonalShopperIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CheckoutsCountByPersonalShopperIdView;
}

const countCheckoutsByPersonalShopperIdHandler: QueryHandlerFunction<
  CountCheckoutsByPersonalShopperId,
  CountCheckoutsByPersonalShopperIdResult,
  CountCheckoutsByPersonalShopperIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ personalShopperId }) =>
    view({ personalShopperId, signal });

export type {
  CheckoutsCountByPersonalShopperIdView,
  CountCheckoutsByPersonalShopperId,
  CountCheckoutsByPersonalShopperIdResult,
};
export {
  COUNT_CHECKOUTS_BY_PERSONAL_SHOPPER_ID,
  countCheckoutsByPersonalShopperId,
  countCheckoutsByPersonalShopperIdHandler,
};
