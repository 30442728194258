import { useCallback } from "react";
import { useTracker } from "./useTracker";
import { Section } from "./Tracker";

interface TrackPageViewFunction {
  (): void;
}

interface UseTrackPageViewFunctionArgs {
  readonly boxId: string;
  readonly psId?: string;
  readonly section: Section;
  readonly userId: string;
  readonly origin?: Section;
}

interface UseTrackPageViewFunction {
  (args: UseTrackPageViewFunctionArgs): TrackPageViewFunction;
}

const useTrackPageView: UseTrackPageViewFunction = ({ section, psId, userId, boxId, origin }) => {
  const tracker = useTracker();

  const trackPageView = useCallback(() => {
    if (!(tracker && psId)) {
      return;
    }

    tracker.pageView({
      boxId,
      psId,
      section,
      userId,
      origin,
    });
  }, [boxId, origin, psId, section, tracker, userId]);

  return trackPageView;
};

export { useTrackPageView };
