import { FC } from "react";
import { useIntl } from "react-intl";
import Locale from "../../../../../domain/country/model/Locale";
import MeasurementProjection, { MeasurementFormat } from "../../../../../projection/measurement/model/Measurement";

type MeasurementProps = {
  readonly measurement: MeasurementProjection;
  readonly format?: keyof MeasurementFormat;
};
const Measurement: FC<MeasurementProps> = ({ measurement, format = "format" }: MeasurementProps) => {
  const { locale } = useIntl();

  return <>{locale === Locale.en_GB ? measurement.uk[format] : measurement.lookiero[format]}</>;
};

export default Measurement;
