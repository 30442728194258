import { FC } from "react";
import Modal from "../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import { ProductVariantWithProductInfo } from "./ProductVariantWithProductInfo";
import { TrackingPage } from "../../../tracking/Tracker";

interface ProductVariantWithProductInfoModalProps {
  readonly productVariantId: string;
  readonly boxId: string;
  readonly customerId: string;
  readonly visible: boolean;
  readonly origin: TrackingPage;
  readonly onCancel: () => void;
}

const ProductVariantWithProductInfoModal: FC<ProductVariantWithProductInfoModalProps> = ({
  productVariantId,
  boxId,
  customerId,
  visible,
  origin,
  onCancel,
}) => (
  <Modal label="product-variant-with-product-info-modal" visible={visible} onHide={onCancel}>
    <ProductVariantWithProductInfo
      boxId={boxId}
      customerId={customerId}
      origin={origin}
      productVariantId={productVariantId}
      visible={visible}
    />
  </Modal>
);
export { ProductVariantWithProductInfoModal };
