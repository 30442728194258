import { ClosedBoxItemFeedbackType } from "../../../../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";
import Text, { TextVariant } from "../../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { FeedbackItem } from "../FeedbackItem";
import "./feedback-textarea-item.css";

const FeedbackTextareaItem: FeedbackItem<ClosedBoxItemFeedbackType.TEXTAREA> = ({ answer }) => (
  <Text className="feedback-textarea-item" tag="p" variant={TextVariant.BODY_SMALL}>
    <strong>{answer.comment}</strong>
  </Text>
);

export { FeedbackTextareaItem };
