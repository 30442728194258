import { command, Command } from "@lookiero/messaging";

const REVIEW_CHECKOUT = "review_checkout";

interface ReviewCheckoutPayload {
  readonly aggregateId: string;
  readonly personalShopperId: string;
}

interface ReviewCheckout extends Command<typeof REVIEW_CHECKOUT>, ReviewCheckoutPayload {}

interface ReviewCheckoutFunction {
  (payload: ReviewCheckoutPayload): ReviewCheckout;
}

const reviewCheckout: ReviewCheckoutFunction = ({ aggregateId, ...payload }) => ({
  ...command({ name: REVIEW_CHECKOUT, aggregateId }),
  ...payload,
});

export type { ReviewCheckout };
export { REVIEW_CHECKOUT, reviewCheckout };
