import { useListNoteAlertsByBoxNumber } from "./useListNoteAlertsByBoxNumber";
import { AlertProjection } from "../../../../projection/alert/alert";
import { useAutomaticSelection } from "../../../ui/hooks/useAutomaticSelection";

interface UseListAlertsByBoxNumberFunctionArgs {
  readonly boxNumber: string;
}

interface UseListAlertsByBoxNumberFunction {
  (args: UseListAlertsByBoxNumberFunctionArgs): AlertProjection[];
}

const useListAlertsByBoxNumber: UseListAlertsByBoxNumberFunction = ({ boxNumber }) => {
  const { selectionAlerts } = useAutomaticSelection();
  const [noteAlerts] = useListNoteAlertsByBoxNumber({ boxNumber });

  return [...(selectionAlerts ? selectionAlerts : []), ...(noteAlerts ? noteAlerts : [])];
};

export { useListAlertsByBoxNumber };
