import { I18nMessage, useI18nMessage, useIntl } from "@lookiero/i18n-react";
import { EditorState } from "draft-js";
import { FC, FormEventHandler, useCallback, useMemo, useRef, useState } from "react";
import Button, { ButtonVariant } from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import ActionButton from "../../../../../../shared/ui/uiKit/components/molecules/actionButton/ActionButton";
import Input from "../../../../../../shared/ui/uiKit/components/molecules/input/Input";
import AsyncActionState from "../../../../../../shared/ui/uiKit/_common/AsyncActionState";
import filterNoteContent from "../../../../views/selectionAndNote/components/note/filterNoteContent";
import NoteEditorStatus from "../../../../views/selectionAndNote/components/note/components/noteEditorStatus/NoteEditorStatus";
import NoteTemplateI18n, { NOTE_TEMPLATE_I18N_PREFIX } from "../../../../i18n/NoteTemplateI18n";
import {
  NoteTemplateValidation,
  NoteTemplateValidationErrorId,
} from "../../../../../../projection/noteTemplateValidation/noteTemplateValidation";
import { NoteTemplateProjection } from "../../../../../../projection/noteTemplate/noteTemplate";
import NoteEditorToolbar from "../../../../components/organisms/noteEditorToolbar/NoteEditorToolbar";
import Editor from "../../../../components/organisms/editor/Editor";
import "./note-template-form.css";

type NoteTemplateFormProps = {
  readonly noteTemplate: NoteTemplateProjection;
  readonly noteTemplateValidation: NoteTemplateValidation | undefined;
  readonly onCancel: () => void;
  readonly onChanged: (noteTemplate: NoteTemplateProjection) => void;
  readonly onSave: (noteTemplate: NoteTemplateProjection) => void;
  readonly onSaveState: AsyncActionState;
};

const NoteTemplateForm: FC<NoteTemplateFormProps> = ({
  noteTemplate,
  noteTemplateValidation = { errors: [] },
  onCancel,
  onChanged,
  onSave,
  onSaveState,
}: NoteTemplateFormProps) => {
  const [titleTouched, setTitleTouched] = useState(false);
  const [categoryTouched, setCategoryTouched] = useState(false);
  const [contentTouched, setContentTouched] = useState(false);
  const noteTemplateRef = useRef(noteTemplate);
  const { formatMessage } = useIntl();

  const titlePlaceholder = useI18nMessage({
    id: NoteTemplateI18n.TEMPLATES_FORM_FIELD_TITLE,
    prefix: NOTE_TEMPLATE_I18N_PREFIX,
  });
  const categoryPlaceholder = useI18nMessage({
    id: NoteTemplateI18n.TEMPLATES_FORM_FIELD_CATEGORY,
    prefix: NOTE_TEMPLATE_I18N_PREFIX,
  });
  const titleErrorMessage = useMemo(() => {
    const titleError = noteTemplateValidation.errors.find(({ id }) => id === NoteTemplateValidationErrorId.TITLE);

    return titleError ? formatMessage({ id: titleError.message }) : "";
  }, [formatMessage, noteTemplateValidation.errors]);
  const categoryErrorMessage = useMemo(() => {
    const categoryError = noteTemplateValidation.errors.find(({ id }) => id === NoteTemplateValidationErrorId.CATEGORY);

    return categoryError ? formatMessage({ id: categoryError.message }) : "";
  }, [formatMessage, noteTemplateValidation.errors]);
  const contentErrorMessage = useMemo(() => {
    const contentError = noteTemplateValidation.errors.find(({ id }) => id === NoteTemplateValidationErrorId.CONTENT);

    return contentError ? formatMessage({ id: contentError.message }) : "";
  }, [formatMessage, noteTemplateValidation.errors]);

  const handleOnTitleChanged = useCallback(
    (title: string) => {
      setTitleTouched(true);
      onChanged({ ...noteTemplate, title });
    },
    [noteTemplate, onChanged],
  );
  const handleOnCategoryChanged = useCallback(
    (category: string) => {
      setCategoryTouched(true);
      onChanged({ ...noteTemplate, category });
    },
    [noteTemplate, onChanged],
  );
  const handleOnContentChanged = useCallback(
    (content: string) => {
      onChanged({ ...noteTemplate, content });
      setContentTouched(true);
    },
    [noteTemplate, onChanged],
  );
  const handleOnSubmit: FormEventHandler<HTMLFormElement> = useCallback((event) => event.preventDefault(), []);
  const handleOnSave = useCallback(() => onSave(noteTemplate), [noteTemplate, onSave]);

  const toolbar = useCallback(
    (state: EditorState, onChange: (state: EditorState) => void) => (
      <NoteEditorToolbar state={state} onChange={onChange}>
        <NoteEditorStatus state={onSaveState} />
      </NoteEditorToolbar>
    ),
    [onSaveState],
  );

  const isModified =
    noteTemplateRef.current.title !== noteTemplate.title ||
    noteTemplateRef.current.category !== noteTemplate.category ||
    noteTemplateRef.current.content !== noteTemplate.content;

  return (
    <form aria-label="note-template-form" className="note-template-form" onSubmit={handleOnSubmit}>
      <Input
        error={titleTouched ? titleErrorMessage : undefined}
        placeholder={titlePlaceholder}
        value={noteTemplate.title || ""}
        onChange={handleOnTitleChanged}
      />
      <Input
        error={categoryTouched ? categoryErrorMessage : undefined}
        placeholder={categoryPlaceholder}
        value={noteTemplate.category || ""}
        onChange={handleOnCategoryChanged}
      />
      <Editor
        error={contentTouched ? contentErrorMessage : undefined}
        filter={filterNoteContent}
        toolbar={toolbar}
        value={noteTemplate.content}
        onChanged={handleOnContentChanged}
        onChangedDebounce={0}
      />
      <div className="note-template-form__actions">
        <Button onClick={onCancel}>
          <I18nMessage id={NoteTemplateI18n.TEMPLATES_CANCEL} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
        </Button>
        <ActionButton
          aria-label="save-template-button"
          disabled={!isModified || noteTemplateValidation.errors.length > 0}
          state={onSaveState}
          type="submit"
          variant={ButtonVariant.PRIMARY}
          onClick={handleOnSave}
        >
          <I18nMessage id={NoteTemplateI18n.TEMPLATES_SAVE} prefix={NOTE_TEMPLATE_I18N_PREFIX} />
        </ActionButton>
      </div>
    </form>
  );
};

export default NoteTemplateForm;
