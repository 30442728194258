import classNames from "classnames";
import { FC } from "react";
import Button from "../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { AlertProjection } from "../../../../../projection/alert/alert";
import "./alerts-toggle.css";

type AlertsToggleProps = {
  readonly alerts?: AlertProjection[];
  readonly onToggle?: () => void;
};
const AlertsToggle: FC<AlertsToggleProps> = ({ alerts, onToggle }: AlertsToggleProps) => {
  const hasAlerts = alerts && alerts.length > 0;
  const hasBlockerAlerts = alerts?.some((alert) => alert.isBlocker);

  return (
    <div className="alerts-toggle">
      <Button aria-label="toggle-alerts" disabled={!hasAlerts} icon={IconVariant.BASKET} onClick={onToggle} />
      {hasAlerts && (
        <span
          className={classNames("alerts-toggle__indicator", {
            "alerts-toggle__indicator--blocker": hasBlockerAlerts,
          })}
        />
      )}
    </div>
  );
};

export default AlertsToggle;
