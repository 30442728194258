import { FC, ReactNode, useCallback } from "react";
import classNames from "classnames";
import { ClosedBoxItemProjection } from "../../../../../projection/_shared/closedBox/closedBoxItem";
import Picture from "../../../../../shared/ui/uiKit/components/atoms/picture/Picture";
import AspectRatio from "../../../../../shared/ui/uiKit/_common/AspectRatio";
import Price from "../../atoms/price/Price";
import Sizing from "../../atoms/sizing/Sizing";
import cdnImageUrl from "../../../util/cdnImageUrl";
import { mainImageFromMedia } from "../../../../../projection/media/model/Media";
import "./closed-box-item.css";

const CLOSED_BOX_ITEM_PRODUCT_VARIANT_WIDTH = 180;

interface OnViewDetailFunctionArgs {
  readonly productId: string;
  readonly productVariantId: string;
}
interface OnViewDetailFunction {
  (args: OnViewDetailFunctionArgs): void;
}

interface ClosedBoxItemProps {
  readonly productVariant: ClosedBoxItemProjection;
  readonly stripFeedback: ReactNode;
  readonly bottomFeedback: ReactNode;
  readonly size: ReactNode;
  readonly onViewDetail: OnViewDetailFunction;
  readonly label?: string;
  readonly className?: string;
}

const ClosedBoxItem: FC<ClosedBoxItemProps> = ({
  productVariant,
  stripFeedback,
  bottomFeedback,
  size,
  onViewDetail,
  label,
  className,
}) => {
  const productVariantPictureSrc = cdnImageUrl({
    url: mainImageFromMedia(productVariant.media).url,
    width: CLOSED_BOX_ITEM_PRODUCT_VARIANT_WIDTH,
  });

  const handleOnClick = useCallback(
    () =>
      onViewDetail?.({
        productId: productVariant.productId,
        productVariantId: productVariant.productVariantId,
      }),
    [onViewDetail, productVariant.productId, productVariant.productVariantId],
  );

  return (
    <article
      aria-label={label}
      role="listitem"
      className={classNames("closed-box-item", className, {
        "closed-box-item--returned": !productVariant.kept,
      })}
      onClick={handleOnClick}
    >
      <section className="closed-box-item__image">
        <Picture
          aspectRatio={AspectRatio.R_4_5}
          className="closed-box-item__picture"
          label={productVariant.productId}
          src={productVariantPictureSrc}
          lazy
        />

        <div className="closed-box-item__iconography">{stripFeedback}</div>
        <Price price={productVariant.price} />
        {!productVariant.kept && (
          <svg className="closed-box-item__returned-stroke" preserveAspectRatio="none" viewBox="0 0 100 100">
            <line vectorEffect="non-scaling-stroke" x1="0" x2="100" y1="0" y2="100" />
          </svg>
        )}
      </section>
      <section className="closed-box-item__details">
        <div className="closed-box-item__size">
          {size}
          <Sizing sizing={productVariant.sizing} />
        </div>
        {bottomFeedback}
      </section>
    </article>
  );
};

export type { OnViewDetailFunction as ClosedBoxItemOnViewDetailFunction };
export { ClosedBoxItem };
