import { isCheckoutReviewed } from "../../../../domain/checkout/model/checkoutReviewed";
import { HttpCheckoutsSaveFunction } from "./httpCheckouts";

interface HttpCheckoutReviewedFunction extends HttpCheckoutsSaveFunction {}

const httpCheckoutReviewed: HttpCheckoutReviewedFunction =
  ({ httpPost }) =>
  async ({ aggregateId, personalShopperId, domainEvents }) => {
    const checkoutReviewed = domainEvents.find(isCheckoutReviewed);

    if (!checkoutReviewed) {
      return;
    }

    await httpPost({
      endpoint: "/review-checkout",
      body: {
        checkoutId: aggregateId,
        personalShopperId,
      },
    });
  };

export { httpCheckoutReviewed };
