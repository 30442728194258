import { FC, useCallback, useState } from "react";
import { HistoryShopProductVariantProjection } from "../../../../../../../../projection/historyShop/historyShopProductVariant";
import { PersonalShopperProjection } from "../../../../../../../../projection/personalShopper/personalShopper";
import { TrackingPage } from "../../../../../../../tracking/Tracker";
import { useTrackDetailView } from "../../../../../../../tracking/useTrackDetailView";
import Card from "../../../../../../../../shared/ui/uiKit/components/layouts/card/Card";
import {
  ClosedBoxItem,
  ClosedBoxItemOnViewDetailFunction,
} from "../../../../../../components/organisms/closedBoxItem/ClosedBoxItem";
import { HistoryShopProductVariantFeedback } from "./components/historyShopProductVariantFeedback/HistoryShopProductVariantFeedback";
import { ClosedBoxItemSize } from "../../../../../../components/molecules/closedBoxItemSize/ClosedBoxItemSize";
import Tooltip from "../../../../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import Icon, { IconVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { ProductVariantWithProductInfoModal } from "../../../../../productVariantWithProductInfo/ProductVariantWithProductInfoModal";
import "./history-shop.css";

interface HistoryShopProductVariantProps {
  readonly productVariant: HistoryShopProductVariantProjection;
  readonly onViewDetail: ClosedBoxItemOnViewDetailFunction;
}

const HistoryShopProductVariant: FC<HistoryShopProductVariantProps> = ({ productVariant, onViewDetail }) => (
  <ClosedBoxItem
    label="history-shop-product-variant"
    productVariant={productVariant}
    size={<ClosedBoxItemSize size={productVariant.size} sizeChange={undefined} />}
    stripFeedback={<HistoryShopProductVariantFeedback productVariant={productVariant} />}
    bottomFeedback={
      productVariant.feedback.comment && (
        <div>
          <Tooltip title={productVariant.feedback.comment}>
            <Icon variant={IconVariant.COMMENT} />
          </Tooltip>
        </div>
      )
    }
    onViewDetail={onViewDetail}
  />
);

interface HistoryShopProps {
  readonly customerId: string;
  readonly historyShop: HistoryShopProductVariantProjection[];
  readonly personalShopper: PersonalShopperProjection;
}

const HistoryShop: FC<HistoryShopProps> = ({ customerId, historyShop, personalShopper }) => {
  const trackDetailView = useTrackDetailView({
    section: TrackingPage.HISTORY_SHOP,
    psId: personalShopper.id,
    userId: customerId,
  });

  const [productVariantId, setProductVariantId] = useState<string | null>(null);
  const handleOnViewProductVariantDetail: ClosedBoxItemOnViewDetailFunction = useCallback(
    ({ productId, productVariantId }) => {
      trackDetailView({ productId, productVariantId });
      setProductVariantId(productVariantId);
    },
    [trackDetailView],
  );
  const closeProductVariantWithProductInfoModal = useCallback(() => setProductVariantId(null), []);

  return (
    <>
      <ProductVariantWithProductInfoModal
        boxId="" // There is no boxId here
        customerId={customerId}
        origin={TrackingPage.HISTORY_SHOP}
        productVariantId={productVariantId || ""}
        visible={Boolean(productVariantId)}
        onCancel={closeProductVariantWithProductInfoModal}
      />

      <Card className="history-shop" label="history-shop">
        {historyShop.map((historyShopProductVariant) => (
          <HistoryShopProductVariant
            key={historyShopProductVariant.productVariantId}
            productVariant={historyShopProductVariant}
            onViewDetail={handleOnViewProductVariantDetail}
          />
        ))}
      </Card>
    </>
  );
};

export { HistoryShop };
