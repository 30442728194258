import { FC } from "react";
import classNames from "classnames";
import { I18nNumber } from "@lookiero/i18n-react";
import PriceProjection from "../../../../../projection/price/model/Price";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/components/atoms/text/Text";
import "./price.css";

enum PriceVariant {
  DEFAULT = "default",
  LARGE = "large",
}

type PriceProps = {
  readonly className?: string;
  readonly price: PriceProjection;
  readonly variant?: PriceVariant;
};
const Price: FC<PriceProps> = ({ className, price, variant = PriceVariant.DEFAULT }: PriceProps) => {
  const isDiscounted = price.discountedPrice && price.discountedPrice.percentage !== 0;
  const mainPriceTextVariant = variant === PriceVariant.LARGE ? TextVariant.HEADING_2 : TextVariant.BODY_BOLD;
  const beforeDiscountPriceTextVariant = variant === PriceVariant.LARGE ? TextVariant.HEADING_2 : TextVariant.BODY;

  return (
    <div className={classNames("price", `price--${variant}`, { "price--discounted": isDiscounted }, className)}>
      <Text className="price__main" variant={mainPriceTextVariant}>
        <I18nNumber
          currency={price.currency}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          value={(isDiscounted ? price.discountedPrice?.amount ?? 0 : price.amount) / 100}
        />
      </Text>
      {isDiscounted && (
        <Text className="price__before-discount" variant={beforeDiscountPriceTextVariant}>
          <I18nNumber
            currency={price.currency}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={price.amount / 100}
          />
        </Text>
      )}
    </div>
  );
};

export { PriceVariant };

export default Price;
