import { useCallback } from "react";
import invariant from "ts-invariant";
import { v4 as uuid } from "uuid";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { writeNote } from "../../../../domain/note/command/writeNote";

interface WriteNoteFunctionArgs {
  readonly content: string;
}
interface WriteNoteFunction {
  (args: WriteNoteFunctionArgs): Promise<void>;
}

interface UseWriteNoteFunctionArgs {
  readonly id?: string;
  readonly boxId: string;
  readonly legacyBoxId: string;
  readonly psId?: string;
}

interface UseWriteNoteFunction {
  (args: UseWriteNoteFunctionArgs): [write: WriteNoteFunction, status: CommandStatus];
}

const useWriteNote: UseWriteNoteFunction = ({ id, psId, legacyBoxId, boxId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const write: WriteNoteFunction = useCallback(
    async ({ content }) => {
      invariant(psId, "Cannot write a note without a psId");

      try {
        await commandBus(writeNote({ aggregateId: id ?? uuid(), boxId, content, legacyBoxId, psId }));
      } catch (error) {}
    },
    [boxId, commandBus, id, legacyBoxId, psId],
  );

  return [write, status];
};

export { useWriteNote };
