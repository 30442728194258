import { CSSProperties, FC, ReactNode } from "react";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import classNames from "classnames";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CUSTOMER_I18N_PREFIX } from "../../../../i18n/CustomerI18n";
import "./style-profile-question.css";

interface StyleProfileQuestionProps {
  readonly title: string;
  readonly children: ReactNode;
  readonly direction?: "column" | "row";
  readonly className?: string;
  readonly style?: CSSProperties;
}
const StyleProfileQuestion: FC<StyleProfileQuestionProps> = ({
  title,
  children,
  direction = "column",
  className,
  style: customStyle,
}) => {
  const titleText = useI18nMessage({ id: title, prefix: CUSTOMER_I18N_PREFIX });

  return (
    <div
      className={classNames("style-profile-question", `style-profile-question--${direction}`, className)}
      style={customStyle}
    >
      <Text className="style-profile-question__title" variant={TextVariant.BODY_SMALL}>
        {titleText}
      </Text>
      {children}
    </div>
  );
};

export { StyleProfileQuestion };
