import { FC, useCallback, useEffect, useRef, useState } from "react";
import RootLoader from "../../components/atoms/rootLoader/RootLoader";
import { useParams } from "react-router-dom";
import useEnvironment from "../../hooks/useEnvironment";
import { QueryStatus } from "@lookiero/messaging-react";
import {
  OnNavigateToBoxFunction,
  OnViewBoxPreviewFunction,
  OnViewProductVariantDetailFunction,
} from "./components/checkoutsList/Checkout";
import { CheckoutsList } from "./components/checkoutsList/CheckoutsList";
import { useCountCheckoutsByPersonalShopperId } from "../../../projection/checkout/react/useCountCheckoutsByPersonalShopperId";
import useQueryParameters from "../../routing/queryParams/useQueryParameters";
import QueryParams from "../../routing/queryParams/config";
import { usePaginatedCheckoutsByPersonalShopperId } from "./hooks/usePaginatedCheckoutsByPersonalShopperId";
import { EmptyCheckouts } from "./components/emptyCheckouts/EmptyCheckouts";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/components/atoms/text/Text";
import { I18nMessage } from "@lookiero/i18n-react";
import { CHECKOUTS_I18N_PREFIX, CheckoutsI18n } from "../../i18n/CheckoutsI18n";
import { boxUrl } from "../../routing/boxUrl";
import { Locale } from "@lookiero/i18n";
import { TrackingPage } from "../../../tracking/Tracker";
import { ProductVariantWithProductInfoModal } from "../productVariantWithProductInfo/ProductVariantWithProductInfoModal";
import { BoxPreviewDetailModal } from "../boxPreviewDetail/BoxPreviewDetailModal";
import "./checkouts.css";

const Checkouts: FC = () => {
  const { psId, locale } = useParams();
  const { params, setParams } = useQueryParameters();
  const page = parseInt(params[QueryParams.PAGE] as string) || 1;
  const onPageChanged = useCallback(
    (page: number): void => setParams({ [QueryParams.PAGE]: page === 1 ? null : String(page) }),
    [setParams],
  );
  const {
    checkouts: { perPage },
  } = useEnvironment();

  const [checkoutsCount] = useCountCheckoutsByPersonalShopperId({
    personalShopperId: psId,
  });
  const [checkouts, checkoutsStatus] = usePaginatedCheckoutsByPersonalShopperId({
    personalShopperId: psId,
    page,
    perPage,
  });

  const [productVariantDetailDeps, setProductVariantDetailDeps] = useState<{
    productVariantId: string;
    boxId: string;
    customerId: string;
  } | null>(null);
  const handleOnViewProductVariantDetail: OnViewProductVariantDetailFunction = useCallback(
    ({ productVariantId, boxId, customerId }) => setProductVariantDetailDeps({ productVariantId, boxId, customerId }),
    [],
  );
  const closeProductVariantWithProductInfoModal = useCallback(() => setProductVariantDetailDeps(null), []);

  const [boxPreviewDeps, setBoxPreviewDeps] = useState<{
    boxId: string;
    customerId: string;
  } | null>(null);
  const handleOnViewBoxPreview: OnViewBoxPreviewFunction = useCallback(
    ({ boxId, customerId }) => setBoxPreviewDeps({ boxId, customerId }),
    [],
  );
  const closeBoxPreviewModal = useCallback(() => setBoxPreviewDeps(null), []);

  const handleOnNavigateToBox: OnNavigateToBoxFunction = useCallback(
    ({ boxNumber }) => window.open(boxUrl({ locale: locale as Locale, boxNumber }), "_blank"),
    [locale],
  );

  const checkoutsRef = useRef<HTMLDivElement>(null);

  const renderInitialLoaderRef = useRef(true);
  useEffect(() => {
    if (!renderInitialLoaderRef.current || checkoutsStatus === QueryStatus.LOADING) {
      return;
    }

    renderInitialLoaderRef.current = false;
  }, [checkoutsStatus]);

  if (!psId) {
    return null;
  }

  return (
    <section ref={checkoutsRef} className="checkouts">
      {renderInitialLoaderRef.current ? (
        <RootLoader />
      ) : checkoutsCount && checkoutsCount > 0 && checkouts ? (
        <>
          <header className="checkouts__header">
            <Text variant={TextVariant.HEADING_2}>
              <I18nMessage id={CheckoutsI18n.HEADER_TITLE} prefix={CHECKOUTS_I18N_PREFIX} />
            </Text>
            <Text className="checkouts__header-count" variant={TextVariant.BODY_SMALL}>
              <I18nMessage
                id={CheckoutsI18n.HEADER_COUNT}
                prefix={CHECKOUTS_I18N_PREFIX}
                values={{ count: `${checkoutsCount}` }}
              />
            </Text>
          </header>
          <CheckoutsList
            checkouts={checkouts}
            count={checkoutsCount}
            page={page}
            perPage={perPage}
            personalShopperId={psId}
            scrollElement={checkoutsRef.current}
            onNavigateToBox={handleOnNavigateToBox}
            onPageChanged={onPageChanged}
            onViewBoxPreview={handleOnViewBoxPreview}
            onViewProductVariantDetail={handleOnViewProductVariantDetail}
          />
          <ProductVariantWithProductInfoModal
            boxId={productVariantDetailDeps?.boxId || ""}
            customerId={productVariantDetailDeps?.customerId || ""}
            origin={TrackingPage.CHECKOUTS}
            productVariantId={productVariantDetailDeps?.productVariantId || ""}
            visible={Boolean(productVariantDetailDeps)}
            onCancel={closeProductVariantWithProductInfoModal}
          />

          <BoxPreviewDetailModal
            boxId={boxPreviewDeps?.boxId || ""}
            customerId={boxPreviewDeps?.customerId || ""}
            origin={TrackingPage.CHECKOUTS}
            visible={Boolean(boxPreviewDeps)}
            onCancel={closeBoxPreviewModal}
          />
        </>
      ) : (
        <EmptyCheckouts />
      )}
    </section>
  );
};

export { Checkouts };
