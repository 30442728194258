import { FC, useCallback, useState } from "react";
import { TrackingPage, TrackingReviewedInfo } from "../../../../../../../tracking/Tracker";
import { PersonalShopperProjection } from "../../../../../../../../projection/personalShopper/personalShopper";
import { HistoryBoxProjection } from "../../../../../../../../projection/historyBox/historyBox";
import { useTrackCheckLastBoxesInfo } from "../../../../../../../tracking/useTrackCheckLastBoxesInfo";
import { useTrackDetailView } from "../../../../../../../tracking/useTrackDetailView";
import { HistoryBox, OnViewProductVariantDetailFunction } from "./components/historyBox/HistoryBox";
import { BoxPreviewDetailModal } from "../../../../../boxPreviewDetail/BoxPreviewDetailModal";
import { ProductVariantWithProductInfoModal } from "../../../../../productVariantWithProductInfo/ProductVariantWithProductInfoModal";
import { OnViewBoxPreviewFunction } from "../../../../../checkouts/components/checkoutsList/Checkout";
import "./history-boxes.css";

interface HistoryBoxesProps {
  readonly customerId: string;
  readonly historyBoxes: HistoryBoxProjection[];
  readonly personalShopper: PersonalShopperProjection;
}

const HistoryBoxes: FC<HistoryBoxesProps> = ({ customerId, historyBoxes, personalShopper }) => {
  const trackDetailView = useTrackDetailView({
    section: TrackingPage.HISTORY_BOXES,
    psId: personalShopper.id,
    userId: customerId,
  });

  const trackCheckLastBoxesInfo = useTrackCheckLastBoxesInfo({
    section: TrackingPage.HISTORY_BOXES,
    psId: personalShopper.id,
    userId: customerId,
  });

  const [productVariantDetailDeps, setProductVariantDetailDeps] = useState<{
    productVariantId: string;
    boxId: string;
    customerId: string;
  } | null>(null);
  const handleOnViewProductVariantDetail: OnViewProductVariantDetailFunction = useCallback(
    ({ productId, productVariantId, boxId, customerId }) => {
      trackDetailView({ productId, productVariantId, boxId });
      setProductVariantDetailDeps({ productVariantId, boxId, customerId });
    },
    [trackDetailView],
  );
  const closeProductVariantWithProductInfoModal = useCallback(() => setProductVariantDetailDeps(null), []);

  const [boxPreviewDeps, setBoxPreviewDeps] = useState<{
    boxId: string;
    customerId: string;
  } | null>(null);
  const handleOnViewBoxPreview: OnViewBoxPreviewFunction = useCallback(
    ({ boxId, customerId }) => {
      setBoxPreviewDeps({ boxId, customerId });
      trackCheckLastBoxesInfo({
        reviewedInfo: TrackingReviewedInfo.PREVIEW,
        checkedBoxId: boxId,
      });
    },
    [trackCheckLastBoxesInfo],
  );
  const closeBoxPreviewModal = useCallback(() => setBoxPreviewDeps(null), []);

  return (
    <>
      <ProductVariantWithProductInfoModal
        boxId={productVariantDetailDeps?.boxId || ""}
        customerId={productVariantDetailDeps?.customerId || ""}
        origin={TrackingPage.HISTORY_BOXES}
        productVariantId={productVariantDetailDeps?.productVariantId || ""}
        visible={Boolean(productVariantDetailDeps)}
        onCancel={closeProductVariantWithProductInfoModal}
      />

      <BoxPreviewDetailModal
        boxId={boxPreviewDeps?.boxId || ""}
        customerId={boxPreviewDeps?.customerId || ""}
        origin={TrackingPage.HISTORY_BOXES}
        visible={Boolean(boxPreviewDeps)}
        onCancel={closeBoxPreviewModal}
      />

      <div aria-label="history-boxes" className="history-boxes">
        {historyBoxes.map((box) => (
          <HistoryBox
            key={box.legacyId}
            box={box}
            customerId={customerId}
            onViewBoxPreview={handleOnViewBoxPreview}
            onViewProductVariantDetail={handleOnViewProductVariantDetail}
          />
        ))}
      </div>
    </>
  );
};

export { HistoryBoxes };
