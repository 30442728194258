import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { FeedbackItem } from "../components/feedbacks/components/FeedbackItem";
import { ClosedBoxItemFeedbackType } from "../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";

type FeedbackItems = Record<ClosedBoxItemFeedbackType, FeedbackItem<ClosedBoxItemFeedbackType>>;

const FeedbackItemContext = createContext<FeedbackItems>({} as FeedbackItems);

interface FeedbackItemContextProviderProps {
  readonly feedbackItems: FeedbackItems;
  readonly children: ReactNode;
}

const FeedbackItemProvider: FC<FeedbackItemContextProviderProps> = ({ feedbackItems, children }) => (
  <FeedbackItemContext.Provider value={feedbackItems}>{children}</FeedbackItemContext.Provider>
);

interface UseFeedbackItemFunctionArgs<FT extends ClosedBoxItemFeedbackType> {
  readonly type: FT;
}

interface UseFeedbackItemFunction {
  <FT extends ClosedBoxItemFeedbackType>(args: UseFeedbackItemFunctionArgs<FT>): FeedbackItem<FT>;
}

const useFeedbackItem: UseFeedbackItemFunction = ({ type }) => {
  const items = useContext<FeedbackItems>(FeedbackItemContext);
  const item = useMemo(() => items[type], [items, type]);

  invariant(
    items,
    "Your are trying to use the useFeedbackItem hook without wrapping your app with the <FeedbackItemProvider>.",
  );

  invariant(item, `The provided FeedbackType (${type}) is NOT SUPPORTED`);

  return item;
};

export type { FeedbackItems };
export { useFeedbackItem, FeedbackItemProvider };
