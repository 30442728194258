import { useEffect, useState } from "react";
import FeatureToggle from "./FeatureToggle";

type UseIsFeatureToggleEnabledParameters = {
  readonly featureToggle: FeatureToggle;
  readonly defaultIsEnabled?: boolean;
};
const useIsFeatureToggleEnabled = ({
  featureToggle,
  defaultIsEnabled,
}: UseIsFeatureToggleEnabledParameters): boolean | undefined => {
  const [isSupported, setIsSupported] = useState<boolean | undefined>(defaultIsEnabled);

  useEffect(() => {
    const processFeatureToggle = async () => setIsSupported(await featureToggle.isEnabled());

    processFeatureToggle();
  }, [featureToggle]);

  return isSupported;
};

export default useIsFeatureToggleEnabled;
