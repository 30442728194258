import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import {
  CanPersonalShopperSendBoxesToSuperviseResult,
  canPersonalShopperSendBoxesToSupervise,
} from "../../../../projection/personalShopper/canPersonalShopperSendBoxesToSupervise";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";

interface UseCanPersonalShopperSendBoxesToSuperviseFunction {
  (): UseQueryFunctionResult<CanPersonalShopperSendBoxesToSuperviseResult>;
}

const useCanPersonalShopperSendBoxesToSupervise: UseCanPersonalShopperSendBoxesToSuperviseFunction = () =>
  useQuery({
    query: canPersonalShopperSendBoxesToSupervise(),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: Infinity },
  });

export { useCanPersonalShopperSendBoxesToSupervise };
