import { useCallback } from "react";
import { Section, Subsection } from "./Tracker";
import { useTracker } from "./useTracker";

interface TrackSelectDeselectProductVariantFunctionArgs {
  readonly position?: number;
  readonly productVariantId: string;
  readonly select: boolean;
}
interface TrackSelectDeselectProductVariantFunction {
  (args: TrackSelectDeselectProductVariantFunctionArgs): void;
}

interface UseTrackSelectDeselectProductVariantFunctionArgs {
  readonly boxId: string;
  readonly psId?: string;
  readonly section: Section;
  readonly subsection: Subsection;
  readonly userId: string;
}

interface UseTrackSelectDeselectProductVariantFunction {
  (args: UseTrackSelectDeselectProductVariantFunctionArgs): TrackSelectDeselectProductVariantFunction;
}
const useTrackSelectDeselectProductVariant: UseTrackSelectDeselectProductVariantFunction = ({
  section,
  boxId,
  psId,
  subsection,
  userId,
}) => {
  const tracker = useTracker();

  const trackSelectDeselectProductVariant: TrackSelectDeselectProductVariantFunction = useCallback(
    ({ position, productVariantId, select }) => {
      if (!(tracker && section && boxId && productVariantId && psId && subsection && userId)) {
        return;
      }

      tracker.selectDeselectProductVariant({
        section,
        boxId,
        position,
        productVariantId,
        psId,
        select,
        subsection,
        userId,
      });
    },
    [boxId, psId, section, subsection, tracker, userId],
  );

  return trackSelectDeselectProductVariant;
};

export { useTrackSelectDeselectProductVariant };
