import Cookies from "js-cookie";
import { ReadFunction, RemoveFunction, WriteFunction } from "./storage";

const read: ReadFunction<string> = async (key) => {
  const value = await Cookies.get(key);

  return value || null;
};

const write: WriteFunction<string> = async (key, value, props) => {
  Cookies.set(key, value, props);
};

const remove: RemoveFunction = async (key) => {
  Cookies.remove(key);
};

export { read, write, remove };
