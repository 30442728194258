import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { BoxPreviewStatus } from "../../../../domain/boxPreview/model/BoxPreviewStatus";
import { deselectProductVariant } from "../../../../domain/selection/command/deselectProductVariant";
import invariant from "ts-invariant";
import { Logger } from "../../../logging/Logger";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";

interface DeselectProductVariantFunctionArgs {
  readonly productVariantId: string;
}

interface DeselectProductVariantFunction {
  (args: DeselectProductVariantFunctionArgs): Promise<void>;
}

type UseDeselectProductVariantFunctionResult = [deselect: DeselectProductVariantFunction, status: CommandStatus];

interface UseDeselectProductVariantFunctionArgs {
  readonly boxNumber: string;
  readonly selectionId: string | undefined;
  readonly boxPreviewStatus: BoxPreviewStatus | undefined;
  readonly logger: Logger;
}

interface UseDeselectProductVariantFunction {
  (args: UseDeselectProductVariantFunctionArgs): UseDeselectProductVariantFunctionResult;
}

const useDeselectProductVariant: UseDeselectProductVariantFunction = ({
  boxNumber,
  selectionId,
  boxPreviewStatus,
  logger,
}) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const select: DeselectProductVariantFunction = useCallback(
    async ({ productVariantId }) => {
      invariant(selectionId, "Cannot deselect product variant without a selectionId");

      try {
        await commandBus(
          deselectProductVariant({
            aggregateId: selectionId,
            boxNumber,
            boxPreviewStatus,
            productVariantId,
          }),
        );
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [boxNumber, boxPreviewStatus, commandBus, logger, selectionId],
  );

  return [select, status];
};

export { useDeselectProductVariant };
