import { FC, useCallback } from "react";
import AspectRatio from "../../../../../../../../shared/ui/uiKit/_common/AspectRatio";
import Picture from "../../../../../../../../shared/ui/uiKit/components/atoms/picture/Picture";
import cdnImageUrl from "../../../../../../util/cdnImageUrl";
import { mainImageFromMedia } from "../../../../../../../../projection/media/model/Media";
import { LookAndLikeFeedbackProjection } from "../../../../../../../../projection/lookAndLikeFeedback/lookAndLikeFeedback";
import "./look-and-like-feedback-item.css";

const LOOK_AND_LIKE_FEEDBACK_IMAGE_WIDTH = 100;

interface LookAndLikeFeedbackItemProps {
  readonly feedback: LookAndLikeFeedbackProjection;
  readonly onClick: (productVariantId: string) => void;
}

const LookAndLikeFeedbackItem: FC<LookAndLikeFeedbackItemProps> = ({ feedback, onClick }) => {
  const productVariantPictureSrc = cdnImageUrl({
    url: mainImageFromMedia(feedback.productVariant.media).url,
    width: LOOK_AND_LIKE_FEEDBACK_IMAGE_WIDTH,
  });

  const handleOnClick = useCallback(() => onClick(feedback.productVariant.id), [feedback.productVariant.id, onClick]);

  return (
    <div
      aria-label="look-and-like-feedback-item"
      className="look-and-like-feedback-item"
      role="listitem"
      onClick={handleOnClick}
    >
      <Picture aspectRatio={AspectRatio.R_4_5} label={feedback.productVariant.id} src={productVariantPictureSrc} />
    </div>
  );
};

export default LookAndLikeFeedbackItem;
