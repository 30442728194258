import { FC } from "react";
import HistoryNotesTemplate from "./components/historyNotes/HistoryNotes";
import useBoxSharedContext from "../../hooks/useBoxSharedContext";
import { useListHistoryNotesByCustomerId } from "../../../../infrastructure/projection/historyNote/react/useListHistoryNotesByCustomerId";
import "./history-notes.css";

const HistoryNotes: FC = () => {
  const { box } = useBoxSharedContext();
  const [historyNotes] = useListHistoryNotesByCustomerId({ customerId: box.customerId });

  return <HistoryNotesTemplate historyNotes={historyNotes} />;
};

export default HistoryNotes;
