import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import {
  countCheckoutsByPersonalShopperId,
  CountCheckoutsByPersonalShopperIdResult,
} from "../../../../projection/checkout/countCheckoutsByPersonalShopperId";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isCheckoutReviewed } from "../../../../domain/checkout/model/checkoutReviewed";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isCheckoutReviewed(event);

interface UseCountCheckoutsByPersonalShopperIdFunctionArgs {
  readonly personalShopperId: string | undefined;
}

interface UseCountCheckoutsByPersonalShopperIdFunction {
  (
    args: UseCountCheckoutsByPersonalShopperIdFunctionArgs,
  ): UseQueryFunctionResult<CountCheckoutsByPersonalShopperIdResult>;
}

const useCountCheckoutsByPersonalShopperId: UseCountCheckoutsByPersonalShopperIdFunction = ({ personalShopperId }) =>
  useQuery({
    query: countCheckoutsByPersonalShopperId({ personalShopperId: personalShopperId as string }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(personalShopperId),
      keepPreviousData: true,
    },
  });

export { useCountCheckoutsByPersonalShopperId };
