import { camelcaseKeys } from "../../delivery/http/camelcaseKeys";
import {
  HistoryBoxesByCriteriaView,
  SearchHistoryBoxesByCriteriaResult,
} from "../../../projection/historyBox/searchHistoryBoxesByCriteria";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { HistoryBoxDto, toHistoryBoxProjection } from "./historyBox";

interface HttpHistoryBoxesByCriteriaView extends HistoryBoxesByCriteriaView {}

interface HttpHistoryBoxesByCriteriaViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpHistoryBoxesByCriteriaViewFunction {
  (args: HttpHistoryBoxesByCriteriaViewFunctionArgs): HttpHistoryBoxesByCriteriaView;
}

interface SearchHistoryBoxesByCriteriaResponse {
  readonly result: HistoryBoxDto[];
}

const httpHistoryBoxesByCriteriaView: HttpHistoryBoxesByCriteriaViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<SearchHistoryBoxesByCriteriaResponse, SearchHistoryBoxesByCriteriaResult>({
      endpoint: "/search-history-boxes-by-criteria",
      body: { customer_id: customerId },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result).map(toHistoryBoxProjection),
      },
    });

export { httpHistoryBoxesByCriteriaView };
