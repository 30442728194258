import { useCallback, useState, FC, useRef, useLayoutEffect } from "react";
import classNames from "classnames";
import AspectRatio from "../../../_common/AspectRatio";
import AspectRatioContainer from "../../layouts/aspectRatioContainer/AspectRatioContainer";
import Loader from "../loader/Loader";
import Icon, { IconVariant } from "../icon/Icon";
import { useLogger } from "../../../../../../infrastructure/logging/useLogger";
import "./picture.css";

type UseLoaderWithErrorReturnType = {
  readonly loaded: boolean;
  readonly error: boolean;
  readonly onLoad: () => void;
  readonly onError: () => void;
};
const useLoaderWithError = (src?: string): UseLoaderWithErrorReturnType => {
  const [{ loading, error }, setState] = useState({
    loading: true,
    error: false,
  });
  const srcRef = useRef(src);
  const logger = useLogger();

  useLayoutEffect(() => {
    if (srcRef.current === src) {
      return;
    }

    srcRef.current = src;
    setState({ error: false, loading: true });
  }, [src]);

  const onError = useCallback(() => {
    if (!src) {
      return;
    }

    const error = new Error(`Error on load ${src} image`);
    logger.captureException(error);

    setState({ error: true, loading: false });
  }, [logger, src]);

  const onLoad = useCallback(() => setState({ error: false, loading: false }), []);

  const loaded = !loading && !error;

  return { loaded, error, onLoad, onError };
};

interface PictureProps {
  readonly className?: string;
  readonly src?: string;
  readonly label?: string;
  readonly aspectRatio?: AspectRatio;
  readonly lazy?: boolean;
}
const Picture: FC<PictureProps> = ({
  className,
  src,
  label,
  aspectRatio = AspectRatio.R_3_4,
  lazy = false,
}: PictureProps) => {
  const { loaded, error, onLoad, onError } = useLoaderWithError(src);

  return (
    <AspectRatioContainer
      aspectRatio={aspectRatio}
      className={classNames("picture", `picture--${aspectRatio}`, { "picture--loaded": loaded }, className)}
    >
      <picture className="picture__picture">
        <img
          alt={label}
          aria-label={label}
          className="picture__image"
          draggable="false"
          loading={lazy ? "lazy" : undefined}
          src={src}
          onError={onError}
          onLoad={onLoad}
        />
      </picture>
      {!loaded && !error && <Loader />}
      {error && <Icon className="picture__error" variant={IconVariant.CLOSE} />}
    </AspectRatioContainer>
  );
};

export default Picture;
