import { FC } from "react";
import SizingProjection from "../../../../../domain/sizing/model/Sizing";
import Icon, { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";

const SIZING_ICON: Record<SizingProjection, IconVariant | undefined> = {
  [SizingProjection.BIG_3]: IconVariant.SIZING_LARGE_3,
  [SizingProjection.BIG_2]: IconVariant.SIZING_LARGE_2,
  [SizingProjection.BIG_1]: IconVariant.SIZING_LARGE_1,
  [SizingProjection.BIG]: IconVariant.SIZING_LARGE,
  [SizingProjection.NORMAL]: undefined,
  [SizingProjection.SMALL]: IconVariant.SIZING_SMALL,
  [SizingProjection.SMALL_1]: IconVariant.SIZING_SMALL_1,
  [SizingProjection.SMALL_2]: IconVariant.SIZING_SMALL_2,
  [SizingProjection.SMALL_3]: IconVariant.SIZING_SMALL_3,
};

type SizingProps = {
  readonly sizing: SizingProjection;
};
const Sizing: FC<SizingProps> = ({ sizing }: SizingProps) =>
  SIZING_ICON[sizing] ? <Icon className="sizing" variant={SIZING_ICON[sizing] as IconVariant} /> : null;

export default Sizing;
