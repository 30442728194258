import { useTracker } from "./useTracker";
import { Section } from "./Tracker";
import { useCallback } from "react";

interface TrackDetailViewFunctionArgs {
  readonly productId: string;
  readonly productVariantId?: string;
  readonly boxId?: string;
}

interface TrackDetailViewFunction {
  (args: TrackDetailViewFunctionArgs): void;
}

interface UseTrackDetailViewFunctionArgs {
  readonly section: Section;
  readonly psId?: string;
  readonly userId?: string;
}

interface UseTrackDetailViewFunction {
  (args: UseTrackDetailViewFunctionArgs): TrackDetailViewFunction;
}

const useTrackDetailView: UseTrackDetailViewFunction = ({ section, psId, userId }) => {
  const tracker = useTracker();

  const trackDetailView: TrackDetailViewFunction = useCallback(
    ({ productId, productVariantId, boxId }) => {
      if (!(tracker && section && psId && userId && productId)) {
        return;
      }

      tracker.detailView({ section, productId, productVariantId, psId, boxId, userId });
    },
    [psId, section, tracker, userId],
  );

  return trackDetailView;
};

export { useTrackDetailView };
