import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import {
  IsStockSurveySubmittedByBoxIdResult,
  isStockSurveySubmittedByBoxId,
} from "../../../../projection/stockSurvey/isStockSurveySubmittedByBoxId";
import { isStockSurveySubmitted } from "../../../../domain/stockSurvey/model/stockSurveySubmitted";

interface UseIsStockSurveySubmittedByBoxIdFunctionArgs {
  readonly boxId: string;
}

interface UseIsStockSurveySubmittedByBoxIdFunction {
  (args: UseIsStockSurveySubmittedByBoxIdFunctionArgs): UseQueryFunctionResult<IsStockSurveySubmittedByBoxIdResult>;
}

const useIsStockSurveySubmittedByBoxId: UseIsStockSurveySubmittedByBoxIdFunction = ({ boxId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: isStockSurveySubmittedByBoxId({ boxId }),
    invalidation: isStockSurveySubmitted,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useIsStockSurveySubmittedByBoxId };
