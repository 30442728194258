import FeatureToggles from "./FeatureToggles";
import useFeatureToggle from "../../../shared/featureToggle/useFeatureToggle";
import useIsFeatureToggleEnabled from "../../../shared/featureToggle/useIsFeatureToggleEnabled";

interface UseIsCustomerInsightsEnabledFunction {
  (): boolean;
}

const useIsCustomerInsightsEnabled: UseIsCustomerInsightsEnabledFunction = () => {
  const customerInsightsFeatureToggle = useFeatureToggle(FeatureToggles.CUSTOMER_INSIGHTS);
  const customerInsightsFeatureToggleEnabled = useIsFeatureToggleEnabled({
    featureToggle: customerInsightsFeatureToggle,
  });

  return Boolean(customerInsightsFeatureToggleEnabled);
};

export { useIsCustomerInsightsEnabled };
