import { FC, useMemo } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { StyleProfileCharacteristics } from "../../../../../../projection/styleProfile/styleProfile";
import { CUSTOMER_I18N_PREFIX, CustomerI18n } from "../../../../i18n/CustomerI18n";
import { Height } from "../../../../components/atoms/units/Height";
import { Weight } from "../../../../components/atoms/units/Weight";
import { Answer } from "../answer/Answer";
import { StyleProfileCardTitle } from "../styleProfileCard/components/Title";
import { StyleProfileCard } from "../styleProfileCard/StyleProfileCard";
import { IconQuestionItem } from "../styleProfileQuestion/components/iconQuestionItem/IconQuestionItem";
import { TextQuestionItem } from "../styleProfileQuestion/components/textQuestionItem/TextQuestionItem";
import { StyleProfileQuestion } from "../styleProfileQuestion/StyleProfileQuestion";
import "./characteristics.css";

type MandatoryCharacteristics = Pick<
  StyleProfileCharacteristics,
  | "height"
  | "weight"
  | "topSize"
  | "bottomSize"
  | "footwearSize"
  | "topFit"
  | "bottomFit"
  | "braSize"
  | "cupSize"
  | "silhouette"
>;

const MANDATORY: (keyof MandatoryCharacteristics)[] = [
  "height",
  "weight",
  "topSize",
  "bottomSize",
  "footwearSize",
  "topFit",
  "bottomFit",
  "braSize",
  "cupSize",
  "silhouette",
];

type RestCharacteristics = Omit<StyleProfileCharacteristics, keyof MandatoryCharacteristics>;

interface Location {
  readonly col: number;
  readonly row: number;
}

const MANDATORY_LOCATION: Record<"measurements" | "sizes_up_bo_foot", Location> = {
  measurements: {
    col: 1,
    row: 1,
  },
  sizes_up_bo_foot: {
    col: 2,
    row: 1,
  },
};

interface CharacteristicsProps {
  readonly characteristics: StyleProfileCharacteristics;
  readonly previousCharacteristics?: StyleProfileCharacteristics;
}
const Characteristics: FC<CharacteristicsProps> = ({ characteristics, previousCharacteristics }) => {
  const titleText = useI18nMessage({ id: CustomerI18n.CHARACTERISTICS_TITLE, prefix: CUSTOMER_I18N_PREFIX });

  const restCharacteristics: RestCharacteristics = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(characteristics).filter(([key]) => !MANDATORY.includes(key as keyof MandatoryCharacteristics)),
      ) as RestCharacteristics,
    [characteristics],
  );

  return (
    <StyleProfileCard className="characteristics" label="characteristics-card">
      <StyleProfileCardTitle>{titleText}</StyleProfileCardTitle>

      <div className="characteristics__content">
        <StyleProfileQuestion
          style={{ gridColumn: MANDATORY_LOCATION.measurements.col, gridRow: MANDATORY_LOCATION.measurements.row }}
          title={CustomerI18n.MEASUREMENTS}
        >
          <Answer answer={characteristics.height} previousAnswer={previousCharacteristics?.height}>
            {(height) => (
              <TextQuestionItem>
                <Height height={height} />
              </TextQuestionItem>
            )}
          </Answer>
          <Answer answer={characteristics.weight} previousAnswer={previousCharacteristics?.weight}>
            {(weight) => (
              <TextQuestionItem>
                <Weight weight={weight} />
              </TextQuestionItem>
            )}
          </Answer>
        </StyleProfileQuestion>

        <StyleProfileQuestion
          title={CustomerI18n.SIZES_UP_BO_FOOT}
          style={{
            gridColumn: MANDATORY_LOCATION.sizes_up_bo_foot.col,
            gridRow: MANDATORY_LOCATION.sizes_up_bo_foot.row,
          }}
        >
          <div className="characteristics__content-list">
            <Answer answer={characteristics.topSize} previousAnswer={previousCharacteristics?.topSize}>
              {(topSize) => <TextQuestionItem>{topSize}</TextQuestionItem>}
            </Answer>
            <span> / </span>
            <Answer answer={characteristics.topFit} previousAnswer={previousCharacteristics?.topFit}>
              {(topFit) => <TextQuestionItem>{topFit}</TextQuestionItem>}
            </Answer>
          </div>

          <div className="characteristics__content-list">
            <Answer answer={characteristics.bottomSize} previousAnswer={previousCharacteristics?.bottomSize}>
              {(bottomSize) => <TextQuestionItem>{bottomSize}</TextQuestionItem>}
            </Answer>
            <span> / </span>
            <Answer answer={characteristics.bottomFit} previousAnswer={previousCharacteristics?.bottomFit}>
              {(bottomFit) => <TextQuestionItem>{bottomFit}</TextQuestionItem>}
            </Answer>
          </div>

          <Answer answer={characteristics.footwearSize} previousAnswer={previousCharacteristics?.footwearSize}>
            {(footwearSize) => <TextQuestionItem>{footwearSize}</TextQuestionItem>}
          </Answer>
        </StyleProfileQuestion>

        {(characteristics.braSize || characteristics.cupSize) && (
          <StyleProfileQuestion title={CustomerI18n.BRA}>
            <div className="characteristics__bra">
              <Answer answer={characteristics.braSize} previousAnswer={previousCharacteristics?.braSize}>
                {(braSize) => <TextQuestionItem>{braSize}</TextQuestionItem>}
              </Answer>
              <span> </span>
              <Answer answer={characteristics.cupSize} previousAnswer={previousCharacteristics?.cupSize}>
                {(cupSize) => <TextQuestionItem>{cupSize}</TextQuestionItem>}
              </Answer>
            </div>
          </StyleProfileQuestion>
        )}

        {characteristics.silhouette && (
          <StyleProfileQuestion title={CustomerI18n.SILHOUETTE}>
            <Answer answer={characteristics.silhouette} previousAnswer={previousCharacteristics?.silhouette}>
              {(silhouette) => (
                <IconQuestionItem icon={silhouette.icon} label={silhouette.translationKey}>
                  {silhouette}
                </IconQuestionItem>
              )}
            </Answer>
          </StyleProfileQuestion>
        )}

        {Object.entries(restCharacteristics).map(
          ([key, value]) =>
            value && (
              <StyleProfileQuestion key={key} title={key}>
                <Answer answer={value} previousAnswer={previousCharacteristics?.[key as keyof RestCharacteristics]}>
                  {(answer) => <TextQuestionItem>{answer}</TextQuestionItem>}
                </Answer>
              </StyleProfileQuestion>
            ),
        )}
      </div>
    </StyleProfileCard>
  );
};

export { Characteristics };
