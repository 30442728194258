import Country from "../../../../domain/country/model/Country";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { PersonalShopperProductionProjection } from "../../../../projection/personalShopperProduction/personalShopperProduction";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { viewPersonalShopperProductionByCriteria } from "../../../../projection/personalShopperProduction/viewPersonalShopperProductionByCriteria";

interface UseViewPersonalShopperProductionByCriteriaFunctionArgs {
  readonly country?: Country;
  readonly team?: string;
}

interface UseViewPersonalShopperProductionByCriteriaFunction {
  (args: UseViewPersonalShopperProductionByCriteriaFunctionArgs): UseQueryFunctionResult<
    PersonalShopperProductionProjection[]
  >;
}

const useViewPersonalShopperProductionByCriteria: UseViewPersonalShopperProductionByCriteriaFunction = ({
  country,
  team,
}) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewPersonalShopperProductionByCriteria({ country, team }),
    options: {
      staleTime: 60 * 1000,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useViewPersonalShopperProductionByCriteria };
