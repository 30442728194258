import { useEffect, useRef, useState } from "react";
import { QueryStatus, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { LookAndLikeFeedbackProjection } from "../../../../projection/lookAndLikeFeedback/lookAndLikeFeedback";
import { searchLookAndLikeFeedbacksByCriteria } from "../../../../projection/lookAndLikeFeedback/searchLookAndLikeFeedbacksByCriteria";
import { isEqual } from "lodash";

interface UseSearchLookAndLikeFeedbacksByCriteriaFunctionArgs {
  readonly familyIds: string[];
  readonly customerId: string;
  readonly response: boolean;
  readonly page: number;
  readonly perPage: number;
}

interface UseSearchLookAndLikeFeedbacksByCriteriaFunction {
  (args: UseSearchLookAndLikeFeedbacksByCriteriaFunctionArgs): [
    projection: Record<number, LookAndLikeFeedbackProjection[]>,
    status: QueryStatus,
  ];
}

const useSearchLookAndLikeFeedbacksByCriteria: UseSearchLookAndLikeFeedbacksByCriteriaFunction = ({
  familyIds,
  customerId,
  response,
  page,
  perPage,
}) => {
  const [pagedFeedbacks, setPagedFeedbacks] = useState<Record<number, LookAndLikeFeedbackProjection[]>>({});
  const familyIdsRef = useRef(familyIds);

  const invalidationId = ["SearchLookAndLikeFeedbacksByCriteria", familyIds, customerId, response, perPage];
  const queryId = [...invalidationId, page];

  const [result, status] = useQuery<LookAndLikeFeedbackProjection[]>({
    id: queryId,
    invalidationId,
    invalidationRefetchType: "active",
    contextId: MESSAGING_CONTEXT_ID,
    query: searchLookAndLikeFeedbacksByCriteria({
      criteria: {
        familyIds,
        customerId,
        response,
        page,
        perPage,
      },
    }),
    options: { staleTime: 60 * 60 * 1000 },
  });

  useEffect(() => {
    if (isEqual(familyIdsRef.current, familyIds)) {
      return;
    }

    familyIdsRef.current = familyIds;

    setPagedFeedbacks({});
  }, [familyIds]);

  useEffect(() => {
    if (!result) {
      return;
    }

    setPagedFeedbacks((prevFeedbacks) => ({
      ...prevFeedbacks,
      [page]: result,
    }));
  }, [result, page]);

  return [pagedFeedbacks, status];
};

export { useSearchLookAndLikeFeedbacksByCriteria };
