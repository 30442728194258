import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { searchHistoryBoxesByCriteria } from "../../../../projection/historyBox/searchHistoryBoxesByCriteria";
import { HistoryBoxProjection } from "../../../../projection/historyBox/historyBox";

interface UseSearchHistoryBoxesByCriteriaFunctionArgs {
  readonly customerId: string;
}

interface UseSearchHistoryBoxesByCriteriaFunction {
  (args: UseSearchHistoryBoxesByCriteriaFunctionArgs): UseQueryFunctionResult<HistoryBoxProjection[]>;
}

const useSearchHistoryBoxesByCriteria: UseSearchHistoryBoxesByCriteriaFunction = ({ customerId }) =>
  useQuery({
    query: searchHistoryBoxesByCriteria({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: { staleTime: 10 * 60 * 1000, retry: 0 },
  });

export { useSearchHistoryBoxesByCriteria };
