import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import AnnotationsI18n, { ANNOTATIONS_I18N_PREFIX } from "../../../../i18n/AnnotationsI18n";

import { ButtonVariant } from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import ActionButton from "../../../../../../shared/ui/uiKit/components/molecules/actionButton/ActionButton";
import "./annotations.css";
import { StyleProfileCardTitle } from "../styleProfileCard/components/Title";
import { StyleProfileCard } from "../styleProfileCard/StyleProfileCard";

interface AnnotationsProps {
  readonly positive?: string;
  readonly negative?: string;
  readonly onAdd: () => void;
}

const Annotations: FC<AnnotationsProps> = ({ positive, negative, onAdd }) => {
  const titleText = useI18nMessage({ id: AnnotationsI18n.TITLE, prefix: ANNOTATIONS_I18N_PREFIX });
  const positivesText = useI18nMessage({ id: AnnotationsI18n.POSITIVES, prefix: ANNOTATIONS_I18N_PREFIX });
  const negativesText = useI18nMessage({ id: AnnotationsI18n.NEGATIVES, prefix: ANNOTATIONS_I18N_PREFIX });
  const addText = useI18nMessage({ id: AnnotationsI18n.ADD, prefix: ANNOTATIONS_I18N_PREFIX });

  return (
    <StyleProfileCard className="annotations" label="annotations-card">
      <header className="annotations__header">
        <StyleProfileCardTitle>{titleText}</StyleProfileCardTitle>

        <ActionButton
          aria-label="annotation-add-button"
          className="annotation__add-button"
          variant={ButtonVariant.PLAIN}
          onClick={onAdd}
        >
          <Text>{addText}</Text>
        </ActionButton>
      </header>

      {(positive || negative) && (
        <div className="annotations__content">
          {positive && (
            <div>
              <Text className="annotations__title" variant={TextVariant.BODY_SMALL}>
                {positivesText}
              </Text>
              <Text className="annotations__comment" variant={TextVariant.BODY_BOLD}>
                {positive}
              </Text>
            </div>
          )}

          {negative && (
            <div>
              <Text className="annotations__title" variant={TextVariant.BODY_SMALL}>
                {negativesText}
              </Text>
              <Text className="annotations__comment" variant={TextVariant.BODY_BOLD}>
                {negative}
              </Text>
            </div>
          )}
        </div>
      )}
    </StyleProfileCard>
  );
};

export { Annotations };
