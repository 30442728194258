import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { ProductDetailProjection } from "../../../../projection/product/productDetail";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { viewProductById } from "../../../../projection/product/viewProductById";

interface UseViewProductByIdFunctionArgs {
  readonly productId?: string;
  readonly boxId: string;
}

interface UseViewProductByIdFunction {
  (args: UseViewProductByIdFunctionArgs): UseQueryFunctionResult<ProductDetailProjection>;
}

const useViewProductById: UseViewProductByIdFunction = ({ boxId, productId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewProductById({ id: productId as string, boxId }),
    options: {
      staleTime: 5 * 60 * 1000,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(productId),
    },
  });

export { useViewProductById };
