import { FC, memo } from "react";
import { useFeedbackItem } from "../../hooks/useFeedbackItem";
import { ClosedBoxItemFeedbackProjection } from "../../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";

interface FeedbackProps {
  readonly feedback: ClosedBoxItemFeedbackProjection;
}

const Feedback: FC<FeedbackProps> = ({ feedback }) => {
  const Item = useFeedbackItem({ type: feedback.answer.type });

  return <Item answer={feedback.answer} question={feedback.question} />;
};

const MemorizedFeedback = memo(Feedback);

export { MemorizedFeedback as Feedback };
