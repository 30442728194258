import { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { useKey } from "react-use";
import useCaptureAndStopEvent from "../../../hooks/useCaptureAndStopEvent";
import { IconVariant } from "../../atoms/icon/Icon";
import Button from "../../atoms/button/Button";
import "./carousel.css";

interface RenderBulletsFunctionArgs {
  readonly activeIndex: number;
  readonly count: number;
  readonly onChange: (index: number) => void;
}
interface RenderBulletsFunction {
  (args: RenderBulletsFunctionArgs): JSX.Element;
}

interface RenderItemFunctionArgs<T> {
  readonly item: T;
  readonly index: number;
}
interface RenderItemFunction<T> {
  (args: RenderItemFunctionArgs<T>): JSX.Element;
}

interface CarouselProps<T> {
  readonly data: T[];
  readonly activeIndex: number;
  readonly children: RenderItemFunction<T>;
  readonly bullets?: RenderBulletsFunction;
  readonly onChange: (itemIndex: number) => void;
  readonly onClick?: () => void;
  readonly keyboardControlled?: boolean;
}
const Carousel = <T,>({
  data,
  activeIndex,
  children,
  bullets,
  onChange,
  onClick,
  keyboardControlled,
}: CarouselProps<T>) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const activeIndexRef = useRef<number>(0);
  useEffect(() => {
    if (activeIndexRef.current === activeIndex) {
      return;
    }

    activeIndexRef.current = activeIndex;
    carouselRef.current?.scroll?.({ left: carouselRef.current?.offsetWidth * activeIndex });
  }, [activeIndex]);

  const goPreviousCallback = useCallback(
    () => onChange(activeIndexRef.current - 1 < 0 ? data.length - 1 : activeIndexRef.current - 1),
    [onChange, data.length],
  );
  const goPrevious = useCaptureAndStopEvent(goPreviousCallback);
  const goNextCallback = useCallback(
    () => onChange(activeIndexRef.current + 1 >= data.length ? 0 : activeIndexRef.current + 1),
    [onChange, data.length],
  );
  const goNext = useCaptureAndStopEvent(goNextCallback);

  const handleOnArrowLeftPressed = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      keyboardControlled && goPreviousCallback();
    },
    [goPreviousCallback, keyboardControlled],
  );

  const handleOnArrowRightPressed = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      keyboardControlled && goNextCallback();
    },
    [goNextCallback, keyboardControlled],
  );
  useKey("ArrowLeft", handleOnArrowLeftPressed);
  useKey("ArrowRight", handleOnArrowRightPressed);

  const displayControls = data.length > 1;

  return (
    <section aria-label="carousel" className={classNames("carousel", onClick && "carousel-pointer")} onClick={onClick}>
      <div ref={carouselRef} className="carousel__items">
        {data.map((item, index) => (
          <div
            key={index}
            aria-checked={index === activeIndex}
            aria-label="carousel-item"
            className="carousel__items-item"
            id={String(index)}
          >
            {children({ item, index })}
          </div>
        ))}
      </div>

      {bullets && displayControls && bullets({ activeIndex, count: data.length, onChange })}

      {displayControls && (
        <>
          <Button
            aria-label="carousel-button-previous"
            className="carousel__button carousel__button--previous"
            icon={IconVariant.MINOR_DROPDOWN}
            onClick={goPrevious}
          />
          <Button
            aria-label="carousel-button-next"
            className="carousel__button carousel__button--next"
            icon={IconVariant.MINOR_DROPDOWN}
            onClick={goNext}
          />
        </>
      )}
    </section>
  );
};

export type { RenderItemFunction, RenderBulletsFunction };
export { Carousel };
