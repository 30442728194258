import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Tooltip from "../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import Button from "../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { BoxPreviewStatus } from "../../../../../domain/boxPreview/model/BoxPreviewStatus";
import { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../../i18n/HistoryI18n";
import "./closed-box-preview-button.css";

interface ClosedBoxPreviewButtonProps {
  readonly status: BoxPreviewStatus;
  readonly onClick: () => void;
}

const ClosedBoxPreviewButton: FC<ClosedBoxPreviewButtonProps> = ({ status, onClick }) => {
  const expired = status === BoxPreviewStatus.EXPIRED;
  const tooltipTitleDefault = useI18nMessage({ id: HistoryI18n.BOX_VIEW_PREVIEW, prefix: HISTORY_I18N_PREFIX });
  const tooltipTitleExpired = useI18nMessage({ id: HistoryI18n.BOX_PREVIEW_EXPIRED, prefix: HISTORY_I18N_PREFIX });

  return (
    <Tooltip title={expired ? tooltipTitleExpired : tooltipTitleDefault}>
      <Button
        aria-label="closed-box-preview-button"
        className="closed-box-preview-button"
        disabled={expired}
        icon={expired ? IconVariant.EYE_STROKE : IconVariant.EYE}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export { ClosedBoxPreviewButton };
