import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { SelectionProjection } from "../../../projection/selection/selection";
import invariant from "ts-invariant";
import RootLoader from "../components/atoms/rootLoader/RootLoader";
import { SelectionAlertProjection } from "../../../projection/alert/selectionAlert";
import { BoxProjection } from "../../../projection/box/box";
import { PersonalShopperProjection } from "../../../projection/personalShopper/personalShopper";
import { useViewBoxByBoxNumber } from "../../projection/box/react/useViewBoxByBoxNumber";
import { useViewPersonalShopper } from "../../projection/personalShopper/react/useViewPersonalShopper";
import { useIsAutomaticSelectionEnabled } from "../../projection/selection/react/useIsAutomaticSelectionEnabled";
import { usePoolSelectionByBoxNumber } from "../../projection/selection/react/usePoolSelectionByBoxNumber";
import { useStartAutomaticSelection } from "../../domain/selection/react/useStartAutomaticSelection";
import { QueryStatus } from "@lookiero/messaging-react";
import { useLogger } from "../../logging/useLogger";

enum AutomaticSelectionStatus {
  DISABLED = "DISABLED",
  IDLE = "IDLE",
  STARTED = "STARTED",
  FINISHED = "FINISHED",
}

interface IsAutomaticSelectionStartedFunctionArgs {
  readonly selection: SelectionProjection | null;
}

interface IsAutomaticSelectionStartedFunction {
  (args: IsAutomaticSelectionStartedFunctionArgs): boolean;
}

const isAutomaticSelectionStarted: IsAutomaticSelectionStartedFunction = ({ selection }) => {
  if (!selection) {
    return false;
  }

  return selection.automaticSelectionStartedOn !== null && selection.automaticSelectionFinishedOn === null;
};

interface IsAutomaticSelectionFinishedFunctionArgs {
  readonly selection: SelectionProjection | null;
}

interface IsAutomaticSelectionFinishedFunction {
  (args: IsAutomaticSelectionFinishedFunctionArgs): boolean;
}

const isAutomaticSelectionFinished: IsAutomaticSelectionFinishedFunction = ({ selection }) => {
  if (!selection) {
    return false;
  }

  return selection.automaticSelectionStartedOn !== null && selection.automaticSelectionFinishedOn !== null;
};

interface ShouldStartAutomaticSelectionFunctionArgs {
  readonly selection: SelectionProjection | null;
  readonly box: BoxProjection;
  readonly personalShopper: PersonalShopperProjection;
}

interface ShouldStartAutomaticSelectionFunction {
  (args: ShouldStartAutomaticSelectionFunctionArgs): boolean;
}

const shouldStartAutomaticSelection: ShouldStartAutomaticSelectionFunction = ({ selection, box, personalShopper }) => {
  const hasAlreadyStarted = selection && selection.automaticSelectionStartedOn !== null;
  const isAssignedPersonalShopper =
    box?.psNumber === personalShopper?.psNumber || box?.frequentPsNumber === personalShopper?.psNumber;

  return !hasAlreadyStarted && isAssignedPersonalShopper;
};

interface AutomaticSelectionApi {
  readonly selection: SelectionProjection | undefined;
  readonly selectionStatus: QueryStatus;
  readonly automaticSelectionStatus: AutomaticSelectionStatus;
  readonly selectionAlerts: SelectionAlertProjection[] | undefined;
  readonly selectionAlertsStatus: QueryStatus;
}

const AutomaticSelectionContext = createContext<AutomaticSelectionApi>(null as unknown as AutomaticSelectionApi);

interface AutomaticSelectionProviderProps {
  readonly boxNumber: string;
  readonly children: ReactNode;
}

const AutomaticSelectionProvider: FC<AutomaticSelectionProviderProps> = ({ boxNumber, children }) => {
  const logger = useLogger();

  const [box] = useViewBoxByBoxNumber({ boxNumber });
  const { selection, selectionStatus, selectionAlerts, selectionAlertsStatus } = usePoolSelectionByBoxNumber({
    boxNumber,
  });
  const [personalShopper] = useViewPersonalShopper();
  const [automaticSelectionEnabled] = useIsAutomaticSelectionEnabled({ psId: personalShopper?.id });

  const [startAutomaticSelection] = useStartAutomaticSelection({ logger });

  const [automaticSelectionStatus, setAutomaticSelectionStatus] = useState(AutomaticSelectionStatus.IDLE);

  useEffect(() => {
    if (
      automaticSelectionEnabled === undefined ||
      selection === undefined ||
      box === undefined ||
      personalShopper === undefined ||
      personalShopper === null
    ) {
      return;
    }

    if (automaticSelectionEnabled === false) {
      setAutomaticSelectionStatus(AutomaticSelectionStatus.DISABLED);

      return;
    }

    if (isAutomaticSelectionStarted({ selection })) {
      setAutomaticSelectionStatus(AutomaticSelectionStatus.STARTED);

      return;
    }

    if (isAutomaticSelectionFinished({ selection })) {
      setAutomaticSelectionStatus(AutomaticSelectionStatus.FINISHED);

      return;
    }

    if (
      !shouldStartAutomaticSelection({
        selection,
        box,
        personalShopper,
      })
    ) {
      return;
    }

    startAutomaticSelection({ selectionId: uuid(), boxId: box.id });
  }, [automaticSelectionEnabled, box, personalShopper, selection, startAutomaticSelection]);

  const value: AutomaticSelectionApi = useMemo(
    () => ({ selection, selectionStatus, automaticSelectionStatus, selectionAlerts, selectionAlertsStatus }),
    [automaticSelectionStatus, selection, selectionAlerts, selectionAlertsStatus, selectionStatus],
  );

  return box ? (
    <AutomaticSelectionContext.Provider value={value}>{children}</AutomaticSelectionContext.Provider>
  ) : (
    <RootLoader />
  );
};

interface UseAutomaticSelectionFunction {
  (): AutomaticSelectionApi;
}

const useAutomaticSelection: UseAutomaticSelectionFunction = () => {
  const automaticSelectionContext = useContext<AutomaticSelectionApi>(AutomaticSelectionContext);

  invariant(
    automaticSelectionContext,
    "Your are trying to use the useAutomaticSelection hook without wrapping your app with the <AutomaticSelectionProvider>.",
  );

  return automaticSelectionContext;
};

export { useAutomaticSelection, AutomaticSelectionStatus, AutomaticSelectionProvider };
