import { FC } from "react";
import useBoxSharedContext from "../../../../../../hooks/useBoxSharedContext";
import SelectionHeaderSummary from "../../../../../../components/molecules/selectionHeaderSummary/SelectionHeaderSummary";
import { useViewBoxPreviewByBoxId } from "../../../../../../../projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { useViewSortedProductsByBoxNumber } from "../../../../../../../projection/selection/react/useViewSortedProductsByBoxNumber";

interface SelectionSummaryHeaderProps {
  readonly showAutomaticSelectionTooltip?: boolean;
}
const SelectionSummaryHeader: FC<SelectionSummaryHeaderProps> = ({ showAutomaticSelectionTooltip }) => {
  const { box } = useBoxSharedContext();
  const [boxPreview] = useViewBoxPreviewByBoxId({ boxId: box.id });
  const [sortedProducts] = useViewSortedProductsByBoxNumber({ boxNumber: String(box.boxNumber) });

  return (
    <SelectionHeaderSummary
      boxPreviewStatus={boxPreview?.status}
      products={sortedProducts}
      showAutomaticSelectionTooltip={showAutomaticSelectionTooltip}
    />
  );
};

export { SelectionSummaryHeader };
