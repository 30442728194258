import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { ProductDetailProjection } from "../../../../../../projection/product/productDetail";
import { ProductVariantProjection } from "../../../../../../projection/product/productVariant";
import Sizing from "../../../../components/atoms/sizing/Sizing";
import ProductModelNotification from "../../../../components/molecules/productModelNotification/ProductModelNotification";
import ProductAlerts from "../../../../components/organisms/productAlerts/ProductAlerts";
import { ProductDetailData } from "../../../../components/organisms/productDetailData/ProductDetailData";
import { ProductFeatures } from "../../../../components/organisms/productFeatures/ProductFeatures";
import ProductVariantDetailGallery from "../../../../components/organisms/productVariantDetailGallery/ProductVariantDetailGallery";
import ProductVariantSelector from "../../../../components/organisms/productVariantSelector/ProductVariantSelector";
import ProductVariantZoomGallery from "../../../../components/organisms/productVariantZoomGallery/ProductVariantZoomGallery";
import Modal from "../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import "./product-detail.css";

type ProductDetailProps = {
  readonly product: ProductDetailProjection;
  readonly productVariant: ProductVariantProjection;
  readonly actions?: ReactNode;
  readonly disabled?: boolean;
  readonly onProductVariantChanged: (productVariant: ProductVariantProjection) => void;
};
const ProductDetail: FC<ProductDetailProps> = ({
  product,
  productVariant,
  actions,
  disabled,
  onProductVariantChanged,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  useEffect(() => setSelectedItemIndex(0), [productVariant.id]);

  const [zoomed, setZoomed] = useState(false);
  const handleOnOpenZoom = useCallback(() => setZoomed(true), []);
  const handleOnCloseZoom = useCallback(() => setZoomed(false), []);

  return (
    <article
      aria-label="product-detail"
      className={classNames("product-detail", {
        "product-detail--disabled": disabled,
      })}
    >
      <ProductVariantDetailGallery
        keyboardControlled={!zoomed}
        media={productVariant.media}
        selectedItemIndex={selectedItemIndex}
        onChange={setSelectedItemIndex}
        onZoomed={handleOnOpenZoom}
      />
      <div className="product-detail__alerts">
        {product.model?.fashionModelTestedSize && (
          <ProductModelNotification testedSize={product.model?.fashionModelTestedSize} />
        )}
        <ProductAlerts alerts={product.alerts} />
      </div>
      <section className="product-detail__details">
        <header className="product-detail__header">
          <div className="product-detail__header-main">
            <ProductDetailData
              brand={product.brand}
              group={product.group}
              price={productVariant.price}
              season={productVariant.season}
              title={product.title}
            />
            <ProductVariantSelector
              productVariants={product.productVariants}
              selectedProductVariant={productVariant}
              onChange={onProductVariantChanged}
            >
              <Sizing sizing={product.sizing} />
            </ProductVariantSelector>
          </div>

          <div className="product-detail__actions">{actions}</div>
        </header>

        <ProductFeatures
          characteristics={product.characteristics}
          description={product.description}
          model={product.model}
          others={product.others}
        />
      </section>

      <Modal visible={zoomed} onHide={handleOnCloseZoom}>
        <ProductVariantZoomGallery
          media={productVariant.media}
          selectedItemIndex={selectedItemIndex}
          onChange={setSelectedItemIndex}
          onZoomClose={handleOnCloseZoom}
        />
      </Modal>
    </article>
  );
};

export { ProductDetail };
