import { FC, useCallback } from "react";
import Country from "../../../../../domain/country/model/Country";
import Dropdown from "../../../../../shared/ui/uiKit/components/organisms/dropdown/Dropdown";
import FormInputOption from "../../../../../shared/ui/uiKit/_common/FormInputOption";

const countryOptions: FormInputOption[] = Object.values(Country).map((country) => ({ label: country, value: country }));

interface CountryDropdownProps {
  readonly label: string;
  readonly selected: Country[] | undefined;
  readonly onChange: (countries: Country[]) => void;
}
const CountryDropdown: FC<CountryDropdownProps> = ({ label, selected, onChange }) => {
  const handleOnChange = useCallback(
    (countries: string | string[] | null | undefined) => onChange(countries as Country[]),
    [onChange],
  );

  return <Dropdown label={label} options={countryOptions} value={selected} isMulti onChange={handleOnChange} />;
};

export { CountryDropdown };
