import { isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { CheckoutProjection } from "../../../../../projection/checkout/checkout";
import { useListCheckoutsByPersonalShopperId } from "../../../../projection/checkout/react/useListCheckoutsByPersonalShopperId";

interface UsePaginatedCheckoutsByPersonalShopperIdFunctionArgs {
  readonly personalShopperId: string | undefined;
  readonly page: number;
  readonly perPage: number;
}

interface UsePaginatedCheckoutsByPersonalShopperIdFunction {
  (args: UsePaginatedCheckoutsByPersonalShopperIdFunctionArgs): UseQueryFunctionResult<
    Record<number, CheckoutProjection[]>
  >;
}

const usePaginatedCheckoutsByPersonalShopperId: UsePaginatedCheckoutsByPersonalShopperIdFunction = ({
  personalShopperId,
  page,
  perPage,
}) => {
  const [pagedCheckouts, setPagedCheckouts] = useState<Record<number, CheckoutProjection[]>>({});
  const pagedCheckoutsRef = useRef(pagedCheckouts);
  pagedCheckoutsRef.current = pagedCheckouts;

  const queryId = ["PaginatedCheckoutsByPersonalShopperId", personalShopperId, perPage, page];
  const invalidationId = ["PaginatedCheckoutsByPersonalShopperId", personalShopperId, perPage];

  const [result, status] = useListCheckoutsByPersonalShopperId({
    queryId,
    invalidationId,
    personalShopperId,
    page,
    perPage,
  });

  /**
   * Add new page-results to pagedCheckouts
   */
  useEffect(() => {
    if (!result || isEqual(pagedCheckoutsRef.current[page], result)) {
      return;
    }

    setPagedCheckouts({ ...pagedCheckoutsRef.current, [page]: result });
  }, [result, page]);

  return [pagedCheckouts, status];
};

export { usePaginatedCheckoutsByPersonalShopperId };
