import { FC } from "react";
import { Feedback } from "./Feedback";
import { ClosedBoxItemFeedbackProjection } from "../../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";

interface FeedbacksProps {
  readonly feedbacks: ClosedBoxItemFeedbackProjection[];
}

const Feedbacks: FC<FeedbacksProps> = ({ feedbacks }) => (
  <>
    {feedbacks.map((feedback) => (
      <Feedback key={feedback.question.id} feedback={feedback} />
    ))}
  </>
);

export { Feedbacks };
