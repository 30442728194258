import {
  FilteringByCustomerIdView,
  ViewFilteringByCustomerIdResult,
} from "../../../../projection/filtering/viewFilteringByCustomerId";
import { camelcaseKeys } from "../../../delivery/http/camelcaseKeys";
import { HttpPostFunction } from "../../../delivery/http/httpClient";
import { FilteringDto, toFilteringProjection } from "./filtering";

interface HttpFilteringByCustomerIdView extends FilteringByCustomerIdView {}

interface HttpFilteringByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpFilteringByCustomerIdViewFunction {
  (args: HttpFilteringByCustomerIdViewFunctionArgs): HttpFilteringByCustomerIdView;
}

interface ViewFilteringByCustomerIdResponse {
  readonly result: FilteringDto;
}

const httpFilteringByCustomerIdView: HttpFilteringByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<ViewFilteringByCustomerIdResponse, ViewFilteringByCustomerIdResult>({
      endpoint: "/view-filtering-by-customer-id",
      body: { customer_id: customerId },
      signal,
      result: {
        error: null,
        success: (filtering) => toFilteringProjection({ filtering: camelcaseKeys(filtering.result) }),
      },
    });

export { httpFilteringByCustomerIdView };
