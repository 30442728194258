import { I18nMessage } from "@lookiero/i18n-react";
import { FC, ReactNode } from "react";
import Text, { TextVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { ShortlistFilterProjection } from "../../../../../../../../projection/shortlistFilter/shortlistFilter";
import "./shortlist-filter-header.css";

interface ShortlistFilterHeaderProps {
  readonly filter: ShortlistFilterProjection;
  readonly level?: number;
  readonly children?: ReactNode;
}

const ShortlistFilterHeader: FC<ShortlistFilterHeaderProps> = ({
  filter,
  level = 0,
  children,
}: ShortlistFilterHeaderProps) => (
  <header className="shortlist-filter-header">
    <Text
      className="shortlist-filter-header__text"
      variant={level === 0 ? TextVariant.SMALL_CAPS : TextVariant.BODY_SMALL}
      ellipsis
    >
      <I18nMessage id={filter.translationKey} />
    </Text>
    {children}
  </header>
);

export default ShortlistFilterHeader;
