import { FC, memo } from "react";
import NullShortlistFilterItem from "../../shortlistFilterItem/components/nullShortlistFilterItem/NullShortlistFilterItem";
import PillShortlistFilterItem from "../../shortlistFilterItem/components/pillShortlistFilterItem/PillShortlistFilterItem";
import {
  ShortlistFilterItemProvider,
  ShortlistFilterItems,
} from "../../shortlistFilterItem/hooks/useShortlistFilterItem";
import ShortlistFilters from "../../ShortlistFilters";

import { LegacyRangePillShortlistFilterItem } from "../../shortlistFilterItem/components/legacyRangePillShortlistFilterItem/LegacyRangePillShortlistFilterItem";
import { RangePillShortlistFilterItem } from "../../shortlistFilterItem/components/rangePillShortlistFilterItem/RangePillShortlistFilterItem";
import "./shortlist-filters-summary.css";
import {
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../../../../projection/shortlistFilter/shortlistFilter";

const SHORTLIST_FILTER_SUMMARY_ITEMS: ShortlistFilterItems = {
  [ShortlistFilterType.HOST_NULL]: PillShortlistFilterItem,
  [ShortlistFilterType.HOST_DEFAULT]: PillShortlistFilterItem,
  [ShortlistFilterType.HOST_SEARCH]: PillShortlistFilterItem,
  [ShortlistFilterType.HOST_RANGE]: LegacyRangePillShortlistFilterItem,
  [ShortlistFilterType.HOST_RANGE_2]: RangePillShortlistFilterItem,
  [ShortlistFilterType.CHECKBOX]: PillShortlistFilterItem,
  [ShortlistFilterType.OPTION]: PillShortlistFilterItem,
  [ShortlistFilterType.COLOR]: PillShortlistFilterItem,
  [ShortlistFilterType.RANGE]: NullShortlistFilterItem,
  [ShortlistFilterType.RANGE_LOWER_BOUND]: NullShortlistFilterItem,
  [ShortlistFilterType.RANGE_UPPER_BOUND]: NullShortlistFilterItem,
  [ShortlistFilterType.ICON]: PillShortlistFilterItem,
  [ShortlistFilterType.NULL]: PillShortlistFilterItem,
};

type ShortlistFiltersSummaryProps = {
  readonly filters: ShortlistFilterProjection[];
};
const ShortlistFiltersSummary: FC<ShortlistFiltersSummaryProps> = ({ filters }: ShortlistFiltersSummaryProps) => (
  <section className="shortlist-filters-summary">
    <ShortlistFilterItemProvider shortlistFilterItems={SHORTLIST_FILTER_SUMMARY_ITEMS}>
      <ShortlistFilters filters={filters} />
    </ShortlistFilterItemProvider>
  </section>
);

export default memo(ShortlistFiltersSummary);
