import { I18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { PlanningI18n, PLANNING_I18N_PREFIX } from "../../../../i18n/PlanningI18n";
import "./planned-boxes-list-header.css";

const PlannedBoxesListHeader: FC = () => (
  <header className="planned-boxes-list-header">
    <section className="planned-boxes-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_BOX} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
    <section className="planned-boxes-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD} ellipsis>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_PS} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
    <section className="planned-boxes-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD} ellipsis>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_SEGMENT} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
    <section className="planned-boxes-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_NAME} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
    <section className="planned-boxes-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_COUNTRY} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
    <section className="planned-boxes-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD} ellipsis>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_PREPARE_FOR} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
    <section className="planned-boxes-list-header__col">
      <Text tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_STATUS} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
    <section className="planned-boxes-list-header__col">
      <Text className="planned-box-list-header__box-count" tag="div" variant={TextVariant.BODY_BOLD}>
        <I18nMessage id={PlanningI18n.TABLE_HEADER_BOX_COUNT} prefix={PLANNING_I18N_PREFIX} />
      </Text>
    </section>
  </header>
);

export { PlannedBoxesListHeader };
