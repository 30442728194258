import { FC, Fragment, useMemo } from "react";
import { StyleProfilePreferences } from "../../../../../../projection/styleProfile/styleProfile";
import { StyleProfileCard } from "../styleProfileCard/StyleProfileCard";
import { StyleProfileCardTitle } from "../styleProfileCard/components/Title";
import { StyleProfileQuestion } from "../styleProfileQuestion/StyleProfileQuestion";
import { TextQuestionItem } from "../styleProfileQuestion/components/textQuestionItem/TextQuestionItem";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CUSTOMER_I18N_PREFIX, CUSTOMER_PRICES_I18N_PREFIX, CustomerI18n } from "../../../../i18n/CustomerI18n";
import { Answer } from "../answer/Answer";
import "./preferences.css";
import classNames from "classnames";

type MandatoryPreferences = Pick<StyleProfilePreferences, "price" | "avoid">;
const MANDATORY: (keyof MandatoryPreferences)[] = ["price", "avoid"];

type ExcludePreferences = Pick<StyleProfilePreferences, "footwearExtraInfo" | "colors" | "patterns">;
const EXCLUDE: (keyof ExcludePreferences)[] = ["footwearExtraInfo", "colors", "patterns"];

type RestPreferences = Omit<StyleProfilePreferences, keyof MandatoryPreferences | keyof ExcludePreferences>;

interface Location {
  readonly col: number;
  readonly row: number;
}

const MANDATORY_LOCATION: Record<"price" | "avoid", Location> = {
  price: {
    col: 1,
    row: 1,
  },
  avoid: {
    col: 2,
    row: 1,
  },
};

interface PreferencesProps {
  readonly preferences: StyleProfilePreferences;
  readonly previousPreferences?: StyleProfilePreferences;
  readonly pinned?: boolean;
}

const Preferences: FC<PreferencesProps> = ({ preferences, previousPreferences, pinned }) => {
  const titleText = useI18nMessage({ id: CustomerI18n.PREFERENCES_TITLE, prefix: CUSTOMER_I18N_PREFIX });

  const restPreferences: RestPreferences = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(preferences).filter(
          ([key]) =>
            !MANDATORY.includes(key as keyof MandatoryPreferences) &&
            !EXCLUDE.includes(key as keyof ExcludePreferences),
        ),
      ) as RestPreferences,
    [preferences],
  );

  const price = Object.fromEntries(Object.entries(preferences.price).filter(([key, value]) => Boolean(value)));
  const previousPrice = Object.fromEntries(
    Object.entries(previousPreferences?.price || {}).filter(([key, value]) => Boolean(value)),
  );
  const avoid = [...(preferences.avoid || []), ...(preferences?.colors || []), ...(preferences?.patterns || [])];
  const previousAvoid = [
    ...(previousPreferences?.avoid || []),
    ...(previousPreferences?.colors || []),
    ...(previousPreferences?.patterns || []),
  ];

  if (
    !(Object.values(price).length > 0) &&
    !(avoid && avoid.length > 0) &&
    !Object.values(restPreferences).some((value) => value && value?.length > 0) &&
    !preferences.footwearExtraInfo
  ) {
    return null;
  }

  return (
    <StyleProfileCard className="preferences" label="preferences-card">
      <StyleProfileCardTitle>{titleText}</StyleProfileCardTitle>

      <div className={classNames("preferences__content", { "preferences__content--pinned": pinned })}>
        <StyleProfileQuestion
          style={{ gridColumn: MANDATORY_LOCATION.price.col, gridRow: MANDATORY_LOCATION.price.row }}
          title={CustomerI18n.PRICE}
        >
          {Object.keys(price).map((key) => (
            <Answer key={key} answer={price[key]} previousAnswer={previousPrice?.[key]}>
              {(price) => (
                <div className="preferences__price">
                  <TextQuestionItem>{{ translationKey: `${CUSTOMER_PRICES_I18N_PREFIX}${key}` }}</TextQuestionItem>
                  <TextQuestionItem>{price}</TextQuestionItem>
                </div>
              )}
            </Answer>
          ))}
        </StyleProfileQuestion>

        {avoid && avoid.length > 0 && (
          <StyleProfileQuestion
            style={{ gridColumn: MANDATORY_LOCATION.avoid.col, gridRow: MANDATORY_LOCATION.avoid.row }}
            title={CustomerI18n.AVOID}
          >
            <Answer answer={avoid} previousAnswer={previousAvoid}>
              {(avoids) => (
                <div className="preferences__content-list">
                  {avoids.map(
                    (avoid, index) =>
                      avoid && (
                        <Fragment key={index}>
                          <TextQuestionItem>{avoid}</TextQuestionItem>
                          {index < avoids.length - 1 ? ", " : ""}
                        </Fragment>
                      ),
                  )}
                </div>
              )}
            </Answer>
          </StyleProfileQuestion>
        )}

        {Object.entries(restPreferences).map(
          ([key, value]) =>
            value &&
            value.length > 0 && (
              <StyleProfileQuestion key={key} title={key}>
                <Answer answer={value} previousAnswer={previousPreferences?.[key as keyof RestPreferences]}>
                  {(values) => (
                    <div className="preferences__content-list">
                      {values.map(
                        (item, index) =>
                          value && (
                            <Fragment key={index}>
                              <TextQuestionItem>{item}</TextQuestionItem>
                              {index < values.length - 1 ? ", " : ""}
                            </Fragment>
                          ),
                      )}
                    </div>
                  )}
                </Answer>
              </StyleProfileQuestion>
            ),
        )}

        {preferences.footwearExtraInfo && (
          <StyleProfileQuestion title={CustomerI18n.FOOTWEAR_EXTRA_INFO}>
            <Answer answer={preferences.footwearExtraInfo} previousAnswer={previousPreferences?.footwearExtraInfo}>
              {(footwearExtraInfo) => <TextQuestionItem>{footwearExtraInfo}</TextQuestionItem>}
            </Answer>
          </StyleProfileQuestion>
        )}
      </div>
    </StyleProfileCard>
  );
};

export { Preferences };
