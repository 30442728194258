import { FC, ReactNode } from "react";
import Text, { TextVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { useIntl } from "@lookiero/i18n-react";
import { StyleProfileTranslatable } from "../../../../../../../../projection/styleProfile/styleProfile";
import "./text-question-item.css";

const questionItemIsTranslatable = (
  question: null | ReactNode | StyleProfileTranslatable,
): question is StyleProfileTranslatable =>
  question !== null && (question as StyleProfileTranslatable).translationKey !== undefined;

interface TextQuestionItemProps {
  readonly children: null | ReactNode | StyleProfileTranslatable;
}
const TextQuestionItem: FC<TextQuestionItemProps> = ({ children }) => {
  const { formatMessage } = useIntl();

  const childrenText = questionItemIsTranslatable(children) ? formatMessage({ id: children.translationKey }) : children;

  return (
    <Text className="text-question-item" variant={TextVariant.BODY_BOLD}>
      {childrenText}
    </Text>
  );
};

export type { TextQuestionItemProps };
export { TextQuestionItem };
