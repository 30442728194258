import { FC } from "react";
import Modal from "../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import { BoxPreviewDetail } from "./BoxPreviewDetail";
import { TrackingPage } from "../../../tracking/Tracker";
import "./box-preview-detail-modal.css";

interface BoxPreviewDetailModalProps {
  readonly boxId: string;
  readonly customerId: string;
  readonly origin: TrackingPage;
  readonly visible: boolean;
  readonly onCancel: () => void;
}

const BoxPreviewDetailModal: FC<BoxPreviewDetailModalProps> = ({ boxId, customerId, visible, origin, onCancel }) => (
  <Modal className="box-preview-detail-modal" title="Preview" visible={visible} onHide={onCancel}>
    <BoxPreviewDetail boxId={boxId} customerId={customerId} origin={origin} visible={visible} />
  </Modal>
);

export { BoxPreviewDetailModal };
