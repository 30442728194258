import { FC } from "react";
import { useViewStyleProfileByBoxId } from "../../../../../../../projection/styleProfile/react/useViewStyleProfileByBoxId";
import useBoxSharedContext from "../../../../../../hooks/useBoxSharedContext";
import { CustomerName } from "../../../customerName/CustomerName";
import { Potential } from "../../../customer/potential/Potential";
import { useViewCustomerById } from "../../../../../../../projection/customer/react/useViewCustomerById";
import "./customer-pinned-header.css";

const CustomerPinnedHeader: FC = () => {
  const { box } = useBoxSharedContext();
  const [styleProfile] = useViewStyleProfileByBoxId({ boxId: box.id });
  const [customer] = useViewCustomerById({ id: box.customerId });

  return (
    <div className="customer-pinned-header">
      <CustomerName customer={styleProfile?.customer} />
      {customer?.potential && <Potential potential={customer?.potential} />}
    </div>
  );
};

export { CustomerPinnedHeader };
