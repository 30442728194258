import { I18nMessage } from "@lookiero/i18n-react";
import { isAnyLeafActive, areAllLeavesActive, activate, deactivate } from "../../../ShortlistFilterState";
import Checkbox, {
  CheckboxState,
} from "../../../../../../../../shared/ui/uiKit/components/molecules/checkbox/Checkbox";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import ShortlistFilterItemRoot from "../shortlistFilterItemRoot/ShortlistFilterItemRoot";
import { useCallback, useMemo } from "react";
import classNames from "classnames";
import "./checkbox-shortlist-filter-item.css";
import { ShortlistFilterAction } from "../../../../../../../../projection/shortlistFilter/shortlistFilter";

const CheckboxShortlistFilterItem: ShortlistFilterItemHOC = ({ variant, useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function CheckboxShortlistFilterItemComponent({ filter, children, level }) {
    const { state, onChange } = useShortlistFiltering();

    const checkboxState = useMemo(
      () =>
        isAnyLeafActive({ filter, state, action: ShortlistFilterAction.FILTER })
          ? areAllLeavesActive({ filter, state, action: ShortlistFilterAction.FILTER })
            ? CheckboxState.CHECKED
            : CheckboxState.INDETERMINATE
          : CheckboxState.DEFAULT,
      [filter, state],
    );

    const handleOnChange = useCallback(
      (checked: boolean) =>
        onChange({
          state: checked
            ? activate({ filter, state, action: ShortlistFilterAction.FILTER })
            : deactivate({ filter, state, action: ShortlistFilterAction.FILTER }),
        }),
      [filter, onChange, state],
    );

    const ShortlistFilterItemRootComponent = useMemo(() => ShortlistFilterItemRoot({ useShortlistFiltering }), []);

    return (
      <ShortlistFilterItemRootComponent
        className={classNames("checkbox-shortlist-filter-item", `checkbox-shortlist-filter-item--${variant}`)}
        filter={filter}
        level={level}
        item={
          <Checkbox
            label={<I18nMessage id={filter.translationKey} />}
            state={checkboxState}
            onChange={handleOnChange}
          />
        }
      >
        {children}
      </ShortlistFilterItemRootComponent>
    );
  };

export default CheckboxShortlistFilterItem;
