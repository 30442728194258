import { FC } from "react";
import Picture from "../../../../../shared/ui/uiKit/components/atoms/picture/Picture";
import Card from "../../../../../shared/ui/uiKit/components/layouts/card/Card";
import AspectRatio from "../../../../../shared/ui/uiKit/_common/AspectRatio";
import ProductDataSkeleton from "./productData/ProductDataSkeleton";
import "./product-preview.css";

const ProductPreviewSkeleton: FC = () => (
  <article aria-label="product-preview-skeleton" className="product-preview product-preview--skeleton" role="listitem">
    <Card>
      <Picture aspectRatio={AspectRatio.R_4_5} />
      <ProductDataSkeleton />
    </Card>
  </article>
);

export default ProductPreviewSkeleton;
