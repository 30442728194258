import { FC, ReactNode } from "react";
import Text, { TextVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import "./box-header-info.css";

interface BoxHeaderInfoProps {
  readonly label?: string;
  readonly title: ReactNode;
  readonly children: ReactNode;
}

const BoxHeaderInfo: FC<BoxHeaderInfoProps> = ({ label, title, children }) => {
  return (
    <div aria-label={label} className="box-header-info">
      <Text className="box-header-info__title" variant={TextVariant.BODY_SMALL}>
        {title}
      </Text>
      <Text className="box-header-info__content" variant={TextVariant.BODY_SMALL}>
        {children}
      </Text>
    </div>
  );
};

export { BoxHeaderInfo };
