import { FC } from "react";
import Tooltip from "../../../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import { FeedbackItemProvider, FeedbackItems } from "../../hooks/useFeedbackItem";
import { Feedbacks } from "../feedbacks/Feedbacks";
import classNames from "classnames";
import { HostDefaultFeedbackItem } from "../feedbacks/components/hostDefaultFeedbackItem/HostDefaultFeedbackItem";
import { FeedbackIconItem } from "../feedbacks/components/feedbackIconItem/FeedbackIconItem";
import { ClosedBoxItemFeedbackTooltip } from "../closedBoxItemFeedbackTooltip/ClosedBoxItemFeedbackTooltip";
import "./closed-box-item-feedback.css";
import {
  ClosedBoxItemFeedbackProjection,
  ClosedBoxItemFeedbackType,
} from "../../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";

const feedbackItems: FeedbackItems = {
  [ClosedBoxItemFeedbackType.HOST_DEFAULT]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.HOST_TEXTAREA]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.HOST_SELECT]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.HOST_STACK]: HostDefaultFeedbackItem,
  [ClosedBoxItemFeedbackType.TEXTAREA]: FeedbackIconItem,
  [ClosedBoxItemFeedbackType.OPTION]: FeedbackIconItem,
};

interface ClosedBoxItemFeedbackProps {
  readonly feedbacks: ClosedBoxItemFeedbackProjection[];
  readonly className?: string;
}

const ClosedBoxItemFeedback: FC<ClosedBoxItemFeedbackProps> = ({ feedbacks, className }) => (
  <Tooltip title={<ClosedBoxItemFeedbackTooltip feedbacks={feedbacks} />}>
    <ul className={classNames("closed-box-item-feedback", className)}>
      <FeedbackItemProvider feedbackItems={feedbackItems}>
        <Feedbacks feedbacks={feedbacks} />
      </FeedbackItemProvider>
    </ul>
  </Tooltip>
);

export { ClosedBoxItemFeedback };
