import { QueryStatus, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { countLookAndLikeFeedbacksByCriteria } from "../../../../projection/lookAndLikeFeedback/countLookAndLikeFeedbacksByCriteria";

interface UseCountLookAndLikeFeedbacksByCriteriaFunctionArgs {
  readonly familyIds: string[];
  readonly customerId: string;
  readonly response: boolean;
  readonly itemCountWhileQuerying: number;
}

interface UseCountLookAndLikeFeedbacksByCriteriaFunction {
  (args: UseCountLookAndLikeFeedbacksByCriteriaFunctionArgs): [projection: number, status: QueryStatus];
}

const useCountLookAndLikeFeedbacksByCriteria: UseCountLookAndLikeFeedbacksByCriteriaFunction = ({
  familyIds,
  customerId,
  response,
  itemCountWhileQuerying,
}) => {
  const queryId = ["CountLookAndLikeFeedbacksByCriteria", familyIds, customerId, response];

  const [result, status] = useQuery<number>({
    id: queryId,
    invalidationRefetchType: "active",
    contextId: MESSAGING_CONTEXT_ID,
    query: countLookAndLikeFeedbacksByCriteria({
      criteria: {
        familyIds,
        customerId,
        response,
      },
    }),
    options: { staleTime: 60 * 60 * 1000 },
  });

  return [result ?? itemCountWhileQuerying, status];
};

export { useCountLookAndLikeFeedbacksByCriteria };
