import { FC, useCallback } from "react";
import classNames from "classnames";
import AspectRatio from "../../../../../shared/ui/uiKit/_common/AspectRatio";
import Button from "../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import ProductVariantGallery from "../productVariantGallery/ProductVariantGallery";
import useCaptureAndStopEvent from "../../../../../shared/ui/uiKit/hooks/useCaptureAndStopEvent";
import { isVideoSrc } from "../../../util/srcType";
import Picture from "../../../../../shared/ui/uiKit/components/atoms/picture/Picture";
import Video from "../../../../../shared/ui/uiKit/components/atoms/video/Video";
import cdnImageUrl from "../../../util/cdnImageUrl";
import Media from "../../../../../projection/media/model/Media";
import "./product-variant-detail-gallery.css";

const PRODUCT_VARIANT_DETAIL_GALLERY_THUMB_WIDTH = 80;
const PRODUCT_VARIANT_DETAIL_GALLERY_DETAIL_WIDTH = 680;

interface ProductVariantDetailGalleryThumbProps {
  readonly index: number;
  readonly thumb: string;
  readonly onChange: (index: number) => void;
  readonly active: boolean;
}

const ProductVariantDetailGalleryThumb: FC<ProductVariantDetailGalleryThumbProps> = ({
  index,
  thumb,
  onChange,
  active,
}) => {
  const handleOnClick = useCallback(() => onChange(index), [index, onChange]);

  return (
    <li
      aria-label="view-detail"
      role="button"
      className={classNames("product-variant-detail-gallery__thumb-item", {
        "product-variant-detail-gallery__thumb-item--active": active,
      })}
      onClick={handleOnClick}
    >
      {!isVideoSrc(thumb) ? (
        <Picture
          aspectRatio={AspectRatio.R_4_5}
          label="image-thumb"
          src={cdnImageUrl({ url: thumb, width: PRODUCT_VARIANT_DETAIL_GALLERY_THUMB_WIDTH })}
          lazy
        />
      ) : (
        <Video aspectRatio={AspectRatio.R_4_5} label="video-thumb" src={thumb} autoPlay loop muted />
      )}
    </li>
  );
};

interface ProductVariantDetailGalleryProps {
  readonly media: Media[];
  readonly selectedItemIndex: number;
  readonly onChange: (itemIndex: number) => void;
  readonly onZoomed?: () => void;
  readonly keyboardControlled?: boolean;
}

const ProductVariantDetailGallery: FC<ProductVariantDetailGalleryProps> = ({
  media,
  selectedItemIndex,
  onChange,
  onZoomed,
  keyboardControlled,
}) => {
  const emptyFn = useCallback(() => void 0, []);
  const handleOnOpenZoom = useCaptureAndStopEvent(onZoomed || emptyFn);
  const galleryMedia = media.map((media) =>
    isVideoSrc(media.url)
      ? media.url
      : cdnImageUrl({ url: media.url, width: PRODUCT_VARIANT_DETAIL_GALLERY_DETAIL_WIDTH }),
  );

  return (
    <section
      className={classNames("product-variant-detail-gallery", { "product-variant-detail-gallery--zoomable": onZoomed })}
    >
      <ul className="product-variant-detail-gallery__thumbs">
        {media.map((media, index) => (
          <ProductVariantDetailGalleryThumb
            key={`${media.url}-${index}`}
            active={selectedItemIndex === index}
            index={index}
            thumb={media.url}
            onChange={onChange}
          />
        ))}
      </ul>
      <div
        aria-label="trigger-zoom-in"
        className="product-variant-detail-gallery__gallery"
        role="button"
        onClick={handleOnOpenZoom}
      >
        <ProductVariantGallery
          aspectRatio={AspectRatio.R_4_5}
          className="product-variant-detail-gallery__variant-gallery"
          index={selectedItemIndex}
          itemLabel="detail"
          keyboardControlled={keyboardControlled}
          media={galleryMedia}
          isActive
          onChange={onChange}
        />
        {onZoomed && <Button className="product-variant-detail-gallery__gallery-zoom" icon={IconVariant.SEARCH} />}
      </div>
    </section>
  );
};

export default ProductVariantDetailGallery;
