import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { ReviewCheckout } from "../command/reviewCheckout";
import { checkoutReviewed } from "./checkoutReviewed";

interface Checkout extends AggregateRoot {
  readonly personalShopperId: string;
}

const reviewCheckoutHandler: CommandHandlerFunction<ReviewCheckout, Checkout> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { personalShopperId } = command;

    return {
      ...aggregateRoot,
      personalShopperId,
      domainEvents: [checkoutReviewed({ aggregateId: aggregateRoot.aggregateId })],
    };
  };

export type { Checkout };
export { reviewCheckoutHandler };
