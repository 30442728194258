import { BoxProjection } from "../../../projection/box/box";
import { BoxByIdView, ViewBoxByIdResult } from "../../../projection/box/viewBoxById";
import { camelcaseKeys } from "../../delivery/http/camelcaseKeys";
import { HttpPostFunction } from "../../delivery/http/httpClient";

interface HttpBoxByIdView extends BoxByIdView {}

interface HttpBoxByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxByIdViewFunction {
  (args: HttpBoxByIdViewFunctionArgs): HttpBoxByIdView;
}

interface ViewBoxByIdResponse {
  readonly result: BoxProjection;
}

const httpBoxByIdView: HttpBoxByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<ViewBoxByIdResponse, ViewBoxByIdResult>({
      endpoint: "/view-box-by-id",
      body: { id },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result),
      },
    });

export { httpBoxByIdView };
