import { createContext, FC, useContext, useMemo } from "react";
import { invariant } from "ts-invariant";
import { ShortlistFilterItem } from "../ShortlistFilterItem";
import { ShortlistFilterType } from "../../../../../../../projection/shortlistFilter/shortlistFilter";

export type ShortlistFilterItems = Record<ShortlistFilterType, ShortlistFilterItem>;

const ShortlistFilterItemContext = createContext<ShortlistFilterItems>({} as ShortlistFilterItems);

interface ShortlistFilterItemContextProviderProps {
  readonly shortlistFilterItems: ShortlistFilterItems;
  readonly children: JSX.Element;
}

const ShortlistFilterItemProvider: FC<ShortlistFilterItemContextProviderProps> = ({
  shortlistFilterItems,
  children,
}: ShortlistFilterItemContextProviderProps) => (
  <ShortlistFilterItemContext.Provider value={shortlistFilterItems}>{children}</ShortlistFilterItemContext.Provider>
);

interface UseShortlistFilterItemFunctionArgs {
  readonly type: ShortlistFilterType;
}

interface UseShortlistFilterItemFunction {
  (args: UseShortlistFilterItemFunctionArgs): ShortlistFilterItem;
}

const useShortlistFilterItem: UseShortlistFilterItemFunction = ({ type }) => {
  const items = useContext<ShortlistFilterItems>(ShortlistFilterItemContext);
  const item = useMemo(() => items[type], [items, type]);

  invariant(
    items,
    "Your are trying to use the useShortlistFilterItem hook without wrapping your app with the <ShortlistFilterItemContextProvider>.",
  );

  invariant(item, `The provided ShorlistFilterType (${type}) is NOT SUPPORTED`);

  return item;
};

export { ShortlistFilterItemProvider, useShortlistFilterItem };
