import { FC, ReactNode } from "react";
import classNames from "classnames";
import Icon, { IconVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { StyleProfileSocialNetworks } from "../../../../../../../../projection/styleProfile/styleProfile";
import "./social-network-question-item.css";

interface ExternalLinkProps {
  readonly children: ReactNode;
  readonly href?: string;
  readonly disabled?: boolean;
}
const ExternalLink: FC<ExternalLinkProps> = ({ children, href, disabled = false }) => (
  <a
    className={classNames("external-link", disabled && "external-link--disabled")}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
);

type SocialNetwork = keyof StyleProfileSocialNetworks;

const SOCIAL_NETWORKS: Record<SocialNetwork, { icon: IconVariant; url: string }> = {
  instagram: { icon: IconVariant.INSTAGRAM, url: "https://www.instagram.com/" },
  pinterest: { icon: IconVariant.PINTEREST, url: "https://www.pinterest.com/" },
};

interface SocialNetworksQuestionItemProps {
  readonly socialNetworks: StyleProfileSocialNetworks;
}
const SocialNetworksQuestionItem: FC<SocialNetworksQuestionItemProps> = ({ socialNetworks }) => (
  <div aria-label="social-networks-question-item" className="social-networks-question-item">
    {Object.entries(socialNetworks).map(([key, value]) => (
      <ExternalLink key={key} disabled={!Boolean(value)} href={`${SOCIAL_NETWORKS[key as SocialNetwork].url}${value}`}>
        <Icon label={key} variant={SOCIAL_NETWORKS[key as SocialNetwork].icon} />
      </ExternalLink>
    ))}
  </div>
);

export { SocialNetworksQuestionItem };
