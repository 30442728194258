import { camelcaseKeys } from "../../delivery/http/camelcaseKeys";
import {
  SelectionByBoxNumberView,
  ViewSelectionByBoxNumberResult,
} from "../../../projection/selection/viewSelectionByBoxNumber";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { SelectionDto, toSelectionProjection } from "./selection";

interface HttpSelectionByBoxNumberView extends SelectionByBoxNumberView {}

interface HttpSelectionByBoxNumberViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpSelectionByBoxNumberViewFunction {
  (args: HttpSelectionByBoxNumberViewFunctionArgs): HttpSelectionByBoxNumberView;
}

interface ViewBoxByBoxNumberResponse {
  readonly result: SelectionDto;
}

const httpSelectionByBoxNumberView: HttpSelectionByBoxNumberViewFunction =
  ({ httpPost }) =>
  async ({ boxNumber, signal }) =>
    await httpPost<ViewBoxByBoxNumberResponse, ViewSelectionByBoxNumberResult>({
      endpoint: "/view-selection-by-box-number",
      body: { box_number: boxNumber },
      signal,
      result: {
        error: null,
        success: (response) => toSelectionProjection(camelcaseKeys(response.result)),
      },
    });

export { httpSelectionByBoxNumberView };
