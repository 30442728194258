import { FC } from "react";
import classNames from "classnames";
import AspectRatioContainer from "../../layouts/aspectRatioContainer/AspectRatioContainer";
import AspectRatio from "../../../_common/AspectRatio";
import "./video.css";

type VideoProps = {
  readonly className?: string;
  readonly src: string;
  readonly label?: string;
  readonly aspectRatio?: AspectRatio;
  readonly controls?: boolean;
  readonly autoPlay?: boolean;
  readonly muted?: boolean;
  readonly loop?: boolean;
  readonly playsInline?: boolean;
};
const Video: FC<VideoProps> = ({
  className,
  src,
  label,
  aspectRatio = AspectRatio.R_3_4,
  controls,
  autoPlay,
  muted,
  loop,
  playsInline,
}: VideoProps) => (
  <AspectRatioContainer aspectRatio={aspectRatio} className={classNames("video", className)}>
    <video
      aria-label={label}
      autoPlay={autoPlay}
      className="video__video"
      controls={controls}
      loop={loop}
      muted={muted}
      playsInline={playsInline}
      role="application"
    >
      <source src={src} type="video/mp4" />
    </video>
  </AspectRatioContainer>
);

export default Video;
