import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { SelectionAlertProjection } from "../../../../projection/alert/selectionAlert";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { listSelectionAlertsByBoxNumber } from "../../../../projection/alert/listSelectionAlertsByBoxNumber";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isProductVariantDeselected } from "../../../../domain/selection/model/ProductVariantDeselected";
import { isProductVariantReplaced } from "../../../../domain/selection/model/ProductVariantReplaced";
import { isProductVariantSelected } from "../../../../domain/selection/model/ProductVariantSelected";
import { isProductVariantUnmarkedAsCandidate } from "../../../../domain/selection/model/productVariantUnmarkedAsCandidate";
import { isProductVariantMarkedAsCandidate } from "../../../../domain/selection/model/productVariantMarkedAsCandidate";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isProductVariantDeselected(event) ||
  isProductVariantReplaced(event) ||
  isProductVariantSelected(event) ||
  isProductVariantUnmarkedAsCandidate(event) ||
  isProductVariantMarkedAsCandidate(event);

interface UseListSelectionAlertsByBoxNumberFunctionArgs {
  readonly queryId?: string | string[];
  readonly boxNumber: string;
}

interface UseListSelectionAlertsByBoxNumberFunction {
  (args: UseListSelectionAlertsByBoxNumberFunctionArgs): UseQueryFunctionResult<SelectionAlertProjection[]>;
}

const useListSelectionAlertsByBoxNumber: UseListSelectionAlertsByBoxNumberFunction = ({ queryId, boxNumber }) =>
  useQuery({
    id: queryId,
    contextId: MESSAGING_CONTEXT_ID,
    query: listSelectionAlertsByBoxNumber({ boxNumber }),
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useListSelectionAlertsByBoxNumber };
