import { QueryStatus, useQuery } from "@lookiero/messaging-react";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { NoteTemplateCategory } from "../../../../projection/noteTemplateCategory/noteTemplateCategory";
import { isNoteTemplateWritten } from "../../../../domain/noteTemplate/model/noteTemplateWritten";
import { isNoteTemplateRemoved } from "../../../../domain/noteTemplate/model/noteTemplateRemoved";
import {
  CountNoteTemplatesByCriteriaResult,
  countNoteTemplatesByCriteria,
} from "../../../../projection/noteTemplate/countNoteTemplatesByCriteria";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isNoteTemplateWritten(event) || isNoteTemplateRemoved(event);

interface UseCountNoteTemplatesByCriteriaFunctionArgs {
  readonly psId: string | undefined;
  readonly categories: NoteTemplateCategory[];
  readonly itemCountWhileQuerying: number | undefined;
}

interface UseCountNoteTemplatesByCriteriaFunction {
  (args: UseCountNoteTemplatesByCriteriaFunctionArgs): [CountNoteTemplatesByCriteriaResult | undefined, QueryStatus];
}

const useCountNoteTemplatesByCriteria: UseCountNoteTemplatesByCriteriaFunction = ({
  psId,
  categories,
  itemCountWhileQuerying,
}) => {
  const [count, status] = useQuery<number, Error>({
    contextId: MESSAGING_CONTEXT_ID,
    query: countNoteTemplatesByCriteria({ criteria: { psId: psId as string, categories } }),
    invalidation: shouldInvalidate,
    options: { staleTime: 5 * 60 * 1000, enabled: psId !== undefined },
  });

  return [count ?? itemCountWhileQuerying, status];
};

export { useCountNoteTemplatesByCriteria };
