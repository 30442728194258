import { FC, useCallback, useMemo } from "react";
import { I18nDate, I18nMessage, useI18nMessage } from "@lookiero/i18n-react";
import { HistoryBoxProjection } from "../../../../../../../../../../projection/historyBox/historyBox";
import Text, { TextVariant } from "../../../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import Price from "../../../../../../../../components/atoms/price/Price";
import AssignedPs from "../../../../../../../../components/molecules/assignedPs/AssignedPs";
import { CustomerI18n, CUSTOMER_I18N_PREFIX } from "../../../../../../../../i18n/CustomerI18n";
import HistoryI18n, { HISTORY_I18N_PREFIX } from "../../../../../../../../i18n/HistoryI18n";
import { ClosedBoxPreviewButton } from "../../../../../../../../components/molecules/closedBoxPreviewButton/ClosedBoxPreviewButton";
import Card from "../../../../../../../../../../shared/ui/uiKit/components/layouts/card/Card";
import Tooltip from "../../../../../../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import {
  ClosedBoxItem,
  ClosedBoxItemOnViewDetailFunction,
} from "../../../../../../../../components/organisms/closedBoxItem/ClosedBoxItem";
import { ClosedBoxItemFeedback } from "../../../../../../../../components/organisms/closedBoxItem/components/closedBoxItemFeedback/ClosedBoxItemFeedback";
import { ClosedBoxItemSize } from "../../../../../../../../components/molecules/closedBoxItemSize/ClosedBoxItemSize";
import { HistoryBoxProductVariantProjection } from "../../../../../../../../../../projection/historyBox/historyBoxProductVariant";
import { ClosedBoxItemFeedbackProjection } from "../../../../../../../../../../projection/_shared/closedBox/closedBoxItemFeedback";
import "./history-box.css";

interface HistoryBoxProductVariantProps {
  readonly productVariant: HistoryBoxProductVariantProjection;
  readonly onViewDetail: ClosedBoxItemOnViewDetailFunction;
}

const HistoryBoxProductVariant: FC<HistoryBoxProductVariantProps> = ({ productVariant, onViewDetail }) => {
  const { stripFeedbacks, bottomFeedbacks } = useMemo(
    () =>
      productVariant.feedback.reduce(
        (acc, returnQuestion) => ({
          stripFeedbacks: [
            ...acc.stripFeedbacks,
            ...(returnQuestion.question.section === "strip" ? [returnQuestion] : []),
          ],
          bottomFeedbacks: [
            ...acc.bottomFeedbacks,
            ...(returnQuestion.question.section === "bottom" ? [returnQuestion] : []),
          ],
        }),
        {
          stripFeedbacks: [] as ClosedBoxItemFeedbackProjection[],
          bottomFeedbacks: [] as ClosedBoxItemFeedbackProjection[],
        },
      ),
    [productVariant],
  );

  return (
    <ClosedBoxItem
      bottomFeedback={<ClosedBoxItemFeedback className="history-box__bottom-feedback" feedbacks={bottomFeedbacks} />}
      label="history-product-variant"
      productVariant={productVariant}
      size={<ClosedBoxItemSize size={productVariant.size} sizeChange={productVariant.sizeChange} />}
      stripFeedback={<ClosedBoxItemFeedback feedbacks={stripFeedbacks} />}
      onViewDetail={onViewDetail}
    />
  );
};

interface OnViewProductVariantDetailFunctionArgs {
  readonly boxId: string;
  readonly customerId: string;
  readonly productId: string;
  readonly productVariantId: string;
}

interface OnViewProductVariantDetailFunction {
  (args: OnViewProductVariantDetailFunctionArgs): void;
}

interface OnViewBoxPreviewFunctionArgs {
  readonly boxId: string;
  readonly customerId: string;
}

interface OnViewBoxPreviewFunction {
  (args: OnViewBoxPreviewFunctionArgs): void;
}

interface HistoryBoxProps {
  readonly customerId: string;
  readonly box: HistoryBoxProjection;
  readonly onViewProductVariantDetail: OnViewProductVariantDetailFunction;
  readonly onViewBoxPreview: OnViewBoxPreviewFunction;
}

const HistoryBox: FC<HistoryBoxProps> = ({ customerId, box, onViewProductVariantDetail, onViewBoxPreview }) => {
  const orderCommentLabel = useI18nMessage({ id: CustomerI18n.COMMENT_GENERAL_PREVIOUS, prefix: CUSTOMER_I18N_PREFIX });
  const checkoutCommentLabel = useI18nMessage({
    id: CustomerI18n.COMMENT_CHECKOUT,
    prefix: CUSTOMER_I18N_PREFIX,
  });
  const customerServiceCommentLabel = useI18nMessage({
    id: CustomerI18n.COMMENT_CUSTOMER_SERVICE,
    prefix: CUSTOMER_I18N_PREFIX,
  });

  const handleOnClickBoxPreview = useCallback(
    () => onViewBoxPreview({ boxId: box.id, customerId }),
    [box.id, customerId, onViewBoxPreview],
  );

  const handleOnViewProductVariantDetail: ClosedBoxItemOnViewDetailFunction = useCallback(
    ({ productId, productVariantId }) =>
      onViewProductVariantDetail({
        productId,
        productVariantId,
        boxId: box.id,
        customerId,
      }),
    [box.id, customerId, onViewProductVariantDetail],
  );

  return (
    <article aria-label="history-box" className="history-box" role="listitem">
      <Card className="history-box__card">
        {box.items.map((item) => (
          <HistoryBoxProductVariant
            key={item.productVariantId}
            productVariant={item}
            onViewDetail={handleOnViewProductVariantDetail}
          />
        ))}

        <section className="history-box__info">
          <div className="history-box__header">
            <div className="history-box__pricing">
              <Price className="history-box__pricing-purchased" price={box.pricing.purchased} />
              <Text className="history-box__pricing-separator">|</Text>
              <Price className="history-box__pricing-total" price={box.pricing.total} />
            </div>

            <div className="history-box__row">
              {box.preview && <ClosedBoxPreviewButton status={box.preview.status} onClick={handleOnClickBoxPreview} />}
              {box.assignedPs && <AssignedPs legacyId={box.assignedPs.legacyId} />}
            </div>
          </div>

          <div>
            <Text className="history-box__box-data" variant={TextVariant.BODY_SMALL}>
              <I18nMessage id={HistoryI18n.BOX_TITLE} prefix={HISTORY_I18N_PREFIX} /> {box.legacyId}
              <span> · </span>
              <I18nDate dateStyle="medium" value={box.plannedFor} />
            </Text>
          </div>

          <div className="history-box__comment-container">
            {box.comment && (
              <Tooltip title={box.comment}>
                <Text className="history-box__comment-text" variant={TextVariant.BODY_SMALL}>
                  <span className="history-box__comment-title">{orderCommentLabel}: </span> {box.comment}
                </Text>
              </Tooltip>
            )}

            {box.checkoutComment && (
              <Tooltip title={box.checkoutComment}>
                <Text className="history-box__comment-text" variant={TextVariant.BODY_SMALL}>
                  <span className="history-box__comment-title">{checkoutCommentLabel}: </span> {box.checkoutComment}
                </Text>
              </Tooltip>
            )}

            {box.customerServiceComment && (
              <Tooltip title={box.customerServiceComment}>
                <Text className="history-box__comment-text" variant={TextVariant.BODY_SMALL}>
                  <span className="history-box__comment-title">{customerServiceCommentLabel}: </span>
                  {box.customerServiceComment}
                </Text>
              </Tooltip>
            )}
          </div>
        </section>
      </Card>
    </article>
  );
};

export type { OnViewProductVariantDetailFunction };
export { HistoryBox };
