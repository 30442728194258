import {
  CheckoutsListByPersonalShopperIdView,
  ListCheckoutsByPersonalShopperIdResult,
} from "../../../projection/checkout/listCheckoutsByPersonalShopperId";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { CheckoutProjectionDto, toCheckoutProjection } from "./checkout";

interface HttpCheckoutsListByPersonalShopperIdView extends CheckoutsListByPersonalShopperIdView {}

interface HttpCheckoutsListByPersonalShopperIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpCheckoutsListByPersonalShopperIdViewFunction {
  (args: HttpCheckoutsListByPersonalShopperIdViewFunctionArgs): HttpCheckoutsListByPersonalShopperIdView;
}

const httpCheckoutsListByPersonalShopperIdView: HttpCheckoutsListByPersonalShopperIdViewFunction =
  ({ httpPost }) =>
  async ({ personalShopperId, page, perPage, signal }) =>
    await httpPost<CheckoutProjectionDto[], ListCheckoutsByPersonalShopperIdResult>({
      endpoint: "/list-checkouts-by-personal-shopper-id",
      body: { personalShopperId, page, perPage },
      signal,
      result: {
        error: null,
        success: (response) => response.map((checkoutDto) => toCheckoutProjection({ checkout: checkoutDto })),
      },
    });

export { httpCheckoutsListByPersonalShopperIdView };
