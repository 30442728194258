/* eslint-disable react/jsx-no-bind */
import { useState, ReactNode, useCallback, FC } from "react";
import Text, { TextVariant } from "../../../../shared/ui/uiKit/components/atoms/text/Text";
import Button, { ButtonVariant } from "../../../../shared/ui/uiKit/components/atoms/button/Button";
import Input from "../../../../shared/ui/uiKit/components/molecules/input/Input";
import Tooltip from "../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import Badge, { BadgeVariant } from "../../../../shared/ui/uiKit/components/atoms/badge/Badge";
import Picture from "../../../../shared/ui/uiKit/components/atoms/picture/Picture";
import PictureListItem from "../../../../shared/ui/uiKit/components/molecules/pictureListItem/PictureListItem";
import Card from "../../../../shared/ui/uiKit/components/layouts/card/Card";
import Tabs from "../../../../shared/ui/uiKit/components/layouts/tabs/Tabs";
import Dropdown from "../../../../shared/ui/uiKit/components/organisms/dropdown/Dropdown";
import Checkbox, { CheckboxState } from "../../../../shared/ui/uiKit/components/molecules/checkbox/Checkbox";
import DatePicker from "../../../../shared/ui/uiKit/components/organisms/datePicker/DatePicker";
import ActionButton from "../../../../shared/ui/uiKit/components/molecules/actionButton/ActionButton";
import ColorCheckbox from "../../../../shared/ui/uiKit/components/molecules/colorCheckbox/ColorCheckbox";
import Pill from "../../../../shared/ui/uiKit/components/molecules/pill/Pill";
import {
  Notification,
  NotificationVariant,
} from "../../../../shared/ui/uiKit/components/molecules/notification/Notification";
import AsyncActionState from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import Icon, { IconVariant } from "../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import AspectRatio from "../../../../shared/ui/uiKit/_common/AspectRatio";
import { Tag, TagVariant } from "../../../../shared/ui/uiKit/components/atoms/tag/Tag";

const Group = ({ label, children }: { label: string; children: ReactNode }): JSX.Element => (
  <div style={{ margin: "1em 0 4em" }}>
    <Text variant={TextVariant.BODY_SMALL}>{label}</Text>
    <hr style={{ border: 0, borderTop: "1px solid var(--color-dark-20)" }} />
    {children}
  </div>
);
const Row = ({ children }: { children: ReactNode }): JSX.Element => <div style={{ margin: "1em 0" }}>{children}</div>;
const Gutter = (): JSX.Element => <span style={{ width: "1em", display: "inline-block" }} />;

const dropdownOptions = [
  { label: "foo", value: "foo" },
  { label: "bar", value: "bar" },
];

const UiKit: FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [dropdownValue, setDropdownValue] = useState<string | string[] | null | undefined>();
  const [checkboxState, setCheckboxState] = useState<CheckboxState>(CheckboxState.DEFAULT);
  const onCheckboxChanged = useCallback(
    (checked: boolean) => setCheckboxState(checked ? CheckboxState.CHECKED : CheckboxState.DEFAULT),
    [],
  );

  return (
    <div style={{ padding: "2em", background: "white" }}>
      <Text variant={TextVariant.HEADING_1}>UI Kit</Text>
      <Group label="Text">
        <Row>
          <Text variant={TextVariant.HEADING_1}>Heading 1</Text>
        </Row>
        <Row>
          <Text variant={TextVariant.HEADING_2}>Heading 2</Text>
        </Row>
        <Row>
          <Text variant={TextVariant.BODY_BOLD}>Body Bold</Text>
        </Row>
        <Row>
          <Text variant={TextVariant.BODY}>Body</Text>
        </Row>
        <Row>
          <Text variant={TextVariant.BODY_SMALL}>Body Small</Text>
        </Row>
      </Group>
      <Group label="Icon">
        <Row>
          {Object.keys(IconVariant).map((variant) => (
            <span key={variant}>
              <Icon variant={variant as IconVariant} />
              <Gutter />
            </span>
          ))}
        </Row>
      </Group>
      <Group label="Button">
        <Row>
          <Button>Ver pedido</Button>
          <Gutter />
          <Button disabled>Ver pedido</Button>
        </Row>
        <Row>
          <Button variant={ButtonVariant.PRIMARY}>Crear pedido</Button>
          <Gutter />
          <Button variant={ButtonVariant.PRIMARY} disabled>
            Crear pedido
          </Button>
        </Row>
        <Row>
          <Button variant={ButtonVariant.DESTRUCTIVE}>Eliminar pedido</Button>
          <Gutter />
          <Button variant={ButtonVariant.DESTRUCTIVE} disabled>
            Eliminar pedido
          </Button>
        </Row>
      </Group>

      <Group label="ActionButton">
        <Row>
          <ActionButton>Basic</ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.PENDING}>Pending</ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.ERROR}>Error</ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.SUCCESS}>Success</ActionButton>
        </Row>
        <Row>
          <ActionButton variant={ButtonVariant.PRIMARY}>Primary</ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.PENDING} variant={ButtonVariant.PRIMARY}>
            Pending
          </ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.ERROR} variant={ButtonVariant.PRIMARY}>
            Error
          </ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.SUCCESS} variant={ButtonVariant.PRIMARY}>
            Success
          </ActionButton>
        </Row>
        <Row>
          <ActionButton variant={ButtonVariant.DESTRUCTIVE}>Destructive</ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.PENDING} variant={ButtonVariant.DESTRUCTIVE}>
            Pending
          </ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.ERROR} variant={ButtonVariant.DESTRUCTIVE}>
            Error
          </ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.SUCCESS} variant={ButtonVariant.DESTRUCTIVE}>
            Success
          </ActionButton>
        </Row>
        <Row>
          <ActionButton variant={ButtonVariant.PLAIN}>Plain</ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.PENDING} variant={ButtonVariant.PLAIN}>
            Pending
          </ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.ERROR} variant={ButtonVariant.PLAIN}>
            Error
          </ActionButton>
          <Gutter />
          <ActionButton state={AsyncActionState.SUCCESS} variant={ButtonVariant.PLAIN}>
            Success
          </ActionButton>
        </Row>
      </Group>

      <Group label="Checkbox">
        <Row>
          <Checkbox state={checkboxState} onChange={onCheckboxChanged} />
          <Gutter />
          <Checkbox state={CheckboxState.INDETERMINATE} onChange={(): void => void 0} />
          <Gutter />
          <Checkbox disabled onChange={(): void => void 0} />
          <Gutter />
          <Checkbox label="checkbox" state={checkboxState} onChange={onCheckboxChanged} />
          <Gutter />
          <Checkbox label="checkbox" state={checkboxState} disabled onChange={onCheckboxChanged} />
        </Row>
      </Group>
      <Group label="Input">
        <Row>
          <Input
            error={inputValue === "" ? "Campo requerido" : undefined}
            placeholder="foo"
            value={inputValue}
            onChange={setInputValue}
          />
          <Gutter />
          <Input placeholder="foo" value={inputValue} disabled onChange={setInputValue} />
          <Gutter />
          <Input placeholder="foo" value={inputValue} readOnly onChange={setInputValue} />
        </Row>
        <Row>
          <Input
            error={inputValue === "" ? "Campo requerido" : undefined}
            icon={IconVariant.SEARCH}
            placeholder="foo"
            value={inputValue}
            onChange={setInputValue}
          />
          <Gutter />
          <Input icon={IconVariant.SEARCH} placeholder="foo" value={inputValue} disabled onChange={setInputValue} />
          <Gutter />
          <Input icon={IconVariant.SEARCH} placeholder="foo" value={inputValue} readOnly onChange={setInputValue} />
        </Row>
      </Group>
      <Group label="Tooltip">
        <Row>
          <Tooltip title="Compra exclusiva: UK">UK</Tooltip>
        </Row>
      </Group>
      <Group label="Badge">
        <Row>
          <Badge>Realizado</Badge>
          <Badge variant={BadgeVariant.BASIC_DARK}>Realizado</Badge>
          <Gutter />
          <Badge variant={BadgeVariant.YELLOW}>Registrado</Badge>
          <Badge variant={BadgeVariant.YELLOW_DARK}>Registrado</Badge>
          <Gutter />
          <Badge variant={BadgeVariant.ORANGE}>Recibido</Badge>
          <Badge variant={BadgeVariant.ORANGE_DARK}>Recibido</Badge>
          <Gutter />
          <Badge variant={BadgeVariant.RED}>Recepcionado</Badge>
          <Badge variant={BadgeVariant.RED_DARK}>Recepcionado</Badge>
          <Gutter />
          <Badge variant={BadgeVariant.PURPLE}>Priorizado</Badge>
          <Badge variant={BadgeVariant.PURPLE_DARK}>Priorizado</Badge>
          <Gutter />
          <Badge variant={BadgeVariant.BLUE}>Informando</Badge>
          <Badge variant={BadgeVariant.BLUE_DARK}>Informando</Badge>
          <Gutter />
          <Badge variant={BadgeVariant.GREEN}>Informado</Badge>
          <Badge variant={BadgeVariant.GREEN_DARK}>Informado</Badge>
        </Row>
      </Group>
      <Group label="Tag">
        <Row>
          <Tag>Realizado</Tag>
          <Gutter />
          <Tag variant={TagVariant.YELLOW}>Registrado</Tag>
          <Gutter />
          <Tag variant={TagVariant.ORANGE}>Recibido</Tag>
          <Gutter />
          <Tag variant={TagVariant.RED}>Recepcionado</Tag>
          <Gutter />
          <Tag variant={TagVariant.PURPLE}>Priorizado</Tag>
          <Gutter />
          <Tag variant={TagVariant.BLUE}>Informando</Tag>
          <Gutter />
          <Tag variant={TagVariant.GREEN}>Informado</Tag>
        </Row>
      </Group>
      <Group label="Color checkbox">
        <Row>
          <ColorCheckbox
            checked={false}
            color={{ id: "", code: "C24", name: "Coral", hexadecimal: "DD424A", parent: null }}
            onChange={(): void => void 0}
          />
          <ColorCheckbox
            checked={true}
            color={{ id: "", code: "C24", name: "Coral", hexadecimal: "DD424A", parent: null }}
            onChange={(): void => void 0}
          />
        </Row>
      </Group>
      <Group label="Pill">
        <Pill onRemove={(): void => void 0}>White</Pill>
      </Group>

      <Group label="Picture">
        <Row>
          <div style={{ width: 240 }}>
            <Picture
              aspectRatio={AspectRatio.R_3_4}
              label="dummy-cloth"
              src="https://cdn-catalog-back-prod.envs.lookiero.tech/f1/01/f1011c57-4e4c-43eb-9a1c-23f5246cfcf7.jpg"
            />
          </div>
        </Row>
      </Group>

      <Group label="Picture List Item">
        <Row>
          <PictureListItem
            alt=""
            familyName=""
            src="https://cdn-catalog-back-prod.envs.lookiero.tech/f1/01/f1011c57-4e4c-43eb-9a1c-23f5246cfcf7.jpg"
          >
            <Text variant={TextVariant.BODY}>ES7532</Text>
          </PictureListItem>
        </Row>
        <Row>
          <PictureListItem
            alt=""
            familyName=""
            src="https://cdn-catalog-back-prod.envs.lookiero.tech/f1/01/f1011c57-4e4c-43eb-9a1c-23f5246cfcf7.jpg"
            selected
          >
            <Text variant={TextVariant.BODY}>ES7532</Text>
          </PictureListItem>
        </Row>
        <Row>
          <PictureListItem alt="" familyName="CAMISA" selected>
            <Text variant={TextVariant.BODY}>ES7532</Text>
          </PictureListItem>
        </Row>
      </Group>

      <Group label="Dropdown">
        <Row>
          <Dropdown label="Market" options={dropdownOptions} value={dropdownValue} onChange={setDropdownValue} />
          <Gutter />
          <Dropdown
            label="Market"
            options={dropdownOptions}
            value={dropdownValue}
            isMulti
            onChange={setDropdownValue}
          />
          <Gutter />
          <Dropdown
            label="Market"
            options={dropdownOptions}
            value={dropdownValue}
            disabled
            onChange={setDropdownValue}
          />
          <Gutter />
          <Dropdown
            label="Market"
            options={dropdownOptions}
            value={dropdownValue}
            readOnly
            onChange={setDropdownValue}
          />
          <Gutter />
          <Dropdown
            error="This is an error"
            label="Market"
            options={dropdownOptions}
            value={dropdownValue}
            onChange={setDropdownValue}
          />
        </Row>
      </Group>

      <Group label="Datepicker">
        <Row>
          <DatePicker label="Fecha" onChange={(): void => void 0} />
          <Gutter />
          <DatePicker label="Fecha" value={new Date()} readOnly onChange={(): void => void 0} />
          <Gutter />
          <DatePicker error="This is an error" label="Fecha" onChange={(): void => void 0} />
        </Row>
      </Group>

      <Group label="Tabs">
        <Row>
          <Tabs labels={["Pedidos", "Compras", "Facturas"]}>
            <div>Pedidos</div>
            <div>Compras</div>
            <div>Facturas</div>
          </Tabs>
        </Row>
      </Group>

      <Group label="Card">
        <Row>
          <Card>This is a Card component</Card>
        </Row>
      </Group>

      <Group label="Notification">
        <Row>
          <Notification variant={NotificationVariant.DEFAULT}>This is a basic notification</Notification>
        </Row>
        <Row>
          <Notification variant={NotificationVariant.ERROR}>This is a error notification</Notification>
        </Row>
        <Row>
          <Notification variant={NotificationVariant.SUCCESS}>This is a success notification</Notification>
        </Row>
      </Group>
    </div>
  );
};

export default UiKit;
