import { FC, memo } from "react";
import CheckboxShortlistFilterItem from "../../shortlistFilterItem/components/checkboxShortlistFilterItem/CheckboxShortlistFilterItem";
import ButtonOptionShortlistFilterItem from "../../shortlistFilterItem/components/buttonOptionShortlistFilterItem/ButtonOptionShortlistFilterItem";
import ColorOptionShortlistFilterItem from "../../shortlistFilterItem/components/colorOptionShortlistFilterItem/ColorOptionShortlistFilterItem";
import IconOptionShortlistFilterItem from "../../shortlistFilterItem/components/iconOptionShortlistFilterItem/IconOptionShortlistFilterItem";
import DefaultHostShortlistFilterItem from "../../shortlistFilterItem/components/defaultHostShortlistFilterItem/DefaultHostShortlistFilterItem";
import SearchHostShortlistFilterItem from "../../shortlistFilterItem/components/searchHostShortlistFilterItem/SearchHostShortlistFilterItem";
import NullShortlistFilterItem from "../../shortlistFilterItem/components/nullShortlistFilterItem/NullShortlistFilterItem";
import {
  ShortlistFilterItemProvider,
  ShortlistFilterItems,
} from "../../shortlistFilterItem/hooks/useShortlistFilterItem";
import ShortlistFilters from "../../ShortlistFilters";
import { useShortlistFilteringNegatives } from "../../shortlistFilterItem/hooks/useShortlistFilteringNegatives";
import {
  ShortlistFilterProjection,
  ShortlistFilterType,
} from "../../../../../../../projection/shortlistFilter/shortlistFilter";
import { RangeHostShortlistFilterItem } from "../../shortlistFilterItem/components/rangeHostShortlistFilterItem/RangeHostShortlistFilterItem";
import { LegacyRangeHostShortlistFilterItem } from "../../shortlistFilterItem/components/legacyRangeHostShortlistFilterItem/LegacyRangeHostShortlistFilterItem";
import "./shortlist-filters-negatives.css";

const SHORTLIST_FILTER_ITEMS: ShortlistFilterItems = {
  [ShortlistFilterType.HOST_NULL]: NullShortlistFilterItem,
  [ShortlistFilterType.HOST_DEFAULT]: DefaultHostShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.HOST_SEARCH]: SearchHostShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.HOST_RANGE]: LegacyRangeHostShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.HOST_RANGE_2]: RangeHostShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.CHECKBOX]: CheckboxShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.OPTION]: ButtonOptionShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.COLOR]: ColorOptionShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.RANGE]: NullShortlistFilterItem,
  [ShortlistFilterType.RANGE_LOWER_BOUND]: NullShortlistFilterItem,
  [ShortlistFilterType.RANGE_UPPER_BOUND]: NullShortlistFilterItem,
  [ShortlistFilterType.ICON]: IconOptionShortlistFilterItem({
    variant: "negative",
    useShortlistFiltering: useShortlistFilteringNegatives,
  }),
  [ShortlistFilterType.NULL]: NullShortlistFilterItem,
};

type ShortlistFiltersNegativesProps = {
  readonly filters: ShortlistFilterProjection[];
};
const ShortlistFiltersNegatives: FC<ShortlistFiltersNegativesProps> = ({ filters }) => (
  <section className="shortlist-filters-negatives" data-testid="shortlist-filters-negatives">
    <ShortlistFilterItemProvider shortlistFilterItems={SHORTLIST_FILTER_ITEMS}>
      <div className="shortlist-filters-negatives__content">
        <ShortlistFilters filters={filters} />
      </div>
    </ShortlistFilterItemProvider>
  </section>
);

const MemoizedShortlistFiltersNegatives = memo(ShortlistFiltersNegatives);

export { MemoizedShortlistFiltersNegatives as ShortlistFiltersNegatives };
