import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { viewForecastByCustomerId } from "../../../../projection/forecast/viewForecastByCustomerId";
import { ForecastProjection } from "../../../../projection/forecast/forecast";

interface UseViewForecastByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseViewForecastByCustomerIdFunction {
  (args: UseViewForecastByCustomerIdFunctionArgs): UseQueryFunctionResult<ForecastProjection>;
}

const useViewForecastByCustomerId: UseViewForecastByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewForecastByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  });

export { useViewForecastByCustomerId };
