import BoxStatus from "../../../domain/box/model/BoxStatus";
import Country from "../../../domain/country/model/Country";
import Currency from "../../../domain/country/model/Currency";
import { Language } from "../../../domain/country/model/language";
import { BasicBoxDto, BoxDto, toBasicBoxProjection, toBoxProjection } from "./box";

const boxDto: BoxDto = {
  id: "eae62d37-dbcd-4cae-a437-72f0fcec6dc3",
  legacyId: 2362971,
  market: {
    id: "b7f8f0d1-694f-4845-9b99-13d31df99907",
    name: "spain",
    country: Country.ES,
    currency: Currency.EUR,
    locale: Language.ES,
  },
  customerId: "0e796116-84ff-4e5c-a2d7-9f2e77318154",
  status: BoxStatus.FINISHED,
  boxNumber: 2362971,
  closedAt: "2021-12-01",
  orderId: "5b729228-e743-47bb-85f0-e3d456b1c82c",
  plannedFor: "2021-12-01",
  prepareFor: "2021-11-29",
  psNumber: 0,
  frequentPsNumber: 40,
};

const box = toBoxProjection(boxDto);

const basicBoxesDto: BasicBoxDto[] = [
  {
    id: "eae62d37-dbcd-4cae-a437-72f0fcec6dc3",
    boxNumber: 2362971,
    plannedFor: "2023-12-01",
    status: BoxStatus.CANCELLED,
  },
  {
    id: "605c5544-3d5f-4f00-a54f-c7c830577fd2",
    boxNumber: 2362972,
    plannedFor: "2023-12-31",
    status: BoxStatus.FINISHED,
  },
  {
    id: "be988288-dc00-454d-8f2c-e8022165e7fc",
    boxNumber: 2362973,
    plannedFor: "2024-01-01",
    status: BoxStatus.FINISHED,
  },
  {
    id: "1952ed75-d6d2-498c-bdb4-184c5b594a9d",
    boxNumber: 2362974,
    plannedFor: "2024-01-10",
    status: BoxStatus.READY,
  },
];

const basicBoxes = basicBoxesDto.map(toBasicBoxProjection);

export { box, boxDto, basicBoxesDto, basicBoxes };
