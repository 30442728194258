import { v4 as uuid } from "uuid";
import AsyncActionState, { asyncActionStateForCommandStatus } from "../../../shared/ui/uiKit/_common/AsyncActionState";
import { useSearchFavouritesByPsId } from "../../projection/favourite/react/useSearchFavouritesByPsId";
import { useMarkProductAsFavourite } from "../../domain/favourite/react/useMarkProductAsFavourite";
import { useUnmarkProductAsFavourite } from "../../domain/favourite/react/useUnmarkProductAsFavourite";
import { ShortlistProductProjection } from "../../../projection/shortlist/shortlistProduct";
import { useViewPersonalShopper } from "../../projection/personalShopper/react/useViewPersonalShopper";
import { useCallback } from "react";
import { useTrackMarkUnmarkFavourite } from "../../tracking/useTrackMarkUnmarkFavourite";
import useBoxSharedContext from "./useBoxSharedContext";
import { Section } from "../../tracking/Tracker";
import { SelectionProductProjection } from "../../../projection/selection/selection";

interface UseFavouritesArgs {
  readonly product: ShortlistProductProjection | SelectionProductProjection;
  readonly section: Section;
  readonly position?: number;
}

interface MarkUnmarkProductAsFavouriteFunction {
  (): void;
}

interface UseFavouritesReturn {
  readonly isFavourite: boolean;
  readonly markProductAsFavouriteState: AsyncActionState;
  readonly markProductAsFavourite: MarkUnmarkProductAsFavouriteFunction;
  readonly unmarkProductAsFavouriteState: AsyncActionState;
  readonly unmarkProductAsFavourite: MarkUnmarkProductAsFavouriteFunction;
}

interface UseFavouritesFunction {
  (args: UseFavouritesArgs): UseFavouritesReturn;
}

const useFavourites: UseFavouritesFunction = ({ product, section, position }) => {
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();
  const [favourites] = useSearchFavouritesByPsId({ psId: personalShopper?.id });

  const [markProductAsFavourite, markProductAsFavouriteStatus] = useMarkProductAsFavourite({
    favouriteId: favourites?.[product.id]?.id || uuid(),
    psId: personalShopper?.id,
    productId: product.id,
  });

  const [unmarkProductAsFavourite, unmarkProductAsFavouriteStatus] = useUnmarkProductAsFavourite({
    favouriteId: favourites?.[product.id]?.id,
  });

  const trackMarkUnmarkFavourite = useTrackMarkUnmarkFavourite({
    boxId: box.id,
    boxNumber: String(box.boxNumber),
    section,
    userId: box.customerId,
    psId: personalShopper?.id,
  });
  const markProductAsFavouriteAndTrack: MarkUnmarkProductAsFavouriteFunction = useCallback(async () => {
    await markProductAsFavourite();
    trackMarkUnmarkFavourite({ productId: product.id, position, selected: true });
  }, [markProductAsFavourite, position, product.id, trackMarkUnmarkFavourite]);
  const unmarkProductAsFavouriteAnTrack: MarkUnmarkProductAsFavouriteFunction = useCallback(async () => {
    await unmarkProductAsFavourite();
    trackMarkUnmarkFavourite({ productId: product.id, position, selected: false });
  }, [position, product.id, trackMarkUnmarkFavourite, unmarkProductAsFavourite]);

  return {
    isFavourite: Boolean(favourites?.[product.id]?.isEnabled),
    markProductAsFavouriteState: asyncActionStateForCommandStatus[markProductAsFavouriteStatus],
    markProductAsFavourite: markProductAsFavouriteAndTrack,
    unmarkProductAsFavouriteState: asyncActionStateForCommandStatus[unmarkProductAsFavouriteStatus],
    unmarkProductAsFavourite: unmarkProductAsFavouriteAnTrack,
  };
};

export default useFavourites;
