import { FC, ReactNode, useCallback, useMemo } from "react";
import { useFirstMountState } from "react-use";
import { useI18nMessage } from "@lookiero/i18n-react";
import classNames from "classnames";
import useCaptureAndStopEvent from "../../../hooks/useCaptureAndStopEvent";
import Icon, { IconVariant } from "../icon/Icon";
import Tooltip from "../tooltip/Tooltip";
import Color from "../../../../../../projection/color/model/Color";
import "./color-option.css";

type ColorOptionTooltipProps = {
  readonly color: Color;
  readonly children: ReactNode;
};
const ColorOptionTooltip: FC<ColorOptionTooltipProps> = ({ color, children }: ColorOptionTooltipProps) => {
  const colorName = useI18nMessage({ id: color.translationKey });
  const tooltipTitle = color.code ? `${colorName} - ${color.code}` : colorName;

  return <Tooltip title={tooltipTitle}>{children}</Tooltip>;
};

enum ColorOptionState {
  DEFAULT = "default",
  SELECTED = "selected",
  VISUALLY_DISABLED = "visually-disabled",
}

type ColorOptionProps = {
  readonly className?: string;
  readonly color: Color;
  readonly state?: string;
  readonly onChange?: (selected: boolean) => void;
  readonly showTooltipOnFirstMount?: boolean;
};
const ColorOption: FC<ColorOptionProps> = ({
  className,
  color,
  state = ColorOptionState.DEFAULT,
  onChange,
  showTooltipOnFirstMount = true,
}: ColorOptionProps) => {
  const onChangeCallback = useCallback(
    () => onChange && onChange(state !== ColorOptionState.SELECTED),
    [onChange, state],
  );
  const onClick = useCaptureAndStopEvent(onChangeCallback);
  const colorStyle = useMemo(() => ({ backgroundColor: `#${color.hexadecimal}` }), [color.hexadecimal]);

  const isFirstMount = useFirstMountState();
  const content = (
    <div aria-label={color.code} onClick={onClick}>
      <div className="color-option__color" style={colorStyle} />
      <Icon variant={IconVariant.CLOSE} />
    </div>
  );

  return (
    <div
      aria-selected={state === ColorOptionState.SELECTED}
      className={classNames("color-option", `color-option--${state}`, className)}
      role="option"
    >
      {isFirstMount && !showTooltipOnFirstMount ? (
        content
      ) : (
        <ColorOptionTooltip color={color}>{content}</ColorOptionTooltip>
      )}
    </div>
  );
};

export { ColorOptionState };

export default ColorOption;
