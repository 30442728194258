import { FilteringProjection } from "../../../../../../../projection/filtering/filtering";
import { ShortlistFilterProjection } from "../../../../../../../projection/shortlistFilter/shortlistFilter";
import { StateFilteringValue } from "../../../../../components/organisms/shortlistFilters/ShortlistFilterState";
import { hasFilteringChanged } from "./hasFilteringChanged";

interface FindFilterByIdFunctionArgs {
  readonly filters: ShortlistFilterProjection[];
  readonly id: string;
}

interface FindFilterByIdFunction {
  (args: FindFilterByIdFunctionArgs): ShortlistFilterProjection | undefined;
}

const findFilterById: FindFilterByIdFunction = ({ filters, id }) => {
  let filter = filters.find((el) => el.id === id);

  if (filter) {
    return filter;
  } else {
    for (let i = 0; i < filters.length; i += 1) {
      filter = findFilterById({ filters: filters[i].children || [], id });

      if (filter) {
        return filter;
      }
    }

    return undefined;
  }
};

interface AllFiltersAreEditableFunctionArgs {
  readonly positives: StateFilteringValue[];
  readonly negatives: StateFilteringValue[];
  readonly filters: ShortlistFilterProjection[];
}

interface AllFiltersAreEditableFunction {
  (args: AllFiltersAreEditableFunctionArgs): boolean;
}

const allFiltersAreEditable: AllFiltersAreEditableFunction = ({ positives, negatives, filters }) =>
  positives.every(({ id }) => findFilterById({ filters, id })?.metadata?.positiveEditable) &&
  negatives.every(({ id }) => findFilterById({ filters, id })?.metadata?.negativeEditable);

interface IsDifferentFromPreviousFilteringFunctionArgs {
  readonly positives: StateFilteringValue[];
  readonly negatives: StateFilteringValue[];
  readonly filtering: FilteringProjection;
}

interface IsDifferentFromPreviousFilteringFunction {
  (args: IsDifferentFromPreviousFilteringFunctionArgs): boolean;
}

const isDifferentFromPreviousFiltering: IsDifferentFromPreviousFilteringFunction = ({
  positives,
  negatives,
  filtering,
}) =>
  hasFilteringChanged({ filtering: positives, previousFiltering: filtering.positives }) ||
  hasFilteringChanged({ filtering: negatives, previousFiltering: filtering.negatives });

interface UseCanSaveFilteringsFunctionArgs {
  readonly positives: StateFilteringValue[];
  readonly negatives: StateFilteringValue[];
  readonly filtering: FilteringProjection;
  readonly filters: ShortlistFilterProjection[];
}

interface UseCanSaveFilteringsFunction {
  (args: UseCanSaveFilteringsFunctionArgs): boolean;
}

const useCanSaveFilterings: UseCanSaveFilteringsFunction = ({ positives, negatives, filtering, filters }) =>
  (positives.length > 0 || negatives.length > 0) &&
  isDifferentFromPreviousFiltering({ positives, negatives, filtering }) &&
  allFiltersAreEditable({ positives, negatives, filters });
export { useCanSaveFilterings };
