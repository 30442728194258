import {
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";
import { Editor as Wysiwyg, EditorState, RichUtils, DraftHandleValue, Modifier } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import classNames from "classnames";
import invariant from "ts-invariant";
import useSyncLocalValue from "../../../hooks/useSyncLocalValue";
import Error from "../../../../../shared/ui/uiKit/components/atoms/error/Error";
import { useEditorSharedContext } from "../../../hooks/useEditorSharedContext";
import "./editor.css";
import "draft-js/dist/Draft.css";

type EditorProps = {
  readonly placeholder?: string;
  readonly value?: string;
  readonly onChanged?: (value: string) => void;
  readonly onChangedDebounce?: number;
  readonly disabled?: boolean;
  readonly toolbar?: (state: EditorState, onChanged: (state: EditorState) => void) => ReactNode;
  readonly filter?: (state: EditorState) => EditorState;
  readonly error?: string;
};

export type EditorHandle = {
  readonly inject: (newContent: string) => void;
  readonly replace: (newContent: string) => void;
};

const Editor: ForwardRefRenderFunction<EditorHandle, EditorProps> = (
  {
    placeholder,
    value = "",
    onChanged = () => void 0,
    onChangedDebounce = 1000,
    disabled = false,
    toolbar,
    filter = (state: EditorState) => state,
    error,
  },
  ref,
) => {
  const { editorStateRef } = useEditorSharedContext();

  const initialFocusTrackedRef = useRef(false);
  const editorRef = useRef<Wysiwyg>(null);
  const focusEditor = useCallback(() => {
    editorRef.current?.focus();

    if (!editorStateRef.current || initialFocusTrackedRef.current || disabled) {
      return;
    }

    onChanged(convertToHTML(editorStateRef.current.getCurrentContent()));
    initialFocusTrackedRef.current = true;
  }, [disabled, editorStateRef, onChanged]);

  const valueRef = useRef(value);
  valueRef.current = value;
  const debouncedOnChange = useCallback(
    (editorState: EditorState) => {
      const htmlValue = convertToHTML(editorState.getCurrentContent());

      if (valueRef.current === htmlValue) {
        return;
      }

      onChanged(htmlValue);
    },
    [onChanged],
  );

  const [state, setState] = useSyncLocalValue<EditorState>(
    onChangedDebounce,
    editorStateRef.current || EditorState.createWithContent(convertFromHTML(value)),
    debouncedOnChange,
  );
  editorStateRef.current = state;

  const inject = useCallback(
    (newContent: string) => {
      invariant(editorStateRef.current, "Can't call to editor inject imperative handle");

      const contentState = editorStateRef.current.getCurrentContent();
      editorStateRef.current.mustForceSelection();
      const selectionState = editorStateRef.current.getSelection();

      const newContentState = convertFromHTML(newContent);
      const blockMap = newContentState.getBlockMap();

      const replacedContentState = Modifier.replaceWithFragment(contentState, selectionState, blockMap);

      setState(EditorState.push(editorStateRef.current, replacedContentState, "insert-fragment"));
    },
    [editorStateRef, setState],
  );

  const replace = useCallback(
    (newContent: string) => {
      invariant(editorStateRef.current, "Can't call to editor replace imperative handle");

      setState(EditorState.createWithContent(convertFromHTML(newContent)));
    },
    [editorStateRef, setState],
  );

  useImperativeHandle(ref, () => ({
    inject,
    replace,
  }));

  const handleChange = useCallback((nextState: EditorState) => setState(filter(nextState)), [filter, setState]);
  const handleKeyCommand = useCallback(
    (command: string, editorState: EditorState): DraftHandleValue => {
      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState) {
        handleChange(newState);

        return "handled";
      }

      return "not-handled";
    },
    [handleChange],
  );
  const handleOnKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => event.stopPropagation(), []);

  return (
    <section className={classNames("editor", { "editor--error": Boolean(error) })}>
      <div className="editor__editor">
        {toolbar && (
          <header aria-label="editor-toolbar" className="editor__toolbar" role="toolbar">
            {toolbar(state, handleChange)}
          </header>
        )}
        <div className="editor__content" onClick={focusEditor} onKeyDown={handleOnKeyDown}>
          <Wysiwyg
            ref={editorRef}
            editorState={state}
            handleKeyCommand={handleKeyCommand}
            placeholder={placeholder}
            readOnly={disabled}
            webDriverTestID="editor"
            spellCheck
            onChange={handleChange}
          />
        </div>
      </div>
      <Error error={error} />
    </section>
  );
};

export default forwardRef(Editor);
