import React, { FC, useCallback, useEffect } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { NotificationLevel } from "../../../../domain/notification/model/notification";
import { ToastNotificationProjection } from "../../../../projection/notification/notification";
import {
  Notification,
  NotificationVariant,
} from "../../../../../ui/uiKit/components/molecules/notification/Notification";

const toastVariantForNotificationLevel: Record<NotificationLevel, NotificationVariant> = {
  [NotificationLevel.INFO]: NotificationVariant.ERROR,
  [NotificationLevel.ERROR]: NotificationVariant.DEFAULT,
  [NotificationLevel.SUCCESS]: NotificationVariant.SUCCESS,
};

interface ToastNotificationItemProps {
  readonly className?: string;
  readonly notification: ToastNotificationProjection;
  readonly onRemove: (notificationId: string) => void;
  readonly autoHideTimeout?: number;
  readonly testID?: string;
}

const ToastNotificationItem: FC<ToastNotificationItemProps> = ({
  className,
  notification,
  onRemove,
  autoHideTimeout,
  testID,
}) => {
  const body = useI18nMessage({ id: notification.bodyI18nKey });
  const handleOnClose = useCallback(() => onRemove(notification.id), [notification.id, onRemove]);

  useEffect(() => {
    if (autoHideTimeout === undefined) {
      return;
    }

    const now = Date.now();
    const timeout = setTimeout(
      () => onRemove(notification.id),
      Math.max(0, (notification.timestamp || 0) + autoHideTimeout - now),
    );

    return (): void => clearTimeout(timeout);
  }, [autoHideTimeout, notification.id, notification.timestamp, onRemove]);

  return (
    <Notification
      className={className}
      testID={testID}
      variant={toastVariantForNotificationLevel[notification.level]}
      onCancel={handleOnClose}
    >
      {body}
    </Notification>
  );
};

export { ToastNotificationItem };
