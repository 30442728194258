import { useCallback } from "react";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { reviewCheckout } from "../../../../domain/checkout/command/reviewCheckout";

interface ReviewCheckoutFunctionArgs {
  readonly checkoutId: string;
}

interface ReviewCheckoutFunction {
  (args: ReviewCheckoutFunctionArgs): Promise<void>;
}

type UseReviewCheckoutReturn = [review: ReviewCheckoutFunction, status: CommandStatus];

interface UseReviewCheckoutFunctionArgs {
  readonly personalShopperId: string;
}

interface UseReviewCheckoutFunction {
  (args: UseReviewCheckoutFunctionArgs): UseReviewCheckoutReturn;
}

const useReviewCheckout: UseReviewCheckoutFunction = ({ personalShopperId }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const review: ReviewCheckoutFunction = useCallback(
    async ({ checkoutId }) => await commandBus(reviewCheckout({ aggregateId: checkoutId, personalShopperId })),
    [commandBus, personalShopperId],
  );

  return [review, status];
};

export { useReviewCheckout };
