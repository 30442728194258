import { FC } from "react";
import Skeleton from "../../../../../shared/ui/uiKit/components/atoms/Skeleton/Skeleton";
import "./product-detail-data.css";

const ProductDetailDataSkeleton: FC = () => (
  <section className="product-detail-data product-detail-data--skeleton">
    <header className="product-detail-data__header">
      <Skeleton className="product-detail-data__brand" />
      <Skeleton className="product-detail-data__price" />
    </header>
    <div className="product-detail-data__info">
      <Skeleton />
    </div>
  </section>
);

export { ProductDetailDataSkeleton };
