import { FC } from "react";
import { Category } from "../../../../../../../../projection/customer/category";
import BoxStatus from "../../../../../../../../domain/box/model/BoxStatus";
import { Tag, TagVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/tag/Tag";
import { BOX_I18N_PREFIX, BoxI18n } from "../../../../../../i18n/BoxI18n";
import { I18nMessage } from "@lookiero/i18n-react";

interface TagVariantAndI18nKeyFunctionArgs {
  readonly category: Category;
  readonly boxStatus: BoxStatus;
  readonly askedForPsChange: boolean;
}

interface TagVariantAndI18nKeyFunction {
  (args: TagVariantAndI18nKeyFunctionArgs):
    | {
        readonly tagVariant: TagVariant;
        readonly i18nKey: string;
      }
    | undefined;
}

const tagVariantAndI18nKey: TagVariantAndI18nKeyFunction = ({ category, boxStatus, askedForPsChange }) => {
  if (boxStatus === BoxStatus.BLOCKED) {
    return {
      tagVariant: TagVariant.ORANGE,
      i18nKey: BoxI18n.TAG_FRAUD,
    };
  }

  if (askedForPsChange) {
    return {
      tagVariant: TagVariant.RED,
      i18nKey: BoxI18n.TAG_PS_CHANGE,
    };
  }

  switch (category) {
    case Category.INFLUENCER:
      return {
        tagVariant: TagVariant.GREEN,
        i18nKey: BoxI18n.TAG_INFLUENCER,
      };
    case Category.VIP:
      return {
        tagVariant: TagVariant.PURPLE,
        i18nKey: BoxI18n.TAG_VIP,
      };
    case Category.FACEBOOK:
      return {
        tagVariant: TagVariant.BLUE,
        i18nKey: BoxI18n.TAG_FACEBOOK,
      };
  }
};

interface CustomerTagProps {
  readonly category: Category;
  readonly boxStatus: BoxStatus;
  readonly askedForPsChange: boolean;
}

const CustomerTag: FC<CustomerTagProps> = ({ category, boxStatus, askedForPsChange }) => {
  const { tagVariant, i18nKey } = tagVariantAndI18nKey({ category, boxStatus, askedForPsChange }) || {};

  return i18nKey ? (
    <Tag variant={tagVariant}>
      <I18nMessage id={i18nKey} prefix={BOX_I18N_PREFIX} />
    </Tag>
  ) : null;
};

export { CustomerTag };
