import { FC, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import RootLoader from "../../../../components/atoms/rootLoader/RootLoader";
import useBoxSharedContext from "../../../../hooks/useBoxSharedContext";
import { Comment } from "../comment/Comment";
import { Characteristics } from "../characteristics/Characteristics";
import { Styles } from "../styles/Styles";
import { Preferences } from "../preferences/Preferences";
import {
  StyleProfileCharacteristics,
  StyleProfilePreferences,
  StyleProfileStyles,
} from "../../../../../../projection/styleProfile/styleProfile";
import { StyleProfileCard } from "../styleProfileCard/StyleProfileCard";
import Pill from "../../../../../../shared/ui/uiKit/components/molecules/pill/Pill";
import { I18nMessage } from "@lookiero/i18n-react";
import { SocialNetworksQuestionItem } from "../styleProfileQuestion/components/socialNetworksQuestionItem/SocialNetworksQuestionItem";
import Button from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { LookAndLikeModal } from "../../components/lookAndLike/LookAndLikeModal";
import { QueryStatus } from "@lookiero/messaging-react";
import { useViewAnnotationByCustomerId } from "../../../../../projection/annotation/react/useViewAnnotationByCustomerId";
import { useUpdateAnnotation } from "../../../../../domain/annotation/react/useUpdateAnnotation";
import { AnnotationsModal } from "../annotations/annotationsModal/AnnotationsModal";
import { Annotations } from "../annotations/Annotations";
import { useViewPersonalShopper } from "../../../../../projection/personalShopper/react/useViewPersonalShopper";
import { TrackingClick, TrackingPage } from "../../../../../tracking/Tracker";
import { useTrackAnnotationsPageView } from "../../../../../tracking/useTrackAnnotationsPageView";
import { useTrackClick } from "../../../../../tracking/useTrackClick";
import { useViewStyleProfileByBoxId } from "../../../../../projection/styleProfile/react/useViewStyleProfileByBoxId";
import { useViewCustomerInsightsByCustomerId } from "../../../../../projection/customerInsight/react/useViewCustomerInsightsByCustomerId";
import { useIsCustomerInsightsEnabled } from "../../../../featureToggle/useIsCustomerInsightsEnabled";
import "./customer-pinned.css";

interface HandleOnUpdateAnnotationsFunctionArgs {
  readonly positive: string;
  readonly negative: string;
}
interface HandleOnUpdateAnnotationsFunction {
  (args: HandleOnUpdateAnnotationsFunctionArgs): void;
}

const PINNED_CHARACTERISTICS: (keyof StyleProfileCharacteristics)[] = [
  "height",
  "weight",
  "topSize",
  "bottomSize",
  "footwearSize",
  "topFit",
  "bottomFit",
  "braSize",
  "cupSize",
];
const PINNED_STYLES: (keyof StyleProfileStyles)[] = [
  "leisure",
  "work",
  "adventurous",
  "focusLeisure",
  "focusWork",
  "focusNight",
  "styles",
];
const PINNED_PREFERENCES: (keyof StyleProfilePreferences)[] = [
  "price",
  "avoid",
  "cover",
  "highlight",
  "colors",
  "patterns",
];

interface FilterPinnedFunctionArgs<
  K extends StyleProfileCharacteristics | StyleProfileStyles | StyleProfilePreferences,
> {
  readonly data: K;
  readonly keys: (keyof K)[];
}

interface FilterPinnedFunction {
  <K extends StyleProfileCharacteristics | StyleProfileStyles | StyleProfilePreferences>(
    args: FilterPinnedFunctionArgs<K>,
  ): { [X in keyof K]: K[X] };
}

const filterPinned: FilterPinnedFunction = <
  K extends StyleProfileCharacteristics | StyleProfileStyles | StyleProfilePreferences,
>({
  data,
  keys,
}: FilterPinnedFunctionArgs<K>) =>
  (Object.keys(data) as (keyof K)[])
    .filter((key) => keys.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {} as K);

interface CustomerPinnedProps {
  readonly onClickLookAndLike: () => void;
}
const CustomerPinned: FC<CustomerPinnedProps> = ({ onClickLookAndLike }) => {
  const { locale } = useParams();
  const { box } = useBoxSharedContext();
  const [personalShopper] = useViewPersonalShopper();

  const isCustomerInsightEnabled = useIsCustomerInsightsEnabled();
  const [customerInsights] = useViewCustomerInsightsByCustomerId({
    customerId: box.customerId,
    enabled: isCustomerInsightEnabled,
  });
  const showCustomerInsights = isCustomerInsightEnabled && Boolean(customerInsights?.hasLookAndLikeFeedback);

  const [styleProfile, styleProfileStatus] = useViewStyleProfileByBoxId({ boxId: box.id });

  const [annotation] = useViewAnnotationByCustomerId({ customerId: box.customerId });
  const [updateAnnotation, updateAnnotationStatus] = useUpdateAnnotation({
    id: annotation?.id,
    customerId: box.customerId,
  });

  const trackClick = useTrackClick({
    boxId: box.id,
    userId: box.customerId,
    psId: personalShopper?.id,
    section: TrackingPage.CUSTOMER,
  });
  const trackAnnotationsPageView = useTrackAnnotationsPageView({
    boxId: box.id,
    userId: box.customerId,
    psId: personalShopper?.id,
    section: TrackingPage.CUSTOMER,
  });
  const [annotationsModalVisible, setAnnotationsModalVisible] = useState(false);
  const handleOnClickAddAnnotations = useCallback(() => {
    setAnnotationsModalVisible(true);
    trackAnnotationsPageView();
  }, [trackAnnotationsPageView]);
  const handleOnCancelAnnotationsModal = useCallback(() => setAnnotationsModalVisible(false), []);
  const handleOnUpdateAnnotations: HandleOnUpdateAnnotationsFunction = useCallback(
    async ({ negative, positive }) => {
      await updateAnnotation({ negative, positive });
      trackClick({ clickId: TrackingClick.UPDATE_ANNOTATIONS });
      handleOnCancelAnnotationsModal();
    },
    [handleOnCancelAnnotationsModal, trackClick, updateAnnotation],
  );

  const [familyIds, setFamilyIds] = useState<string[] | undefined>();
  const [lookAndLikeModalVisible, setLookAndLikeModalVisible] = useState(false);
  const handleOnClickLookAndLike = useCallback(() => {
    setLookAndLikeModalVisible(true);
    onClickLookAndLike();
  }, [onClickLookAndLike]);
  const handleOnCancelLookAndLikeModal = useCallback(() => {
    setLookAndLikeModalVisible(false);
    setFamilyIds(undefined);
  }, []);
  const handleOnClickCustomerInsight = useCallback(
    (selectedFamilyIds: string[]) => {
      setFamilyIds(selectedFamilyIds);
      handleOnClickLookAndLike();
    },
    [handleOnClickLookAndLike],
  );

  const characteristics = useMemo(
    () =>
      styleProfile?.characteristics
        ? filterPinned({ data: styleProfile.characteristics, keys: PINNED_CHARACTERISTICS })
        : undefined,
    [styleProfile?.characteristics],
  );
  const styles = useMemo(
    () => (styleProfile?.styles ? filterPinned({ data: styleProfile.styles, keys: PINNED_STYLES }) : undefined),
    [styleProfile?.styles],
  );
  const preferences = useMemo(
    () =>
      styleProfile?.preferences
        ? filterPinned({ data: styleProfile.preferences, keys: PINNED_PREFERENCES })
        : undefined,
    [styleProfile?.preferences],
  );

  const dependenciesLoadedStatuses = [QueryStatus.ERROR, QueryStatus.SUCCESS];
  const dependenciesLoaded =
    dependenciesLoadedStatuses.includes(styleProfileStatus) && styleProfile && characteristics && styles && preferences;

  if (!dependenciesLoaded) {
    return <RootLoader />;
  }

  return (
    <section className="customer-pinned">
      <main className="customer-pinned__main">
        <LookAndLikeModal
          boxId={box.id}
          customerId={box.customerId}
          familyIds={familyIds}
          origin={TrackingPage.CUSTOMER_PINNED}
          visible={lookAndLikeModalVisible}
          onCancel={handleOnCancelLookAndLikeModal}
        />

        <AnnotationsModal
          locale={locale as string}
          negative={annotation?.negative}
          positive={annotation?.positive}
          updateStatus={updateAnnotationStatus}
          updatedOn={annotation?.updatedOn}
          visible={annotationsModalVisible}
          onCancel={handleOnCancelAnnotationsModal}
          onUpdate={handleOnUpdateAnnotations}
        />

        <StyleProfileCard className="customer-pinned__customer-card" label="customer-card">
          <div className="customer-pinned__banner">
            <Pill>
              <I18nMessage id={styleProfile.customer.segment.translationKey} />
            </Pill>

            <div className="customer-pinned__banner-icons">
              <SocialNetworksQuestionItem socialNetworks={styleProfile.customer.socialNetworks} />

              {!isCustomerInsightEnabled && (
                <Button
                  aria-label="look-and-like"
                  className="customer-pinned__look-and-like"
                  icon={IconVariant.NEWSFEED}
                  type="button"
                  onClick={handleOnClickLookAndLike}
                />
              )}
            </div>
          </div>
        </StyleProfileCard>

        <Comment
          className="customer-pinned__comment"
          comment={styleProfile.comment}
          locale={locale as string}
          showDate={false}
        />

        <Annotations
          negative={annotation?.negative}
          positive={annotation?.positive}
          onAdd={handleOnClickAddAnnotations}
        />

        <Characteristics characteristics={characteristics} />

        <Styles
          boxId={box.id}
          customerId={box.customerId}
          customerInsights={customerInsights}
          origin={TrackingPage.CUSTOMER_PINNED}
          showCustomerInsights={showCustomerInsights}
          showImages={false}
          styles={styles}
          showStylesDefinedText
          onClickCustomerInsight={handleOnClickCustomerInsight}
          onClickLookAndLike={handleOnClickLookAndLike}
        />

        <Preferences preferences={preferences} pinned />
      </main>
    </section>
  );
};

export { CustomerPinned };
