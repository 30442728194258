import { useCallback } from "react";
import invariant from "ts-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { Logger } from "../../../logging/Logger";
import { setLooks } from "../../../../domain/look/command/setLooks";
import { LookDto } from "../../../persistence/lookDto";

interface SetLooksFunctionArgs {
  readonly looks: LookDto[];
}

interface SetLooksFunction {
  (args: SetLooksFunctionArgs): Promise<void>;
}

interface UseSetLooksFunctionArgs {
  readonly selectionId: string | undefined;
  readonly logger: Logger;
}

type UseSetLooksFunctionResult = [setLooks: SetLooksFunction, status: CommandStatus];

interface UseSetLooksFunction {
  (args: UseSetLooksFunctionArgs): UseSetLooksFunctionResult;
}

const useSetLooks: UseSetLooksFunction = ({ selectionId, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const set: SetLooksFunction = useCallback(
    async ({ looks }) => {
      invariant(selectionId, "Cannot set the Looks without a selectionId");

      try {
        await commandBus(setLooks({ aggregateId: selectionId, looks }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [selectionId, commandBus, logger],
  );

  return [set, status];
};

export { useSetLooks };
