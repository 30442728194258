import { FC, MouseEventHandler, ReactElement, ReactNode, useCallback, useState } from "react";
import classNames from "classnames";
import {
  isAnyLeafActive,
  deactivate,
  StateFilteringValue,
  isAnyLeafFilteringActive,
} from "../../../ShortlistFilterState";
import Button from "../../../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Collapsible from "../../../../../../../../shared/ui/uiKit/components/layouts/collapsible/Collapsible";
import { uniqBy } from "lodash";
import { ShortlistFilterProjection } from "../../../../../../../../projection/shortlistFilter/shortlistFilter";
import "./shortlist-filter-item-root.css";

type ShortlistFilterItemRootProps = {
  readonly className?: string;
  readonly filter: ShortlistFilterProjection;
  readonly item?: ReactNode;
  readonly children?: ReactElement;
  readonly level?: number;
};

type ShortlistFilterItemRootComponent = FC<ShortlistFilterItemRootProps>;

interface OnChangeFunctionArgs {
  readonly state: StateFilteringValue[];
}

interface OnChangeFunction {
  (args: OnChangeFunctionArgs): void;
}

interface ShortlistFilteringFunctionReturn {
  readonly state: StateFilteringValue[];
  readonly filtering?: { readonly id: string }[];
  readonly onChange: OnChangeFunction;
}

interface UseShortlistFilteringFunction {
  (): ShortlistFilteringFunctionReturn;
}

interface ShortlistFilterItemRootHOCArgs {
  readonly testId?: string;
  readonly useShortlistFiltering: UseShortlistFilteringFunction;
}

interface ShortlistFilterItemRootHOC {
  (args: ShortlistFilterItemRootHOCArgs): ShortlistFilterItemRootComponent;
}

const ShortlistFilterItemRoot: ShortlistFilterItemRootHOC = ({ useShortlistFiltering, testId }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function ShortlistFilterItemRootComponent({ className, level = 0, filter, item, children }) {
    const { state, filtering = [], onChange } = useShortlistFiltering();
    const canRemoveSelection =
      level === 0 && isAnyLeafActive({ filter, state }) && !isAnyLeafFilteringActive({ filter, state, filtering });
    const canCollapse = Boolean(filter.metadata && filter.metadata?.collapsed !== null && children);

    const handleRemoveSelection: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        event.stopPropagation();

        const deactivatedState = deactivate({ filter, state });

        const newState = uniqBy([...deactivatedState, ...filtering], "id");

        onChange({ state: newState });
      },
      [filter, filtering, onChange, state],
    );
    const [collapsed, setCollapsed] = useState(canCollapse && filter.metadata?.collapsed);
    const handleOnCollapsibleToggled: MouseEventHandler<HTMLDivElement | HTMLButtonElement> = useCallback((event) => {
      event.stopPropagation();
      setCollapsed((collapsed) => !collapsed);
    }, []);

    return (
      <div
        data-testid={testId}
        className={classNames(
          "shortlist-filter-item-root",
          { "shortlist-filter-item-root--collapsed": collapsed },
          className,
        )}
      >
        {item && (
          <div
            className={classNames("shortlist-filter-item-root__header", {
              "shortlist-filter-item-root__header--collapsible": canCollapse,
            })}
            onClick={canCollapse ? handleOnCollapsibleToggled : undefined}
          >
            {item}
            {(canRemoveSelection || canCollapse) && (
              <div>
                {canRemoveSelection && (
                  <Button
                    className="shortlist-filter-item-root__action shortlist-filter-item-root__action--remove"
                    icon={IconVariant.TRASH}
                    onClick={handleRemoveSelection}
                  />
                )}
                {canCollapse && (
                  <Button
                    className="shortlist-filter-item-root__action shortlist-filter-item-root__action--collapse"
                    icon={collapsed ? IconVariant.PLUS : IconVariant.MINUS}
                    onClick={handleOnCollapsibleToggled}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {children && (
          <div className="shortlist-filter-item-root__children">
            {canCollapse ? <Collapsible opened={!collapsed}>{children}</Collapsible> : children}
          </div>
        )}
      </div>
    );
  };

export default ShortlistFilterItemRoot;
