import { FC, useCallback, useRef } from "react";
import useBoxSharedContext from "../../../../../../hooks/useBoxSharedContext";
import { useSortSelectionOrder } from "../../../../../../../domain/selectionOrder/react/useSortSelectionOrder";
import SortableGrid from "../../../../../../../../shared/ui/uiKit/components/layouts/sortableGrid/SortableGrid";
import { SelectionAlertProjection } from "../../../../../../../../projection/alert/selectionAlert";
import { useViewSortedProductsByBoxNumber } from "../../../../../../../projection/selection/react/useViewSortedProductsByBoxNumber";
import { useLogger } from "../../../../../../../logging/useLogger";
import Locale from "../../../../../../../../domain/country/model/Locale";
import {
  SelectionProductProjection,
  SelectionProductVariantProjection,
} from "../../../../../../../../projection/selection/selection";
import { SelectionProductPreview } from "../selectionProductPreview/SelectionProductPreview";
import "./selection-products.css";

const ITEM_MIN_WIDTH = 185;

interface OnDeselectProductVariantFunctionArgs {
  readonly position: number;
  readonly productVariantId: string;
}
interface OnDeselectProductVariantFunction {
  (args: OnDeselectProductVariantFunctionArgs): void;
}

interface OnClickProductFunctionArgs {
  readonly position: number;
  readonly productId: string;
}
interface OnClickProductFunction {
  (args: OnClickProductFunctionArgs): void;
}

interface SelectionProductsProps {
  readonly locale: Locale;
  readonly alerts?: SelectionAlertProjection[];
  readonly onDeselectProductVariant?: OnDeselectProductVariantFunction;
  readonly onClickProduct?: OnClickProductFunction;
}

const SelectionProducts: FC<SelectionProductsProps> = ({
  locale,
  alerts,
  onDeselectProductVariant,
  onClickProduct,
}) => {
  const { box, selection } = useBoxSharedContext();

  const logger = useLogger();
  const [sortedProducts] = useViewSortedProductsByBoxNumber({ boxNumber: String(box.boxNumber) });
  const [sortSelectionOrder] = useSortSelectionOrder({
    boxNumber: String(box.boxNumber),
    selectionId: selection?.selectionId,
    logger,
  });

  const handleOnOrderChanged = useCallback(
    (selectionProducts: SelectionProductProjection[]) => {
      const sortedProductVariantIds = selectionProducts.map(
        (product) =>
          (
            product.productVariants.find(
              (productVariant) => productVariant.isSelected,
            ) as SelectionProductVariantProjection
          ).id,
      );

      sortSelectionOrder({ productVariantIds: sortedProductVariantIds });
    },
    [sortSelectionOrder],
  );

  const itemsPerRow = useCallback((gridWidth: number): number => Math.floor(gridWidth / ITEM_MIN_WIDTH) || 1, []);

  const selectionProductRenderer = useCallback(
    (product: SelectionProductProjection, index: number) => (
      <SelectionProductPreview
        key={product.id}
        alerts={alerts}
        locale={locale}
        position={index}
        product={product}
        onClickProduct={onClickProduct}
        onDeselectProductVariant={onDeselectProductVariant}
      />
    ),
    [alerts, locale, onClickProduct, onDeselectProductVariant],
  );

  const scrollTargetRef = useRef<HTMLElement>(null);

  return (
    <section ref={scrollTargetRef} aria-label="selection-products" className="selection-products" role="list">
      {sortedProducts && (
        <SortableGrid
          data={sortedProducts}
          itemsPerRow={itemsPerRow}
          scrollTarget={scrollTargetRef}
          onChanged={handleOnOrderChanged}
        >
          {selectionProductRenderer}
        </SortableGrid>
      )}
    </section>
  );
};

export { SelectionProducts };
