import { useCallback, useMemo } from "react";
import { useIntl } from "@lookiero/i18n-react";
import Pill from "../../../../../../../../shared/ui/uiKit/components/molecules/pill/Pill";
import { ShortlistFilterItem } from "../../ShortlistFilterItem";
import { deactivate, findBound } from "../../../ShortlistFilterState";
import "./range-pill-shortlist-filter-item.css";
import { useShortlistFilteringPositives } from "../../hooks/useShortlistFilteringPositives";
import { useShortlistFilteringNegatives } from "../../hooks/useShortlistFilteringNegatives";
import { ShortlistFilterType } from "../../../../../../../../projection/shortlistFilter/shortlistFilter";

const RangePillShortlistFilterItem: ShortlistFilterItem = ({ filter }) => {
  const { formatMessage } = useIntl();
  const { state: positiveState, onChange: positiveOnChange } = useShortlistFilteringPositives();
  const { state: negativeState } = useShortlistFilteringNegatives();
  const handleOnPositiveRemove = useCallback(
    () => positiveOnChange({ state: deactivate({ filter, state: positiveState }) }),
    [filter, positiveOnChange, positiveState],
  );

  const positiveFiltersState = useMemo(() => {
    const lowerBoundFilter = findBound({
      filters: filter.children || [],
      state: positiveState,
      boundType: ShortlistFilterType.RANGE_LOWER_BOUND,
    });
    const upperBoundFilter = findBound({
      filters: filter.children || [],
      state: positiveState,
      boundType: ShortlistFilterType.RANGE_UPPER_BOUND,
    });

    return [...(lowerBoundFilter ? [lowerBoundFilter] : []), ...(upperBoundFilter ? [upperBoundFilter] : [])];
  }, [filter.children, positiveState]);

  const negativeFiltersState = useMemo(() => {
    const lowerBoundFilter = findBound({
      filters: filter.children || [],
      state: negativeState,
      boundType: ShortlistFilterType.RANGE_LOWER_BOUND,
    });
    const upperBoundFilter = findBound({
      filters: filter.children || [],
      state: negativeState,
      boundType: ShortlistFilterType.RANGE_UPPER_BOUND,
    });

    return [...(lowerBoundFilter ? [lowerBoundFilter] : []), ...(upperBoundFilter ? [upperBoundFilter] : [])];
  }, [filter.children, negativeState]);

  return (
    <>
      {positiveFiltersState.length > 0 ? (
        <Pill className="range-pill-shortlist-filter-item--positive" onRemove={handleOnPositiveRemove}>
          {positiveFiltersState.map(({ translationKey }) => formatMessage({ id: translationKey })).join(" - ")}
        </Pill>
      ) : null}
      {negativeFiltersState.length > 0 ? (
        <Pill className="range-pill-shortlist-filter-item--negative">
          {negativeFiltersState.map(({ translationKey }) => formatMessage({ id: translationKey })).join(" - ")}
        </Pill>
      ) : null}
    </>
  );
};

export { RangePillShortlistFilterItem };
