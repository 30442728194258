import { I18nMessage, I18nDate } from "@lookiero/i18n-react";
import classNames from "classnames";
import { FC, useCallback, useState } from "react";
import { HistoryNoteProjection } from "../../../../../../projection/historyNote/historyNote";
import Icon, { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import Collapsible from "../../../../../../shared/ui/uiKit/components/layouts/collapsible/Collapsible";
import NoteI18n, { NOTE_I18N_PREFIX } from "../../../../i18n/NoteI18n";
import "./history-note.css";

interface HistoryNoteProps {
  readonly historyNote: HistoryNoteProjection;
}

const HistoryNote: FC<HistoryNoteProps> = ({ historyNote }) => {
  const [opened, setOpened] = useState(true);
  const toggleOpened = useCallback(() => setOpened((isOpened) => !isOpened), []);

  return (
    <article aria-label="history-note" className="history-note" role="listitem">
      <header
        className={classNames("history-note__header", { "history-note__header--opened": opened })}
        onClick={toggleOpened}
      >
        <div className="history-note__header-info">
          <Text className="history-note__header-box-number" variant={TextVariant.BODY_BOLD}>
            <I18nMessage id={NoteI18n.BOX} prefix={NOTE_I18N_PREFIX} /> {historyNote.boxNumber}
          </Text>
          <Text className="history-note__header-date" data-testid="history-note-planned-for">
            <I18nDate dateStyle="long" value={historyNote.boxPlannedFor} />
          </Text>
        </div>
        <Icon className="history-note__header-toggle" variant={IconVariant.CHEVRON_DOWN} />
      </header>
      <Collapsible opened={opened}>
        <div className="history-note__content">
          <Text>
            <span dangerouslySetInnerHTML={{ __html: historyNote.content }} data-testid="history-note-content"></span>
          </Text>
        </div>
      </Collapsible>
    </article>
  );
};

export default HistoryNote;
