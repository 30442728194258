import { FC, ReactNode, useCallback } from "react";
import { CheckoutProjection } from "../../../../../../projection/checkout/checkout";
import {
  Checkout,
  OnNavigateToBoxFunction,
  OnViewBoxPreviewFunction,
  OnViewProductVariantDetailFunction,
} from "./Checkout";
import VirtualizedList from "../../../../../../shared/ui/uiKit/components/layouts/virtualizedList/VirtualizedList";
import { CheckoutSkeleton } from "./CheckoutSkeleton";
import "./checkouts-list.css";

const estimatedItemHeight = () => 300;
const itemsPerRow = () => 1;

interface CheckoutsListProps {
  readonly personalShopperId: string;
  readonly checkouts: Record<number, CheckoutProjection[]>;
  readonly count: number;
  readonly page: number;
  readonly perPage: number;
  readonly scrollElement: Element | null;
  readonly onPageChanged: (page: number) => void;
  readonly onViewProductVariantDetail: OnViewProductVariantDetailFunction;
  readonly onViewBoxPreview: OnViewBoxPreviewFunction;
  readonly onNavigateToBox: OnNavigateToBoxFunction;
}

const CheckoutsList: FC<CheckoutsListProps> = ({
  personalShopperId,
  checkouts,
  count,
  page,
  perPage,
  scrollElement,
  onViewProductVariantDetail,
  onViewBoxPreview,
  onNavigateToBox,
  onPageChanged,
}) => {
  const checkoutItem = useCallback(
    (index: number, checkout: CheckoutProjection | null): ReactNode =>
      checkout ? (
        checkout.id ? (
          <Checkout
            key={checkout.id}
            checkout={checkout}
            personalShopperId={personalShopperId}
            onNavigateToBox={onNavigateToBox}
            onViewBoxPreview={onViewBoxPreview}
            onViewProductVariantDetail={onViewProductVariantDetail}
          />
        ) : (
          <CheckoutSkeleton key={index} />
        )
      ) : (
        // Empty placeholder to fill the space
        <div key={index} />
      ),
    [onViewBoxPreview, onViewProductVariantDetail, onNavigateToBox, personalShopperId],
  );

  return (
    <div aria-label="checkouts-list" className="checkouts-list" role="list">
      <VirtualizedList
        data={checkouts}
        estimatedItemHeight={estimatedItemHeight}
        itemCount={count}
        itemsPerRow={itemsPerRow}
        page={page}
        perPage={perPage}
        scrollElement={scrollElement}
        onPageChanged={onPageChanged}
      >
        {checkoutItem}
      </VirtualizedList>
    </div>
  );
};

export { CheckoutsList };
