import { useCallback, useMemo, useState } from "react";
import { MessageDescriptor } from "react-intl";
import { I18nMessage, useI18nMessage, useIntl } from "@lookiero/i18n-react";
import Text, { TextVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import Modal from "../../../../../../../../shared/ui/uiKit/components/layouts/modal/Modal";
import Dropdown from "../../../../../../../../shared/ui/uiKit/components/organisms/dropdown/Dropdown";
import FormInputOption from "../../../../../../../../shared/ui/uiKit/_common/FormInputOption";
import ShortlistFiltersI18n, { SHORTLIST_FILTERS_I18N_PREFIX } from "../../../../../../i18n/ShortlistFiltersI18n";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import useSearchShortlistFiltersRecursivelyForNamePartial from "../../hooks/useSearchShortlistFiltersRecursivelyForNamePartial";
import ShortlistFilterHeader from "../shortlistFilterHeader/ShortlistFilterHeader";
import ShortlistFilterItemRoot from "../shortlistFilterItemRoot/ShortlistFilterItemRoot";
import { activate } from "../../../ShortlistFilterState";
import { ShortlistFilterProjection } from "../../../../../../../../projection/shortlistFilter/shortlistFilter";
import "./search-host-shortlist-filter-item.css";

const mapShortlistFiltersToFormInputOptions = (
  filters: ShortlistFilterProjection[],
  formatMessage: (descriptor: MessageDescriptor) => string,
): FormInputOption[] =>
  filters.map((filter) => ({ label: formatMessage({ id: filter.translationKey }), value: filter.id }));

const SearchHostShortlistFilterItem: ShortlistFilterItemHOC = ({ useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function SearchHostShortlistFilterItem({ filter, children, level }) {
    const { state, onChange } = useShortlistFiltering();
    const [childrenModalOpened, setChildrenModalOpened] = useState(false);
    const handleOnChildrenModalCancelled = useCallback(() => setChildrenModalOpened(false), []);
    const handleOpenChildrenModal = useCallback(() => setChildrenModalOpened(true), []);
    const intl = useIntl();

    const [inputValue, setInputValue] = useState("");
    const matchingShortlistFilters = useSearchShortlistFiltersRecursivelyForNamePartial({
      name: inputValue,
      filter,
      formatMessage: intl.formatMessage,
    });
    const matchingShortlistFiltersOptions = useMemo(
      () => mapShortlistFiltersToFormInputOptions(matchingShortlistFilters, intl.formatMessage),
      [intl.formatMessage, matchingShortlistFilters],
    );

    const handleOnMatchingShortlistFilterOptionSelected = useCallback(
      (filterId: string | string[] | undefined | null) => {
        const selectedShortlistFilter = matchingShortlistFilters.find((filter) => filter.id === filterId);

        if (!selectedShortlistFilter) {
          return;
        }

        onChange({ state: activate({ filter: selectedShortlistFilter, state }) });
      },
      [matchingShortlistFilters, onChange, state],
    );

    const label = useI18nMessage({ id: ShortlistFiltersI18n.SEARCH, prefix: SHORTLIST_FILTERS_I18N_PREFIX });
    const noOptionsI18nMessage = useI18nMessage({
      id: ShortlistFiltersI18n.NO_OPTIONS,
      prefix: SHORTLIST_FILTERS_I18N_PREFIX,
    });
    const noOptionsMessage = useCallback(() => noOptionsI18nMessage, [noOptionsI18nMessage]);

    const ShortlistFilterItemRootComponent = useMemo(() => ShortlistFilterItemRoot({ useShortlistFiltering }), []);

    return (
      <ShortlistFilterItemRootComponent
        filter={filter}
        item={<ShortlistFilterHeader filter={filter} level={level} />}
        level={level}
      >
        <div className="search-host-shortlist-filter-item">
          <Dropdown
            inputValue={inputValue}
            label={label}
            noOptionsMessage={noOptionsMessage}
            options={matchingShortlistFiltersOptions}
            onChange={handleOnMatchingShortlistFilterOptionSelected}
            onInputChange={setInputValue}
          />
          <div className="search-host-shortlist-filter-item__modal-toggle" onClick={handleOpenChildrenModal}>
            <Text variant={TextVariant.LINK}>
              <I18nMessage id={ShortlistFiltersI18n.VIEW_ALL} prefix={SHORTLIST_FILTERS_I18N_PREFIX} />
            </Text>
          </div>

          <Modal
            className="search-host-shortlist-filter-item__modal"
            title={<I18nMessage id={filter.translationKey} />}
            visible={childrenModalOpened}
            onHide={handleOnChildrenModalCancelled}
          >
            <div className="search-host-shortlist-filter-item__modal-content">{children}</div>
          </Modal>
        </div>
      </ShortlistFilterItemRootComponent>
    );
  };

export default SearchHostShortlistFilterItem;
