import { WindowsMessengerChild } from "@lookiero/windows-messenger.js";
import isIframeEmbedded from "./isIframeEmbedded";
import ToggleFeatureEnablementEventEmitter from "./ToggleFeatureEnablementEventEmitter";

type CustomerChildEvents = {
  customerFeatureSupported: boolean;
};

class CustomerWindowsMessenger implements ToggleFeatureEnablementEventEmitter {
  private readonly windowsMessenger: WindowsMessengerChild<CustomerChildEvents, Record<never, never>>;

  public constructor(windowsMessenger: WindowsMessengerChild<CustomerChildEvents, Record<never, never>>) {
    this.windowsMessenger = windowsMessenger;
  }

  public async emit(isSupported: boolean): Promise<void> {
    if (!isIframeEmbedded()) {
      return;
    }

    await this.windowsMessenger.emit("customerFeatureSupported", isSupported);
  }
}

export { CustomerWindowsMessenger };
