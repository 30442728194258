import { FC, ReactNode } from "react";
import Icon, { IconVariant } from "../../../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import OptionSelector from "../../../../../../../../../../shared/ui/uiKit/components/molecules/optionSelector/OptionSelector";
import { LookTemplate } from "../../../../../../../../../../projection/looks/lookTemplate";
import "./look-template-selector.css";

const options: {
  readonly value: string;
  readonly children: ReactNode;
}[] = [
  {
    value: LookTemplate.TWO,
    children: <Icon variant={IconVariant.LOOK_TEMPLATE_TWO} />,
  },
  {
    value: LookTemplate.THREE,
    children: <Icon variant={IconVariant.LOOK_TEMPLATE_THREE} />,
  },
  {
    value: LookTemplate.FOUR,
    children: <Icon variant={IconVariant.LOOK_TEMPLATE_FOUR} />,
  },
];

type LookTemplateSelectorProps = {
  readonly value: LookTemplate;
  readonly onChanged: (lookTemplate: LookTemplate) => void;
};
const LookTemplateSelector: FC<LookTemplateSelectorProps> = ({ value, onChanged }) => (
  <section aria-label="look-template-selector" className="look-template-selector">
    <OptionSelector options={options} value={`${value}`} onChange={onChanged as (value: string) => void} />
  </section>
);

export default LookTemplateSelector;
