import { ReactElement, ReactNode, useCallback, useMemo } from "react";
import classNames from "classnames";
import Size from "../../../../../projection/size/model/Size";
import Color from "../../../../../projection/color/model/Color";
import ColorSelector from "../../molecules/colorSelector/ColorSelector";
import SizeSelector from "../../molecules/sizeSelector/SizeSelector";
import "./product-variant-selector.css";
import {
  ProductVariantProjection,
  bestProductVariantForColor,
  bestProductVariantForSize,
} from "../../../../../projection/product/productVariant";

enum ProductVariantSelectorVariant {
  DEFAULT = "default",
  SMALL = "small",
}

const sortSizesByVisualOrder = (sizes: Size[]): Size[] =>
  [...sizes].sort((aSize, bSize) => (aSize.visualOrder || 0) - (bSize.visualOrder || 0));

const uniqueSizesFromProductVariants = (productVariants: ProductVariantProjection[]): Size[] => {
  const sizeMap = productVariants.reduce(
    // TODO update size.lookiero.format for the size.id once the endpoint properly returns the expected id property
    (acc, { size }) => ({ ...acc, ...(acc[size.lookiero.format] ? {} : { [size.lookiero.format]: size }) }),
    {} as Record<string, Size>,
  );

  return sortSizesByVisualOrder(Object.values(sizeMap));
};

const uniqueColorsFromProductVariants = (productVariants: ProductVariantProjection[]): Color[] => {
  const colorMap = productVariants.reduce(
    (acc, { color }) => ({ ...acc, ...(acc[color.code] ? {} : { [color.code]: color }) }),
    {} as Record<string, Color>,
  );

  return Object.values(colorMap);
};

const compatibleSizesWithColor = (color: Color, productVariants: ProductVariantProjection[]): Size[] =>
  uniqueSizesFromProductVariants(productVariants.filter((productVariant) => productVariant.color.code === color.code));

const compatibleColorsWithSize = (size: Size, productVariants: ProductVariantProjection[]): Color[] =>
  uniqueColorsFromProductVariants(
    // TODO update size.lookiero.format for the size.id once the endpoint properly returns the expected id property
    productVariants.filter((productVariant) => productVariant.size.lookiero.format === size.lookiero.format),
  );

type ProductVariantSelectorProps<PV extends ProductVariantProjection> = {
  readonly productVariants: PV[];
  readonly selectedProductVariant: PV;
  readonly onChange: (productVariant: PV) => void;
  readonly variant?: ProductVariantSelectorVariant;
  readonly children?: ReactNode;
};
const ProductVariantSelector: <PV extends ProductVariantProjection>(
  props: ProductVariantSelectorProps<PV>,
) => ReactElement = <PV extends ProductVariantProjection>({
  productVariants,
  selectedProductVariant,
  onChange,
  variant = ProductVariantSelectorVariant.DEFAULT,
  children,
}: ProductVariantSelectorProps<PV>) => {
  const sizes = useMemo(() => uniqueSizesFromProductVariants(productVariants), [productVariants]);
  const compatibleSizes = useMemo(
    () => compatibleSizesWithColor(selectedProductVariant.color, productVariants),
    [selectedProductVariant.color, productVariants],
  );
  const handleOnSizeChange = useCallback(
    (size: Size) => {
      const productVariant = bestProductVariantForSize(size, selectedProductVariant.color, productVariants);

      onChange(productVariant);
    },
    [onChange, productVariants, selectedProductVariant.color],
  );

  const colors = useMemo(() => uniqueColorsFromProductVariants(productVariants), [productVariants]);
  const compatibleColors = useMemo(
    () => compatibleColorsWithSize(selectedProductVariant.size, productVariants),
    [selectedProductVariant.size, productVariants],
  );
  const handleOnColorChange = useCallback(
    (color: Color) => {
      const productVariant = bestProductVariantForColor(color, selectedProductVariant.size, productVariants);

      onChange(productVariant);
    },
    [onChange, productVariants, selectedProductVariant.size],
  );

  return (
    <section className={classNames("product-variant-selector", `product-variant-selector--${variant}`)}>
      <ColorSelector
        colors={colors}
        compatibleColors={compatibleColors}
        selectedColor={selectedProductVariant.color}
        onChange={handleOnColorChange}
      />
      <div className="product-variant-selector__size">
        <SizeSelector
          compatibleSizes={compatibleSizes}
          selectedSize={selectedProductVariant.size}
          sizes={sizes}
          onChange={handleOnSizeChange}
        />
        {children}
      </div>
    </section>
  );
};

export { ProductVariantSelectorVariant };

export default ProductVariantSelector;
