import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useCallback } from "react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { sortSelectionOrder } from "../../../../domain/selectionOrder/command/sortSelectionOrder";
import { Logger } from "../../../logging/Logger";
import invariant from "ts-invariant";

interface SortSelectionFunctionArgs {
  readonly productVariantIds: string[];
}

interface SortSelectionFunction {
  (args: SortSelectionFunctionArgs): Promise<void>;
}

interface UseSortSelectionOrderFunctionArgs {
  readonly boxNumber: string;
  readonly selectionId: string | undefined;
  readonly logger: Logger;
}

type UseSortSelectionOrderFunctionResult = [sortSelection: SortSelectionFunction, status: CommandStatus];

interface UseSortSelectionOrderFunction {
  (args: UseSortSelectionOrderFunctionArgs): UseSortSelectionOrderFunctionResult;
}

const useSortSelectionOrder: UseSortSelectionOrderFunction = ({ selectionId, boxNumber, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const sort: SortSelectionFunction = useCallback(
    async ({ productVariantIds }) => {
      invariant(selectionId, "Cannot sort selection order without a selectionId");

      try {
        await commandBus(sortSelectionOrder({ aggregateId: selectionId, boxNumber, productVariantIds }));
      } catch (error) {
        logger.captureException(error as Error);
      }
    },
    [boxNumber, commandBus, logger, selectionId],
  );

  return [sort, status];
};

export { useSortSelectionOrder };
