import { FC, ReactNode } from "react";
import { MemoryRouter, BrowserRouter } from "react-router-dom";
import isIframeEmbedded from "../../../../infrastructure/integration/isIframeEmbedded";

type RouterProps = {
  readonly children: ReactNode;
  readonly pathname: string;
};
const Router: FC<RouterProps> = ({ children, pathname }: RouterProps) =>
  isIframeEmbedded() ? (
    <MemoryRouter initialEntries={[pathname]}>{children}</MemoryRouter>
  ) : (
    <BrowserRouter>{children}</BrowserRouter>
  );

export default Router;
