import { FC, useCallback } from "react";
import useEnvironment from "../../../../hooks/useEnvironment";
import { Sidetab } from "@typeform/embed-react";
import { useI18nMessage } from "@lookiero/i18n-react";
import SelectionI18n, { SELECTION_I18N_PREFIX } from "../../../../i18n/SelectionI18n";
import { useUpdateUiSetting } from "../../../../../../shared/ui-settings/infrastructure/domain/react/useUpdateUiSetting";
import { MESSAGING_CONTEXT_ID } from "../../../../../../bootstrap";

interface AutomaticSelectionTypeformProps {
  readonly boxId: string;
  readonly personalShopperId: string;
  readonly uiSettingKey: string;
}
const AutomaticSelectionTypeform: FC<AutomaticSelectionTypeformProps> = ({
  boxId,
  personalShopperId,
  uiSettingKey,
}) => {
  const buttonText = useI18nMessage({
    id: SelectionI18n.AUTOMATIC_SELECTION_TYPEFORM_BUTTON,
    prefix: SELECTION_I18N_PREFIX,
  });

  const {
    typeform: { automaticSelectionTypeformId },
  } = useEnvironment();

  const [updateUiSetting] = useUpdateUiSetting({ contextId: MESSAGING_CONTEXT_ID });
  const handleOnSubmit = useCallback(
    () =>
      updateUiSetting({
        key: uiSettingKey,
        value: true,
      }),
    [uiSettingKey, updateUiSetting],
  );

  return (
    <Sidetab
      buttonColor="black"
      buttonText={buttonText}
      id={automaticSelectionTypeformId}
      open="load"
      tracking={{
        box_id: boxId,
        ps_id: personalShopperId,
      }}
      autoClose
      keepSession
      onSubmit={handleOnSubmit}
    />
  );
};

export { AutomaticSelectionTypeform };
