const CHECKOUTS_I18N_PREFIX = "labs.checkout.";

enum CheckoutsI18n {
  HEADER_TITLE = "header_title",
  HEADER_COUNT = "header_count",
  EMPTY_TITLE = "empty_title",
  EMPTY_DESCRIPTION = "empty_description",
  CUSTOMER_FIRST_ORDER = "customer_first_order",
  CUSTOMER_NON_FIRST_ORDER = "customer_non_first_order",
  BOX_TITLE = "box_title",
}

export { CHECKOUTS_I18N_PREFIX, CheckoutsI18n };
