import { FC, ReactNode, useEffect, useMemo, useRef } from "react";
import { generatePath, useMatch, useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../routing/Routing";
import ProductVariantComparer from "./components/productVariantComparer/ProductVariantComparer";
import { BoxSharedContextProvider } from "../../hooks/useBoxSharedContext";
import { ActivityTimeProvider } from "./components/activityTime/hooks/useActivityTime";
import { ProductVariantComparerProvider } from "../../hooks/useProductVariantComparer";
import { UnifiedBox } from "./components/unifiedBox/UnifiedBox";
import { useViewUiSettingByKey } from "../../../../shared/ui-settings/infrastructure/projection/react/useViewUiSettingByKey";
import { useUpdateUiSetting } from "../../../../shared/ui-settings/infrastructure/domain/react/useUpdateUiSetting";
import { AutomaticSelectionProvider } from "../../hooks/useAutomaticSelection";
import UISettings from "../../uiSettings/UISettings";
import "./box.css";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";

interface BoxProps {
  readonly children: ReactNode;
}

const Box: FC<BoxProps> = ({ children }) => {
  const { locale, box: boxNumber } = useParams();
  const locationParams = useMemo(() => ({ locale: locale as string, box: boxNumber as string }), [boxNumber, locale]);
  const customerPath = generatePath(Routes.BOX_CUSTOMER, locationParams);
  const shortlistPath = generatePath(Routes.BOX_SHORTLIST, locationParams);
  const selectionPath = generatePath(Routes.BOX_SELECTION, locationParams);
  const navigate = useNavigate();
  const boxMatch = useMatch(Routes.BOX);
  const shortlistMatch = useMatch(`${Routes.BOX_SHORTLIST}/*`);
  const selectionMatch = useMatch(`${Routes.BOX_SELECTION}/*`);
  const customerMatch = useMatch(`${Routes.BOX_CUSTOMER}/*`);
  const mountComparer = Boolean(shortlistMatch || selectionMatch);

  const boxRoutePathId = `${UISettings.BOX_ROUTE_PATH}-${boxNumber}`;
  const [prevBoxRoutePath] = useViewUiSettingByKey({ contextId: MESSAGING_CONTEXT_ID, key: boxRoutePathId });
  const [updateUiSetting] = useUpdateUiSetting({ contextId: MESSAGING_CONTEXT_ID });
  useEffect(() => {
    if (boxMatch) {
      return;
    }

    let path;
    if (customerMatch) {
      path = customerPath;
    } else if (shortlistMatch) {
      path = shortlistPath;
    } else if (selectionMatch) {
      path = selectionPath;
    }

    if (!path) {
      return;
    }

    updateUiSetting({ key: boxRoutePathId, value: path });
  }, [
    boxMatch,
    boxRoutePathId,
    customerMatch,
    customerPath,
    selectionMatch,
    selectionPath,
    shortlistMatch,
    shortlistPath,
    updateUiSetting,
  ]);

  const navigatedToPreviousRoute = useRef(false);
  useEffect(() => {
    if (navigatedToPreviousRoute.current || !boxMatch || prevBoxRoutePath === undefined) {
      return;
    }

    const path = prevBoxRoutePath?.value || customerPath;

    navigate(path);
    navigatedToPreviousRoute.current = true;
  }, [boxMatch, customerPath, navigate, prevBoxRoutePath]);

  if (boxMatch) {
    return null;
  }

  return (
    <AutomaticSelectionProvider boxNumber={boxNumber as string}>
      <BoxSharedContextProvider boxNumber={boxNumber as string}>
        <ActivityTimeProvider boxNumber={boxNumber as string}>
          <ProductVariantComparerProvider>
            <UnifiedBox>
              {children}
              {mountComparer && (
                <section className="box__product-variant-comparer">
                  <ProductVariantComparer />
                </section>
              )}
            </UnifiedBox>
          </ProductVariantComparerProvider>
        </ActivityTimeProvider>
      </BoxSharedContextProvider>
    </AutomaticSelectionProvider>
  );
};

export { Box };
