import { BoxPreviewByIdView, ViewBoxPreviewByIdResult } from "../../../projection/boxPreview/viewBoxPreviewById";
import { camelcaseKeys } from "../../delivery/http/camelcaseKeys";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { BoxPreviewDto, toBoxPreviewProjection } from "./boxPreview";

interface HttpBoxPreviewByIdView extends BoxPreviewByIdView {}

interface HttpBoxPreviewByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxPreviewByIdViewFunction {
  (args: HttpBoxPreviewByIdViewFunctionArgs): HttpBoxPreviewByIdView;
}

interface ViewBoxPreviewByIdResponse {
  readonly result: BoxPreviewDto;
}

const httpBoxPreviewByIdView: HttpBoxPreviewByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<ViewBoxPreviewByIdResponse, ViewBoxPreviewByIdResult>({
      endpoint: "/view-box-preview-by-id",
      body: { box_preview_id: id },
      signal,
      result: {
        error: null,
        success: (response) => toBoxPreviewProjection(camelcaseKeys(response.result)),
      },
    });

export { httpBoxPreviewByIdView };
