import { FC, useEffect } from "react";
import RootLoader from "../../components/atoms/rootLoader/RootLoader";
import { BoxPreviewDetail as BoxPreviewDetailTemplate } from "./components/boxPreviewDetail/BoxPreviewDetail";
import { useViewBoxPreviewByBoxId } from "../../../projection/boxPreview/react/useViewBoxPreviewByBoxId";
import { QueryStatus } from "@lookiero/messaging-react";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { TrackingPage } from "../../../tracking/Tracker";
import { useViewPersonalShopper } from "../../../projection/personalShopper/react/useViewPersonalShopper";
import "./box-preview-detail.css";

const BoxPreviewDetailLoader: FC = () => (
  <div className="box-preview-detail__loader">
    <RootLoader />
  </div>
);

interface BoxPreviewDetailProps {
  readonly boxId: string;
  readonly customerId: string;
  readonly origin: TrackingPage;
  readonly visible: boolean;
}

const BoxPreviewDetail: FC<BoxPreviewDetailProps> = ({ boxId, customerId, origin, visible }: BoxPreviewDetailProps) => {
  const [boxPreview, boxPreviewStatus] = useViewBoxPreviewByBoxId({ boxId });
  const [personalShopper] = useViewPersonalShopper();
  const trackPageView = useTrackPageView({
    boxId,
    section: TrackingPage.BOX_PREVIEW,
    userId: customerId,
    origin,
    psId: personalShopper?.id,
  });

  useEffect(() => {
    if (!boxPreview || !visible) {
      return;
    }

    trackPageView();
  }, [boxPreview, trackPageView, visible]);

  return boxPreviewStatus === QueryStatus.LOADING ? (
    <BoxPreviewDetailLoader />
  ) : boxPreview ? (
    <BoxPreviewDetailTemplate boxPreview={boxPreview} />
  ) : null;
};

export { BoxPreviewDetail };
