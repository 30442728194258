import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { listFamilies } from "../../../../projection/family/listFamilies";
import { FamilyProjection } from "../../../../projection/family/family";

interface UseListFamiliesFunction {
  (): UseQueryFunctionResult<FamilyProjection[]>;
}

const useListFamilies: UseListFamiliesFunction = () =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listFamilies(),
    options: {
      staleTime: Infinity,
    },
  });

export { useListFamilies };
