import { ComponentType, FC, useEffect, useState } from "react";
import RouteFirewall from "../../firewall/RouteFirewall";
import { Routes } from "../Routing";
import RootLoader from "../../components/atoms/rootLoader/RootLoader";
import { matchPath, useLocation } from "react-router-dom";

const firewallForPath = (
  firewallConfig: Partial<Record<Routes, RouteFirewall>>,
  path: string,
): RouteFirewall | undefined => {
  const firewallConfigKey = Object.keys(firewallConfig).find((route) => Boolean(matchPath(route, path))) as
    | Routes
    | undefined;

  return firewallConfigKey ? firewallConfig[firewallConfigKey as Routes] : undefined;
};

const firewallProtected =
  <P extends Record<string, unknown>>(
    firewallConfig: Partial<Record<Routes, RouteFirewall>>,
    RouteComponent: ComponentType<P>,
  ) =>
  (props: P): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [Component, setComponent] = useState<ComponentType<any>>();
    const location = useLocation();

    useEffect(() => {
      const processFirewall = async () => {
        const firewall = firewallForPath(firewallConfig, location.pathname);
        const component = firewall ? await firewall.process(location.pathname, RouteComponent as FC) : RouteComponent;

        setComponent(() => component);
      };

      processFirewall();
    }, [location.pathname]);

    return Component ? <Component {...props} /> : <RootLoader />;
  };

export default firewallProtected;
