import { StyleProfileProjection } from "../../../../projection/styleProfile/styleProfile";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { viewStyleProfileByBoxId } from "../../../../projection/styleProfile/viewStyleProfileByBoxId";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";

interface UseViewStyleProfileByBoxIdFunctionArgs {
  readonly boxId: string | undefined;
}

interface UseViewStyleProfileByBoxIdFunction {
  (args: UseViewStyleProfileByBoxIdFunctionArgs): UseQueryFunctionResult<StyleProfileProjection>;
}

const useViewStyleProfileByBoxId: UseViewStyleProfileByBoxIdFunction = ({ boxId }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: viewStyleProfileByBoxId({ boxId: boxId as string }),
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(boxId),
    },
  });

export { useViewStyleProfileByBoxId };
