import { createContext, FC, ReactNode, useContext } from "react";
import { invariant } from "ts-invariant";
import FeatureToggles from "../../infrastructure/ui/featureToggle/FeatureToggles";
import FeatureToggle from "./FeatureToggle";

type FeatureToggleConfig = Partial<Record<FeatureToggles, FeatureToggle>>;
const FeatureToggleContext = createContext<FeatureToggleConfig | null>(null);

type FeatureToggleContextProviderProps = {
  readonly featureToggles: FeatureToggleConfig;
  readonly children: ReactNode;
};

const FeatureToggleProvider: FC<FeatureToggleContextProviderProps> = ({
  featureToggles,
  children,
}: FeatureToggleContextProviderProps) => (
  <FeatureToggleContext.Provider value={featureToggles}>{children}</FeatureToggleContext.Provider>
);

const useFeatureToggle = (featureToggleKey: FeatureToggles): FeatureToggle => {
  const featureToggles = useContext<FeatureToggleConfig | null>(FeatureToggleContext);

  invariant(
    featureToggles,
    "Your are trying to use the useFeatureToggle hook without wrapping your app with the <FeatureToggleProvider>.",
  );

  const featureToggle = featureToggles[featureToggleKey];

  invariant(featureToggle, `There is no FeatureToggle defined for "${featureToggle}".`);

  return featureToggle;
};

export type { FeatureToggleConfig };
export { FeatureToggleProvider };

export default useFeatureToggle;
