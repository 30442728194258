import { FC } from "react";
import { TextQuestionItem, TextQuestionItemProps } from "../textQuestionItem/TextQuestionItem";
import Icon, { IconVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import { StyleProfileIcon } from "../../../../../../../../projection/styleProfile/styleProfile";
import "./icon-question-item.css";

const ICON_VARIANT: Record<StyleProfileIcon, IconVariant> = {
  "labs.customer.silhouette.icon.triangle": IconVariant.SHAPE_TRIANGLE,
  "labs.customer.silhouette.icon.oval": IconVariant.SHAPE_OVAL,
  "labs.customer.silhouette.icon.inverted_triangle": IconVariant.SHAPE_WEDGE,
  "labs.customer.silhouette.icon.rectangle": IconVariant.SHAPE_RECTANGLE,
  "labs.customer.silhouette.icon.hourglass": IconVariant.HOURGLASS,
  "labs.customer.silhouette.icon.diamond": IconVariant.SHAPE_DIAMOND,
};

interface IconQuestionItemProps extends TextQuestionItemProps {
  readonly icon: StyleProfileIcon;
  readonly label: string;
}
const IconQuestionItem: FC<IconQuestionItemProps> = ({ children, icon, label }) => {
  const iconVariant = ICON_VARIANT[icon];

  return (
    <div className="icon-question-item">
      {iconVariant && <Icon label={label} variant={iconVariant} />}
      <TextQuestionItem>{children}</TextQuestionItem>
    </div>
  );
};

export { IconQuestionItem };
