import { useCallback } from "react";
import { Section } from "./Tracker";
import { useTracker } from "./useTracker";

interface TrackPreviewSentFunctionArgs {
  readonly boxNumber: string;
}
interface TrackPreviewSentFunction {
  (args: TrackPreviewSentFunctionArgs): void;
}

interface UseTrackPreviewSentFunctionArgs {
  readonly boxId: string;
  readonly psId?: string;
  readonly section: Section;
  readonly userId: string;
}

interface UseTrackPreviewSentFunction {
  (args: UseTrackPreviewSentFunctionArgs): TrackPreviewSentFunction;
}
const useTrackPreviewSent: UseTrackPreviewSentFunction = ({ section, boxId, psId, userId }) => {
  const tracker = useTracker();

  const trackPreviewSent: TrackPreviewSentFunction = useCallback(
    ({ boxNumber }) => {
      if (!(tracker && section && boxId && psId && userId && boxNumber)) {
        return;
      }

      tracker.previewSent({
        section,
        boxId,
        psId,
        userId,
        boxNumber,
      });
    },
    [boxId, psId, section, tracker, userId],
  );

  return trackPreviewSent;
};

export { useTrackPreviewSent };
