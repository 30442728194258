import { FC } from "react";
import { matchPath } from "react-router-dom";
import { I18n } from "@lookiero/i18n-react";
import {
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError,
} from "@formatjs/intl";
import { Environment } from "../../../../projection/environment/model/Environment";
import Locale from "../../../../domain/country/model/Locale";
import { EnvironmentProvider } from "../../hooks/useEnvironment";
import { SentryErrorHOC } from "../../../logging/SentryErrorHOC";
import { Routes, Routing } from "../../routing/Routing";
import Router from "./Router";
import RouteFirewall from "../../firewall/RouteFirewall";
import RootFirewall from "./RootFirewall";
import { FeatureToggleConfig, FeatureToggleProvider } from "../../../../shared/featureToggle/useFeatureToggle";
import { TrackerProvider } from "../../../tracking/useTracker";
import { Tracker } from "../../../tracking/Tracker";
import { Logger } from "../../../logging/Logger";
import "./root.css";

declare global {
  interface Window {
    missingI18n: string[];
  }
}
const handleOnI18nError = (
  error:
    | Error
    | MissingTranslationError
    | MessageFormatError
    | MissingDataError
    | InvalidConfigError
    | UnsupportedFormatterError,
) => {
  // by default it outputs a console.error
  // Gather all the missing translations and expose them in the window
  if (error instanceof MissingTranslationError) {
    window.missingI18n = [
      ...new Set([...(window.missingI18n || []), error.descriptor ? `${error.descriptor.id}` : "no-descriptor"]),
    ];
  }
};

interface RootProps {
  readonly I18nRootComponent: I18n;
  readonly environment: Environment;
  readonly firewallConfig: Partial<Record<Routes, RouteFirewall>>;
  readonly featureToggleConfig: FeatureToggleConfig;
  readonly tracker: Tracker;
  readonly logger: Logger;
}

const Root: FC<RootProps> = ({
  I18nRootComponent,
  environment,
  firewallConfig,
  featureToggleConfig,
  tracker,
  logger,
}) => {
  // eslint-disable-next-line no-restricted-globals
  const { pathname } = location;
  const localeRoute = matchPath(`${Routes.HOME}/*`, pathname);
  const locale = localeRoute?.params.locale || environment.internationalization.defaultLocale;

  return (
    <SentryErrorHOC logger={logger}>
      <EnvironmentProvider environment={environment}>
        <FeatureToggleProvider featureToggles={featureToggleConfig}>
          <TrackerProvider tracker={tracker}>
            <Router pathname={pathname}>
              <RootFirewall config={firewallConfig}>
                <Routing
                  I18nRootComponent={I18nRootComponent}
                  locale={locale as Locale}
                  onI18nError={handleOnI18nError}
                />
              </RootFirewall>
            </Router>
          </TrackerProvider>
        </FeatureToggleProvider>
      </EnvironmentProvider>
    </SentryErrorHOC>
  );
};

export { Root };
