import { FC, ReactNode, useCallback } from "react";
import NoteTemplateActions from "./components/noteTemplateActionButtons/NoteTemplateActions";
import QueryParams from "../../routing/queryParams/config";
import useQueryParameters from "../../routing/queryParams/useQueryParameters";
import { Routes } from "../../routing/Routing";
import { useEditorSharedContext } from "../../hooks/useEditorSharedContext";
import useEnvironment from "../../hooks/useEnvironment";
import NoteTemplatesTemplate from "./components/noteTemplates/NoteTemplates";
import { useCountNoteTemplatesByCriteria } from "../../../../infrastructure/projection/noteTemplate/react/useCountNoteTemplatesByCriteria";
import { useSearchNoteTemplatesByCriteria } from "../../../../infrastructure/projection/noteTemplate/react/useSearchNoteTemplatesByCriteria";
import { useRemoveNoteTemplate } from "../../../../infrastructure/domain/noteTemplate/react/useRemoveNoteTemplate";
import { NoteTemplateProjection } from "../../../../projection/noteTemplate/noteTemplate";
import { asyncActionStateForCommandStatus } from "../../../../shared/ui/uiKit/_common/AsyncActionState";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useViewPersonalShopper } from "../../../../infrastructure/projection/personalShopper/react/useViewPersonalShopper";

interface NoteTemplatesProps {
  readonly children?: ReactNode;
}

const NoteTemplates: FC<NoteTemplatesProps> = ({ children }) => {
  const {
    noteTemplates: { perPage },
  } = useEnvironment();
  const navigate = useNavigate();
  const { locale, box: legacyBoxId } = useParams();
  const [personalShopper] = useViewPersonalShopper();
  const { injectTemplate } = useEditorSharedContext();

  const { params, setParams } = useQueryParameters();
  const categoriesParams = params[QueryParams.CATEGORIES];
  const categories = categoriesParams ? JSON.parse(categoriesParams as string) : [];
  const page = parseInt(params[QueryParams.PAGE] as string) || 1;
  const onPageChanged = useCallback(
    (page: number): void => setParams({ [QueryParams.PAGE]: page === 1 ? null : String(page) }),
    [setParams],
  );
  const navigateToTemplateDetail = useCallback(
    (noteTemplateId: string) => {
      navigate(
        generatePath(Routes.BOX_SELECTION_NOTES_TEMPLATES_DETAIL, {
          locale: locale as string,
          box: legacyBoxId as string,
          template: noteTemplateId,
        }),
        { replace: true },
      );
    },
    [legacyBoxId, locale, navigate],
  );

  const [countNoteTemplates] = useCountNoteTemplatesByCriteria({
    psId: personalShopper?.id,
    categories,
    itemCountWhileQuerying: perPage,
  });

  const [noteTemplates] = useSearchNoteTemplatesByCriteria({
    psId: personalShopper?.id,
    page,
    perPage,
    categories,
  });

  const [removeNoteTemplate, removeNoteTemplateStatus] = useRemoveNoteTemplate();

  const useNoteTemplate = useCallback(
    (noteTemplateContent: string) => {
      injectTemplate(noteTemplateContent);
      navigate(generatePath(Routes.BOX_SELECTION, { locale: locale as string, box: legacyBoxId as string }));
    },
    [injectTemplate, navigate, locale, legacyBoxId],
  );

  const noteTemplateActions = useCallback(
    (noteTemplate: NoteTemplateProjection) => (
      <NoteTemplateActions
        noteTemplate={noteTemplate}
        removeState={asyncActionStateForCommandStatus[removeNoteTemplateStatus]}
        onEdit={navigateToTemplateDetail}
        onRemove={removeNoteTemplate}
        onUse={useNoteTemplate}
      />
    ),
    [navigateToTemplateDetail, removeNoteTemplate, removeNoteTemplateStatus, useNoteTemplate],
  );

  return (
    <>
      <NoteTemplatesTemplate
        itemCount={countNoteTemplates as number}
        noteTemplateActions={noteTemplateActions}
        noteTemplates={noteTemplates}
        page={page}
        perPage={perPage}
        onPageChanged={onPageChanged}
      />
      {children}
    </>
  );
};

export default NoteTemplates;
