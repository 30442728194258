import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import {
  listCheckoutsByPersonalShopperId,
  ListCheckoutsByPersonalShopperIdResult,
} from "../../../../projection/checkout/listCheckoutsByPersonalShopperId";
import { DomainEvent, MessageName } from "@lookiero/messaging";
import { isCheckoutReviewed } from "../../../../domain/checkout/model/checkoutReviewed";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isCheckoutReviewed(event);

type QueryKey = string | unknown[];

interface UseListCheckoutsByPersonalShopperIdFunctionArgs {
  readonly queryId?: QueryKey;
  readonly invalidationId?: QueryKey;
  readonly invalidationCallback?: () => void;
  readonly personalShopperId: string | undefined;
  readonly page: number;
  readonly perPage: number;
}

interface UseListCheckoutsByPersonalShopperIdFunction {
  (
    args: UseListCheckoutsByPersonalShopperIdFunctionArgs,
  ): UseQueryFunctionResult<ListCheckoutsByPersonalShopperIdResult>;
}

const useListCheckoutsByPersonalShopperId: UseListCheckoutsByPersonalShopperIdFunction = ({
  queryId,
  invalidationId,
  personalShopperId,
  page,
  perPage,
  invalidationCallback,
}) =>
  useQuery({
    id: queryId,
    invalidationId,
    query: listCheckoutsByPersonalShopperId({ personalShopperId: personalShopperId as string, page, perPage }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: Boolean(personalShopperId),
      keepPreviousData: true,
    },
  });

export { useListCheckoutsByPersonalShopperId };
