import { FC, ReactNode, useCallback } from "react";
import Button from "../../../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import classNames from "classnames";
import "./box-tab-pinneable.css";

interface BoxTabPinneableProps {
  readonly children: ReactNode;
  readonly label?: string;
  readonly className?: string;
  readonly pinned: boolean;
  readonly header: ReactNode;
  readonly disableButton?: boolean;
  readonly onPinnedChanged: (pinned: boolean) => void;
}

const BoxTabPinneable: FC<BoxTabPinneableProps> = ({
  children,
  label,
  className,
  pinned,
  header,
  disableButton = false,
  onPinnedChanged,
}) => {
  const handleOnActionClick = useCallback(() => onPinnedChanged(!pinned), [onPinnedChanged, pinned]);

  return (
    <section aria-label={label} className={classNames("box-tab-pinneable", className)}>
      {header && (
        <header className="box-tab-pinneable__header">
          {header}
          <Button
            aria-label="box-tab-pinneable-button"
            disabled={disableButton}
            icon={pinned ? IconVariant.CLOSE : IconVariant.PIN}
            onClick={handleOnActionClick}
          />
        </header>
      )}
      <div className="box-tab-pinneable__content">{children}</div>
    </section>
  );
};

export { BoxTabPinneable };
