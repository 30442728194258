import { useCallback, useMemo } from "react";
import Color from "../../../../../../../../projection/color/model/Color";
import ColorOption, {
  ColorOptionState,
} from "../../../../../../../../shared/ui/uiKit/components/atoms/colorOption/ColorOption";
import { activate, deactivate, isAnyLeafActive } from "../../../ShortlistFilterState";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import "./color-option-shortlist-filter-item.css";

const ColorOptionShortlistFilterItem: ShortlistFilterItemHOC = ({ useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function ColorOptionShortlistFilterItemComponent({ filter }) {
    const { state, onChange } = useShortlistFiltering();
    const optionState = useMemo(
      () => (isAnyLeafActive({ filter, state }) ? ColorOptionState.SELECTED : ColorOptionState.DEFAULT),
      [filter, state],
    );

    const handleOnChange = useCallback(
      (checked: boolean) => onChange({ state: checked ? activate({ filter, state }) : deactivate({ filter, state }) }),
      [filter, onChange, state],
    );

    const color: Color = useMemo(
      () => ({ hexadecimal: filter.metadata?.alias, translationKey: filter.translationKey } as Color),
      [filter.metadata?.alias, filter.translationKey],
    );

    return (
      <ColorOption
        className="color-option-shortlist-filter-item"
        color={color}
        state={optionState}
        onChange={handleOnChange}
      />
    );
  };

export default ColorOptionShortlistFilterItem;
