import { useMemo, useCallback, ReactNode, FC, KeyboardEvent } from "react";
import classNames from "classnames";
import Select, { createFilter, InputActionMeta, MultiValue, SingleValue } from "react-select";
import FormInputOption from "../../../_common/FormInputOption";
import FormInputOptionGroup from "../../../_common/FormInputOptionGroup";
import Error from "../../atoms/error/Error";
import IndicatorSeparator from "./DropdownIndicatorSeparator";
import MultiValueRemove from "./DropdownMultiValueRemove";
import DropdownIndicator from "./DropdownIndicator";
import dropdownValueContainer from "./DropdownValueContainer";
import dropdownOption from "./DropdownOption";
import dropdownGroupHeading from "./DropdownGroupHeading";
import dropdownMultiValue from "./DropdownMultiValue";
import "./dropdown.css";

interface DropdownProps {
  readonly label?: string;
  readonly noValueLabel?: string;
  readonly noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  readonly options: FormInputOption[] | FormInputOptionGroup[];
  readonly opened?: boolean;
  readonly isMulti?: boolean;
  readonly isSearchable?: boolean;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  readonly value?: null | string | string[];
  readonly highlightedValue?: string[];
  readonly inputValue?: string;
  readonly onChange: (value?: null | string | string[]) => void;
  readonly onInputChange?: (value: string) => void;
  readonly children?: (children: ReactNode, data: FormInputOption) => ReactNode;
  readonly error?: string;
}
const Dropdown: FC<DropdownProps> = ({
  label,
  noValueLabel = "",
  noOptionsMessage,
  options,
  opened,
  isSearchable = true,
  isMulti = false,
  disabled = false,
  readOnly = false,
  value,
  highlightedValue,
  inputValue,
  onChange,
  onInputChange,
  children = (children): ReactNode => children,
  error,
}: DropdownProps) => {
  const dropdownValue = useMemo(() => (Array.isArray(value) ? value : [value]).filter((value) => value), [value]);

  const onGroupHeadingClick = useCallback(
    (groupLabel: string) => {
      if (!isMulti) {
        return;
      }

      const group = (options as FormInputOptionGroup[]).find((option) => option.label === groupLabel);

      if (!group) {
        return;
      }

      const groupOptionsValues = group.options.map((option) => option.value);
      const areAllOptionsSelected = groupOptionsValues.every((value) => dropdownValue.includes(value));

      const newValue = areAllOptionsSelected
        ? dropdownValue.filter((value) => !groupOptionsValues.includes(value as string))
        : [...new Set([...dropdownValue, ...groupOptionsValues])];

      onChange(newValue as string[]);
    },
    [dropdownValue, onChange, options, isMulti],
  );

  const GroupHeading = useMemo(() => dropdownGroupHeading(onGroupHeadingClick), [onGroupHeadingClick]);
  const ValueContainer = useMemo(() => dropdownValueContainer({ label, noValueLabel }), [label, noValueLabel]);
  const MultiValue = useMemo(() => dropdownMultiValue(highlightedValue), [highlightedValue]);
  const Option = useMemo(() => dropdownOption(children), [children]);

  const handleOnInputChange = useCallback(
    (value: string, { action }: InputActionMeta) => {
      if (onInputChange && action !== "input-blur" && action !== "menu-close") {
        onInputChange(value);
      }
    },
    [onInputChange],
  );

  const handleOnChange = useCallback(
    (option: SingleValue<FormInputOption> | MultiValue<FormInputOption>): void => {
      const newValue = option
        ? Array.isArray(option)
          ? option.map<string>((option) => option.value)
          : (option as FormInputOption).value === value ||
            (Array.isArray(value) && value.length > 0 && (option as FormInputOption).value === value[0])
          ? null
          : (option as FormInputOption).value
        : undefined;
      onChange(newValue);
    },
    [onChange, value],
  );

  const handleOnKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => event.stopPropagation(), []);

  const dropdownValueOption = useMemo(
    () =>
      (options as (FormInputOption | FormInputOptionGroup)[]).reduce(
        (acc: FormInputOption[], option: FormInputOption | FormInputOptionGroup) => {
          if (option.hasOwnProperty("options")) {
            const valueOption = (option as FormInputOptionGroup).options.filter((option) =>
              dropdownValue.find((value) => value === option.value),
            );
            return [...acc, ...(valueOption ? valueOption : [])];
          } else {
            const valueOption = dropdownValue.find((value) => (option as FormInputOption).value === value)
              ? [option]
              : [];
            return [...acc, ...(valueOption as FormInputOption[])];
          }
        },
        [] as FormInputOption[],
      ),
    [dropdownValue, options],
  );

  const customComponents = useMemo(
    () => ({
      ValueContainer,
      MultiValue,
      MultiValueRemove,
      IndicatorSeparator,
      DropdownIndicator,
      Option,
      GroupHeading,
    }),
    [GroupHeading, MultiValue, Option, ValueContainer],
  );

  const filterOption = useMemo(
    () =>
      createFilter({
        matchFrom: "any",
        stringify: (option) => `${option.label}`,
      }),
    [],
  );

  return (
    <div
      className={classNames("dropdown-container", {
        "dropdown-container--error": Boolean(error),
        "dropdown-container--readonly": readOnly,
      })}
    >
      <label htmlFor="dropdown" hidden>
        {label}
      </label>
      <Select
        backspaceRemovesValue={true}
        captureMenuScroll={false}
        className="dropdown"
        classNamePrefix="dropdown"
        closeMenuOnSelect={!isMulti}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        components={customComponents}
        filterOption={filterOption}
        inputId="dropdown"
        inputValue={inputValue}
        isClearable={false}
        isDisabled={disabled || readOnly}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuIsOpen={opened}
        name="dropdown"
        noOptionsMessage={noOptionsMessage}
        options={options}
        placeholder=""
        tabSelectsValue={false}
        value={dropdownValueOption}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        onKeyDown={handleOnKeyDown}
      />
      <Error error={error} />
    </div>
  );
};

export default Dropdown;
