import { FC } from "react";
import { MultiValueRemoveProps, components } from "react-select";
import FormInputOption from "../../../_common/FormInputOption";
import Icon, { IconVariant } from "../../atoms/icon/Icon";

const DropdownMultiValueRemove: FC<MultiValueRemoveProps<FormInputOption, boolean>> = (props) => (
  <components.MultiValueRemove {...props}>
    <Icon variant={IconVariant.CLOSE} />
  </components.MultiValueRemove>
);

export default DropdownMultiValueRemove;
