import { FC } from "react";
import Locale from "../../../../../../domain/country/model/Locale";
import FeatureUnit from "../../../../../../domain/feature/model/FeatureUnit";

type ProductFeatureUnitProps = {
  readonly unit: FeatureUnit;
  readonly locale: Locale;
};
const ProductFeatureUnit: FC<ProductFeatureUnitProps> = ({ unit, locale }: ProductFeatureUnitProps) => {
  switch (unit) {
    case FeatureUnit.CENTIMETERS:
      return locale === Locale.en_GB ? <span>in</span> : <span>cm</span>;
    case FeatureUnit.PERCENTAGE:
      return <span>%</span>;
  }

  return null;
};

export default ProductFeatureUnit;
