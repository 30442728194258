import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { viewCustomerInsightsByCustomerId } from "../../../../projection/customerInsight/viewCustomerInsightsByCustomerId";
import { CustomerInsightProjection } from "../../../../projection/customerInsight/customerInsight";

interface UseViewCustomerInsightsByCustomerIdFunctionArgs {
  readonly customerId: string;
  readonly enabled?: boolean;
}

interface UseViewCustomerInsightsByCustomerIdFunction {
  (args: UseViewCustomerInsightsByCustomerIdFunctionArgs): UseQueryFunctionResult<CustomerInsightProjection>;
}

const useViewCustomerInsightsByCustomerId: UseViewCustomerInsightsByCustomerIdFunction = ({
  customerId,
  enabled = true,
}) =>
  useQuery({
    query: viewCustomerInsightsByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled,
    },
  });

export { useViewCustomerInsightsByCustomerId };
