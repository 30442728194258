import { FC } from "react";
import FeatureToggle from "../../../shared/featureToggle/FeatureToggle";
import RouteFirewall from "./RouteFirewall";
import RouteFirewallSideEffect from "./sideEffects/RouteFirewallSideEffect";

class FeatureToggleRouteFirewall implements RouteFirewall {
  private readonly featureToggles: FeatureToggle[];
  private readonly fallbackComponent: FC;
  private readonly sideEffects: RouteFirewallSideEffect[];

  public constructor(
    featureToggles: FeatureToggle[],
    fallbackComponent: FC,
    sideEffects: RouteFirewallSideEffect[] = [],
  ) {
    this.featureToggles = featureToggles;
    this.fallbackComponent = fallbackComponent;
    this.sideEffects = sideEffects;
  }

  public async process(route: string, component: FC): Promise<FC> {
    const isEnabled = await this.isFeatureEnabled(route);

    this.sideEffects.forEach(async (sideEffect) => await sideEffect.execute(route, isEnabled));

    return isEnabled ? component : this.fallbackComponent;
  }

  private async isFeatureEnabled(route: string): Promise<boolean> {
    try {
      for (const featureToggle of this.featureToggles) {
        if (await featureToggle.isEnabled(route)) {
          return true;
        }
      }

      return false;
    } catch (error) {
      return false;
    }
  }
}

export default FeatureToggleRouteFirewall;
