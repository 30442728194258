import { ReactNode, useState, useEffect, FC, useCallback } from "react";
import classNames from "classnames";
import useCaptureAndStopEvent from "../../../hooks/useCaptureAndStopEvent";
import AsyncActionState from "../../../_common/AsyncActionState";
import Button, { ButtonProps } from "../../atoms/button/Button";
import Icon, { IconVariant } from "../../atoms/icon/Icon";
import Loader from "../../atoms/loader/Loader";
import "./action-button.css";

interface ActionButtonProps extends ButtonProps {
  readonly className?: string;
  readonly children: ReactNode;
  readonly state?: AsyncActionState;
  readonly defaultStateTimeout?: number;
  readonly onClick?: () => void;
}

const ActionButton: FC<ActionButtonProps> = ({
  className,
  children,
  state = AsyncActionState.DEFAULT,
  onClick,
  defaultStateTimeout = 2000,
  ...rest
}: ActionButtonProps) => {
  const [currentState, setCurrentState] = useState(state);
  useEffect(() => {
    setCurrentState(state);

    const initTimeout = () => setTimeout(() => setCurrentState(AsyncActionState.DEFAULT), defaultStateTimeout);

    if (state === AsyncActionState.DEFAULT || state === AsyncActionState.PENDING) {
      return;
    }

    const timeout = initTimeout();

    return (): void => clearTimeout(timeout);
  }, [setCurrentState, state, defaultStateTimeout]);

  const onClickCallback = useCallback(() => {
    if (currentState !== AsyncActionState.DEFAULT) {
      return;
    }

    onClick && onClick();
  }, [currentState, onClick]);
  const handleOnClick = useCaptureAndStopEvent(onClickCallback);

  return (
    <Button
      {...rest}
      className={classNames("action-button", `action-button--${currentState}`, className)}
      onClick={handleOnClick}
    >
      <div className="action-button__content">{children}</div>
      <div className="action-button__loader">
        <Loader />
      </div>
      <div className="action-button__error">
        <Icon variant={IconVariant.CLOSE} />
      </div>
      <div className="action-button__success">
        <Icon variant={IconVariant.BASIC_CHECK} />
      </div>
    </Button>
  );
};

export default ActionButton;
