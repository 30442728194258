import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const CHECKOUT_REVIEWED = "checkout_reviewed";

interface CheckoutReviewedPayload {
  readonly aggregateId: string;
}

interface CheckoutReviewed extends DomainEvent<typeof CHECKOUT_REVIEWED> {}

interface CheckoutReviewedFunction {
  (payload: CheckoutReviewedPayload): CheckoutReviewed;
}

const checkoutReviewed: CheckoutReviewedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: CHECKOUT_REVIEWED });

const isCheckoutReviewed = (event: DomainEvent<MessageName>): event is CheckoutReviewed =>
  event.name === CHECKOUT_REVIEWED;

export type { CheckoutReviewed };
export { CHECKOUT_REVIEWED, checkoutReviewed, isCheckoutReviewed };
