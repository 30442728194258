import { DomainEvent, MessageName } from "@lookiero/messaging";
import { UseQueryFunctionResult, useQuery } from "@lookiero/messaging-react";
import { isNoteWritten } from "../../../../domain/note/model/noteWritten";
import { isNoteGenerated } from "../../../../domain/note/model/noteGenerated";
import { NoteAlertProjection } from "../../../../projection/alert/noteAlert";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { listNoteAlertsByBoxNumber } from "../../../../projection/alert/listNoteAlertsByBoxNumber";

const shouldInvalidate = (event: DomainEvent<MessageName>) => isNoteWritten(event) || isNoteGenerated(event);

interface UseListNoteAlertsByBoxNumberFunctionArgs {
  readonly boxNumber: string;
}

interface UseListNoteAlertsByBoxNumberFunction {
  (args: UseListNoteAlertsByBoxNumberFunctionArgs): UseQueryFunctionResult<NoteAlertProjection[]>;
}

const useListNoteAlertsByBoxNumber: UseListNoteAlertsByBoxNumberFunction = ({ boxNumber }) =>
  useQuery({
    contextId: MESSAGING_CONTEXT_ID,
    query: listNoteAlertsByBoxNumber({ boxNumber }),
    invalidation: shouldInvalidate,
    options: { staleTime: Infinity, retry: 0, refetchOnWindowFocus: false },
  });

export { useListNoteAlertsByBoxNumber };
