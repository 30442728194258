import { FC, useCallback, useState } from "react";
import useEnvironment from "../../../../hooks/useEnvironment";
import { useListFamilies } from "../../../../../projection/family/react/useListFamilies";
import LookAndLikeFeedbacksTemplate from "./components/lookAndLikeFeedbacks/LookAndLikeFeedbacks";
import { useCountLookAndLikeFeedbacksByCriteria } from "../../../../../projection/lookAndLikeFeedback/react/useCountLookAndLikeFeedbacksByCriteria";
import { useSearchLookAndLikeFeedbacksByCriteria } from "../../../../../projection/lookAndLikeFeedback/react/useSearchLookAndLikeFeedbacksByCriteria";
import { ProductVariantWithProductInfoModal } from "../../../productVariantWithProductInfo/ProductVariantWithProductInfoModal";
import { TrackingPage } from "../../../../../tracking/Tracker";

const normalizeFamilyIds = (familyIds: string | string[] | null | undefined): string[] =>
  familyIds ? (Array.isArray(familyIds) ? familyIds : [familyIds]) : [];

interface LookAndLikeProps {
  readonly boxId: string;
  readonly customerId: string;
  readonly familyIds?: string[];
  readonly origin: TrackingPage;
}

const LookAndLike: FC<LookAndLikeProps> = ({ boxId, customerId, familyIds: familyIdsFilter, origin }) => {
  const {
    lookAndLikeFeedback: { perPage },
  } = useEnvironment();
  const [families] = useListFamilies();

  const [likesPage, setLikesPage] = useState(1);
  const [dislikesPage, setDislikesPage] = useState(1);

  const [familyIds, setFamilyIds] = useState<string | string[] | null | undefined>(familyIdsFilter);
  const normalizedFamilyIds = normalizeFamilyIds(familyIds);

  const [likesCount] = useCountLookAndLikeFeedbacksByCriteria({
    itemCountWhileQuerying: perPage,
    customerId,
    familyIds: normalizedFamilyIds,
    response: true,
  });

  const [likes] = useSearchLookAndLikeFeedbacksByCriteria({
    customerId,
    familyIds: normalizedFamilyIds,
    response: true,
    page: likesPage,
    perPage,
  });

  const [dislikesCount] = useCountLookAndLikeFeedbacksByCriteria({
    itemCountWhileQuerying: perPage,
    customerId,
    familyIds: normalizedFamilyIds,
    response: false,
  });

  const [dislikes] = useSearchLookAndLikeFeedbacksByCriteria({
    customerId,
    familyIds: normalizedFamilyIds,
    response: false,
    page: dislikesPage,
    perPage,
  });

  const [productVariantId, setProductVariantId] = useState<string | null>(null);
  const handleOnClickProductVariant = useCallback(
    (productVariantId: string) => setProductVariantId(productVariantId),
    [],
  );
  const closeProductVariantWithProductInfoModal = useCallback(() => setProductVariantId(null), []);

  return (
    <>
      <LookAndLikeFeedbacksTemplate
        dislikes={dislikes}
        dislikesCount={dislikesCount}
        dislikesPage={dislikesPage}
        dislikesPerPage={perPage}
        families={families}
        familyIds={familyIds}
        likes={likes}
        likesCount={likesCount}
        likesPage={likesPage}
        likesPerPage={perPage}
        onClickProductVariant={handleOnClickProductVariant}
        onDislikesPageChanged={setDislikesPage}
        onFamilyIdsChanged={setFamilyIds}
        onLikesPageChanged={setLikesPage}
      />

      <ProductVariantWithProductInfoModal
        boxId={boxId}
        customerId={customerId}
        origin={origin}
        productVariantId={productVariantId || ""}
        visible={Boolean(productVariantId)}
        onCancel={closeProductVariantWithProductInfoModal}
      />
    </>
  );
};

export { LookAndLike };
