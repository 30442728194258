import { FC, ReactNode, useCallback, useMemo } from "react";
import { Locale } from "@lookiero/i18n";
import VirtualizedList from "../../../../../../shared/ui/uiKit/components/layouts/virtualizedList/VirtualizedList";
import { CapacityCalendarProjection } from "../../../../../../projection/capacityCalendar/capacityCalendar";
import { CapacityListItem } from "../capacityListItem/CapacityListItem";
import { CapacityListItemSkeleton } from "../capacityListItem/CapacityListItemSkeleton";
import "./capacity-list.css";

const ITEM_HEIGHT = 52;

interface CapacityListItemFunction {
  (index: number, capacityCalendar: CapacityCalendarProjection | null): ReactNode;
}

interface CapacityListProps {
  readonly locale: Locale;
  readonly capacityCalendar: Record<number, CapacityCalendarProjection[]> | undefined;
  readonly countCapacityCalendar: number;
  readonly page: number;
  readonly perPage: number;
  readonly onPageChanged: (page: number) => void;
}
const CapacityList: FC<CapacityListProps> = ({
  locale,
  capacityCalendar,
  countCapacityCalendar,
  page,
  perPage,
  onPageChanged,
}) => {
  const itemsPerRow = useCallback(() => 1, []);
  const estimatedItemHeight = useCallback(() => ITEM_HEIGHT, []);

  const data = useMemo(() => capacityCalendar ?? { [page]: new Array(perPage) }, [capacityCalendar, page, perPage]);

  const capacityListItem: CapacityListItemFunction = useCallback(
    (index, capacityCalendar) =>
      capacityCalendar ? (
        capacityCalendar.date ? (
          <CapacityListItem key={index} capacityCalendar={capacityCalendar} locale={locale} />
        ) : (
          <CapacityListItemSkeleton key={index} />
        )
      ) : (
        <td key={index} />
      ),
    [locale],
  );

  const listRowStyleFn = useCallback(
    (data: [number, CapacityCalendarProjection][]) =>
      data[0][1].boxes && data[0][1].boxes.difference < 0 ? { backgroundColor: "#fc999f" } : {},
    [],
  );

  return (
    <VirtualizedList
      className={{ virtualizedList: "capacity-list", listRow: "capacity-list__row" }}
      data={data}
      estimatedItemHeight={estimatedItemHeight}
      itemCount={countCapacityCalendar}
      itemsPerRow={itemsPerRow}
      listRowStyleFn={listRowStyleFn}
      page={page}
      perPage={perPage}
      tagName={{
        innerElementTagName: "tbody",
        listRow: "tr",
        outerElementTagName: "table",
      }}
      onPageChanged={onPageChanged}
    >
      {capacityListItem}
    </VirtualizedList>
  );
};

export { CapacityList };
