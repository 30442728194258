import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { CheckoutProjection } from "./checkout";

const LIST_CHECKOUTS_BY_PERSONAL_SHOPPER_ID = "list_checkouts_by_personal_shopper_id";

interface ListCheckoutsByPersonalShopperIdPayload {
  readonly personalShopperId: string;
  readonly page: number;
  readonly perPage: number;
}

interface ListCheckoutsByPersonalShopperId
  extends Query<typeof LIST_CHECKOUTS_BY_PERSONAL_SHOPPER_ID>,
    ListCheckoutsByPersonalShopperIdPayload {}

interface ListCheckoutsByPersonalShopperIdFunction {
  (payload: ListCheckoutsByPersonalShopperIdPayload): ListCheckoutsByPersonalShopperId;
}

const listCheckoutsByPersonalShopperId: ListCheckoutsByPersonalShopperIdFunction = (payload) => ({
  ...query({ name: LIST_CHECKOUTS_BY_PERSONAL_SHOPPER_ID }),
  ...payload,
});

type ListCheckoutsByPersonalShopperIdResult = CheckoutProjection[] | null;

interface CheckoutsListByPersonalShopperIdViewArgs extends CancelableQueryViewArgs {
  readonly personalShopperId: string;
  readonly page: number;
  readonly perPage: number;
}

interface CheckoutsListByPersonalShopperIdView {
  (args: CheckoutsListByPersonalShopperIdViewArgs): Promise<ListCheckoutsByPersonalShopperIdResult>;
}

interface ListCheckoutsByPersonalShopperIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: CheckoutsListByPersonalShopperIdView;
}

const listCheckoutsByPersonalShopperIdHandler: QueryHandlerFunction<
  ListCheckoutsByPersonalShopperId,
  ListCheckoutsByPersonalShopperIdResult,
  ListCheckoutsByPersonalShopperIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ personalShopperId, page, perPage }) =>
    view({ personalShopperId, page, perPage, signal });

export type {
  CheckoutsListByPersonalShopperIdView,
  ListCheckoutsByPersonalShopperId,
  ListCheckoutsByPersonalShopperIdResult,
};
export {
  LIST_CHECKOUTS_BY_PERSONAL_SHOPPER_ID,
  listCheckoutsByPersonalShopperId,
  listCheckoutsByPersonalShopperIdHandler,
};
