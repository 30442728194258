import { FC } from "react";
import Price from "../../../../../projection/price/model/Price";
import Text, { TextVariant } from "../../../../../shared/ui/uiKit/components/atoms/text/Text";
import "./sales-tag.css";

type SalesTagProps = {
  readonly price: Price;
};
const SalesTag: FC<SalesTagProps> = ({ price }: SalesTagProps) => {
  const isDiscounted = price.discountedPrice?.percentage !== 0;

  return isDiscounted ? (
    <Text className="sales-tag" variant={TextVariant.BODY_BOLD}>{`-${price.discountedPrice?.percentage}%`}</Text>
  ) : null;
};

export default SalesTag;
