import { FC, ReactNode } from "react";
import Text, { TextVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import VirtualizedList from "../../../../../../../../shared/ui/uiKit/components/layouts/virtualizedList/VirtualizedList";
import { LookAndLikeFeedbackProjection } from "../../../../../../../../projection/lookAndLikeFeedback/lookAndLikeFeedback";
import "./look-and-like-feedbacks-list.css";

const ITEM_MIN_WIDTH = 100;
const ITEM_ASPECT_RATIO = 3 / 2;
const itemsPerRow = (listWidth: number): number => Math.floor(listWidth / ITEM_MIN_WIDTH) || 1;
const estimatedItemHeight = (listWidth: number): number => (listWidth * ITEM_ASPECT_RATIO) / itemsPerRow(listWidth);

interface LookAndLikeFeedbacksListProps {
  readonly title: ReactNode;
  readonly children: (index: number, feedback: LookAndLikeFeedbackProjection | null) => ReactNode;
  readonly feedbacks: Record<number, LookAndLikeFeedbackProjection[]>;
  readonly itemCount: number;
  readonly page: number;
  readonly perPage: number;
  readonly onPageChanged: (page: number) => void;
  readonly scrollElement: Element | null;
}

const LookAndLikeFeedbacksList: FC<LookAndLikeFeedbacksListProps> = ({
  title,
  children,
  feedbacks,
  itemCount,
  page,
  perPage,
  onPageChanged,
  scrollElement,
}) => (
  <section className="look-and-like-feedbacks-list">
    <Text className="look-and-like-feedbacks-list__title" variant={TextVariant.HEADING_3}>
      {title}
    </Text>
    <VirtualizedList
      data={feedbacks}
      estimatedItemHeight={estimatedItemHeight}
      itemCount={itemCount}
      itemsPerRow={itemsPerRow}
      page={page}
      perPage={perPage}
      scrollElement={scrollElement}
      onPageChanged={onPageChanged}
    >
      {children}
    </VirtualizedList>
  </section>
);

export default LookAndLikeFeedbacksList;
