import { AutomaticSelectionStatus, useAutomaticSelection } from "../../../../hooks/useAutomaticSelection";
import { useIsAutomaticSelectionEnabled } from "../../../../../projection/selection/react/useIsAutomaticSelectionEnabled";
import { useViewUiSettingByKey } from "../../../../../../shared/ui-settings/infrastructure/projection/react/useViewUiSettingByKey";
import FeatureToggles from "../../../../featureToggle/FeatureToggles";
import useFeatureToggle from "../../../../../../shared/featureToggle/useFeatureToggle";
import useIsFeatureToggleEnabled from "../../../../../../shared/featureToggle/useIsFeatureToggleEnabled";
import { MESSAGING_CONTEXT_ID } from "../../../../../../bootstrap";

interface UseIsAutomaticSelectionTypeformActiveFunctionArgs {
  readonly personalShopperId?: string;
  readonly uiSettingKey: string;
}

interface UseIsAutomaticSelectionTypeformActiveFunction {
  (args: UseIsAutomaticSelectionTypeformActiveFunctionArgs): boolean;
}

const useIsAutomaticSelectionTypeformActive: UseIsAutomaticSelectionTypeformActiveFunction = ({
  personalShopperId,
  uiSettingKey,
}) => {
  const [automaticSelectionEnabled] = useIsAutomaticSelectionEnabled({ psId: personalShopperId });
  const { automaticSelectionStatus } = useAutomaticSelection();

  const automaticSelectionTypeformFeatureToggle = useFeatureToggle(FeatureToggles.AUTOMATIC_SELECTION_TYPEFORM);
  const automaticSelectionTypeformFeatureToggleEnabled = useIsFeatureToggleEnabled({
    featureToggle: automaticSelectionTypeformFeatureToggle,
  });

  const [uiSetting] = useViewUiSettingByKey({ contextId: MESSAGING_CONTEXT_ID, key: uiSettingKey });

  return Boolean(
    !uiSetting &&
      automaticSelectionEnabled &&
      automaticSelectionTypeformFeatureToggleEnabled &&
      automaticSelectionStatus === AutomaticSelectionStatus.FINISHED,
  );
};

export { useIsAutomaticSelectionTypeformActive };
