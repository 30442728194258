import { useState } from "react";
import { FC } from "react";
import RootLoader from "../../components/atoms/rootLoader/RootLoader";
import { RealTimeBoxProduction as RealTimeBoxProductionTemplate } from "./components/realTimeBoxProduction/RealTimeBoxProduction";
import { useViewPendingBoxes } from "../../../../infrastructure/projection/pendingBox/react/useViewPendingBoxes";
import { QueryStatus } from "@lookiero/messaging-react";
import { PersonalShopperCountryProjection } from "../../../../projection/personalShopperCountry/personalShopperCountry";
import { useListPersonalShopperCountries } from "../../../../infrastructure/projection/personalShopperCountry/react/useListPersonalShopperCountries";
import { useListPersonalShopperTeams } from "../../../../infrastructure/projection/personalShopperTeam/react/useListPersonalShopperTeams";
import { useViewPersonalShopperProductionByCriteria } from "../../../../infrastructure/projection/personalShopperProduction/react/useViewPersonalShopperProductionByCriteria";

const RealTimeBoxProduction: FC = () => {
  const [personalShopperTeams, personalShopperTeamsStatus] = useListPersonalShopperTeams();
  const [personalShopperCountries, personalShopperCountriesStatus] = useListPersonalShopperCountries();
  const [pendingBoxes, pendingBoxesStatus] = useViewPendingBoxes();

  const [personalShopperTeamSelected, setPersonalShopperTeamSelected] = useState<string>();
  const [personalShopperCountrySelected, setPersonalShopperCountrySelected] =
    useState<PersonalShopperCountryProjection>();

  const [personalShopperProduction, personalShopperProductionStatus] = useViewPersonalShopperProductionByCriteria({
    country: personalShopperCountrySelected,
    team: personalShopperTeamSelected,
  });

  const isLoading =
    personalShopperTeamsStatus === QueryStatus.LOADING ||
    personalShopperCountriesStatus === QueryStatus.LOADING ||
    pendingBoxesStatus === QueryStatus.LOADING ||
    personalShopperProductionStatus === QueryStatus.LOADING;

  if (isLoading) {
    return <RootLoader />;
  }

  return (
    <RealTimeBoxProductionTemplate
      pendingBoxes={pendingBoxes}
      personalShopperCountries={personalShopperCountries}
      personalShopperCountrySelected={personalShopperCountrySelected}
      personalShopperProduction={personalShopperProduction}
      personalShopperTeamSelected={personalShopperTeamSelected}
      personalShopperTeams={personalShopperTeams}
      onPersonalShopperCountrySelectedChanged={setPersonalShopperCountrySelected}
      onPersonalShopperTeamSelectedChanged={setPersonalShopperTeamSelected}
    />
  );
};

export default RealTimeBoxProduction;
