import { useCallback, useMemo } from "react";
import { I18nMessage } from "@lookiero/i18n-react";
import ButtonOption, {
  ButtonOptionState,
} from "../../../../../../../../shared/ui/uiKit/components/atoms/buttonOption/ButtonOption";
import { activate, deactivate, isAnyLeafActive } from "../../../ShortlistFilterState";
import { ShortlistFilterItemHOC } from "../../ShortlistFilterItem";
import classNames from "classnames";
import "./button-option-shortlist-filter-item.css";

const ButtonOptionShortlistFilterItem: ShortlistFilterItemHOC = ({ variant, useShortlistFiltering }) =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  function ButtonOptionShortlistFilterItemComponent({ filter, children }) {
    const { state, onChange } = useShortlistFiltering();

    const optionState = useMemo(
      () => (isAnyLeafActive({ filter, state }) ? ButtonOptionState.SELECTED : ButtonOptionState.DEFAULT),
      [filter, state],
    );

    const handleOnChange = useCallback(
      (checked: boolean) => onChange({ state: checked ? activate({ filter, state }) : deactivate({ filter, state }) }),
      [filter, onChange, state],
    );

    return (
      <>
        <ButtonOption
          label={<I18nMessage id={filter.translationKey} />}
          state={optionState}
          className={classNames(
            "button-option-shortlist-filter-item",
            `button-option-shortlist-filter-item--${variant}`,
          )}
          onChange={handleOnChange}
        />
        {children}
      </>
    );
  };

export default ButtonOptionShortlistFilterItem;
