import { camelcaseKeys } from "../../delivery/http/camelcaseKeys";
import {
  ProductVariantWithProductInfoByIdView,
  ViewProductVariantWithProductInfoByIdResult,
} from "../../../projection/productVariantWithProductInfo/viewProductVariantWithProductInfoById";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import {
  ProductVariantWithProductInfoDto,
  toProductVariantWithProductInfoProjection,
} from "./productVariantWithProductInfo";

interface HttpProductVariantWithProductInfoByIdView extends ProductVariantWithProductInfoByIdView {}

interface HttpProductVariantWithProductInfoByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpProductVariantWithProductInfoByIdViewFunction {
  (args: HttpProductVariantWithProductInfoByIdViewFunctionArgs): HttpProductVariantWithProductInfoByIdView;
}

interface ViewProductVariantWithProductInfoByIdResponse {
  readonly result: ProductVariantWithProductInfoDto;
}

const httpProductVariantWithProductInfoByIdView: HttpProductVariantWithProductInfoByIdViewFunction =
  ({ httpPost }) =>
  async ({ productVariantId, signal }) =>
    await httpPost<ViewProductVariantWithProductInfoByIdResponse, ViewProductVariantWithProductInfoByIdResult>({
      endpoint: "/view-product-variant-with-product-info-by-product-variant-id",
      body: {
        product_variant_id: productVariantId,
      },
      signal,
      result: {
        error: null,
        success: (response) => toProductVariantWithProductInfoProjection(camelcaseKeys(response.result)),
      },
    });

export { httpProductVariantWithProductInfoByIdView };
