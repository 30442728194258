import {
  ListSelectionAlertsByBoxNumberResult,
  SelectionAlertsByBoxNumberView,
} from "../../../projection/alert/listSelectionAlertsByBoxNumber";
import { camelcaseKeys } from "../../delivery/http/camelcaseKeys";
import { HttpPostFunction } from "../../delivery/http/httpClient";
import { SelectionAlertDto } from "./alert";

interface HttpSelectionAlertsByBoxNumberView extends SelectionAlertsByBoxNumberView {}

interface HttpSelectionAlertsByBoxNumberViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpSelectionAlertsByBoxNumberViewFunction {
  (args: HttpSelectionAlertsByBoxNumberViewFunctionArgs): HttpSelectionAlertsByBoxNumberView;
}

interface ViewSelectionAlertsByBoxNumberResponse {
  readonly result: SelectionAlertDto[];
}

const httpSelectionAlertsByBoxNumberView: HttpSelectionAlertsByBoxNumberViewFunction =
  ({ httpPost }) =>
  async ({ boxNumber, signal }) =>
    await httpPost<ViewSelectionAlertsByBoxNumberResponse, ListSelectionAlertsByBoxNumberResult>({
      endpoint: "/list-selection-alerts-by-box-number",
      body: { box_number: boxNumber },
      signal,
      result: {
        error: null,
        success: (response) => camelcaseKeys(response.result),
      },
    });

export { httpSelectionAlertsByBoxNumberView };
