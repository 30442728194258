import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import invariant from "ts-invariant";
import {
  bestProductVariantByScoreAndSeason,
  ProductVariantProjection,
} from "../../../projection/product/productVariant";
import { ProductProjection } from "../../../projection/product/product";

type ProductVariantSelection = Record<string, string | undefined>; // productId, productVariantId;
type SetSelectedProductVariantParameters = {
  readonly productId: string;
  readonly productVariantId: string | undefined;
};
type ProductVariantSelectionContextApi = {
  readonly productVariantSelection: ProductVariantSelection;
  readonly setSelectedProductVariant: ({ productId, productVariantId }: SetSelectedProductVariantParameters) => void;
};
const ProductVariantSelectionContext = createContext<ProductVariantSelectionContextApi>(
  null as unknown as ProductVariantSelectionContextApi,
);

type ProductVariantSelectionProviderProps = {
  readonly children: ReactNode;
};

const ProductVariantSelectionProvider: FC<ProductVariantSelectionProviderProps> = ({
  children,
}: ProductVariantSelectionProviderProps) => {
  const [productVariantSelection, setProductVariantSelection] = useState<ProductVariantSelection>({});

  const setSelectedProductVariant = useCallback(
    ({ productId, productVariantId }: SetSelectedProductVariantParameters) =>
      setProductVariantSelection((prevProductVariantSelection) => ({
        ...prevProductVariantSelection,
        [productId]: productVariantId,
      })),
    [],
  );

  const value: ProductVariantSelectionContextApi = useMemo(
    () => ({ productVariantSelection, setSelectedProductVariant }),
    [productVariantSelection, setSelectedProductVariant],
  );

  return <ProductVariantSelectionContext.Provider value={value}>{children}</ProductVariantSelectionContext.Provider>;
};

const getBestProductVariant = <PV extends ProductVariantProjection>(
  productVariants: PV[],
  filter?: (productVariants: PV[]) => PV[],
) => bestProductVariantByScoreAndSeason(filter ? filter(productVariants) : productVariants);

type UseProductVariantSelectionParameters<PV extends ProductVariantProjection> = {
  readonly selectionProductVariant?: PV;
  readonly product?: ProductProjection<PV>;
  readonly filter?: (productVariants: PV[]) => PV[];
};
type UseProductVariantSelectionReturn<PV extends ProductVariantProjection> = [
  productVariant: PV,
  setProductVariant: (productVariant: PV | undefined) => void,
];
const useProductVariantSelection = <PV extends ProductVariantProjection>({
  selectionProductVariant,
  product = { productVariants: [] as PV[] } as ProductProjection<PV>,
  filter,
}: UseProductVariantSelectionParameters<PV>): UseProductVariantSelectionReturn<PV> => {
  const productVariantSelectionContext = useContext<ProductVariantSelectionContextApi>(ProductVariantSelectionContext);

  invariant(
    productVariantSelectionContext,
    "Your are trying to use the useProductVariantSelection hook without wrapping your app with the <ProductVariantSelectionProvider>.",
  );

  const { productVariantSelection, setSelectedProductVariant } = productVariantSelectionContext;

  const setProductVariant = useCallback(
    (productVariant: PV | undefined) =>
      setSelectedProductVariant({ productId: product.id, productVariantId: productVariant?.id }),
    [product.id, setSelectedProductVariant],
  );

  const selectedProductVariantRef = useRef<PV>();
  const selectedProductVariant =
    selectionProductVariant ||
    (productVariantSelection[product.id] &&
      product.productVariants.find((productVariant) => productVariant.id === productVariantSelection[product.id])) ||
    getBestProductVariant(product.productVariants, filter);

  useEffect(() => {
    if (selectedProductVariantRef.current === selectedProductVariant) {
      return;
    }

    selectedProductVariantRef.current = selectedProductVariant;
    setProductVariant(selectedProductVariant);
  }, [product.id, selectedProductVariant, setProductVariant]);

  return [selectedProductVariant, setProductVariant];
};

export { ProductVariantSelectionProvider };

export default useProductVariantSelection;
