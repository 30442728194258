import { generatePath } from "react-router-dom";
import { Routes } from "./Routing";

interface BoxUrlFunctionArgs {
  readonly locale: string;
  readonly boxNumber: string;
}
interface BoxUrlFunction {
  (args: BoxUrlFunctionArgs): string;
}

const boxUrl: BoxUrlFunction = ({ locale, boxNumber }) =>
  window.location !== window.parent.location
    ? document.referrer.concat(`box/${boxNumber}`)
    : generatePath(Routes.BOX, { locale, box: boxNumber });

export { boxUrl };
