import { useMemo } from "react";
import { MessageDescriptor } from "react-intl";
import { ShortlistFilterProjection } from "../../../../../../../projection/shortlistFilter/shortlistFilter";

const MIN_CHARACTERS = 2;

const filterMatchesName = (
  filter: ShortlistFilterProjection,
  name: string,
  formatMessage: (descriptor: MessageDescriptor) => string,
): boolean => formatMessage({ id: filter.translationKey }).toLowerCase().trim().includes(name.toLowerCase().trim());

const recursivelyFindShortlistFiltersMatchingName = (
  filter: ShortlistFilterProjection,
  name: string,
  formatMessage: (descriptor: MessageDescriptor) => string,
): ShortlistFilterProjection[] => {
  const matchingFilters: ShortlistFilterProjection[] = [];

  if (filterMatchesName(filter, name, formatMessage)) {
    matchingFilters.push(filter);
  }

  if (!filter.children) {
    return matchingFilters;
  }

  return filter.children.reduce((acc, childFilter): ShortlistFilterProjection[] => {
    const matchedFilters = recursivelyFindShortlistFiltersMatchingName(childFilter, name, formatMessage);

    return [...acc, ...matchedFilters];
  }, matchingFilters);
};

type UseSearchShortlistFiltersRecursivelyForNamePartialParams = {
  readonly filter: ShortlistFilterProjection;
  readonly name: string;
  readonly formatMessage: (descriptor: MessageDescriptor) => string;
};

const useSearchShortlistFiltersRecursivelyForNamePartial = ({
  filter,
  name,
  formatMessage,
}: UseSearchShortlistFiltersRecursivelyForNamePartialParams): ShortlistFilterProjection[] => {
  const matching = useMemo(
    () =>
      name.length >= MIN_CHARACTERS ? recursivelyFindShortlistFiltersMatchingName(filter, name, formatMessage) : [],
    [filter, formatMessage, name],
  );

  return matching;
};

export default useSearchShortlistFiltersRecursivelyForNamePartial;
