import { FC, memo, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { I18nMessage } from "@lookiero/i18n-react";
import { isEqual } from "lodash";
import useEnvironment from "../../../../hooks/useEnvironment";
import { ShortlistProductProjection } from "../../../../../../projection/shortlist/shortlistProduct";
import ShortlistProductPreview from "../shortlistProductPreview/ShortlistProductPreview";
import ProductPreviewSkeleton from "../../../../components/organisms/productPreview/ProductPreviewSkeleton";
import ShortlistProductsList from "../shortlistProductsList/ShortlistProductsList";
import ShortlistFiltersI18n, { SHORTLIST_FILTERS_I18N_PREFIX } from "../../../../i18n/ShortlistFiltersI18n";
import Text, { TextVariant } from "../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import { useShortlistFilteringPositives } from "../../../../components/organisms/shortlistFilters/shortlistFilterItem/hooks/useShortlistFilteringPositives";
import { useCountShortlistProductsByShortlistFilters } from "../../../../../../infrastructure/projection/shortlist/react/useCountShortlistProductsByShortlistFilters";
import { useSearchShortlistProductsByShortlistFilters } from "../../../../../../infrastructure/projection/shortlist/react/useSearchShortlistProductsByShortlistFilters";
import { QueryStatus } from "@lookiero/messaging-react";
import { useSearchFavouritesByPsId } from "../../../../../../infrastructure/projection/favourite/react/useSearchFavouritesByPsId";
import { useViewFilteringByCustomerId } from "../../../../../../infrastructure/projection/filtering/react/useViewFilteringByCustomerId";
import useBoxSharedContext from "../../../../hooks/useBoxSharedContext";
import { useViewPersonalShopper } from "../../../../../../infrastructure/projection/personalShopper/react/useViewPersonalShopper";
import { useTrackShortlistPageView } from "../../../../../tracking/useTrackShortlistPageView";
import { TrackingPage } from "../../../../../tracking/Tracker";
import "./shortlist-products.css";

interface ShortlistProductsProps {
  readonly boxNumber: string;
  readonly search?: string;
  readonly favourites?: boolean;
  readonly page: number;
  readonly onPageChanged: (page: number) => void;
}
const ShortlistProducts: FC<ShortlistProductsProps> = ({ boxNumber, search, favourites, page, onPageChanged }) => {
  const { box } = useBoxSharedContext();
  const shortlistProductsRef = useRef<HTMLDivElement>(null);
  const { state: positives } = useShortlistFilteringPositives();
  const [filtering] = useViewFilteringByCustomerId({ customerId: box.customerId });
  const { locale } = useParams();
  const {
    shortlist: { perPage },
  } = useEnvironment();
  const [personalShopper] = useViewPersonalShopper();

  /*
   We need to render this hook at this level so the projection gets invalidated.

   It's actually being used by useFavourites hook.
   (but it's unmounted when ShortlistProductPreview component is unmounted)
  */
  useSearchFavouritesByPsId({ psId: personalShopper?.id });

  const [productsCount, productsCountStatus] = useCountShortlistProductsByShortlistFilters({
    psId: personalShopper?.id,
    boxNumber,
    search,
    favourites,
    positives,
    negatives: filtering?.negatives || [],
    itemCountWhileQuerying: perPage,
  }) as [number, QueryStatus];
  const [products] = useSearchShortlistProductsByShortlistFilters({
    psId: personalShopper?.id,
    boxNumber,
    search,
    favourites,
    positives,
    negatives: filtering?.negatives || [],
    page,
    perPage,
  }) as [Record<number, ShortlistProductProjection[]>, QueryStatus];

  const trackShortlistPageView = useTrackShortlistPageView({
    boxId: box.id,
    userId: box.customerId,
    psId: personalShopper?.id,
    section: TrackingPage.CATALOG,
    perPage,
  });
  useEffect(() => {
    if (productsCountStatus !== QueryStatus.SUCCESS) {
      return;
    }

    trackShortlistPageView({ page, count: productsCount });
  }, [page, productsCount, productsCountStatus, trackShortlistPageView]);

  // Reset scroll position when shortlistFilters change
  const positivesRef = useRef(positives);
  useEffect(() => {
    if (isEqual(positivesRef.current, positives)) {
      return;
    }

    positivesRef.current = positives;
    shortlistProductsRef.current?.scrollTo(0, 0);
  }, [positives]);

  const productItem = useCallback(
    (index: number, product: ShortlistProductProjection | null): ReactNode =>
      product ? (
        product.id ? (
          <ShortlistProductPreview key={product.id} locale={locale as string} position={index} product={product} />
        ) : (
          <ProductPreviewSkeleton key={index} />
        )
      ) : (
        // Empty placeholder to fill the space
        <div key={index} />
      ),
    [locale],
  );

  const [scrollElement, setScrollElement] = useState<HTMLDivElement>();
  useEffect(() => {
    if (!shortlistProductsRef.current) {
      return;
    }

    setScrollElement(shortlistProductsRef.current);
  }, []);

  return (
    <section ref={shortlistProductsRef} className="shortlist-products">
      {productsCount > 0 && scrollElement ? (
        <ShortlistProductsList
          itemCount={productsCount}
          page={page}
          perPage={perPage}
          products={products}
          scrollElement={scrollElement}
          onPageChanged={onPageChanged}
        >
          {productItem}
        </ShortlistProductsList>
      ) : (
        <Text className="shortlist-products__no-results" tag="p" variant={TextVariant.BODY_SMALL}>
          <I18nMessage id={ShortlistFiltersI18n.NO_PRODUCT_RESULTS} prefix={SHORTLIST_FILTERS_I18N_PREFIX} />
        </Text>
      )}
    </section>
  );
};

const areEqual = (
  { boxNumber, page, onPageChanged }: ShortlistProductsProps,
  { boxNumber: nextBoxNumber, page: nextPage, onPageChanged: nextOnPageChanged }: ShortlistProductsProps,
): boolean => boxNumber === nextBoxNumber && page === nextPage && onPageChanged === nextOnPageChanged;
export const MemoizedShortlistProducts = memo(ShortlistProducts, areEqual);

export default ShortlistProducts;
