import { FC, ReactNode } from "react";
import Button, { ButtonVariant } from "../../../../../../shared/ui/uiKit/components/atoms/button/Button";
import { IconVariant } from "../../../../../../shared/ui/uiKit/components/atoms/icon/Icon";
import Tooltip from "../../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import useCaptureAndStopEvent from "../../../../../../shared/ui/uiKit/hooks/useCaptureAndStopEvent";
import { TooltipProps as MaterialUiTooltipProps } from "@material-ui/core";
import "./editor-toolbar-button.css";

type TooltipProps = Pick<
  MaterialUiTooltipProps,
  "placement" | "arrow" | "open" | "onOpen" | "onClose" | "classes" | "className"
>;

interface EditorToolbarButtonProps {
  readonly icon: IconVariant;
  readonly label?: string;
  readonly title: ReactNode;
  readonly active?: boolean;
  readonly disabled?: boolean;
  readonly tooltip?: TooltipProps;
  readonly onClick: () => void;
}
const EditorToolbarButton: FC<EditorToolbarButtonProps> = ({
  icon,
  label,
  title,
  active,
  tooltip,
  disabled,
  onClick,
}) => {
  /**
   * It's mandatory to handle the onMouseDown instead of the onClick (and preventDefault())
   * for the Editor to not loosing its focus/selection state
   */
  const handleOnMouseDown = useCaptureAndStopEvent(onClick);

  return (
    <span className="editor-toolbar-button">
      <Tooltip title={title} {...tooltip}>
        <Button
          aria-label={label}
          disabled={disabled}
          icon={icon}
          variant={active ? ButtonVariant.PRIMARY : ButtonVariant.BASIC}
          onMouseDown={handleOnMouseDown}
        />
      </Tooltip>
    </span>
  );
};

export default EditorToolbarButton;
