import { useMemo } from "react";
import { LookProjection } from "../../../../projection/looks/look";
import { MESSAGING_CONTEXT_ID } from "../../../../bootstrap";
import { listLooksBySelectionId } from "../../../../projection/looks/listLooksBySelectionId";
import { QueryStatus, useQuery } from "@lookiero/messaging-react";
import { ProductVariantProjection } from "../../../../projection/product/productVariant";
import { SelectionProjection } from "../../../../projection/selection/selection";
import { isLooksSet } from "../../../../domain/look/model/looksSet";

type UseListLooksForSelectionResult = [projection: LookProjection[], status: QueryStatus];

interface UseListLooksForSelectionFunctionArgs {
  readonly selection: SelectionProjection | undefined;
}

interface UseListLooksForSelectionFunction {
  (args: UseListLooksForSelectionFunctionArgs): UseListLooksForSelectionResult;
}

const useListLooksForSelection: UseListLooksForSelectionFunction = ({ selection }) => {
  const [looks = [], status] = useQuery<LookProjection[]>({
    contextId: MESSAGING_CONTEXT_ID,
    query: listLooksBySelectionId({ selectionId: selection?.selectionId as string }),
    options: {
      staleTime: Infinity,
      enabled: Boolean(selection?.selectionId),
    },
    invalidation: isLooksSet,
  });

  const selectionProductVariants = useMemo(
    () => selection?.products.map((p) => p.productVariants.find((pv) => pv.isSelected) as ProductVariantProjection),
    [selection?.products],
  );

  /**
   * Filter out invalid looks that they don't match the actual selection
   */
  const sanitizedLooks = looks?.reduce((accLooks: LookProjection[], look: LookProjection) => {
    const notFoundProductVariantWithinSelection = look.some(
      (pvId) => !Boolean(selectionProductVariants?.find((spv) => spv.id === pvId)),
    );

    return notFoundProductVariantWithinSelection ? accLooks : [...accLooks, look];
  }, [] as LookProjection[]);

  return [sanitizedLooks, status];
};

export { useListLooksForSelection };
