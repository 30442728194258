import { FC } from "react";
import { useIntl } from "react-intl";
import Locale from "../../../../../domain/country/model/Locale";
import { kgsToImperialWeight } from "./units";

interface WeightProps {
  readonly weight: number;
}
const Weight: FC<WeightProps> = ({ weight }) => {
  const { locale } = useIntl();

  const { pounds, stones } = kgsToImperialWeight({ kgs: weight });

  const weightFormtted = locale === Locale.en_GB ? `${stones} st ${pounds} lb` : `${weight} kg`;

  return <>{weightFormtted}</>;
};

export { Weight };
