import { useI18nMessage } from "@lookiero/i18n-react";
import { FC } from "react";
import Text, { TextVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/text/Text";
import Tooltip from "../../../../../../../../shared/ui/uiKit/components/atoms/tooltip/Tooltip";
import ActivityTimeI18n, { ACTIVITY_TIME_I18N_PREFIX } from "../../../../../../i18n/ActivityTimeI18n";
import Badge, { BadgeVariant } from "../../../../../../../../shared/ui/uiKit/components/atoms/badge/Badge";
import classNames from "classnames";
import "./activity-time-counter.css";

const toHoursMinutesSeconds = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // format as HH:MM:SS || MM:SS
  const formattedHours = padTo2Digits(hours);

  return `${formattedHours !== "00" ? `${formattedHours}:` : ""}${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

const padTo2Digits = (num: number) => num.toString().padStart(2, "0");

interface ActivityTimeCounterProps {
  readonly elapsedTime: number;
  readonly previewedElapsedTime: number | null;
  readonly actionTime: number | undefined;
  readonly enabled: boolean;
}

const ActivityTimeCounter: FC<ActivityTimeCounterProps> = ({
  elapsedTime,
  previewedElapsedTime,
  actionTime,
  enabled,
}) => {
  const activityTimeDescription = useI18nMessage({
    id: ActivityTimeI18n.DESCRIPTION,
    prefix: ACTIVITY_TIME_I18N_PREFIX,
  });
  const actionTimeTitle = useI18nMessage({ id: ActivityTimeI18n.ACTION_TIME, prefix: ACTIVITY_TIME_I18N_PREFIX });

  return (
    <div
      aria-label="activity-time-counter"
      className={classNames("activity-time-counter", { "activity-time-counter--enabled": enabled })}
    >
      <Tooltip title={activityTimeDescription}>
        <div aria-label="activity-time-counter-content" className="activity-time-counter__content">
          {previewedElapsedTime !== null && actionTime && (
            <div className="activity-time-counter__action-time">
              <Text className="activity-time-counter__action-time-title" variant={TextVariant.BODY_SMALL}>
                {actionTimeTitle}
              </Text>
              <Text variant={TextVariant.BODY_BOLD}>{toHoursMinutesSeconds(previewedElapsedTime)}</Text>
              <Text variant={TextVariant.BODY_BOLD}>/</Text>
              <Text variant={TextVariant.BODY_BOLD}>{toHoursMinutesSeconds(actionTime)}</Text>
            </div>
          )}
          <Badge variant={BadgeVariant.BASIC}>
            <Text>{toHoursMinutesSeconds(elapsedTime)}</Text>
          </Badge>
        </div>
      </Tooltip>
    </div>
  );
};

export { toHoursMinutesSeconds, ActivityTimeCounter };
